import React, { useEffect, useRef, useState } from 'react';
import { PieChart, Pie, Sector, Cell, Tooltip } from 'recharts';
import calcPercent from '../utils/calcPercent.js';

const TotalBalancePieChart = ({ application, currentAccount, totalBalance }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const toCurrency = (value) => {
    return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  const formatter = (payload) => {
    return toCurrency(payload?.value);
  }

  const CustomTooltip = ({ active, payload, label, value }) => {
    if (active && payload && payload.length) {
      return (
        application !== 0 && currentAccount !== 0 ?
          <span className="label" style={{ color: 'white', fontWeight: 500 }}>
            {`${payload[0].payload.name}: ${formatter(payload[0])}`}
          </span> : <span className="label" style={{ color: 'white', fontWeight: 500 }}>
          Sem dados
          </span> 
      );
    }
    return null;
  };


  const data = [
    { name: 'Aplicação', value: application || 0 },
    { name: 'Conta Corrente', value: Math.abs(currentAccount) || 100 },
  ];

  const onPieEnter = () => (_, index) => {
      setActiveIndex(index)
  };

  const COLORS = ["#FFFFFF", "#0a7cb4"];

  return (
      <PieChart width={125} height={150}>
          <Pie
            activeIndex={activeIndex}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={30}
            outerRadius={50}
            fill="#fff"
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
        </Pie>
        <Tooltip position={{y: -25}}  content={<CustomTooltip />} />
      </PieChart> 
  );
};

export default TotalBalancePieChart;

