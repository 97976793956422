import React, { Children } from "react";
import PropTypes from "prop-types";
import Fuse from "fuse.js";
import {
  SearchField,
  ApplyButton,
  WhiteBox,
  ParamTitle,
  LImage,
  SelectedRow,
  ValueLabel,
  BlockTitle,
  GreyButton,
  CircleButton,
  Tabs,
  Check,
  Tab
} from "../../styled_components/balance_sheets.js";
import { BlueButton } from "../../styled_components/default.js";
import LUrl from "L.png";
import LoadingDialog from './../LoadingDialog'
import Comment from './../Comment'

var formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});

class Edit extends React.Component {

  constructor(props) {
    super(props);
    this.check = {}
    this.state = {
      query: "",
      tabActive: this.validate_items() ? "receitas" : 'Operacional',
      paramsQuery: "",
      pattern: { contabil_pattern_params: { data: [] } },
      configuredPattern: {},
      sheet_selected: [],
      treeLabelList: {},
      sumResult: {},
      param_selected: null,
      stage_text: "Concluir",
      selectedVisibility: this.props.not_parametrized_only ? false : true,
      optionalBranchEnd: {},
      rootChangeEnabled: false,
      loading: true,
      headerItems: []
    };
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.handleTurbolinksBeforeVisit = this.handleTurbolinksBeforeVisit.bind(this);
  }

  validate_items(){
    var url_atual = window.location.pathname;
    if(url_atual.toString() === "/contabil_pattern/configure" ){
          return true;
    }else{
        return false;
    }
  }

  handleBeforeUnload(event) {
    if (!this.state.saving) {
      const message = 'Realmente deseja sair sem concluir?';
      event.returnValue = message;
      return message;
    }
  }

  handleTurbolinksBeforeVisit(event) {
    if (!this.state.saving) {
      const message = 'Realmente deseja sair sem concluir?';
      if (!window.confirm(message)) {
        event.preventDefault();
      }
    }
  }

  save(balanceCheck) {
    this.saveParamPatterns();

    {/*if(balanceCheck == 0){
      this.saveParamPatterns();
    }else{
      let acceptInvalid = confirm("O resultado da parametrização não é valido, deseja salvar mesmo assim?")
      if (acceptInvalid) {
        this.saveParamPatterns();
      }
    }*/}

  }

  saveParamPatterns() {
    this.setState({ saving: true, stage_text: "Salvando..." });
    const { configuredPattern } = this.state;

    axios
      .post("/configured_patterns.json", { configured_patterns: configuredPattern, balance_sheet_id: this.props.balance_sheet_id })
      .then(result => {
        this.setState({
          stage_text: "Concluído. Redirecionando..."
        });

        Turbolinks.visit(result.data.redirect_url);
      });
  }

  getSumForParameters() {

    const { configuredPattern } = this.state;
    let self = this;
    this.setState({sumLoading: true})
    axios
      .post(
        `/balance_sheets/${this.props.balance_sheet_id}/configured_patterns/sum`,
        {
          configured_patterns: configuredPattern
        }
      )
      .then(result => {
        this.setState({ sumResult: result.data });
        let currentStage = this.state.tabActive;
        let stages = this.validate_items() ?  ["Operacional", "Não Operacional", "Exclusão"] : ["receitas", "despesas"]
        stages.map((stage)=>{
          self.setState({ tabActive: stage });
        })

        this.setState({
          tabActive: currentStage,
          loading: false,
          sumLoading: false
        })


      });
  }

  fetchConfiguredPatterns() {

    axios.get("/configured_patterns").then(result => {
      this.setState({ configuredPattern: result.data });
      if (!this.state.initialFetchForConfiguredPattern) {
        let array = Object.values(result.data);
        [...new Set(array)].map(key => {
          this.fetchTreeLabelFor(key);
        });
        this.setState({ initialFetchForConfiguredPattern: true });

      }
    });
  }

  fetchContabilPattern() {
    axios.get("/contabil_pattern/contabil_pattern_is_not_file_pattern").then(pattern => {
      //debugger
      this.setState({ pattern: pattern.data.data.attributes }, ()=>{
        this.getSumForParameters();
      });
      
    });
  }

  balanceCheck(){
    const {parents} = this.props
    const {sumResult} = this.state

    let balance = 0

    balance = Object.values(sumResult).reduce((a,b)=>{return a + b}, 0)


    return balance

  }
  fetchHeadersRender() {
    let self = this;
    axios
        .get(`/balance_sheets/${this.props.balance_sheet_id}/show_header_pattern_of_beads.json`)
        .then(result => {
          if (result.data) {
            self.setState({ headerItems: result.data});
          }

        });
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    document.addEventListener('turbolinks:before-visit', this.handleTurbolinksBeforeVisit);
    this.fetchConfiguredPatterns();
    this.fetchContabilPattern();
    this.setComments();
    this.fetchHeadersRender();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    document.removeEventListener('turbolinks:before-visit', this.handleTurbolinksBeforeVisit);
  }

  fetchTreeLabelFor(key) {
    if (!this.state.treeLabelList[key]) {
      this.setState(oldState => {
        oldState.treeLabelList[key] = "Carregando...";
        return { ...oldState };
      });
      axios.get(`/tree_label/${key}.json`).then(result => {
        this.setState(oldState => {
          oldState.treeLabelList[key] = result.data;
          return { ...oldState };
        });
      });
    }
  }

  sumForRow(row) {
    return this.state.sumResult[row.key] || 0;
  }

  paramCalc(ctp = {}) {
    let row = ctp.attributes;

    if (row.childreen && row.childreen.data.length > 0) {
      return row.childreen.data
        .map(child => this.paramCalc(child))
        .reduce((a, b) => a + b);
    } else {
      return this.sumForRow(row);
    }
  }

  setupParam() {
    let self = this;
    this.setState(oldState => {
      oldState.sheet_selected.map(code => {
        oldState.configuredPattern[code] = oldState.param_selected;

        self.fetchTreeLabelFor(oldState.param_selected);
      });
      oldState.sheet_selected = [];
      oldState.param_selected = null;

      return { ...oldState };
    });

  }

  isSetable() {
    return this.state.sheet_selected.length > 0 && this.state.param_selected;
  }

  toggleRecordSelected(code) {
    let self = this;
    if (this.isRecordSelected(code)) {
      this.setState(oldState => {
        oldState.sheet_selected = oldState.sheet_selected.filter(
          incode => incode != code
        );
        return { ...oldState };
      });
    } else {
      this.setState(oldState => {
        oldState.sheet_selected.push(code);
        return { ...oldState };
      });
    }
    setTimeout(()=>{
      self.checkVirtualizedConflict()
    }, 100)

  }

  isRecordSelected(code) {
    return this.state.sheet_selected.includes(code);
  }

  currencyFormat(ctp) {
    let floatValue = this.paramCalc(ctp);
    let value = formatter.format(this.paramCalc(ctp));

    if (ctp.attributes.head) {
        this.check[ctp.attributes.head] = floatValue
    }

    if (floatValue > 0) {
      return `${value} D`;
    } else if (floatValue < 0) {
      return `${value} C`;
    } else {
      return `${value}`;
    }



  }

  filterParamConnected(row){

    if (this.state.filteredRow != row){
      this.setState({filteredRow: row})
    }else{
      this.setState({filteredRow: null})
    }
    this.forceUpdate();
  }

  async setComments(){

    let comments = await axios.get('/comments.json', {params:{
      year: 0
    }})  
    
    
    this.setState({comments: comments.data})
  }

  checkVirtualizedConflict(){
    let {param_selected, sheet_selected} = this.state;

    if(param_selected && sheet_selected.length > 0){
      axios.get('/configured_patterns/check_virtualized_conflict', {params:{
        param_selected,
        sheet_selected
      }}).then((res)=>{
        if (res.data?.conflicts?.length > 0) {
          let conflict = `Conflitos na parametrização de outras filiais para o código ${res.data.code} \n\n\n`

          res.data.conflicts.map((conf)=>{
            conflict += `A empresa ${conf.company_name} fez a configuração no parâmetro: \n${conf.current_param}\n\n`
          })

          alert(conflict)
        }
      })
    }



  }

  setupSelectedParam(e){
    let self = this;
    this.setState({ param_selected: e.target.value })

    setTimeout(()=>{
      self.checkVirtualizedConflict()
    }, 100)

  }

  renderParams(contabil_pattern_params, level = 0, params={}) {
    let filtered = {};
    let data = []

    //debugger
    if(this.state.tabActive && !params.not_head){
      data = contabil_pattern_params.data.filter((row)=> row.attributes.label.toUpperCase() === this.state.tabActive.toUpperCase())
    }else{
      data = contabil_pattern_params.data
    }

    if (this.state.paramsQuery.length < 1) {
      filtered = {data};
    } else {
      var options = {
        shouldSort: true,
        threshold: 0.8,

        distance: 1000,
        maxPatternLength: 1500,
        minMatchCharLength: 3,
        keys: ["attributes.childreen_label", "attributes.tree_label"]
      };


      var fuse = new Fuse(data, options); // "list" is the item array
      filtered.data = fuse.search(this.state.paramsQuery);
    }




    let htmlRender = filtered.data.map(ctp => {
      let row = ctp.attributes;
      return (
        <div className={`${row == this.state.filteredRow ? "filteredRow" : ""} `}>
          <ParamTitle level={level} head={row.head} className={'comment-wrapper'}>
            {this.state.comments && <Comment
                  refreshComments={()=> this.setComments()}
                  comments={this.state.comments}
                  model_reference={'contabil_pattern'}
                  month={0}
                  year={0}
                  model_reference_id={row.key}>
                </Comment>}
            <div style={{ textAlign: "left" }}>
              {!row.head && <LImage src={LUrl}></LImage>}
              { row.is_tail &&
                <i
                onClick={()=> this.filterParamConnected(row)}
                style={{fontSize: 14, cursor: 'pointer'}}
                className={`fa ${row == this.state.filteredRow ? "fa-eye-slash" : "fa-eye"}`}
                ></i>}
              {!row.head && row.is_tail && (
                <span className="radio">
                  <label for="company_category_group_type">
                    <input
                      onChange={e =>
                        this.setupSelectedParam(e)

                      }
                      className="radio_buttons optional"
                      type="radio"
                      checked={row.key === this.state.param_selected}
                      value={row.key}
                      name="pattern_param"
                    />
                  </label>
                </span>
              )}

              {row.label}
            </div>
            {/*<ValueLabel>{this.currencyFormat(ctp)}</ValueLabel>*/}
            {/* <GreyButton className={"margin-left-10"}>
              <i className={"fa fa-eye"}></i>
            </GreyButton> */}
          </ParamTitle>
          <div id={"#childreen"}>
            {this.renderParams(row.childreen, level + 1, {not_head: true})}
          </div>
        </div>
      );
    });
    return htmlRender;
  }

  removeRecord(record){
    this.setState((oldState)=>{
      delete oldState.configuredPattern[record.attributes.code]
      return {...oldState}
    }, ()=>{
      this.getSumForParameters();
    })

    
  }


  recordIsSetted(record) {
    //debugger
    return this.state.configuredPattern[record.attributes.code]
  }

  recordIsSettedFor(record) {
    return this.state.configuredPattern[record.attributes.code];
  }

  treeLabel(code) {
    let key = this.state.configuredPattern[code];
    return this.state.treeLabelList[key];
  }

  toggleCheckness(record) {
    this.setState(oldState => {
      oldState.optionalBranchEnd[record.attributes.code] = !oldState
        .optionalBranchEnd[record.attributes.code];
      return { ...oldState };
    });
  }

  addedAsBranchEnd(record) {
    return this.state.optionalBranchEnd[record.attributes.code] == true;
  }

  renderRow(record) {
    if (this.recordIsSetted(record)) {
      if (this.state.selectedVisibility) {
        return (
          <SelectedRow>
            {

                Object.entries(record.attributes).map(([k,v]) =>{
                  <td style={{ color: "white" }}>{v} </td>

                })

            }

            <td style={{ color: "white", textAlign: "right", paddingRight: 20 }} colSpan={3}>{record.attributes.code} - {record.attributes["Descrição(Natureza)"]} - Destino - {this.treeLabel(record.attributes.code)}</td>
            <td>
              <div
                onClick={()=> this.removeRecord(record)}
                style={{borderRadius: 8,
                        backgroundColor: "white",
                        width: 80,
                        textAlign: "center",
                        margin: 16,
                        cursor: 'pointer',
                        textTransform: 'lowercase'}}>
                remover
              </div>
            </td>
          </SelectedRow>
        );
      } else {
        return null;
      }
    } else {

      //debugger
      return (
        <tr>
          <td>
            <div style={{ display: "flex" }}>
              {this.state.rootChangeEnabled && (
                <a onClick={() => this.toggleCheckness(record)}>
                  {record.attributes.is_branch_end &&
                    this.addedAsBranchEnd(record) && (
                      <i className={"margin-right-10 fa fa-eye"} />
                    )}
                  {this.addedAsBranchEnd(record) && (
                    <i className={"margin-right-10 fa fa-eye-slash"} />
                  )}
                </a>
              )}
              {(record.attributes.is_branch_end ||
                this.addedAsBranchEnd(record)) && (
                <span className="radio">
                  <input
                    onChange={e => this.toggleRecordSelected(e.target.value)}
                    className="radio_buttons optional"
                    type="checkbox"
                    checked={this.isRecordSelected(record.attributes.code)}
                    value={record.attributes.code}
                  />
                </span>
              )}
            </div>
          </td>
          {
            Object.entries(record.attributes).map(([k,v]) =>
                <td>{k !== 'code' ? v : ''} </td>
            )

          }

        </tr>
      );
    }
  }
  formatStringToNumber(str){
    String.prototype.replaceAll = function (find, replace) {
        var str = this;
        return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
    };
    if(str && str.search(",") >= 0){
      str = str.replaceAll(".", "").replaceAll(",", ".")
    }
    return formatter.format(parseFloat(str))
  }

  filtered() {
    const { file_uploads } = this.props;
    const {filteredRow} = this.state;
    let filteredData = []

    if (filteredRow) {

      let cp = this.state.configuredPattern
      let filteredCodes = Object.entries(cp).filter(([k,v]) => (v == filteredRow.key)).map(([k,v]) => k)

      filteredData = file_uploads.data.filter((f)=> filteredCodes.includes(f.attributes.code))
    }else{
      filteredData = file_uploads.data
    }


    if (this.state.query.length < 1) {
      if (filteredRow) {
        return filteredData
      }else{
        return file_uploads.data;
      }

    }


    var options = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["attributes.code", "attributes.description"]
    };
    var fuse = new Fuse(filteredData, options); // "list" is the item array
    var result = fuse.search(this.state.query);
    return result;
  }

  toggleSelectedVisibility() {
    this.setState({ selectedVisibility: !this.state.selectedVisibility });
  }

  toggleRootEnabled() {
    this.setState({ rootChangeEnabled: !this.state.rootChangeEnabled });
  }

  checkValidFinalNumber(number){
    let ceil = parseInt(Math.ceil(number))
    let floor = parseInt(Math.floor(number))
    let isValid = false
    if(ceil == 0 || floor == 0){
      isValid = true
    }
    return isValid
  }


  render() {
    const vBalanceCheck = this.balanceCheck();
    const { balance_sheet_records } = this.props;
    const { sample_data } = this.props;
    const { file_uploads } = this.props;
    const { contabil_pattern_params } = this.state.pattern;
    const { tabActive, sumResult, sumLoading , headerItems} = this.state;
    // debugger
    return (

      <div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 4.1 }}>
            <BlockTitle>
              ARQUIVO DE UPLOAD
              <div style={{ display: "flex" }}>
                <GreyButton onClick={this.toggleSelectedVisibility.bind(this)}>
                  <i
                    className={
                      this.state.selectedVisibility
                        ? "fa fa-eye-slash"
                        : "fa fa-eye"
                    }
                    style={{ marginRight: 10 }}
                  />
                  {this.state.selectedVisibility ? "Ocultar" : "Mostrar"} contas
                  parametrizadas
                </GreyButton>
                <CircleButton
                  style={{ marginLeft: 10 }}
                  onClick={this.toggleRootEnabled.bind(this)}
                >
                  <i
                    className={
                      !this.state.rootChangeEnabled
                        ? "fa fa-lock"
                        : "fa fa-unlock"
                    }
                  />
                </CircleButton>
              </div>
            </BlockTitle>
            <SearchField
              placeholder={"Buscar item na planilha"}
              onChange={e => {
                let newValue = e.target.value;
                this.setState({ query: newValue });
              }}
            ></SearchField>
            <div
              style={{
                height: "60vh",
                overflowY: "scroll",
                marginTop: 12,
                borderRadius: 5,
                border: "solid 1px #c4c4c4"
              }}
            >
              <table>
                <thead>
                  <tr>

                    <th>    Check</th>
                    {
                      Object.entries(sample_data).map((r) =>
                          <th>{ r[1] } </th>
                      )
                    }
                    <th></th>
                    <th></th>


                  </tr>
                </thead>
                <tbody>
                  {this.filtered().map(record => this.renderRow(record))}
                </tbody>
              </table>
              
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0 16px" }}
          >
            <ApplyButton
              disabled={!this.isSetable()}
              onClick={this.setupParam.bind(this)}
            >
              Aplicar
            </ApplyButton>
          </div>
          <div style={{ flex: 2.3 }}>
            <div style={{display: 'flex', justifyContent: "space-between"}}>
              <BlockTitle>CATEGORIAS</BlockTitle>
              <GreyButton onClick={()=> Turbolinks.visit("/contabil_pattern/configure") }>
                  <i
                    className={"fa fa-plus"}
                    style={{ marginRight: 10 }}
                  />
                  Adicionar nova conta
                </GreyButton>
            </div>
            <SearchField
              placeholder={"Buscar item no plano de conta"}
              onChange={e => {
                let newValue = e.target.value;
                this.setState({ paramsQuery: newValue });
              }}
            ></SearchField>
            <Tabs>
                <Tab 
                  onClick={()=> this.setState({tabActive: "Operacional"})}
                  active={tabActive === "Operacional" }>
                  Operacional
                </Tab>
                <Tab 
                  onClick={()=> this.setState({tabActive: "Não Operacional"})}
                  active={tabActive === "Não Operacional" }>
                  Não Operacional
                </Tab>
                <Tab
                    onClick={()=> this.setState({tabActive: "Exclusão"})}
                    active={tabActive === "Exclusão" }>
                    Exclusão
                </Tab>
              
            </Tabs>
            <WhiteBox style={{ padding: 16 }}>
              
              {contabil_pattern_params &&
                this.renderParams(contabil_pattern_params)}
            </WhiteBox>
          </div>
        </div>
        <hr></hr>
        <div style={{ display: "flex",
                      justifyContent: "flex-end",
                      borderTop: "1px solid darkgrey",
                      height: 80,
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      backgroundColor: 'white',
                      position: 'fixed'

                       }}>
          {/*sumResult &&
            //<Check id="balance_check" valid={ this.checkValidFinalNumber(vBalanceCheck)}>Checagem: { sumLoading ? "Carregando...": formatter.format(vBalanceCheck)}</Check>
            <Check id="balance_check" >Checagem: { sumLoading ? "Carregando...": "00"}</Check>
          */}
          <BlueButton
            disabled={this.state.saving}
            style={{ alignSelf: "center", marginRight: 24 }}
            className={"right"}
            onClick={() => this.save(vBalanceCheck)}
          >
            
            {this.state.stage_text}
            
          </BlueButton>
        </div>
        <LoadingDialog open={this.state.loading}/>
      </div>
    );
  }
}

export default Edit;
