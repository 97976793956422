import React from 'react';
import styled from 'styled-components';
import IconElevetionRed from 'seta-red.svg'
import IconElevetionGreen from 'seta-verde.svg'

const WidgetPercent = ({total, balance, balanceNext, color, title, subtitle, marginLeft, marginTop}) => {

    const percent = (value) => {
       const percen = (100 * value) / total
       if (isNaN(percen)) { return '0.00%' }
       return `${percen.toFixed(2)}%`
    }

    return (
        <Content marginTop={marginTop} marginLeft={marginLeft}>
            <Wrapper>
                <Label className='text-lg subtitlet'>
                    {title}
                    <Percent color={color}>
                        {percent(balance)}
                    </Percent>
                </Label>
                <Currency className='text-lg subtitlet'>
                    {balance.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                </Currency>
            </Wrapper>
            <Wrapper>
                <Label className='text-lg subtitlet'>
                    {subtitle}
                    <Percent color={color}>
                        {percent(balanceNext)}
                    </Percent>
                </Label>
                <Currency className='text-lg subtitlet'>
                    {balanceNext.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                </Currency>
            </Wrapper>
        </Content>
    )
}

export default WidgetPercent;


const Content = styled.div`
    margin-top: 15px;
`

const Wrapper = styled.div`
`

const Percent = styled.div`
    display: flex;
    border-radius: 10px;
    color: white;
    font-size: 0.9rem;
    background: ${props => (props.color)}
    width: 51px;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    margin-left: 4px;
`

const Currency = styled.p`
  font-size: 16px;
`

const Label = styled.label`
    display: flex;
`
