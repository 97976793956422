import React from "react";
import AgingList from "./AgingList";
import {CentralButton, LeftButton, RightButton} from "../../styled_components/default";

class Detailing extends React.Component {
  constructor(props){
    super(props)
    let current_tab = props.current_tab || "agingList"
    this.state ={
      activeTab: current_tab
    }
  }

  activeTab(tab){
    return this.state.activeTab === tab
  }

  render() {
    return (
      <div>
        <div className="flex">
          <div className="bg-content flex-1 with-header">
            <div className="header" style={{display: 'flex', justifyContent: 'space-between' }}>
              <div className="label" style={{display: 'flex', marginRight: 16}}>Aging List - A Receber (Vencidas)</div>
              {/* <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 20 }}>
                <RightButton active={this.activeTab("agingList")}
                              onClick={()=> this.setState({activeTab: "agingList"})}> Aging List</RightButton>
                <CentralButton> Top 10</CentralButton>
                <LeftButton> Evolução</LeftButton>
              </div> */}
            </div>
            
            {this.activeTab("agingList") && <AgingList />}
            {this.activeTab("showTop") && <ShowTop />}
            {this.activeTab("evolution") && <Evolution />}
          </div>
        </div>
      </div>
    );  
  }
}

export default Detailing;
