import React, {Children } from "react";
import PropTypes from "prop-types";
import Fuse from "fuse.js";
import {
  Select,
  FlexCenter,
  GreenLabel,
  BlueButton
} from "../../styled_components/default";


class Edit extends React.Component {
  
  state = {
    stage_text: "Salvar colunas",
    headerItems: [],
   currentData: []
  };


  fetchHeadersRender() {
    let self = this;
    //debugger
    axios
    .get(`/balance_sheets/${this.props.balance_sheet_id}/show_header_pattern_of_beads.json`,{params: {
            data_type: this.props.header_data_type,
        }})
    .then(result => {
      if (result.data) {
        self.setState({ headerItems: result.data});
      }
      
    });
  }

  optionsRender() {

      return Object.entries(this.props.header_financial_income).map(option => (
          <option value={option[1]}>{option[0]}</option>
      ));
  }

  saveHeaderData() {
    this.setState({ saving: true });
    this.setState({ stage_text: "Salvando linhas..." });

    axios
      .post(`/balance_sheets/${this.props.balance_sheet_id}/configure_header.json`, {
        header_columns: this.state
      })
      .then(result => {
        if (result.data.success) {
          this.setState({ stage_text: "Redirecionando para configuração..." });
          location.reload();
        }

        this.setState({ saving: false });
      });
  }


  componentDidMount() {
    this.fetchHeadersRender();
  }
  

  render() {

    const {headerItems} = this.state
    let {sample_data} = this.props
      //debugger
    return (
   
      <div>
   
       <FlexCenter>
        {

            headerItems.length === 0 ?
             <div> Vazio</div>
              :
            headerItems.map(item =>
            <div className={"margin-10"} style={{ flex: 1 }}>
              <GreenLabel> {item.tree_label_fixed}</GreenLabel>
              <Select

                  onChange={e => this.setState(  {
                      currentData: [ {  'value': e.target.value, 'fileHearder': e.currentTarget[e.currentTarget.selectedIndex].text, 'header': item.tree_label_fixed },  ...this.state.currentData]
                  })}
              >
                  <option>-- selecione a coluna --</option>
                  {this.optionsRender()}
              </Select>
            </div>
          )

        }

        </FlexCenter>
        <FlexCenter>
          <BlueButton onClick={() => this.saveHeaderData()}>
            {this.state.stage_text}
          </BlueButton>
        </FlexCenter>

        <table className={'bordered margin-top-20'} >
            <thead>
              <tr>
                <th colspan={6} style={{textAlign: 'center'}}>Demonstração das colunas escolhidas</th>
              </tr>
            </thead>
            <tr>
            {
                sample_data.map((row)=>(
                <th> {row['header']} </th>

                ))
            }
            </tr>

            <tbody>
            <tr>
              {
                  sample_data.map((row)=>(
               <td>{row['header'] ? row['value'] : "-"} </td>
              ))}
            </tr>
            </tbody>
            
          

        </table>
      </div>
    );
  }
}

export default Edit;
