import React from "react";
import PropTypes from "prop-types";
import {
  WhiteBox,
  Title,
  TextInput,
  Select,
  Label,
  Line,
  TextArea,
  GreenButton,
  EntryBox,
  BlueButton,
  FilterLabel,
  FilterSection
} from "../../styled_components/default.js";
import {
  GreyButton
} from "../../styled_components/balance_sheets.js";
const [ATIVO, PASSIVO, EXCLUSAO] = ["ativo", "passivo", "exclusao"]
import Modal from "./Modal";
import ParamNode from "./ParamNode";
const uuidv4 = () =>
    ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );

class Form extends React.Component {


  state = {
    segments: [],
    cleared: {},
    currentItem: {},
    removed: [],
    filtered_by: ATIVO,
    modalIsOpen: false,
    lastHeader: {},
    items: this.validate_items(),
    willRedirect: false
  };

  validate_items(){
    let items = []
    var url_atual = window.location.pathname;
    console.log(url_atual);
    const {route} = this.props
    if(url_atual.toString() === "/contabil_pattern/configure" || route ){
      console.log('entrou contabil_pattern ');
      items = [
          {
            key: uuidv4(),
            head: ATIVO,
            fixed: true,
            label: "Operacional",
            operation: "credito",
            is_file_pattern: false,
            childreen_attributes:[{
              key: uuidv4(),
              head: ATIVO,
              fixed: true,
              label: "Entradas",
              operation: "credito",
              is_file_pattern: false,
              childreen_attributes: []
            },
              {
                key: uuidv4(),
                head: PASSIVO,
                fixed: true,
                label: "Saídas",
                is_file_pattern: false,
                operation: "credito",
                childreen_attributes: []
              }]

          },{
          key: uuidv4(),
          head: PASSIVO,
          fixed: true,
          label: "Não Operacional",
          operation: "credito",
          is_file_pattern: false,
          childreen_attributes:[{
            key: uuidv4(),
            head: ATIVO,
            fixed: true,
            label: "Entradas ",
            operation: "credito",
            is_file_pattern: false,
            childreen_attributes: []
          },
            {
              key: uuidv4(),
              head: PASSIVO,
              fixed: true,
              label: "Saídas ",
              is_file_pattern: false,
              operation: "credito",
              childreen_attributes: []
            }]

          },{
          key: uuidv4(),
          head: EXCLUSAO,
          fixed: true,
          label: "Exclusão",
          operation: "credito",
          is_file_pattern: false,
          childreen_attributes:[]
      }]


    }else{
      console.log('entrou file_pattern ');
      items = [
        {
          key: uuidv4(),
          head: ATIVO,
          fixed: true,
          label: "Pagos e Recebidos",
          operation: "credito",
          is_file_pattern: true,
          childreen_attributes: [{
            key: uuidv4(),
            head: ATIVO,
            fixed: true,
            label: "Natureza",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: ATIVO,
            fixed: true,
            label: "Descrição (Natureza)",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: ATIVO,
            fixed: true,
            label: "Valor Líquido",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: ATIVO,
            fixed: true,
            label: "Data Emissão NF",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: ATIVO,
            fixed: true,
            label: "CNPJ / CPF",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: ATIVO,
            fixed: true,
            label: "Data Baixa",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: ATIVO,
            fixed: true,
            label: "Cliente / Fornecedor",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: ATIVO,
            fixed: true,
            label: "Numero Nota",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: ATIVO,
            fixed: true,
            label: "Receita/Despesa",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          }


          ]
        },
        {
          key: uuidv4(),
          head: PASSIVO,
          fixed: true,
          label: "A pagar/A receber",
          is_file_pattern: true,
          operation: "credito",
          childreen_attributes: [{
            key: uuidv4(),
            head: PASSIVO,
            fixed: true,
            label: "Natureza",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: PASSIVO,
            fixed: true,
            label: "Descrição (Natureza)",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: PASSIVO,
            fixed: true,
            label: "Valor Líquido",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: PASSIVO,
            fixed: true,
            label: "Data Emissão NF",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: PASSIVO,
            fixed: true,
            label: "CNPJ / CPF",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: PASSIVO,
            fixed: true,
            label: "Data de Vencimento",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: PASSIVO,
            fixed: true,
            label: "Cliente / Fornecedor",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: PASSIVO,
            fixed: true,
            label: "Numero Nota",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          },{
            key: uuidv4(),
            head: PASSIVO,
            fixed: true,
            label: "Receita/Despesa",
            operation: "credito",
            is_file_pattern: true,
            childreen_attributes: []
          }]
        }
      ]

    }
    return items
  }
  buildStringRoute(route = [], filterDeleted = false) {
    if(filterDeleted){
      return route
          .map((a, b) => `[route[${b}]].childreen_attributes.filter((x)=> !x._destroy)`)
          .reduce((a, b) => a + b, "");
    }else{
      return route
          .map((a, b) => `[route[${b}]].childreen_attributes`)
          .reduce((a, b) => a + b, "");
    }
  }

  saveAndRedirect() {
    this.setState({willRedirect: true})
    this.save()
  }

  save(route=null, index=null, editedNode=null, data=null){
    let self = this;
    const {items, segment_id, removed, label} = this.state
    const {contabil_pattern} = this.props
    this.setState({saving: true})

    const params = {
      node: editedNode,
      data: data,
      removed: removed,
      contabil_pattern: {
        label: label,
        segment_id: segment_id,
        contabil_pattern_params_attributes: items,
      }
    }

    const success = function(data){
      if (self.props.editing) {
        console.log("salvo...")
        self.setState({saving: false})

        if (data.data.node) {
          self.saveEdit(route, index, data.data.node, true)
        }
      }

      if (self.state.willRedirect){
        Turbolinks.visit('/contabil_patterns?success=true')
      }
    }

    const fail = function(e){
      console.log(e)
      self.setState({saving: false})
      alert("Ocorreu um erro ao salvar, tente novamente, caso o erro persista entre em contato com o suporte")
    }

    if(contabil_pattern.id){
      axios.patch(`/contabil_patterns/${contabil_pattern.id}.json`, params).then(success).catch(fail)
    }else{
      axios.post('/contabil_patterns', params).then(success).catch(fail)
    }
  }

  setupSegments(){
    this.setState({segments: this.props.segments})

  }

  componentDidMount(){
    this.setupSegments()
    const {label, segment_id} = this.props.contabil_pattern
    if(this.props.items.length > 0){
      this.setState({
        items: this.props.items,
        label: label,
        segment_id: segment_id
      })
    }

    console.log(this.props)
  }

  editNode(route = [], index, item) {
    this.setState({
      modalIsOpen: true,
      currentRoute: route,
      currentIndex: index,
      currentItem: item
    });
  }

  saveEdit(route = [], index, editedNode, savingFromRemote=false){
    this.handleClose();
    editedNode.childreen_attributes = editedNode.childreen_attributes || []
    editedNode.label ||= "Sem nome"
    
    this.setState(oldParams => {
      if (route && route.length > 0) {
        let strRoute = this.buildStringRoute(route);
        eval(`oldParams.items${strRoute}[${index}] = {...oldParams.items${strRoute}[${index}], ...editedNode}`);
      } else {
        eval(`oldParams.items[${index}] = {...oldParams.items[${index}], ...editedNode}`);
      }

      return { ...oldParams };
    });
    let self = this;
    setTimeout(()=>{
      if (self.props.editing && !savingFromRemote) {
        self.save(route, index, editedNode)
      }
    }, 100)

  }

  async addNode(route = [], index, item) {
    let {configured_patterns} = this.props;
    let already_configured = configured_patterns[item.id] && item.childreen_attributes.length <= 0
    if(already_configured && !this.state.cleared[item.id]){
      let remove_parameters = confirm("Antes de adicionar um novo Nó é necessário que você desvincule qualquer conta que possa estar vinculada a esse parametro, deseja remover todos os parametros vinculados a essa conta?")
      if (remove_parameters) {
        let remove_request = await axios.post(`/configured_patterns/${item.id}/clear.json`)
        if(remove_request.data.success){
          this.setState((oldState)=>{
            oldState.cleared[item.id] = true
            return {...oldState}
          })
        }
      }else{
        return false
      }

    }
    let strRoute = this.buildStringRoute(route);
    let lastIndex = 0
    let newNode = ()=> ({
      id: null,
      fr_id: null,
      key: uuidv4(),
      label: null,
      childreen_attributes: []
    });

    this.setState(oldParams => {
      if (route.length > 0) {
        eval(`oldParams.items${strRoute}[${index}].childreen_attributes.push(newNode())`);
      } else {
        eval(`oldParams.items[${index}].childreen_attributes.push(newNode())`);
      }

      return { ...oldParams };
    });

    if (route.length > 0) {
      eval(`lastIndex = this.state.items${strRoute}[${index}].childreen_attributes.length`)
    } else {
      eval(`lastIndex = this.state.items[${index}].childreen_attributes.length`)
    }
    console.log("added", lastIndex)
    this.editNode([...route, index], lastIndex, newNode())


  }

  async removeNode(route = [], index, item={}) {
    let can_remove = confirm("Tem certeza que quer remover esse parametro?")
    if (!can_remove) {
      return false
    }
    let in_use_node = await axios.post('/check_use_in_formulas.json', item)

    if (in_use_node.data.length > 0) {
      alert(`Pra apagar esse parametro remova a refêrencia dele nas seguintes formulas: \n ${in_use_node.data}`)
      return false
    }


    let strRoute = this.buildStringRoute(route);
    let removed = null
    this.setState(oldParams => {
      if (route.length > 0) {
        eval(`removed = oldParams.items${strRoute}.splice(${index}, 1)`);
      } else {
        eval(`removed = oldParams.items.splice(${index}, 1)`);
      }

      return { ...oldParams };
    });

    this.setState((oldState)=>{
      if (removed[0].id){
        oldState.removed.push(removed[0].id);
      }
      return {...oldState}
    })
    // this.setState(oldParams => {
    //   if (route.length > 0) {
    //     eval(`oldParams.items${strRoute}[${index}]._destroy = true`);
    //   } else {
    //     eval(`oldParams.items[${index}]._destroy = true`);
    //   }

    //   return { ...oldParams };
    // });

    if (this.props.editing) {
      this.save()
    }
  }

  getNode(params, route) {
    let strRoute = this.buildStringRoute(route);

    this.setState(oldParams => {
      if (strRoute) {
        eval(`oldParams.items${strRoute} = params`);
      } else {
        eval(`oldParams.items = params`);
      }

      // // oldParams.items[route[0]].childreen_attributes[route[1]].childreen_attributes = params;
      console.log(oldParams.items);
      return { ...oldParams };
    });
  }

  setLastHeader(lastHeader, id){
    this.setState((oldState)=>{
      oldState.lastHeader[lastHeader] = id
      return {...oldState}
    })
  }

  handleClose(){
    this.setState({modalIsOpen: false})
  }

  render() {
    const {editing, route} = this.props;
    const {modalIsOpen, segment_id, filtered_by} = this.state;
    var url_atual = window.location.pathname;
    var click = ''
    var click_segund = ''
    var click_terc = ''
    var title = ''
    if(url_atual.toString() === "/contabil_pattern/configure"  || route) {
      click = 'Operacional'
      click_segund = 'Não Operacional'
      click_terc = 'Exclusão'
      title = 'PLANO DE PADRÃO DE CONTAS'
    }else{
      click = 'Pagos e Recebidos'
      click_segund = 'A pagar/A receber'
      title = 'PLANO DE PADRÃO DE ARQUIVO'
    }

    return (

        <div >
          <div style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            {/*<GreyButton  onClick={()=> window.history.back()}>
              <i className={'fa fa-chevron-left'}></i><i className={'fa fa-chevron-left'} style={{marginRight: 10}}></i>
            </GreyButton> */}
            <FilterSection>
              <FilterLabel
                  active={filtered_by === ATIVO}
                  onClick={()=> this.setState({filtered_by: ATIVO})}>
                {click}
              </FilterLabel>
              <FilterLabel
                  active={filtered_by === PASSIVO}
                  onClick={()=> this.setState({filtered_by: PASSIVO})}>
                {click_segund}
              </FilterLabel>
              {click_terc == 'Exclusão' && <FilterLabel
                  active={filtered_by === EXCLUSAO}
                  onClick={()=> this.setState({filtered_by: EXCLUSAO})}>
                {click_terc}
              </FilterLabel> }


            </FilterSection>
          </div>

          <div style={{ display: "flex" }}>
            <Modal
                open={modalIsOpen}
                handleClose={this.handleClose.bind(this)}
                saveEdit={this.saveEdit.bind(this)}
                currentRoute={this.state.currentRoute}
                currentIndex={this.state.currentIndex}
                currentItem={this.state.currentItem}
                lastHeader={this.state.lastHeader}
                setLastHeader={(lastHeader, id)=> this.setLastHeader(lastHeader, id)}
            />
            {this.props.saga_admin && <WhiteBox style={{flex: 1}}>
              <Title>adicionar item ao plano de conta</Title>
              <div>


                <Label>Segmento</Label>
                <Select onChange={(x)=> this.setState({segment_id: x.currentTarget.value})}>
                  <option >Selecione um segmento</option>
                  {this.state.segments.map((option)=>(
                      <option selected={segment_id === option.id} key={option.id} value={option.id}>{option.label}</option>
                  ))}
                </Select>
                <Label>Modelo</Label>
                <TextInput value={this.state.label} onChange={(x)=> this.setState({label: x.currentTarget.value})} />

                <Line />

                {/*<Label>Categoria Pai</Label>
                          <Select />
                          <Label>Nome</Label>
                          <TextInput />
                          <Label>Descrição</Label>
                          <TextArea />
                          <Label>Padrão</Label>
                          <Select />
              
                          <GreenButton>
                            <i
                              className="fa fa-plus-circle"
                              style={{ fontSize: 20, marginRight: 5 }}
                            />
                            Adicionar
                          </GreenButton>*/}
              </div>
            </WhiteBox>}

            <WhiteBox style={{flex: 2}}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>

                <Title>{title}</Title>
              </div>
              <div>
                <ParamNode
                    filtered_by={this.state.filtered_by}
                    nodePath={[]}
                    node={this.state.items}
                    getNode={this.getNode.bind(this)}
                    addNode={this.addNode.bind(this)}
                    editNode={this.editNode.bind(this)}
                    removeNode={this.removeNode.bind(this)}
                    configured_patterns={this.props.configured_patterns}
                    onDragEnd={(data)=> this.save(null,null,null, data)}
                />
              </div>
            </WhiteBox>

          </div>
          <hr/>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {editing && <BlueButton disabled={this.state.saving} style={{alignSelf: 'center'}} className={'right'} onClick={()=> this.saveAndRedirect()}>
              Concluir
            </BlueButton> }

          </div>

        </div>
    );
  }
}

export default Form;
