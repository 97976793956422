import React from "react";
import Dre from './results/Dre'
import Attachment from './results/Attachment'
import Segmentado from './results/Segmentado'
import SegmentadoByMonth from './results/SegmentadoByMonth'
import Balance from './results/Balance'
import Borboleta from './Borboleta'
import Flow from './results/Flow'
import Detailing from './results/Detailing'
import {BlueLink} from '../styled_components/default';
import Budget from "./results/Budget";

class Result extends React.Component {
    constructor(props){
        super(props)
        let current_tab = props.current_tab || "balance"
        // if(props.is_group_type){
        //     current_tab = props.current_tab || "segmented"
        // }
        this.state ={
            activeTab: current_tab,
            textActiveTab: '',
            year: props.initial_year,
            month: props.initial_month
        }
    }
  	

 	activeTab(tab){
      return this.state.activeTab === tab
	}

  componentDidMount() {
    this.updateTextTab();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.activeTab !== this.state.activeTab) {
      this.updateTextTab();
    }
  }

  updateTextTab() {
    let newText;
    switch (this.state.activeTab) {
      case 'detailing':
        newText = 'Detalhamento Contas a Receber';
        break;
      case 'balance':
        newText = 'Fluxo de Caixa';
        break;
      default:
        newText = '';
    }
    this.setState({ textActiveTab: newText });
  };

    changeYear(year){
        this.setState({year})
    }

    changeMonth(month){
        this.setState({month})
    }
    normal_render(){
        const {months, report, is_group_type, has_childreen} = this.props;
        const {year, month} = this.state;

        let numberToCurrency = function(number){
            return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }

        return (
            <div>
                <br></br>
                <div className={"flex"} style={{justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {this.props.mobile ? <></> : <p className={'float-left title-index'}> Resultado | </p> }
                        {this.props.mobile ? <></> : <p className={'float-left subtitle-index'}> {this.state.textActiveTab}  </p>}
                    </div>

                    {this.props.mobile ? <></> : <div style={{display: 'flex', alignItems: 'center'}}>
                        {this.props.is_budget ? <>
                            <BlueLink
                                active={this.activeTab("budget")}
                                onClick={()=> this.setState({activeTab: "budget"})}
                             > Orçamento </BlueLink>
                        </> :
                        <><React.Fragment>
                            {/*<BlueLink
                                active={this.activeTab("borboleta")}
                                onClick={()=> this.setState({activeTab: "borboleta"})}
                             > Visão Borboleta </BlueLink>
                            <BlueLink
                                active={this.activeTab("dre") || this.activeTab("dre_cc") }
                                onClick={()=> this.setState({activeTab: "dre_cc"})}
                             > Resultado Mensal </BlueLink>*/}

                            <BlueLink
                                active={this.activeTab("balance")}
                                onClick={()=> this.setState({activeTab: "balance"})}
                             > Fluxo de Caixa </BlueLink>

                            <BlueLink
                                active={this.activeTab("detailing")}
                                onClick={()=> this.setState({activeTab: "detailing"})}
                             > Detalhamento Contas a Receber</BlueLink>

                            {/*<BlueLink
                                active={this.activeTab("flow")}
                                onClick={()=> this.setState({activeTab: "flow"})}
                             > Fluxo de Caixa </BlueLink>

                            <BlueLink
                                active={this.activeTab("attachment_table") }
                                onClick={()=> this.setState({activeTab: "attachment_table"})}
                             > Anexos </BlueLink>*/}
                         </React.Fragment>

                          {/*has_childreen && is_group_type && <BlueLink
                            active={this.activeTab("segmented_by_month")}
                            onClick={()=> this.setState({activeTab: "segmented_by_month"})}
                         > Segmentado Por Mês</BlueLink>*/}</> }
                    </div>}
                </div>
                <div className="flex">
                    <div className="bg-content flex-1 with-header">
                        {/*} {this.activeTab("borboleta") && <Borboleta scope={"borboleta"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("dre") && <Dre scope={"dre"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("dre_cc") && <Dre scope={"dre_cc"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} costCenterMode={true} defaultYear={year} {...this.props} />}
                        */}
                        {this.activeTab("balance") && <Balance  changeYear={this.changeYear.bind(this)} changeMonth={this.changeMonth.bind(this)} defaultMonth={month} defaultYear={year} {...this.props} />}
                        {this.activeTab("detailing") && <Detailing />}

                        {/*} {this.activeTab("flow") && <Flow  changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("segmented") && <Segmentado   changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("segmented_by_month") && <SegmentadoByMonth   changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("attachment_table") && <Attachment scope={"attachment_table"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                    */}
                    </div>

                </div>
        </div>);
    }

    budget_render(){
        const {months, report} = this.props;
        const {year} = this.state;

        let numberToCurrency = function(number){
            return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }

        return (
            <div>
                <div className={"flex"} style={{justifyContent: 'space-between'}}>
                    <h1> Orçamento </h1>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                         <BlueLink 
                            active={this.activeTab("dre_cc")}
                            onClick={()=> this.setState({activeTab: "dre_cc"})}
                         > Orçamento</BlueLink>
                    </div>  
                </div>
                <div className="flex">
                    <div className="bg-content flex-1 with-header">
                        {this.activeTab("dre_cc") && <Budget changeYear={this.changeYear.bind(this)} costCenterMode={true} defaultYear={year} {...this.props} />}
                    </div>
                        
                </div>
        </div>);
    }
  render() {
    if (this.props.is_budget) {
        // return this.budget_render()
        return  this.normal_render()
    }else{
        return  this.normal_render()
    }
    
  }
}

export default Result;



