import React from "react";
import LoadingDialog from './../LoadingDialog'
import Comment from './../Comment'
import MonthFilter from './../MonthFilter'
import ResultChart from './../ResultChart'
import PopHover from './../PopHover'
import popupIco from 'popup-ico.svg';
import {popupCenter} from './../popup/popupCenter'
import FlowChart from "../FlowChart";
// import chartIco from 'chart-ico.svg';
import downloadIco from 'bt_download.svg';
import olhoOn from 'bt_olho_on.svg'
import olhoOff from 'bt_olho_off.svg'
import chartIco from 'graph-svg.svg';
class Flow extends React.Component {
  
  constructor(props){
    super(props)
    let months = {}
    Object.entries(props.months).map(([key, value])=>{
      if(Object.keys(props.visible_months[props.defaultYear]).includes(key)){
        months[key] = value
      }
    })
    this.loading = 0
    this.state ={
      currentCompany: null,
      formulasTotalByYear: {},
      chart_titles: {},
      chart_formulas: {},
      chartData: {},
      allYearsData: {},
      showZeroLines: false,
      formulas: {},
      dreFormulas: {},
      report: [],
      dreReport: {},
      labels: [],
      years: [],
      year: this.props.defaultYear,
      recordsFetched: {},
      cost_center_mode: false,
      costCentersFetched: {},
      costCenterAreas:[],
      costCenterRecordsGrouped: {},
      activeAreaType: {}, 
      formulasTotal: {},
      activeKeys: {},
      visibleMonths: props.visible_months[props.defaultYear] || {
        janeiro: true,
        fevereiro: true,
        março: true,
        abril: true,
        maio: true,
        junho: true,
        julho: true,
        agosto: true,
        setembro: true,
        outubro: true,
        novembro: true,
        dezembro: true,
      },
      months: months

    }
  }

  // fetch api/companies
  fetchCurrentCompany(){
    this.requestsCounter += 1
    axios.get(`/api/companies.json`, {params:{
      only_current: true
    }})
    .then(res => {
      this.requestsCounter -= 1
      this.setState({currentCompany: res.data.data[0].attributes})
    })
    .catch(err => {
      this.requestsCounter -= 1
      console.log(err)
    })
  }

  tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }

  async setComments(year){
    
    let comments = await axios.get('/comments.json', {params:{
      year
    }})  
    
    
    this.setState({comments: comments.data})
  }

  async setYears(){
    this.loading += 1
    let years = await axios.get('/balance_sheets/years.json')
    this.setState({years: years.data})
    this.loading -= 1
  }
  async fetchDataForChart(year=null){
    
    this.loading += 1
    let url = '/results/dre.json'
    let result = await axios.get(url, {params: {
        year
    }})
    this.loading -= 1
    const {formulas, report} = result.data
    
    this.setState({
      dreFormulas: formulas,
      dreReport: report
    })  
   
    
    
    
  }

  async fetchData(year=null, loadUniqueData=true){
    const {costCenterMode, is_budget} = this.props;
    this.setState({loading: this.state.loading + 1})
    this.loading += 1
    let result = await axios.get('/results/flow.json', {params: {
        year,
        is_budget
    }})
    const {formulas, report, labels, areas, cost_center_grouped_by_month} = result.data
    
    if(loadUniqueData){
      this.setState({
        formulas,
        report,
        labels,
        costCenterAreas: areas,
        recordsFetched: {},
        costCenterGroupedByMonth: cost_center_grouped_by_month
      })  
    }
    
    this.loading -= 1

    this.setState((oldState)=>{
      oldState.allYearsData[year] = {
        formulas,
        report,
        labels,
        costCenterAreas: areas,
        recordsFetched: {},
        costCenterGroupedByMonth: cost_center_grouped_by_month
      }
      return {...oldState}
    })

    
  }

  getNumerFormatted(value, format){
    let result = value
    if(format == "percentage"){
      result = this.percentFormat(value )
    }
    if(format == "integer"){
      result = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value))
    }
    if(!format || format == "money"){
      result = this.numberToCurrency(value * -1) 
    }
    
    return result
  }
  dataForFormula(result){
    const { months} = this.state;
    let data = []
    let i = 1
    Object.entries(months).map(([name, number]) => {
      if(i < 12){
        data.push(this.tryEval(result[name]))  
      }
      i += 1
    })
    return data
  }
  
  componentDidMount(){
    this.setYears()
    this.setComments(this.state.year);
    this.fetchData(this.state.year)
    this.fetchDataForChart(this.state.year)
    this.fetchDataForRange(this.state.year)
    this.fetchGraphFormulas();
    this.fetchCurrentCompany();
  }

  async fetchGraphFormulas(){
    ["cash_flow", "cash_flow_only"].map(async (situation)=>{
      this.requestsCounter += 1
      let req = await axios.get(`/charts/situations/${situation}.json`)
      let {charts, title} = req.data
      this.requestsCounter -= 1
      this.setState((oldState)=>{
        oldState.chart_formulas[situation] = charts
        oldState.chart_titles[situation] = title
        return {...oldState}
      })
      
    })
    
    
  }
  
  setupChartData(){
    let data = {}
    
    let self = this;
    let {formulas, dreFormulas, report, dreReport, chartData} = this.state;
    
    let mixedReport = {...dreReport, ...report}
    
    let formulas_labels = [
      ...Object.entries(dreFormulas).map(([key, value])=>{return [key, value.fr_id]}),
      ...Object.entries(formulas).map(([key, value])=>{return [key, value.fr_id]})
    ]
    
    formulas_labels.map(([label, id])=> {
      Object.entries(mixedReport).map(([form_id, value])=>{
          try{
              data[`${id}`] = eval(mixedReport?.[label]?.[label]?.result)
          }catch(e){
              
          }
      })
    })
    
    this.setState({chartData: {...chartData, ...data}})
  }


  formulaIsGrouped(key){
    const {formulas} = this.state

    return formulas[key].formula_type == "grouped"

  } 

  setupAllYearsData(){
    let {years} = this.state;
    years.map((year)=>{
      this.loadYear(year, false)
      
    })
  }

  loadYear(year, loadUniqueData=false){
    this.fetchData(year, loadUniqueData)
    this.fetchDataForRange(year, false)
    
    
  }
  defineDefaultVisibleMonths(year){
    let months = {}
    Object.entries(this.props.months).map(([key, value])=>{
      if(Object.keys(this.props.visible_months[year]).includes(key)){
        months[key] = value
      }
    })
    
    this.setState({months, visibleMonths: this.props.visible_months[year]});
  }

  changeYear(year){
    this.setState({year})
    this.props.changeYear(year)
    this.fetchDataForChart(year)
    this.fetchData(year)
    this.setComments(year)
    this.fetchDataForRange(year)
    this.defineDefaultVisibleMonths(year)
  }

  renderLine(line, options={}, level=1){
    
    const { months, showZeroLines} = this.state;
    let linesToInt = []
    let totalLine = 0
    Object.entries(months).map(([name, number]) => {
      if(parseFloat(line.months[name]).toString() != "NaN"){
        linesToInt.push(parseFloat(line.months[name]) || 0 )
        totalLine += parseFloat(line.months[name]) || 0
      }
    })
    // remove all values equal 0 from linestoInt
    linesToInt = linesToInt.filter((value)=>{
      return value != 0
    })
    if(!showZeroLines && linesToInt.length == 0){
      return <></>
    }
    
    return <tr className={"account-line"}>
      <td>
        <div style={{paddingLeft: level * 10}}>{line.label}</div>
      </td>
      {Object.entries(months).map(([name, number]) => {
          return (
            <td className={'comment-wrapper'}>
                  {this.state.comments && <Comment 
                    refreshComments={()=> this.setComments(this.state.year)}
                    comments={this.state.comments}
                    model_reference={'parameter_lines'}
                    month={number}
                    monthName={name}
                    year={this.state.year}
                    model_reference_id={line.label}>
                  </Comment>}
                  {line.months[name] ? (parseFloat(line.months[name]) * - 1).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }) : "-"}
                </td>
          );
        })}
      <td className={'comment-wrapper flex-chart'}>
        {this.state.comments && <Comment 
          refreshComments={()=> this.setComments(this.state.year)}
          comments={this.state.comments}
          model_reference={'parameter_lines'}
          month={"total"}
          monthName={name}
          year={this.state.year}
          model_reference_id={line.id}>
        </Comment>}
          <p>
          {(totalLine * -1).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }) }
          </p>
          <ResultChart
            loadAllYears={()=>{this.setupAllYearsData()}}
            loadYear={(year)=> this.loadYear(year)} 
            years={this.state.years}
            year={this.state.year} 
            title={line.label} 
            data={this.dataForFormula(options["p_value"])} 
            param={options["param"]}
            p_key={options["p_key"]}
            line_key={options["line_key"]}
            dataKey={"param-lines"}
            formula_id={options["key"]}
            months={this.props.months}
            allYearsData={this.state.allYearsData}
          />
        
      </td>
      
    </tr>
  }

  numberToCurrency(number=0) {
    if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
      number = 0
    }
    
    return parseFloat(number * -1).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }

  renderRow(row, options={}, level=1, key=null){
    
    let childrenLines = (entry, month_name)=>{
      try{
        let initial = Object.values(entry.lines).map((x)=> x.months[month_name]).filter((a)=> parseFloat(a).toString() != "NaN").reduce((a,b)=> parseFloat(a) + parseFloat(b), 0)

        if (Object.values(entry.childreen).length > 0) {
          Object.values(entry.childreen).map((child)=> {
            initial += childrenLines(child, month_name)
          })
        }

        return initial
      }
      catch(e){
        console.log("ERRO AQUI", e)
        return 0
      }
      
    }

    const { months} = this.state;
    const  {costCentersFetched, activeKeys} = this.state;
    let sum = 0

    
    
    return <React.Fragment>
        {!row.months ?
          <tr className="account-number-row">
            <td ><div 
                onClick={()=> {
                  
                  this.setState((oldState)=>{
                    if(row.label){
                      
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? oldState.activeKeys[key] : {}
                      oldState.activeKeys[key][row.label] = oldState.activeKeys[key][row.label] ? null : {}
                    }else{
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? null : {}  
                    }
                    
                    return {...oldState}
                  })
                }}
                style={{paddingLeft: (level + 1) * 10}}>
                  <i className={`pull-left fa fa-chevron-${ activeKeys[key] ? 'down' : 'right'}`}/>
                  {row.label}
                </div>
            </td>
            {Object.entries(months).map(([name, number]) => {
              let monthValue = childrenLines(row, name)
              if(monthValue.toString() != "NaN"){
                sum += monthValue  
              }
              
              console.log(sum)
              return (
                <td className={'comment-wrapper'}>
                  {this.state.comments && <Comment 
                    refreshComments={()=> this.setComments(this.state.year)}
                    comments={this.state.comments}
                    model_reference={'render-row'}
                    month={number}
                    monthName={name}
                    year={this.state.year}
                    model_reference_id={row.id}/>}
                  {this.numberToCurrency(monthValue)}
                  
                </td>
              );
            })}
            <td className={'line-total flex-chart'}>
              <p>{this.numberToCurrency(sum)}</p>
              <ResultChart
                    loadAllYears={()=>{this.setupAllYearsData()}}
                    loadYear={(year)=> this.loadYear(year)} 
                    years={this.state.years}
                    year={this.state.year} 
                    title={row.label}
                    data={this.dataForFormula(options["p_value"])} 
                    param={options["param"]}
                    p_key={options["p_key"]}
                    line_key={options["line_key"]}
                    dataKey={"param-head"}
                    formula_id={options["key"]}
                    months={this.props.months}
                    allYearsData={this.state.allYearsData}
                  />
            </td>
        </tr>
      :
        this.renderLine(row, options, level + 1)
      }

      
      {activeKeys[key] && row.childreen && Object.entries(row.childreen).map(([child_key, child])=>{
          
          return this.renderRow(child, {...options, line_key: child_key}, level + 1)
        })}
      {(activeKeys[key] && activeKeys[key][row.label]) && row.lines &&  Object.keys(row.childreen).length < 1 && Object.entries(row.lines).map(([line_key, line])=>{
          
          return this.renderLine(line, {...options, line_key}, level + 2)
        })}
      </React.Fragment>
  }

  renderChildreenFor(key=null, options){
    
    let data = this.state.recordsFetched[key]
    if(data){
      return (<React.Fragment>
        {Object.entries(data).map(([row_id, row])=>(this.renderRow(row, {...options, line_key: row_id}, 0, key)))}
      </React.Fragment>)
    }
  }

  async fetchDetail(key){
    const {year, recordsFetched} = this.state;
    const {cost_center_heads} = this.props;
    
    if(recordsFetched[key]){
      this.setState((oldState)=>{
        oldState.activeKeys[key] = {} 
        oldState.recordsFetched[key] = null
        return {...oldState}
      })
    }else{
      let details = await axios.get(`/results/${key}/detail.json`, {params: {
          year: year,
          cost_center_mode: this.state.cost_center_mode && cost_center_heads[key]
      }})
      
      this.setState((oldState)=>{
        
        if (Object.values(Object.values(details.data)[0].childreen).length > 0){
          oldState.recordsFetched[key] = Object.values(details.data)[0].childreen
        }else{
          oldState.recordsFetched[key] = Object.values(details.data)[0].lines
        }
        
        return {...oldState}
      })
    }
    
  }

  paramLabel(value, param){
    let label;
    if (param == "difference") {
      label = <label title={"diferença"} style={{color: "#232D6A"}}>dif</label>
    }
    if (param == "f_saldo_atual") {
      label = <label title={"saldo atual"} style={{color: "#3FABAE"}}>sat</label> 
    }
    if (param == "f_saldo_anterior") {
      label = <label title={"saldo anterior"} style={{color: "#EFBF6A"}}>san</label>
    }

    // return <div>{value} {label}</div>
    return <div>{value}</div>
  }

  percentFormat(number){
    return `${parseFloat(number).toFixed(2)}%`
  }
  
  changeVisibleMonths(event){
    // event.target.name]: event.target.checked
    let {name, checked} = event.target
    let props = this.props;
    this.setState((oldState)=>{
      oldState.visibleMonths[name] = checked
      oldState.months = {}
      Object.entries(this.props.months_dict).map(([number, month_name])=>{
        if(oldState.visibleMonths[month_name]){
          oldState.months[month_name] = number
        }
      })
      
      return {...oldState}
    });  
  }

  getFloat(number){
    if (parseFloat(number).toLocaleString() == "NaN"){
      return 0
    }else{
      return parseFloat(number)
    }
  }

  isParamZero(p_value, param){
    if(this.state.showZeroLines){
      return false
    }

    const { months} = this.props;
    let isZero = true
    Object.entries(months).map(([name, number]) => {
      
      if(this.getFloat(p_value[name][param]) != 0){
        isZero = false
      }
    })
    return isZero

  }
  
  async fetchDataForRange(year, loadUniqueData=true){
    const {is_budget, scope} = this.props;
    this.loading += 1
    let report = await axios.get('/formulas/borboleta.json',{params: {
        scope: "cash_flow",
        range: {
          start_at: (new Date(`01/01/${year}`)), 
          end_at: (new Date(`12/12/${year}`))
        },
        is_budget
    }})
    this.loading -= 1
    if(loadUniqueData){
      this.setState({
        formulasTotal: report.data
      })
    }
    

    this.setState((oldState)=>{
      oldState.formulasTotalByYear[year] = report.data
      return {...oldState}
    })
  }
  
  handleClose(open){
    this.setState({modalIsOpen: false})
  }

  openChartModal(month){
    this.setupChartData();
    let self = this
    setTimeout(()=>{
      let chartProps = {title: "caregando...",
                  currentMonth: month,
                  chartTitle: this.state.chart_title,
                  chart: this.state.chart_formulas,
                  titles: this.state.chart_titles,
                  comparedLabel: this.state.comparedLabel,
                  chartData: this.state.chartData,
                  open: true,
                  year: this.state.year
                }
    
      axios.post(`/flow_chart_popups.json`, {
          props: chartProps
      }).then(function(result){
        //check if browser is mobile
        let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobile){
          self.setState({currentMonth: month, modalIsOpen: true})
        }else{
          popupCenter({url: `/flow_chart_popups/${result.data.popup_id}`, title: 'Gráfico', w: window.innerWidth * 0.7, h: 450})
        }
          
          
      });
    }, 100)
    
    
  }


  log(c, f){
      debugger
      console.log("CURRENT_COMPANY", c)
      console.log("CURRENT_FORMULA", f)
      return "-"
  }

  render() {
    const { report, labels, formulas, year, years, recordsFetched, showZeroLines, formulasTotal, chartData, modalIsOpen} = this.state;
    const { months} = this.state;



    let numberToCurrency = function(number=0) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }
      
      return parseFloat(number).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };

    return (
      <React.Fragment>
        <div className="header" style={{display: 'flex', justifyContent: 'space-between' }}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20 }}>
            <div
                className={'result-popup-button'}
                onClick={()=> popupCenter({url: `/popups/flow`,title: 'Editar', w: window.innerWidth * 0.8, h: window.innerHeight * 0.8})} 
                style={{backgroundColor: '#F5F5F5', 
                        width: 36, 
                        height: 36,
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'}}>
                <img src={popupIco} style={{width: 14}}></img>
            </div>
            <div className="label" style={{marginRight: 16}}>FLUXO DE CAIXA</div>
            <MonthFilter changeVisibleMonths={(e)=> this.changeVisibleMonths(e)} visibleMonths={this.state.visibleMonths} months={this.props.months}></MonthFilter>
             
            {showZeroLines ?
               <div title="Mostrar linhas zeradas" style={{color: 'rgb(36,44,86)', fontWeight: "bold"}}className={'pointer'} onClick={()=> this.setState({showZeroLines: !showZeroLines})}> <img src={olhoOff} style={{width: 36}}></img></div>
               :
               <div title="Ocultar linhas zeradas" style={{color: 'rgb(36,44,86)', fontWeight: "bold"}}className={'pointer'} onClick={()=> this.setState({showZeroLines: !showZeroLines})}> <img src={olhoOn} style={{width: 36}}></img></div>
             }
          </div>
          <form className="form flex" style={{paddingRight: 35,  alignItems: 'center'}}>
              <div className={'with-date-ico'}>
              {years && <select className={'margin-left-20 margin-right-20'} onChange={(e)=> this.changeYear(e.target.value)}>
                              {years.map((option_year)=>(
                                <option value={option_year} selected={year == option_year }> {option_year} </option>
                              ))}
                              
                              
                            </select>}
              </div>
              <a href={`/export/xlsx/${year}/cash_flow.xlsx`}>
                <img src={downloadIco} style={{width: 36}}></img>
              </a>
            </form>
        </div>
        <small style={{fontSize: 10, padding: "10px 0 0 43px", display: 'block', color: 'grey'}}>* Você está visualizando números no formato n/{this.props.divisor}</small>
        <div id="table-result-wrapper">
          <div className="content margin-left-40 margin-right-40 padding-top-40">
            <table id="table-result">
              <thead>
                <tr>
                  <th>Nome</th>
                  {modalIsOpen && <FlowChart  
                                  titles={this.state.chart_titles}
                                  currentMonth={this.state.currentMonth}
                                  chartTitle={this.state.chart_title}
                                  chart={this.state.chart_formulas} 
                                  comparedLabel={this.state.comparedLabel}
                                  chartData={chartData}
                                  open={modalIsOpen} 
                                  handleClose={this.handleClose.bind(this)}/>}
                  {Object.entries(months).map(([name, month]) => (
                    <th style={{cursor: 'pointer'}} onClick={()=> this.openChartModal(name)}>
                      {monthDict[`${month}`.padStart(2, "0")]}/{year} <img src={chartIco} style={{width: 16, heigth: 16}}></img>
                     
                    </th>
                  ))}

                  <th style={{cursor: 'pointer'}} onClick={()=> this.openChartModal("total")}>
                    Acumulado <img src={chartIco} style={{width: 16, heigth: 16}}></img>
                  </th>
                </tr>
              </thead>

              {Object.entries(report).map(([key, value]) => {
                return (
                  <tbody>
                    {this.formulaIsGrouped(key) && Object.entries(value[key]["params"]).map(
                      ([p_key, p_value]) => {
                        
                        return (
                          <React.Fragment>
                            {value[key]["lines"][p_key] && Object.values(value[key]["lines"][p_key]).map((param)=>(
                              <React.Fragment>
                              {!this.isParamZero(p_value, param) && <tr>
                                  <td  onClick={()=> this.fetchDetail(p_key)}>
                                    <i className={`pull-left fa fa-chevron-${recordsFetched[p_key] ? 'down' : 'right'}`}/> {
                                      this.paramLabel(labels[p_key], param)
                                    }
                                  </td>
                                  {Object.entries(months).map(([name, number]) => {
                                    return (
                                      <td className={'comment-wrapper'}>
                                      {this.state.comments && <Comment 
                                        refreshComments={()=> this.setComments(this.state.year)}
                                        comments={this.state.comments}
                                        model_reference={'cost_centers' + (this.props.is_budget ? '-orçamento': '')}
                                        month={number}
                                        monthName={name}
                                        year={this.state.year}
                                        model_reference_id={p_key}>
                                      </Comment>}
                                        {p_value[name] &&
                                          numberToCurrency(p_value[name][param])}
                                      </td>
                                    );
                                  })}
                                  <td className={'flex-chart comment-wrapper'}>
                                      {this.state.comments && <Comment 
                                        refreshComments={()=> this.setComments(this.state.year)}
                                        comments={this.state.comments}
                                        model_reference={'cost_centers' + (this.props.is_budget ? '-orçamento': '')}
                                        month={'total'}
                                        monthName={'total'}
                                        year={this.state.year}
                                        model_reference_id={p_key}>
                                      </Comment>}
                                    <p>{
                                        (p_value &&
                                          Object.entries(p_value)
                                            .map(([k, v])=> k != "total" && v[param])
                                            .reduce(
                                              (a, b) => parseFloat(a || 0) + parseFloat(b || 0),
                                              0
                                            )
                                            .toLocaleString("pt-BR", {
                                              style: "currency",
                                              currency: "BRL"
                                        }))
                                      }</p>
                                      <ResultChart
                                        loadAllYears={()=>{this.setupAllYearsData()}}
                                        loadYear={(year)=> this.loadYear(year)} 
                                        years={this.state.years}
                                        year={this.state.year} 
                                        title={labels[p_key]} 
                                        data={this.dataForFormula(p_value)} 
                                        param={param}
                                        p_key={p_key}
                                        dataKey={"params"}
                                        formula_id={key}
                                        months={this.props.months}
                                        allYearsData={this.state.allYearsData}
                                        >
                                        </ResultChart>
                                  </td>
                                </tr>}
                                {this.renderChildreenFor(p_key, {
                                  p_key,
                                  param,
                                  key,
                                  p_value
                                })}
                            </React.Fragment>
                            ))}

                          </React.Fragment>
                        );
                      }
                    )}
                    {this.formulaIsGrouped(key) && value[key]["indicators"] && Object.entries(value[key]["indicators"]).map(
                      ([p_key, p_value]) => {
                        
                        let total_indicators = 0
                        return (
                          <React.Fragment>
                            
                              <React.Fragment>
                                {<tr>
                                  <td >
                                    <i className={`pull-left fa}`}/> 
                                    {p_key}
                                  </td>
                                  {Object.entries(months).map(([name, number]) => {
  
                                    total_indicators += p_value?.[name] ? parseFloat(p_value?.[name]) : 0
                                    return (
                                      <td className={'comment-wrapper'}>
                                         {this.state.comments && <Comment 
                                          refreshComments={()=> this.setComments(this.state.year)}
                                          comments={this.state.comments}
                                          model_reference={'indicator-lines'}
                                          month={number}
                                          monthName={name}
                                          year={this.state.year}
                                          model_reference_id={p_key}>
                                        </Comment>}
                                        {
                                          formulas[key].number_format == "percentage" ?
                                            (
                                              p_value && p_value[name] ?
                                                this.percentFormat(p_value[name])
                                              :
                                              "0%"
                                            )
                                          :
                                            (
                                            p_value && p_value[name] ?
                                              numberToCurrency(p_value[name])
                                              :
                                              numberToCurrency(0)
                                            )
                                        }
                                        
  
                                      </td>
                                    );
                                  })}
                                  <td className={'flex-chart comment-wrapper'} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    {this.state.comments && <Comment 
                                        refreshComments={()=> this.setComments(this.state.year)}
                                        comments={this.state.comments}
                                        model_reference={'indicator-lines'}
                                        month={'total'}
                                        monthName={'total'}
                                        year={this.state.year}
                                        model_reference_id={p_key}>
                                      </Comment>}
                                    <p>{
                                      numberToCurrency(total_indicators)
                                    }</p>
                                    <ResultChart
                                      loadAllYears={()=>{this.setupAllYearsData()}}
                                      loadYear={(year)=> this.loadYear(year)} 
                                      years={this.state.years}
                                      year={this.state.year} 
                                      title={p_key} 
                                      data={this.dataForFormula(p_value)} 
                                      p_value={p_value}
                                      p_key={p_key}
                                      dataKey={"indicators"}
                                      formula_id={key}
                                      months={this.props.months}
                                      allYearsData={this.state.allYearsData}
                                      ></ResultChart>
                                  </td>
                                </tr>}
                            </React.Fragment>
                            
                          </React.Fragment>
                        );
                      }
                    )}
                    <tr id="principal" className={`formula-${formulas[key].formula_type}`}>
                      <td>
                        <PopHover 
                            
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}
                            withoutico description={
                              <React.Fragment>
                                {this.state?.formulas?.[key]?.description && <React.Fragment><br></br><div className={"desc"}>{this.state.formulas[key].description}</div><hr></hr></React.Fragment>}
                                <div className={"desc"}>{this.props.formulas[key]}</div>

                              </React.Fragment>
                              }>
                            {key} 
                          </PopHover>
                        </td>
                      {Object.entries(months).map(([name, number]) => {

                        return (
                          <td className={'comment-wrapper'}>
                            {
                              formulas[key].number_format == "percentage" ?
                              (value[key]["result"][name] &&
                                  this.percentFormat(this.tryEval(value[key]["result"][name]))
                              )
                              :
                              (value[key]["result"][name] &&
                                numberToCurrency(
                                  this.tryEval(value[key]["result"][name])
                                )
                              )
                            }
                            {this.state.comments && <Comment 
                              refreshComments={()=> this.setComments(this.state.year)}
                              comments={this.state.comments}
                              model_reference={'formula'}
                              month={number}
                              monthName={name}
                              year={year}
                              model_reference_id={formulas[key]?.id}>
                            </Comment>}
                          </td>
                        );
                      })}

                      <td className={'comment-wrapper'} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        
                        {!formulasTotal[formulas[key].fr_id] && "carregando..."}
                        {formulasTotal[formulas[key].fr_id] &&
                          <React.Fragment>
                            {this.state.comments && <Comment 
                                refreshComments={()=> this.setComments(this.state.year)}
                                comments={this.state.comments}
                                model_reference={'formula' + (this.props.is_budget ? '-orçamento': '')}
                                month={13}
                                monthName={name}
                                year={year}
                                model_reference_id={formulas[key]?.id}>
                              </Comment>}
                            <label data-value={this.tryEval(formulasTotal[formulas[key].fr_id])} style={{width: "100%", paddingRight: 10}}>
                              
                              {this.state?.currentCompany?.initial_cash_formula == formulas?.[key].id || this.state?.currentCompany?.final_cash_formula == formulas?.[key].id ?
                                "-"
                                :
                                this.getNumerFormatted(this.tryEval(formulasTotal[formulas[key].fr_id]) * -1 , formulas[key].number_format)
                              }  
                            </label>
                            <ResultChart
                              loadAllYears={()=>{this.setupAllYearsData(true)}}
                              loadYear={(year)=> this.loadYear(year, false, true)} 
                              years={this.state.years}
                              year={this.state.year} 
                              numberFormat={formulas[key].number_format}
                              title={key} 
                              data={this.dataForFormula(value[key]["result"])}
                              p_key={key}
                              dataKey={"result"}
                              formula_id={key}
                              formula_fr_id={formulas[key].fr_id}
                              months={this.props.months}
                              allYearsData={this.state.allYearsData}
                              formulasTotalByYear={this.state.formulasTotalByYear}
                              ></ResultChart>

                            
                          </React.Fragment>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      {Object.entries(months).map(()=><td></td>)}
                      <td></td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        <LoadingDialog open={this.loading > 0}/>
      </React.Fragment>
    );
  }
}

export default Flow;
const monthDict = {
  "01" : "jan",
  "02" : "fev",
  "03" : "mar",
  "04" : "abr",
  "05" : "mai",
  "06" : "jun",
  "07" : "jul",
  "08" : "ago",
  "09" : "set",
  "10": "out", 
  "11": "nov", 
  "12": "dez", 
}
