import React, { useEffect } from 'react';

const UploadBanksAccountsHandler = () => {
  useEffect(() => {
    const selectElement = document.getElementById('month_upload');

    const handleChange = async (e) => {
      try {
        alert('O arquivo com o saldo será baixado para o seu computador.\n\nAtualize as informações e faça upload novamente para atualizar no sistema.')
        const selectedMonth = e.target.value;
        const res = await axios.get(`/financial_management_bank_acccounts/download_xlsx.xlsx?month=${encodeURIComponent(selectedMonth)}`, { responseType: 'blob' });

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Saldos Bancários.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        alert('Desculpe! Não foi possível baixar o arquivo XLSX.');
        console.error('Não foi possível baixar o arquivo XLSX', error);
      }
    };

    if (selectElement) {
      selectElement.addEventListener('change', handleChange);
    }

    return () => {
      if (selectElement) {
        selectElement.removeEventListener('change', handleChange);
      }
    };
  }, []);

  return null;
};

export default UploadBanksAccountsHandler;
