import React, { Children } from "react";
import PropTypes from "prop-types";
import Fuse from "fuse.js";
import {
  SearchField,
  ApplyButton,
  WhiteBox,
  ParamTitle,
  LImage,
  SelectedRow,
  ValueLabel,
  BlockTitle,
  GreyButton,
  CircleButton,
  Tabs,
  Tab
} from "../../styled_components/balance_sheets.js";
import { BlueButton } from "../../styled_components/default.js";
import RemovedCostCenterCodes from './../RemovedCostCenterCodes'
import LUrl from "L.png";
import Table from './Table'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

var formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});

class Edit extends React.Component {
  constructor(props){
    super(props)
    this.filledRows = 0
    this.state = {
      query: "",
      tabActive: null,
      paramsQuery: "",
      pattern: { contabil_pattern_params: { data: [] } },
      configuredPattern: {},
      sheet_selected: [],
      treeLabelList: {},
      sumResult: {},
      param_selected: null,
      stage_text: "Concluir",
      selectedVisibility: false,
      optionalBranchEnd: {},
      rootChangeEnabled: false,
      costCenterAreas: {},
      fixedCodes: this.props.fixed_codes,
      bannedCodes: [],
      hashBannedCodes: {}
    };
  }
  
  save() {
    this.saveParamPatterns();
  }

  saveParamPatterns() {
    this.setState({ saving: true, stage_text: "Salvando..." });
    const { configuredPattern, fixedCodes } = this.state;

    axios
      .post("/cost_center_parametrizations", { cost_center_parametrizations: configuredPattern, fixed_codes: fixedCodes })
      .then(result => {
        this.setState({
          saving: false,
          stage_text: "Concluido, Redirecionando..."
        });
        if(this.props.is_cost_center_type){
          Turbolinks.visit(`/cost_centers/${this.props.cost_center_id}/setup_fixed`);  
        }else{
          alert("Parametrização de centro de custos concluida")
          Turbolinks.visit(`/`);  
        }
        
      });
  }

  //// getSumForParameters() {
  //   const { configuredPattern } = this.state;

  //   axios
  //     .post(
  //       `/cost_centers/${this.props.cost_center_id}/configured_patterns/sum`,
  //       {
  //         cost_center_parametrizations: configuredPattern
  //       }
  //     )
  //     .then(result => {
  //       this.setState({ sumResult: result.data });
  //     });
  // }

  fetchConfiguredPatterns() {
    axios.get("/cost_center_parametrizations").then(result => {
      this.setState({ configuredPattern: result.data });
      if (!this.state.initialFetchForConfiguredPattern) {
        let array = Object.values(result.data);
        [...new Set(array)].map(key => {
          this.fetchTreeLabelFor(key);
        });
        this.setState({ initialFetchForConfiguredPattern: true });
      }
    });
  }

  

  async fetchResultsParameters() {
    let result = await axios.get("/cost_center_areas.json")
    
    const mapHash = (obj, item) => {
      obj[item.id] = item;
      return obj;
    };

    let data = result.data.reduce(mapHash, {});


    this.setState({costCenterAreas: data})
  }

  componentDidMount() {
    this.fetchConfiguredPatterns();
    // this.fetchContabilPattern();
    this.fetchResultsParameters();
    this.fetchRemovedCodes();
  }

  

  sumForRow(row) {
    return this.state.sumResult[row.key] || 0;
  }

  paramCalc(ctp = {}) {
    let row = ctp.attributes;

    if (row.childreen && row.childreen.data.length > 0) {
      return row.childreen.data
        .map(child => this.paramCalc(child))
        .reduce((a, b) => a + b);
    } else {
      return this.sumForRow(row);
    }
  }

  checkVirtualizedConflict(){
    let {param_selected, sheet_selected} = this.state;
    
    if(param_selected && sheet_selected.length > 0){
      axios.get('/cost_center_parametrizations/check_virtualized_conflict', {params:{
        param_selected,
        sheet_selected
      }}).then((data)=>{
        let conflict = `Conflitos na parametrização de outras filiais\n\n\n`
        data.data.map((conf)=>{
          conflict += `a empresa ${conf.company_name} configurou o código ${conf.balance_code} no parametro ${conf.current_param}\n\n`
        })
        if(data.data.length > 0){
          alert(conflict)
        }
        
      })
    }
    

    
  }

  setupSelectedParam(e){
    let self = this;
    this.setState({ param_selected: e.target.value })

    setTimeout(()=>{
      self.checkVirtualizedConflict()
    }, 100)
    
  }

  setupParam() {
    let self = this;
    this.setState(oldState => {
      oldState.sheet_selected.map(code => {
        oldState.configuredPattern[code] = oldState.param_selected;

        // self.fetchTreeLabelFor(oldState.param_selected);
        oldState.treeLabelList[code] = oldState.param_selected;
      });
      oldState.sheet_selected = [];
      oldState.param_selected = null;
      return { ...oldState };
    });
    //this.getSumForParameters();
  }

  isSetable() {
    return this.state.sheet_selected.length > 0 && this.state.param_selected;
  }

  toggleRecordSelected(code) {
    if (this.isRecordSelected(code)) {
      this.setState(oldState => {
        oldState.sheet_selected = oldState.sheet_selected.filter(
          incode => incode != code
        );
        return { ...oldState };
      });
    } else {
      this.setState(oldState => {
        oldState.sheet_selected.push(code);
        return { ...oldState };
      });
    }
  }

  isRecordSelected(code) {
    return this.state.sheet_selected.includes(code);
  }

  currencyFormat(ctp) {
    let floatValue = this.paramCalc(ctp);
    let value = formatter.format(this.paramCalc(ctp));
    if (floatValue > 0) {
      return `${value} D`;
    } else if (floatValue < 0) {
      return `${value} C`;
    } else {
      return `${value}`;
    }
  }

  filterParamConnected(row){
    
    if (this.state.filteredRow != row){
      this.setState({filteredRow: row})
    }else{
      this.setState({filteredRow: null})
    }
    this.forceUpdate();
  }

  renderParams(contabil_pattern_params, level = 0, params={}) {
    
    let htmlRender = Object.values(contabil_pattern_params).map(ctp => {
      let row = ctp;
      
      return (
        <div>
          <ParamTitle level={level} head={row.head}>
            <div style={{ textAlign: "left" }}>
              
              <i 
                onClick={()=> this.filterParamConnected(row)} 
                style={{fontSize: 14, cursor: 'pointer'}}
                className={`fa ${row == this.state.filteredRow ? "fa-eye-slash" : "fa-eye"}`}
              ></i>
              <span class="radio">
                <label for="company_category_group_type">
                  <input
                    onChange={e =>
                      this.setupSelectedParam(e)
                    }
                    class="radio_buttons optional"
                    type="radio"
                    checked={row.id == this.state.param_selected}
                    value={row.id}
                    name="pattern_param"
                  />
                </label>
              </span>
              

              {row.label}
            </div>
            
            {/* <GreyButton className={"margin-left-10"}>
              <i className={"fa fa-eye"}></i>
            </GreyButton> */}
          </ParamTitle>
          
        </div>
      );
    });
    return htmlRender;
  }
  removeRecord(record){
    this.setState((oldState)=>{
      delete oldState.configuredPattern[record.attributes.code]
      return {...oldState}
    })
    //this.getSumForParameters();
  }


  recordIsSetted(code) {
    return Object.keys(this.state.configuredPattern).includes(
      code
    );
  }

  recordIsSettedFor(record) {
    return this.state.configuredPattern[record.attributes.code];
  }

  treeLabel(code) {
    // let key = this.state.configuredPattern[code];
    return this.state.treeLabelList[code];
  }

  toggleCheckness(record) {
    this.setState(oldState => {
      oldState.optionalBranchEnd[record.attributes.code] = !oldState
        .optionalBranchEnd[record.attributes.code];
      return { ...oldState };
    });
  }

  addedAsBranchEnd(record) {
    return this.state.optionalBranchEnd[record.attributes.code] == true;
  }

  setupBannedData(response){
    this.setState({bannedCodes: response.data})
    let hashData = {}
    response.data.map((code)=>{
      hashData[code] = true
    })
    this.setState({hashBannedCodes: hashData})

  }

  async restoreCode(code){
    let confirmation = confirm(`Tem certeza de que deseja restaurar o código '${code}'?` )
    if(confirmation){
      let response = await axios.post('/banned_codes/restore.json', {code})
      this.setupBannedData(response)
    }
    
  }

  async banCode(code){
    let confirmation = confirm(`Tem certeza de que deseja excluir o código '${code}'?` )
    if(confirmation){
      let response = await axios.post('/banned_codes/ban.json', {code})
      this.setupBannedData(response)
    }
    
  }
  componentDidUpdate(){
    // isso é uma gambiarra, e eu sei disso

    self = this
    setTimeout(()=>{
        self.filledRows = $('#table-body tr').length
        self.setState({updatingView: true})
      }, 100)  
    
    
    
    
  }

  renderRows(){
    let filteredData = this.filtered()
    
    let allRows = filteredData.map((record) => {
      let renderedRow = this.renderRow(record)
      return <React.Fragment key={record.attributes.code}>{renderedRow}</React.Fragment>
    })
    return <React.Fragment>{allRows}</React.Fragment>
    
  }

  renderRow(record) {
    const {costCenterAreas, hashBannedCodes} = this.state;
    
    if (hashBannedCodes[record.attributes.code]) {
      
      return false
    }
    if (!((this.state.fixedCodes[record.attributes.code] == true) || (this.state.fixedCodes[record.attributes.code] == false))) {
      this.state.fixedCodes[record.attributes.code] = true
    }
    
    if (this.recordIsSettedFor(record)) {
      if (this.state.selectedVisibility) {
        return (
          <SelectedRow>
            <td style={{ color: "white" }}>{record.attributes.code}</td>
            <td style={{ color: "white" }}>
              {record.attributes.description}
            </td>
            <td style={{ color: "white" }}>
              {
                !(record.attributes.operacional && this.props.is_cost_center_type) && (this.state.fixedCodes[record.attributes.code] ? "Fixo" : "Variável")
              }
            </td>
            <td
              style={{ color: "white", textAlign: "right", paddingRight: 20 }}
              colSpan={2}
            >
              Destino - {(costCenterAreas && costCenterAreas[this.recordIsSettedFor(record)]) && costCenterAreas[this.recordIsSettedFor(record)].label}
            </td>
            <td>
              <div 
                onClick={()=> this.removeRecord(record)}
                style={{borderRadius: 8,
                        backgroundColor: "white",
                        width: 80,
                        textAlign: "center",
                        margin: 16,
                        cursor: 'pointer',
                        textTransform: 'lowercase'}}>
                remover
              </div>
            </td>
          </SelectedRow>
        );
      } else {
        return null;
      }
    } else {
      return (
        <tr>
          <td>
            <div style={{ display: "flex" }}>
              {this.state.rootChangeEnabled && (
                <a onClick={() => this.toggleCheckness(record)}>
                  {!record.attributes.is_branch_end &&
                    !this.addedAsBranchEnd(record) && (
                      <i className={"margin-right-10 fa fa-eye"} />
                    )}
                  {this.addedAsBranchEnd(record) && (
                    <i className={"margin-right-10 fa fa-eye-slash"} />
                  )}
                </a>
              )}
              {(record.attributes.is_branch_end ||
                this.addedAsBranchEnd(record)) && (
                <span class="radio">
                  <input
                    onChange={e => this.toggleRecordSelected(e.target.value)}
                    class="radio_buttons optional"
                    type="checkbox"
                    checked={this.isRecordSelected(record.attributes.code)}
                    value={record.attributes.code}
                  />
                </span>
              )}
            </div>
          </td>
          {!this.props.is_cost_center_type && <td>{record.attributes.balance_sheet_code}</td>}
          {this.props.is_cost_center_type && 
            <td>{record.attributes.code}</td>}
          
          <td colSpan={this.props.is_cost_center_type ? 2 : 1}>{record.attributes.description}</td>
          <td colSpan={2}>
            {/*(!this.props.is_cost_center_type || !record.attributes.operacional)*/ true &&
              <React.Fragment>
                <small style={{
                  marginRight: 16,
                  opacity: !this.state.fixedCodes[record.attributes.code] ? 1 : 0.5
                }}>Variável</small>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.fixedCodes[record.attributes.code]}
                      onChange={(e)=> this.handleIsFixed(record.attributes.code, e)}
                      color="primary"
                    />
                  }
                  label=""
                />
                <small style={{opacity: this.state.fixedCodes[record.attributes.code] ? 1 : 0.5}}>Fixo</small>
              </React.Fragment>
            }
          </td>
          <td onClick={()=> this.banCode(record.attributes.code)} style={{width: 20}}><i className={' trash-cc pointer fa fa-trash'}/></td>
        </tr>
      );
    }
  }

  handleIsFixed(code, event){

    if(!event.target.checked){
      this.setState((oldState)=>{
        oldState.fixedCodes[code] = false
        return {...oldState}
      })
    }else{
      this.setState((oldState)=>{
        oldState.fixedCodes[code] = true
        return {...oldState}
      })
    }
  }

  filtered() {
    const { cost_center_records } = this.props;
    const {filteredRow} = this.state;

    let filteredData = []

    if (filteredRow) {
      let cp = this.state.configuredPattern
      let filteredCodes = Object.entries(cp).filter(([k,v]) => (v == filteredRow.id)).map(([k,v]) => k)
      
      filteredData = cost_center_records.data.filter((f)=> filteredCodes.includes(f.attributes.code))
    }else{
      filteredData = cost_center_records.data
    }

    if (this.state.query.length < 1) {
      return filteredData;
    }

    var options = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["attributes.code", "attributes.description"]
    };
    var fuse = new Fuse(filteredData, options); // "list" is the item array
    var result = fuse.search(this.state.query);
    return result;
  }

  toggleSelectedVisibility() {
    this.setState({ selectedVisibility: !this.state.selectedVisibility });
  }

  toggleRootEnabled() {
    this.setState({ rootChangeEnabled: !this.state.rootChangeEnabled });
  }

  async fetchRemovedCodes(){
    let result = await axios.get('/banned_codes.json')
    this.setupBannedData(result)
    
  }

  showContasRemovidas(){

  }
  render() {
    const { cost_center_records } = this.props;
    const { tabActive, bannedCodes } = this.state;
    const { contabil_pattern_params } = this.state.pattern;
    let filteredData = this.filtered()
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 4.1 }}>
            <BlockTitle>
              PLANO DE CONTAS ANTIGO
              <div style={{ display: "flex" }}>
                {/*<GreyButton 
                  style={{ marginRight: 10 }}
                  onClick={this.showContasRemovidas.bind(this)}>
                   Contas removidas
                </GreyButton>*/}
                <RemovedCostCenterCodes 
                  bannedCodes={bannedCodes} 
                  restoreCode={(code)=> this.restoreCode(code)}
                  banCode={(code)=> this.banCode(code)}/>
                <GreyButton onClick={this.toggleSelectedVisibility.bind(this)}>
                  <i
                    className={
                      this.state.selectedVisibility
                        ? "fa fa-eye-slash"
                        : "fa fa-eye"
                    }
                    style={{ marginRight: 10 }}
                  />
                  {this.state.selectedVisibility ? "Ocultar" : "Mostrar"} contas
                  parametrizadas
                </GreyButton>
                <CircleButton
                  style={{ marginLeft: 10 }}
                  onClick={this.toggleRootEnabled.bind(this)}
                >
                  <i
                    className={
                      !this.state.rootChangeEnabled
                        ? "fa fa-lock"
                        : "fa fa-unlock"
                    }
                  />
                </CircleButton>
              </div>
            </BlockTitle>
            <SearchField
              placeholder={"Buscar item na planilha"}
              onChange={e => {
                let newValue = e.target.value;
                this.setState({ query: newValue });
              }}
            ></SearchField>
            <div
              style={{
                height: "60vh",
                overflowY: "scroll",
                marginTop: 12,
                borderRadius: 5,
                border: "solid 1px #c4c4c4"
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {!this.props.is_cost_center_type && <th> Conta </th>}
                    {this.props.is_cost_center_type && 
                      <th> Código CC </th>
                    }
                    <th> Descrição </th>
                    <th colSpan={3}> Fixo/Variável </th>
                  </tr>
                </thead>
                <tbody id="table-body">
                  {this.renderRows()}
                </tbody>
              </table>
              {this.filledRows == 0 && 
                <div
                style={{
                  color: "#2A3170",
                  textAlign: "center"
                }}
                >Todas as contas estão parametrizadas</div>}
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0 16px" }}
          >
            <ApplyButton
              disabled={!this.isSetable()}
              onClick={this.setupParam.bind(this)}
            >
              Aplicar
            </ApplyButton>
          </div>
          <div style={{ flex: 2.3 }}>
            <BlockTitle>PLANOS DE CONTAS EMPRESA</BlockTitle>
            <SearchField
              placeholder={"Buscar item no plano de conta"}
              onChange={e => {
                let newValue = e.target.value;
                this.setState({ paramsQuery: newValue });
              }}
            ></SearchField>
            <WhiteBox style={{ padding: 16 }}>
              {this.renderParams(this.state.costCenterAreas)}
            </WhiteBox>
          </div>
        </div>
        <hr></hr>
        <div style={{ display: "flex",
                      justifyContent: "flex-end",
                      borderTop: "1px solid darkgrey",
                      height: 80,
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      backgroundColor: 'white',
                      position: 'fixed',
                      paddingRight: 16

                       }}>
          <BlueButton
            disabled={this.state.saving}
            style={{ alignSelf: "center", marginRight: 20 }}
            className={"right"}
            onClick={() => this.save()}
          >
            {this.state.stage_text}
          </BlueButton>
        </div>
      </div>
    );
  }
}

export default Edit;
