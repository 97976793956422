import React, { useState, useEffect } from 'react';
import MonthRangeInput from "./MonthRangeInput";
import { ListMonthsValue, ListMonthsKey } from "../constants";

const FilterDate = ({ currentDate }) => {
  const [date, setDate] = useState(() => {
    const { search } = document.location;
    const query = new URLSearchParams(search);
    const paramsDate = query.get('year');

    if (paramsDate) {
      const [month, year] = paramsDate.split('/');
      const monthNumber = ListMonthsValue[month] + 1;
      return new Date(`${monthNumber}-01-${year}`);
    }

		if(!currentDate || currentDate === "Nenhuma Atualização") return new Date(); 

    const [year, month, day] = currentDate.split('/')
    return new Date(`${month}-${day}-${year}`);
  });;


  const handleDateChange = (newDate) => {
    setDate(newDate);
    Turbolinks.visit(`/?year=${ListMonthsKey[newDate.getMonth()]}/${newDate.getFullYear()}`);
  };

  return (
    <div className="input date" style={{marginRight: 25}}>
      <MonthRangeInput
        date={date}
        onChange={handleDateChange}
      />
    </div>
  );
};

export default FilterDate;

