import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import calendarIco from 'bt_calendar.svg';
Highcharts.setOptions({
  colors: ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"]
});

export default function MonthFilter(props) {
  const [open, setOpen] = React.useState(false);
  let visibleMonths = props.visibleMonths || {}
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }
  function handleChange(e){
    console.log(e)
    props.changeVisibleMonths(e)
    

  }
  return (
    <div>
      
	  <div style={{marginRight: 10, cursor: 'pointer', color: 'rgb(36,44,86)', fontWeight: "bold"}}  onClick={handleClickOpen}>
      <img title={'Filtrar Meses'} src={calendarIco} style={{width: 36}}/>
	  </div>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'xs'}
      >
        <DialogTitle id="simple-dialog-title">Meses visíveis</DialogTitle>
        <DialogContent >
          <FormGroup>
            {Object.entries(props.months).map(([name, number]) => {
              return <FormControlLabel
                control={<Checkbox checked={visibleMonths[name]} onChange={handleChange} name={name} />}
                key={number + "_month"}
                label={name}
              />
            })}
            
        </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}



