import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import PieOperationIncome from './home/PieOperationIncome';
import { ListMonths, ListMonthsPtBR, monthPtBR, monthsEnToBR, ListMonthsKey } from '../constants'

export default function WaterfellFlowCash({uploadDate}) {
  const [isOpen, setIsOpen] = useState(false);
	const [typeOperation, setTypeOperation] = useState('');
	const [typeCascade, setTypeCascade] = useState('');
	const [movement, setMovement] = useState('');
	const [title, setTitle] = useState('');
	const [titleChart, setTitleChartPie] = useState('');
	const [isOpenChartBar, setIsOpenCharBar] = useState(false);
	const [cascadeData, setCascadeData] = useState(null);
	const [total, setTotal] = useState(0);
	const [dateParse, setDateParse] = useState(null);

	HC_more(Highcharts);

	const fetchData = async () => {
		const { search } = document.location;
		const query = new URLSearchParams(search);
		const paramsDate = query.get('year')

		try {
			let dateFilter;
			if(!paramsDate) {
				if(uploadDate === "Nenhuma Atualização") {
					const today = new Date();
					dateFilter = `${ListMonthsKey[today.getMonth()]}/${today.getFullYear()}`
				} else {
					const [ year, month, day ] = uploadDate.split('-')
					dateFilter = `${ListMonths[month]}/${year}`
				}
			} else {
				dateFilter = paramsDate
			}
			setDateParse(dateFilter)
			const response = await fetch(`/home/cascade_chart_data?year=${dateFilter}`)

			if(!response.ok) throw new Error('Erro fetch data cascade')

			const result = await response.json()
			setCascadeData(result)
		} catch (err) {
			console.log('Ocorreu um erro ao buscar os dados do gráfico cascata', err)	
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	const onClose =() => {
		setIsOpenCharBar(false)
		setIsOpen(false)
	}

  const handlePieOperationIncome = (event, typeCascade) => {
		const { name, activity, movement, y }   =  event.point

		setTypeOperation(activity) 
		setMovement(movement)
		setTypeCascade(typeCascade)
		setTitle(name)
    setIsOpen(true)
		setTotal(y)

		defineTitleChartPie(typeCascade)
  }

	const defineTitleChartPie = (typeCascade) => {
		const [month, year] = dateParse.split("/")
		if(typeCascade === "monthly") {
			setTitleChartPie(`${monthsEnToBR[month]} / ${year}`)
		} else {
			setTitleChartPie(`JANEIRO À ${monthsEnToBR[month]} / ${year}`)
		}
	}
  
  const options = (title, data, typeCascade) => {
    return {
      chart: {
        type: 'waterfall',
       },
       title: {
           text: title
       },
       xAxis: {
           type: 'category'
       },
       yAxis: {
           title: {
               text: 'R$'
           }
       },
       legend: {
           enabled: false
       },
       tooltip: {
           pointFormat: '<b>R$ {point.y:,.2f}</b>'
       }, 
       plotOptions: {
        waterfall: {
          events: {
            click: function(event) {
							handlePieOperationIncome(event, typeCascade)
						}
          },
          borderWidth: 0
        }
       },
       series: data.map(obj => ({
        ...obj,
        dataLabels: {
            enabled: true,
            format: '<b>R$ {point.y:,.2f}</b>',
            align: 'center',
            verticalAlign: 'bottom', // Para alinhar com a parte superior da barra
            inside: false
        }
      })) 
    }
  }

  if (!cascadeData) {
    return (
      <Skeleton height={300} />
    );
  }


	const [month, year] = dateParse.split("/")

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{flex: 1, maxWidth: '50%'}}>
          <HighchartsReact highcharts={Highcharts} options={options('Evolução Mensal Realizado', cascadeData.monthly, 'monthly')} />
        </div>
        <div style={{flex: 1, maxWidth: '50%'}}>
          <HighchartsReact highcharts={Highcharts} options={options('Evolução Anual Realizado', cascadeData.year_to_date, 'year_to_date')} />
        </div>
      </div>

      <PieOperationIncome 
				isOpen={isOpen} 
				typeOperation={typeOperation}
				typeCascade={typeCascade}
				movement={movement}
				title={title}
				titleChart={titleChart}
				month={month}
				year={year}
				total={total}
				onClose={() => onClose()} />
    </>
    )
}

