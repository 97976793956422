import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Card from './Card'
import { ListMonths, ListMonthsPtBR, ListMonthsKey } from '../../constants'
import { Grid } from '../../styled_components/default'
import icon_total from 'widget_total';
import icon_received from 'widget_received';
import icon_payments from 'widget_payments';

export default function Widgets({uploadDate}){
	const [dataWidget, setDataWidgets] = useState(null);

	const fetchData = async () => {
		const { search } = document.location;
		const query = new URLSearchParams(search);
		const paramsDate = query.get('year')

		try {
			let dateFilter;
			if(!paramsDate) {
				if(uploadDate === "Nenhuma Atualização") {
					const today = new Date();
					dateFilter = `${ListMonthsKey[today.getMonth()]}/${today.getFullYear()}`
				} else {
					const [ year, month, day ] = uploadDate.split('-')
					dateFilter = `${ListMonths[month]}/${year}`
				}
			} else {
				dateFilter = paramsDate
			}

			const response = await fetch(`/home/widgets_data?year=${dateFilter}`)

			if(!response.ok) throw new Error('Fetch data error')

			const result = await response.json()
			setDataWidgets(result);
		} catch (err) {
			console.log('Ocorreu um erro ao buscar os dados dos Widgets')
		}
	}

	useEffect(() => {
		fetchData()
	}, [])


  if (!dataWidget) {
    return (
			<Skeleton height={100} />
    );
  }

	return (
		<>
			<Grid> 
				<Card 
					title={'TOTAL DAS CONTAS'} 
					amount={dataWidget.balances?.banks_saldo_total} 
					color={'#0099D8'} 
					colorPercent={'#0a7cb4'}
          colorIcon={'#0589BF'}
					balanceReal={dataWidget.balances?.banks_saldo_conta}
					balanceProvided={dataWidget.balances?.banks_application}
					subTitlePercent={'Conta Corrente'}
					subTitlePercentPrev={'Aplicação'}
					typeGraph={'pie'}
					dataChart={dataWidget.balances?.banks_gp}
          icon={icon_total}
				/>
				<Card 
					title={'RECEBER (TOTAL)'} 
					amount={dataWidget.widgets.recebido_receber} 
					balanceReal={dataWidget.widgets.recebido}
					balanceProvided={dataWidget.widgets.receber}
					color={'#21ca97'} 
          colorIcon={'#03AB79'}
					colorPercent={'#0BB17F'}
					subTitlePercent={'Recebidos'}
					subTitlePercentPrev={'A receber'}
					typeGraph={'bar'}
					dataChart={dataWidget.balances?.monthly_budget["DATA_RECEIVED"]}
          icon={icon_received}
				/>
				<Card 
					title={'PAGAR (TOTAL)'} 
					amount={dataWidget.widgets.pago_pagar} 
					balanceReal={dataWidget.widgets.pago}
					balanceProvided={dataWidget.widgets.pagar}
					color={'#ca2149'} 
					colorPercent={'#A21033'}
          colorIcon={'#B0183C'}
					subTitlePercent={'Pagos'}
					subTitlePercentPrev={'A Pagar'}
					typeGraph={'bar'}
					dataChart={dataWidget.balances?.monthly_budget["DATA_PAYABLE"]}
          icon={icon_payments}
				/>
			</Grid>
		</>
	)
}
