import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import html2pdf from 'html2pdf.js';
import BarIncomeWithSales from './BarIncomeWithSales'
import { FlexCenter, Title, GreenButton } from '../../styled_components/default'
import { ListMonths } from '../../constants'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

HC_more(Highcharts);

export default function PieOperationIncome({
	isOpen, 
	onClose, 
	typeOperation, 
	typeCascade, 
	movement, 
	month, 
	year, 
	title, 
	total,
	titleChart}) {
	const [openChartBar, setOpenChartBar] = useState(false);
	const [data, setData] = useState([]); 
	const [dataWithSales, setDataWithSales] = useState({});
	const [dataBar, setDataBar] = useState('');
	const [dataCategoryBar, setCategoryBar] = useState('');
	const [titleBar, setTitleBar] = useState('');
	const [loading, setLoading] = useState(false);

	const fetchData = async () => {
		try {
			const indexMonth = Object.entries(ListMonths).find(([key, val]) => val === month)?.[0]

			setLoading(true)
			const response = await 
				fetch(`/home/pie_chart_details?year=${year}
							&month=${indexMonth}
							&activity=${typeOperation}
							&movement=${movement}
							&type_cascade=${typeCascade}`)

			if(!response.ok) {
				throw new Error('Failed to fetch data');
			}
			const result = await response.json()
			setData(result)
			setLoading(false)
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	useEffect(() => {
		setLoading(true)
		fetchData();
	}, [isOpen])

	useEffect(() => {
		setOpenChartBar(false)
	}, [onClose])


	const downloadPdf = () => {
		const element = document.getElementById('pie-chart')
		const hiddenElement = document.getElementById('hidden_in_pdf')
		const screenWidth = window.screen.width;
		const screenHeight = window.screen.height;


		hiddenElement.hidden = true
		let opt = {
			html2canvas: { windowHeight: screenHeight, windowWidth: screenWidth },
			jsPDF: { unit: 'px', format: [screenWidth, screenWidth], orientation: 'portrait', userUnit: 2 }
		}
		html2pdf().set(opt).from(element).save();

		setTimeout(()=>{
			hiddenElement.hidden = false
		}, 2000)
	}

	const handleDrillDown = (event) => {
		const name  = event.point.name
		const filterData = data.details.filter((d) => d.label == name)[0]
		setDataWithSales(filterData)

		dataForChartBar(filterData)
		setOpenChartBar(true);
	}

	const dataForChartBar = (data) => {
		const { days, months, label } = data

		setTitleBar(label);
		if(days){
			setDataBar(Object.values(days))
			setCategoryBar(Object.keys(days))
		}
		if(months){
			setDataBar(Object.values(months))
			setCategoryBar(Object.keys(months))
		}
	}
	const series = [{
		name: 'Browsers',
		colorByPoint: true,
		data: data.contabil_patterns
	}]

	function formatterTooltip(point) {
		const value = point.y
		const formattedValue = movement === "despesa" 
			? `-R$ ${Highcharts.numberFormat(value, 2, ',', '.')}` 
			: `R$ ${Highcharts.numberFormat(value, 2, ',', '.')}`;

		return `<b>${formattedValue}</b>`;
	}

	const options =  {
		chart: {
			type: 'pie'
		},
		title: {
			text: `${titleChart}`
		},
		xAxis: {
			type: 'category'
		},
		yAxis: {
			title: {
				text: 'R$'
			}
		},
		legend: {
			enabled: false
		},
		tooltip: {
			formatter: function() {
				return formatterTooltip(this)
			}
		}, 
		plotOptions: {
			pie: {
				events: {
					click: function(event) {
						handleDrillDown(event)
					}
				}
			}, 
			series: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: [{
					enabled: true,
					format: '<h4>{point.name}</h4><br><h4>{point.percentage:.1f}%</h4>',
					style: {
						fontSize: '12px',
					}
				}
				]
			}
		},
		series: series
	} 

	if(loading) {
		return (
			<Skeleton />
		)
	}

	return (
		<Modal open={isOpen} onClose={onClose} center>
			<div id="pie-chart">
				<FlexCenter style={{ width: 800, display: 'flex' }}>
					<Title style={{ display: 'flex'}}>
						{title}
						<span style={{ color: total >= 0 ? 'green' : 'red' }}>{total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
					</Title> 
				</FlexCenter>
			<HighchartsReact highcharts={Highcharts} options={options} />
				{openChartBar && <BarIncomeWithSales 
						dataCategories={dataCategoryBar} 
						dataSeries={dataBar}
						title={titleBar} />}
			</div>
		</Modal>
	)
}
