import React from "react";
import LoadingDialog from './../LoadingDialog'
import Comment from './../Comment'
import MonthFilter from './../MonthFilter'
import popupIco from 'popup-ico.svg';
import {popupCenter} from './../popup/popupCenter'
import olhoOn from 'bt_olho_on.svg'
import olhoOff from 'bt_olho_off.svg'
import ResultChart from "../ResultChart";
import ResultChartWeek from "../ResultChartWeek";
import downloadIco from 'bt_download.svg';
import DetailingResult from "../DetailingResult";
import ManualLaunch from "../ManualLaunch";

class Balance extends React.Component {

  constructor(props){
    super(props)
    this.requestsCounter = 0
    this.state ={
      recordsFetchedByYear: {},
      liquidProfitFormula: {},
      allData: {},
      allYearsData: {},
      showZeroLines: false,
      value_initial_balance: 0,
      day_number_selected: 0,
      year: this.props.defaultYear,
      month: this.props.defaultMonth,
      default_days: {},
      default_weeks: {},
      weeks_foreseen_accomplished: {},
      code_contabil_pattern_param_list: {},
      activeBranches: {},
      recordsFetched: {},
      activeKeys: {},
      loading: false,
      data: [],
      data_balance: [],
      years: [],
      days: [],
      resultIds: [],
      cash_flow_formula_ids: {},
      activeRows: {},
      periods: {},
      weeks: {
        "1" : 1,
        "2" : 2,
        "3" : 3,
        "4" : 4,
        "5" : 5
      },
      result_months: {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0
      },
      visibleMonths: {
        "1" : true,
        "2" : true,
        "3" : true,
        "4" : true,
        "5" : true
      },
      visibleWeeks: {
        "1" : true,
        "2" : true,
        "3" : true,
        "4" : true,
        "5" : true
      },
      months: props.months,
      result_operational_week: {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0

      },
      result_not_operational_week:  {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0
      },
      initial_balance_week: {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0
      },
      final_balance_week: {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0

      },
      vls_saldo_bank: {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0
      },
      check_bank: {
        "1" : 0,
        "2" : 0,
        "3" : 0,
        "4" : 0,
        "5" : 0
      }

    }
  }

  async fetchInitialParams(year=null,changeData=true, weeks=null){

    console.log(" inicial fetchInitialParams")
    this.setState({loading: true})
    this.requestsCounter += 1
    let result = await axios.get('/results/balances_by_week.json', {params: {
        year: year,
        weeks: weeks

      }})
    this.requestsCounter -= 1
    console.log(" final fetchInitialParams")
    this.setState({loading: false})
    if(changeData){

      this.setState({cash_flow_formula_ids: result.data.cash_flow_formula_ids})
      this.setState({data: result.data.params})
      {/*this.setState({result: result.data.result})*/}
      this.setState({result: result.data.result})
      {/*this.setState({resultIds: result.data.result.result_ids})*/}
      this.setState({resultIds: {}})
    }

    this.setState({allYearsData:{
        ...this.state.allYearsData,
        [year]: result.data.result
      }})

  }


  async fetchDetail(key, year=this.state.year, hide=true, updateLocal=true, week = this.state.month){
    const {recordsFetched} = this.state;
    debugger
    if(recordsFetched[key] && hide){
      this.setState((oldState)=>{
        oldState.activeKeys[key] = {}
        oldState.recordsFetched[key] = null
        return {...oldState}
      })
    }else{
      let details = await axios.get(`/results/${key}/detail.json`, {params: {
          year: year,
          value_param: 'f_saldo_atual',
          type: 'week',
          week: week
        }})

      this.setState((oldState)=>{
        let dataFetched = {}
        if (Object.values(Object.values(details.data)[0].childreen).length > 0){
          dataFetched = Object.values(details.data)[0].childreen
        }else{
          dataFetched = Object.values(details.data)[0].lines
        }

        if(updateLocal){
          oldState.recordsFetched[key] = dataFetched
        }

        oldState.recordsFetchedByYear[year] ||= {}
        oldState.recordsFetchedByYear[year][key] = dataFetched
        return {...oldState}
      })
    }

  }

  async setComments(){

    let comments = await axios.get('/comments.json', {params:{
        year: [this.state.year, 0]
      }})


    this.setState({comments: comments.data})
  }
  async getCheckBank(year, week,day_name,number) {


    await axios.get(`/results/get_bank_statements_week.json`, {
      params: {
        year: year,
        week: week,
        number: number
      }
    }).then(response => {
      this.setState((oldState)=>{
        oldState.vls_saldo_bank[number] =  response.data.value
        return {...oldState}
      })

    })

  }
  async exportResult(month,year, initial_balance,  final_balance, result_operational, result_not_operational,vls_saldo_bank,check_bank){
    this.setState({loading: true})
    this.requestsCounter += 1
    await  axios.get(`/export/export_balance`, {params: {
        year: year,
        month: month,
        type_export: 'week',
        initial_balance: initial_balance,
        final_balance: final_balance,
        result_operational: result_operational,
        result_not_operational: result_not_operational,
        vls_saldo_bank: vls_saldo_bank,
        check_bank: check_bank
      }}).then((result)=>{

      window.location.href = "/export/balance_xlsx/" + year + "/" + result.data.filename
    })
    this.requestsCounter -= 1
    this.setState({loading: false})
  }
  async setYears(){
    this.requestsCounter += 1
    let years = await axios.get('/balance_sheets/years.json')
    this.requestsCounter -= 1
    this.setState({years: years.data})
    this.setComments()

  }
  async setWeeks(year, month){
    await axios.get(`/results/weeks.json`, {
      params: {
        month: month,
        year: year
      }
    }).then(response => {
      this.setState({default_weeks:  response.data.weeks_of_the_week })
      this.setState({weeks_foreseen_accomplished:  response.data.weeks_foreseen_accomplished })

    })
  }

  async fetchDataParams(year=null, month,label){


    this.setState((oldState)=>{
      oldState.allData[year] ||= {}
      return {...oldState}
    })

    if(label ==="Operacional" ){
    //  allData[year][month] = {"result_operational":  this.state.result_operational}
      this.setState((oldState)=>{
        oldState.allData[year][month] = {"result_operational":  this.state.result_operational_week}
        return {...oldState}
      })
    }else{
      if(label ==="Não Operacional" ){
        this.setState((oldState)=>{
          oldState.allData[year][month] = {"result_not_operational":  this.state.result_not_operational_week}
          return {...oldState}
        })
      }else{
        if(label ==="initial_balance"){
          this.setState((oldState)=>{
            oldState.allData[year][month] = {"initial_balance":  this.state.initial_balance_week}
            return {...oldState}
          })
        }else{
          if(label ==="final_balance"){
            this.setState((oldState)=>{
              oldState.allData[year][month] = {"final_balance":  this.state.final_balance_week}
              return {...oldState}
            })
          }else{
            if(label ==="check_bank"){
              this.setState((oldState)=>{
                oldState.allData[year][month] = {"check_bank":  this.state.check_bank}
                return {...oldState}
              })
            }else{
              if(label ==="vls_saldo_bank"){
                this.setState((oldState)=>{
                  oldState.allData[year][month] = {"vls_saldo_bank":  this.state.vls_saldo_bank}
                  return {...oldState}
                })
              }else{
                if(label ==="result_months"){
                  this.setState((oldState)=>{
                    oldState.allData[year][month] = {"result_months":  this.state.result_months}
                    return {...oldState}
                  })
                }
              }
            }
          }
        }

      }
    }


  }
  async get_code_contabil_pattern_param_list(year, month){

    await axios.get(`/results/get_code_contabil_pattern_param_list.json`, {
      params: {
        year: year,
        month: month,
        type: "week"
      }
    }).then(response => {
      this.setState({code_contabil_pattern_param_list:  response.data.code_contabil_pattern_param_list })

    })

  }

  componentDidMount(){

    this.setYears()
    this.setComments()
    this.fetchConflicts()
    this.setWeeks(this.state.year, this.state.month)

    this.fetchInitialParams(this.state.year, true,this.state.month )

    this.get_code_contabil_pattern_param_list(this.state.year, this.state.month )
    this.getFinalBalance(this.state.year, this.state.month )

    this.fetchGetPeriods()

    Object.entries(this.state.weeks).map(([name, number])=>(
          this.getCheckBank(this.state.year, this.state.month,name,number )
    ))


  }

  numberToCurrency(number){
    if(number == 0 || number){
      return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
    return "-"

  }

  removeAllChildren(row, rows){
    if(row.childreen_attributes.length > 0){
      rows[row.id] = false
      row.childreen_attributes.map((child)=>(this.removeAllChildren(child, rows)))

    }else{
      rows[row.id] = false
    }
  }


  toggleRow(parent, row){

    let rows = this.state.activeBranches
    rows[row.id] = !rows[row.id]
    if(!rows[row.id]){
      this.removeAllChildren(row, rows)
    }

    this.setState({activeBranches: rows})
  }

  branchIsActive(row){
    return this.state.activeBranches[row.id]
  }

  sumResults(values){
    let result = Object.values(Object.values(values)).reduce((a,b)=> parseFloat(a)+ parseFloat(b), 0)
    return this.numberToCurrency(result)
    // return 0
  }

  renderChildreenFor(key=null, level){

    let data = this.state.recordsFetched[key]

    if(data){
      return (<React.Fragment>
        {Object.values(data).map((row)=>(this.renderRow(row, level, key)))}
      </React.Fragment>)
    }
  }

  renderRow(row, level=0, key=null){


    let childrenLines = (entry, month_name)=>{
      try{
        let initial = Object.values(entry.lines).map((x)=> x.months[month_name]).filter((a)=> parseFloat(a).toString() != "NaN").reduce((a,b)=> parseFloat(a) + parseFloat(b), 0)

        if (Object.values(entry.childreen).length > 0) {
          Object.values(entry.childreen).map((child)=> {
            initial += childrenLines(child, month_name)
          })
        }

        return initial
      }
      catch(e){
        console.log("ERRO AQUI", e)
        return 0
      }

    }


    const  {costCentersFetched, activeKeys,month, default_days,weeks} = this.state;
    let  weeks_util = { "1" : 1, "2" : 2, "3" : 3, "4" : 4, "5" : 5};


    let sum = 0

    return <React.Fragment>
      {!row.months ?
          <tr>
            <td ><div
                onClick={()=> {

                  this.setState((oldState)=>{
                    if(row.label){

                      oldState.activeKeys[key] = oldState.activeKeys[key] ? oldState.activeKeys[key] : {}
                      oldState.activeKeys[key][row.label] = oldState.activeKeys[key][row.label] ? null : {}
                    }else{
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? null : {}
                    }

                    return {...oldState}
                  })
                }}
                style={{paddingLeft: level * 10}}>
              {row.label}
            </div>
            </td>
            {Object.entries(weeks_util).map(([name, number]) => {
              let monthValue = childrenLines(row, name)
              if(monthValue.toString() != "NaN"){
                sum += monthValue
              }

              console.log(sum)
              return (
                  <td className="comment-wrapper">
                    {/*this.state.comments && <Comment
                        refreshComments={()=> this.setComments()}
                        comments={this.state.comments}
                        model_reference={'balance_sheet_row'}
                        week={number}
                        year={this.state.year}
                        model_reference_id={key}>
                    </Comment>*/}
                    {this.numberToCurrency(monthValue)}
                  </td>
              );
            })}
            <td>
              <ResultChartWeek
                  loadAllYears={()=>{
                    this.state.years.map((year)=>{
                      this.state.months.map((month)=>{
                        this.fetchDetail(key, year, false, false,  month )
                      })
                    })
                  }}
                  loadYear={(year)=> {
                    this.fetchDetail(key, year, false, false,  this.state.month)
                  }}
                  years={this.state.years}
                  year={this.state.year}
                  title={row.label}
                  line={row}
                  allYearsData={this.state.recordsFetchedByYear}
                  dataKey={"balance-detail-line"}
                  weeks={this.state.weeks}
                  month={this.state.month}
              />
            </td>

            <td>
              {this.numberToCurrency(sum)}
            </td>
          </tr>
          :
          this.renderLine(row, level, key)
      }


      {activeKeys[key] && row.childreen && Object.values(row.childreen).map((child)=>{
        return this.renderRow(child, level + 1, key)
      })}
      {(activeKeys[key] && activeKeys[key][row.label]) && row.lines &&  Object.keys(row.childreen).length < 1 && Object.values(row.lines).map((line)=>{
        return this.renderLine(line, level + 1, key)
      })}
    </React.Fragment>
  }

  removeSufix(code){
    let {sufix} = this.props;

    return code.replace("-"+sufix, "")
  }

  renderLine(line, level=0, key){
    const { months, year, month , default_days,weeks} = this.state;

    let  weeks_util = { "1" : 1, "2" : 2, "3" : 3, "4" : 4, "5" : 5};
    let totalLine = 0
    line.id = this.removeSufix(line.id)
    return <tr className="line">
      <td>
        <div style={{paddingLeft: level * 40}}>{line.label} </div>
      </td>
      {Object.entries(weeks_util).map(([name, number]) => {

        if(parseFloat(line.weeks[number]).toString() != "NaN"){
          totalLine += parseFloat(line.weeks[number])
        }

        return (
            <td style={{paddingLeft: 16}} className="comment-wrapper">
              {/*this.state.comments && <Comment
                  refreshComments={()=> this.setComments()}
                  comments={this.state.comments}
                  model_reference={'balance_sheet_line'}
                  week={number}
                  year={this.state.year}
                  model_reference_id={line.id}>
              </Comment> */}
              <DetailingResult
                  refreshDetailingResults={()=> this.setDetailingResults()}
                  detailingResults={this.state.comments}
                  model_reference={'balance_sheet'}
                  month={this.state.month}
                  monthName={number}
                  type_detailing_result={"line"}
                  type_result={"week"}
                  year={this.state.year}
                  model_reference_id={line.id}
                  model_reference_label={line.label}
                  reference_id={line.id}>
              </DetailingResult>
              {line.weeks[number] ? (     parseFloat(line.weeks[number]) ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
              }) : "-"}
            </td>
        );
      })}
      <td >

        <ResultChartWeek
            loadAllYears={()=>{
              this.state.years.map((year)=>{
                this.state.months.map((month)=>{
                 this.fetchDetail(key, year, false, false,  month )
                })
              })
            }}
        loadYear={(year)=> {
          this.fetchDetail(key, year, false, false,  this.state.month)
            }}
            years={this.state.years}
            year={this.state.year}
            title={line.label}
            line={line}
            allYearsData={this.state.recordsFetchedByYear}
            dataKey={"balance-detail-line"}
            weeks={this.state.weeks}
            month={this.state.month}
        />
      </td>
    </tr>
  }

  getDataForParam(param){
    const {result, resultIds, cash_flow_formula_ids, showZeroLines, recordsFetched} = this.state;
    console.log(result.params[param.id])

    return result.params[param.id]
  }

  renderChildreen(contabilParam, childreen_attributes=[], level=0, signal = '+'){
    const {months, month, default_days, weeks} = this.state;
    let  weeks_util = { "1" : 1, "2" : 2, "3" : 3, "4" : 4, "5" : 5};


    const {result, resultIds, cash_flow_formula_ids, showZeroLines, recordsFetched, code_contabil_pattern_param_list} = this.state;
    let childreen = null

    //contabilParam.label === 'Saídas'
    childreen = childreen_attributes.map((child)=> {
      //let empty = true
      let empty = false

      {Object.entries(weeks_util).map(([name, number])=>{

        /* let monthValue = signal !== '+' ? (parseFloat(result?.params?.[child.id]?.[name]) * - 1) : (parseFloat(result?.params?.[child.id]?.[name]) )*/
        let monthValue =  (parseFloat(result?.params?.[child.id]?.[number]) )

        if(monthValue.toString() != "NaN"){
          empty = false

        }
      })}

      if(empty == true && !showZeroLines){
        // if(empty == false && !showZeroLines){
        return null
      }else{

        return (<React.Fragment>


          <tr>
            <td className={'comment-wrapper'} style={{paddingLeft: 20 + (10 * level)}}  onClick={()=>
                !child.is_tail ? this.toggleRow(contabilParam, child) : this.fetchDetail(child.fr_id)
            }>
              {this.state.comments && <Comment
                  refreshComments={()=> this.setComments()}
                  comments={this.state.comments}
                  model_reference={'contabil_pattern'}
                  month={0}
                  year={0}
                  model_reference_id={child.key}>
              </Comment>}
              <div style={{marginRight: 10, display: 'inline-block'}}>

                {!child.is_tail && <i className={`fa fa-chevron-${this.branchIsActive(child) ? 'down' : 'right'}`}/>}
                {child.is_tail && <i className={`pull-left fa fa-chevron-${recordsFetched[child.fr_id] ? 'down' : 'right'}`}/>}
              </div>

              {child.label} {cash_flow_formula_ids[child.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
            </td>
            {Object.entries(weeks_util).map(([name, number])=>(
                <td className={'comment-wrapper'}>
                  {/*this.state.comments && <Comment
                      refreshComments={()=> this.setComments()}
                      comments={this.state.comments}
                      model_reference={'balance_sheet'}
                      week={number}
                      weekName={name}
                      year={this.state.year}
                      model_reference_id={child.id}>
                  </Comment>*/}
                  <DetailingResult
                      refreshDetailingResults={()=> this.setDetailingResults()}
                      detailingResults={this.state.comments}
                      model_reference={'balance_sheet'}
                      month={this.state.month}
                      monthName={number}
                      year={this.state.year}
                      type_detailing_result={""}
                      type_result={"week"}
                      model_reference_id={child.id}
                      model_reference_label={child.label}
                      reference_id={child.id}>
                  </DetailingResult>
                  {code_contabil_pattern_param_list[child.id] !== undefined  && code_contabil_pattern_param_list[child.id][number] === true &&
                      <ManualLaunch style={{paddingLeft: 40 }}></ManualLaunch>
                  }
                  {/*result && result.params[child.id] && this.numberToCurrency(signal === '-' ? (parseFloat(result?.params?.[child.id]?.[name]) * - 1) : (parseFloat(result?.params?.[child.id]?.[name]) ))*/}
                  {result && result.params[child.id] && this.numberToCurrency( (parseFloat(result?.params?.[child.id]?.[number]) ))}

                </td>
            ))}
            <td>
                <ResultChartWeek
                    loadAllYears={()=>{
                      this.state.years.map((year)=>{
                        this.fetchInitialParams( year, false,  this.state.month)
                      })
                    }}
                    loadYear={(year)=> this.fetchInitialParams(year, false, this.state.month)}
                    years={this.state.years}
                    year={this.state.year}
                    title={child.label}
                    parameter_id={child.id}
                    allYearsData={this.state.allYearsData}
                    dataKey={"balance-params"}
                    weeks={this.state.weeks}
                    month={this.state.month}
                />
            </td>
          </tr>
          {this.renderChildreenFor(child.fr_id, level)}
          {this.branchIsActive(child) && <React.Fragment>
            {this.renderChildreen(child, child.childreen_attributes, (level + 1), contabilParam.label.trim() === 'Entradas' ? '+' : '-' )}
          </React.Fragment>}

        </React.Fragment>)
      }

    })


    return childreen

  }

  changeWeekYear(year, month){

    this.setState({year})
    this.setState({month})
    this.setWeeks(year, month )
    this.get_code_contabil_pattern_param_list(year,month )
    this.getFinalBalance(year,month )


   Object.entries(this.state.weeks).map(([name, number])=>(
         this.getCheckBank(year, month,name,number )
   ))


    this.fetchInitialParams(year,true,month )

    this.props.changeYear(year)
    this.props.changeMonth(month)
    let self = this

    Object.entries(self.state.initial_balance_week).map(([name, number]) => {
      setTimeout(()=>{
        this.setState((oldState) => {
          oldState.initial_balance_week[number] = 0
          return {...oldState}
        })
      } ,1000 * number)
    })



    Object.keys(this.state.recordsFetched).map((key, i)=>{
      setTimeout(()=>{
        this.fetchDetail(key, year, false,month)
      },1000 * i)

    })
  }
  changeYear(year){
    this.setState({year})

    this.fetchInitialParams(year,true, this.state.month)
    this.props.changeYear(year)
    this.props.changeMonth( this.state.month)
    this.setWeeks(year, this.state.month )
    this.get_code_contabil_pattern_param_list(year, this.state.month )

    Object.keys(this.state.recordsFetched).map((key, i)=>{
      setTimeout(()=>{
        this.fetchDetail(key, year, false, this.state.month)
      },10 * i)

    })

    Object.entries(this.state.weeks).map(([name, number])=>(
          this.getCheckBank(year,  this.state.month,name,number )
    ))



  }



  checkOpeningBalanceMonth(day_name, type_contabilParam = ""){

    let self = this;
    return this.numberToCurrency(self.state.initial_balance_week[day_name]);
  }
  checkMonth(month_name, type_contabilParam = ""){

    let checagem = 0;
    let saidas = [];
    let sum_saidas = 0;
    let entradas = [];
    let entradas_count = 0;
    let saidas_count = 0;
    let sum_entradas = 0;
    let self = this;
    const {data, result} = this.state;


    this.state.data && this.state.data.map((contabilParam)=>{

      if(type_contabilParam === contabilParam.label){
        contabilParam.childreen_attributes.map((childreen)=>{
          if(childreen.label.trim() === 'Saídas'){
            if( saidas_count === 0){
              childreen.childreen_attributes.map((saida)=>{
                saidas.push(saida.id)
              })
              saidas_count = 1
              saidas.map((saida)=>{
                if(result.params[saida]){
                  if(result.params[saida][month_name]){
                    sum_saidas += parseFloat(result.params[saida][month_name])
                  }
                }
              })
            }
          }else{
            if( entradas_count === 0){
              childreen.childreen_attributes.map((entrada)=>{
                entradas.push(entrada.id)
              })
              entradas_count = 1
              entradas.map((entrada)=>{
                if(result.params[entrada]){
                  if(result.params[entrada][month_name]){
                    sum_entradas += parseFloat(result.params[entrada][month_name])
                  }
                }
              })
            }
          }
        })
      }

      if(Math.sign(sum_saidas) < 0){
        checagem = sum_entradas + sum_saidas
      }else{
        checagem = sum_entradas - sum_saidas
      }
    })
    if(type_contabilParam.trim() === 'Operacional' ){
      self.state.result_operational_week[month_name] = checagem
    }else{
      self.state.result_not_operational_week[month_name] = checagem

    }

    return this.numberToCurrency(checagem);
  }

  async getFinalBalance(year, month) {

    await axios.get(`/results/get_final_balance_week.json`, {
      params: {
        year: year,
        month: month
      }
    }).then(response => {
      this.setState((oldState)=>{
        oldState.initial_balance_week[response.data.week] =  response.data.value
        return {...oldState}
      })
      this.setState({week_number_selected: response.data.week})
      this.setState({value_initial_balance: response.data.value})

    })
  }
  getResultFinalMonthsSum(name, number){

    let self = this
    self.state.final_balance_week[number] =  self.state.initial_balance_week[number]  + self.state.result_operational_week[number] + self.state.result_not_operational_week[number]
    if(number + 1 !== self.state.month_number_selected ){
      self.state.initial_balance_week[ number + 1] = self.state.final_balance_week[number]
    }else{
      self.state.initial_balance_week[ number + 1] =   self.state.value_initial_balance
    }

    return this.numberToCurrency(self.state.final_balance_week[number])
  }

  vlSaldoBank( year,week ,week_name,week_number) {
    let self = this;
    //debugger

   //this.getCheckBank(year, week,week_name,week_number )

    return this.numberToCurrency( self.state.vls_saldo_bank[week_number]);
  }
  checkBank(year,month, day_name,day_number){
    let self = this;
    let value_month = 0
    const current_date = new Date();
    const current_year = current_date.getFullYear();
    const current_month = current_date.getMonth();
    const current_day = current_date.getDay();
    const {vls_saldo_bank, final_balance_week} = this.state;

    value_month = vls_saldo_bank[day_name]  - final_balance_week[day_name]

    if(Math.sign(final_balance_week[day_name]) < 0){
      value_month = vls_saldo_bank[day_name]  + final_balance_week[day_name]
    }
    self.state.check_bank[day_name] = value_month


    return this.numberToCurrency(value_month);
  }
  getResultMonthsSum(month_number){
    let checagem = 0
    let self = this
    const {result_operational_week, result_not_operational_week} = this.state;

    checagem = result_operational_week[month_number] + result_not_operational_week[month_number]

    self.state.result_months[month_number] = checagem

    return this.numberToCurrency(checagem);
  }
  changeVisibleMonths(event){
    // event.target.name]: event.target.checked
    let {name, checked} = event.target
    let props = this.props;
    this.setState((oldState)=>{
      oldState.visibleMonths[name] = checked
      oldState.months = {}
      Object.entries(oldState.visibleMonths).map(([name, checked])=>{
        if(checked){
          oldState.months[name] = props.months[name]
        }
      })

      return {...oldState}
    });
  }

  fetchConflicts(){
    axios.get('/configured_patterns/childreen_conflicts.json').then((conflicts)=>{
      this.setState({conflicts: conflicts?.data})
    })
  }

 /* checkResultWithLiquidProfit(monthNumber, monthName, isResult, value){
    let {months, periods, year, days} = this.state;
    // create a inversion of hash months to transform keys into values and values into keys
    let monthsInverted = {}
    Object.entries(


    ).map(([name, number])=>{
      monthsInverted[number] = name
    })

    if(isResult){
      // loop until monthNumber
      let total = 0
      for(let number = 1; number <= monthNumber; number++){
        try{
          if(number <= monthNumber){
            if(periods[year] == "trimestral"){
              if (monthNumber % 3 == 0){
                if(monthNumber - number <= 2){
                  total += parseFloat(eval("(" +this.state.liquidProfitFormula?.result?.[monthsInverted[number]] + ") * -1")) || 0
                }
              }
              if (monthNumber % 3 == 2){
                if(monthNumber - number <= 1){
                  total += parseFloat(eval("(" +this.state.liquidProfitFormula?.result?.[monthsInverted[number]] + ") * -1")) || 0
                }
              }
              if (monthNumber % 3 == 1){
                if(monthNumber - number <= 0){
                  total += parseFloat(eval("(" +this.state.liquidProfitFormula?.result?.[monthsInverted[number]] + ") * -1")) || 0
                }
              }

            } else if(periods[year] == "yearly"){
              total += parseFloat(eval("(" +this.state.liquidProfitFormula?.result?.[monthsInverted[number]] + ") * -1")) || 0
            }

          }

        }catch(e){}
      }
      if(periods[year] == "monthly"){
        total = parseFloat(eval("(" +this.state.liquidProfitFormula?.result?.[monthsInverted[monthNumber]] + ") * -1")) || 0
      }
      console.log("number", monthNumber)
      console.log("checagem", parseInt(total), parseInt(value), parseInt(total) - ((parseInt(value))))
      let check = parseInt(total) - parseInt(value)
      return  check <= 1 && check >= -1 ? "valid_result" : "invalid_result"
    }else{
      return ""
    }
  }*/

  // fetch get_periods using axios
  fetchGetPeriods(){
    axios.get('/liquid_profits/get_periods.json').then((periods)=>{
      this.setState({periods: periods?.data})
    })
  }

  /*getBeforeMonthsSum(monthNumber){
    let {months, year, periods} = this.state;
    let beforeSum = 0
    Object.entries(months).map(([name, number])=>{
      if(number < monthNumber){
        if(periods[year] == "trimestral"){

          if (monthNumber % 3 == 0){
            if(monthNumber - number <= 2){
              beforeSum += parseFloat(eval("(" +this.state.liquidProfitFormula?.result?.[name] + ") * -1")) || 0
            }
          }
          if (monthNumber % 3 == 2){
            if(monthNumber - number <= 1){
              beforeSum += parseFloat(eval("(" +this.state.liquidProfitFormula?.result?.[name] + ") * -1")) || 0
            }
          }

        }
        else if (periods[year] == "yearly"){
          beforeSum += parseFloat(eval("(" +this.state.liquidProfitFormula?.result?.[name] + ") * -1")) || 0
        }

      }

    })
    if(periods[year] == "trimestral"){
      // if month is in (4, 7, 10)
      if(monthNumber == 4 || monthNumber == 7 || monthNumber == 10){
        return 0
      }
    }
    return beforeSum
    // return 0

  }*/

  render() {


    const {state } = this.props;
    const {data, result, year, years, resultIds, cash_flow_formula_ids, showZeroLines, months, conflicts, month, weeks,week, default_days, default_weeks , weeks_foreseen_accomplished, code_contabil_pattern_param_list} = this.state;
    let  weeks_util = { "1" : 1, "2" : 2, "3" : 3, "4" : 4, "5" : 5};
    const {initial_weeks_foreseen_accomplished} = this.props
    let foreseen_accomplished = []
    if(Object.keys(weeks_foreseen_accomplished ).length !== 0 ){
      foreseen_accomplished = weeks_foreseen_accomplished
    }else{
      foreseen_accomplished = initial_weeks_foreseen_accomplished
    }
    //if(default_weeks){
      //weeks = default_weeks
    //}


    return (
        <React.Fragment>

          <br></br>
          <div  style={{display: 'flex', justifyContent: 'space-between' }}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20 }}>


              <MonthFilter changeVisibleMonths={(e)=> this.changeVisibleMonths(e)} visibleMonths={this.state.visibleMonths} months={this.props.months}></MonthFilter>

              {showZeroLines ?
                  <div title="Mostrar linhas zeradas" style={{color: 'rgb(36,44,86)', fontWeight: "bold"}} className={'pointer'} onClick={()=> this.setState({showZeroLines: !showZeroLines})}> <img src={olhoOff} style={{width: 36}}></img></div>
                  :
                  <div title="Ocultar linhas zeradas" style={{color: 'rgb(36,44,86)', fontWeight: "bold"}} className={'pointer'} onClick={()=> this.setState({showZeroLines: !showZeroLines})}> <img src={olhoOn} style={{width: 36}}></img></div>
              }
              <small style={{fontSize: 10, padding: "10px 0 0 43px", display: 'block', color: 'grey'}}>* Você está visualizando números no formato n/{this.props.divisor}</small>
            </div>
            <form className="form flex" style={{paddingRight: 35, alignItems: 'center', height: '100%'}}>
              <div className={'with-date-ico'}>
                <select className={'margin-left-20'} onChange={(e)=> this.changeWeekYear(year, e.target.value )}>
                  {Object.entries(months).map(([name, number])=>(
                      <option value={name} selected={month === number }> {name} </option>
                  ))}
                </select>
              </div>
              <div className={'with-date-ico'}>
                <select className={'margin-left-20'} onChange={(e)=> this.changeYear( e.target.value )}>
                  {years.map((year_name)=>(
                      <option value={year_name} selected={year === year_name }> {year_name} </option>
                  ))}
                </select>
              </div>
              <a style={{marginLeft: 20}} onClick={()=> this.exportResult(month,year, this.state.initial_balance_week, this.state.final_balance_week, this.state.result_operational_week, this.state.result_not_operational_week,this.state.vls_saldo_bank, this.state.check_bank )} >
                <img src={downloadIco} style={{width: 36}}></img>
              </a>
            </form>
          </div>
          <br></br>


          {conflicts?.length > 0 && <div className={" alert alert-alert"} style={{padding: 10}}>
            <ul>
              {conflicts?.map(([code, companies, difference])=>{
                return <React.Fragment>
                  <li >{code} está parametrizado como {difference.reduce((a,b)=> a + ", " + b, "")}</li>
                </React.Fragment>
              })}
            </ul>
          </div>}

          <div id="table-result-wrapper">
            <div className="content margin-left-40 margin-right-40 padding-top-40 ">
              <table id="table-result" className={"margin-bottom-20 without-acumulado balance-table"}>
                <thead>
                <tr>
                  <th>
                    Nome
                  </th>
                  { Object.entries(weeks ).map(([name, number])=>(
                      <th style={{
                        color: foreseen_accomplished[number] !== undefined  && foreseen_accomplished[number] === true ? "#1598d3": "#a7d8ee"
                      }}>{default_weeks[`${number}`]}</th>
                  ))}
                </tr>
                <tr>
                  <th></th>
                  {Object.entries(weeks ).map(([name, number])=>( foreseen_accomplished[number] !== undefined  && foreseen_accomplished[number] === true ? <th style={{color: "#1598d3", paddingLeft: 40, alignItems: 'center'}}>REAL</th> : <th style={{color: "#a7d8ee", paddingLeft: 40, alignItems: 'center'}}>PREV</th>)) }
                </tr>
                </thead>
                {data.map((contabilParam)=>{
                  return(  <React.Fragment>
                    { contabilParam.label !== "Exclusão"  && <tbody key={contabilParam.id}>
                    <tr id="principal">
                      <th className={'comment-wrapper'}>
                        {this.state.comments && <Comment
                            refreshComments={()=> this.setComments()}
                            comments={this.state.comments}
                            model_reference={'contabil_pattern'}
                            month={0}
                            week={0}
                            year={0}
                            model_reference_id={contabilParam.key}>
                        </Comment>}
                        {contabilParam.label.toUpperCase() }  {cash_flow_formula_ids[contabilParam.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
                      </th>

                    </tr>
                    {
                      contabilParam.childreen_attributes.map((child)=> {

                        return( <React.Fragment>
                          <tr id="principal">
                            <th className={'comment-wrapper'}>
                              {this.state.comments && <Comment
                                  refreshComments={()=> this.setComments()}
                                  comments={this.state.comments}
                                  model_reference={'contabil_pattern'}
                                  week={0}
                                  year={0}
                                  model_reference_id={child.key}>
                              </Comment>}
                              {child.label.toUpperCase()}  {cash_flow_formula_ids[child.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
                            </th>
                            {Object.entries(weeks_util).map(([name, number])=>(

                                <th className={'comment-wrapper'}>
                                  {this.state.comments && <Comment
                                      refreshComments={()=> this.setComments()}
                                      comments={this.state.comments}
                                      model_reference={'balance_sheet'}
                                      week={number}
                                      weekName={name}
                                      year={this.state.year}
                                      model_reference_id={child.id}>
                                  </Comment>}
                                  {code_contabil_pattern_param_list[child.id] !== undefined  && code_contabil_pattern_param_list[child.id][number] === true &&
                                      <ManualLaunch style={{paddingLeft: 40 }}></ManualLaunch>
                                  }
                                  {
                                      result  && (  this.numberToCurrency( parseFloat(result?.params?.[child.id]?.[number]) ))
                                  }
                                </th>
                            ))}
                            <td >
                              <ResultChartWeek
                                  loadAllYears={()=>{
                                    this.state.years.map((year)=>{
                                      this.fetchInitialParams(year, false,   this.state.month)
                                    })
                                  }}
                                  loadYear={(year)=> this.fetchInitialParams(year, false,   this.state.month)}
                                  years={this.state.years}
                                  year={this.state.year}
                                  title={child.label}
                                  parameter_id={child.id}
                                  allYearsData={this.state.allYearsData}
                                  dataKey={"balance-params"}
                                  weeks={this.state.weeks}
                                  month={this.state.month}
                              />
                            </td>
                          </tr>

                          {this.renderChildreen(child, child.childreen_attributes, 1, child.label.trim() !== 'Entradas'   ? '-': '+')}
                        </React.Fragment>)

                      })
                    }

                    <tr>

                      <td style={{
                        backgroundColor:  "#e5e5e5",
                        color: '#2A3170',
                        fontSize: 13
                      }}> Supervit/Deficit - {contabilParam.label }</td>
                      {Object.entries(weeks_util).map(([name, number])=>(
                          <td style={{
                            backgroundColor:  "#e5e5e5",
                            color: '#2A3170',
                            fontSize: 13
                          }}>
                             {/* this.numberToCurrency(0)*/}
                            {result && this.checkMonth(number, contabilParam.label)  }
                          </td>
                      ))}
                      <td >
                        <ResultChartWeek
                            loadAllYears={()=>{
                              this.state.years.map((year)=>{
                                  this.fetchDataParams(year,this.state.month, contabilParam.label)

                              })
                            }}
                            loadYear={(year)=>  this.fetchDataParams(year,this.state.month, contabilParam.label)}
                            years={this.state.years}
                            year={this.state.year}
                            title={"Supervit/Deficit - "+ contabilParam.label }
                            keyLabel={contabilParam.label === "Operacional" ? "result_operational" : "result_not_operational"}
                            allYearsData={ this.state.allData}
                            dataKey={"balance-week-line"}
                            weeks={this.state.weeks}
                            month={this.state.month}

                        />
                      </td>

                    </tr>
                    </tbody>}
                  </React.Fragment>) })}
                <tbody>
                <tr></tr>
                <tr>
                  <td style={{
                    backgroundColor:  "#b5c7dc",
                    color: '#2A3170',
                    fontSize: 13
                  }}> Movimentação do dia</td>
                  {Object.entries(weeks_util).map(([name, number])=>(
                      <td style={{
                        backgroundColor:  "#b5c7dc",
                        color: '#2A3170',
                        fontSize: 13
                      }}>
                         {/*this.numberToCurrency(0)*/}
                        {this.getResultMonthsSum(number)}
                      </td>
                  ))}
                  <td >
                    <ResultChartWeek
                        loadAllYears={()=>{
                          this.state.years.map((year)=>{
                            this.fetchDataParams(year,this.state.month, "result_months")

                          })
                        }}
                        loadYear={(year)=>  this.fetchDataParams(year,this.state.month, "result_months")}
                        years={this.state.years}
                        year={this.state.year}
                        title={"Movimentação do dia" }
                        keyLabel={"result_months"}
                        allYearsData={ this.state.allData}
                        dataKey={"balance-week-line"}
                        weeks={this.state.weeks}
                        month={this.state.month}

                    />
                  </td>
                </tr>
                <tr></tr>
                </tbody>

                <tbody>
                <tr></tr>
                <tr>

                  <td style={{
                    backgroundColor:  "#e5e5e5",
                    color: '#2A3170',
                    fontSize: 13
                  }}> Saldo Inicial</td>

                  {Object.entries(weeks_util).map(([name, number])=>(
                      <td style={{
                        backgroundColor:  "#e5e5e5",
                        color: '#2A3170',
                        fontSize: 13
                      }}>

                        {/* this.numberToCurrency(0)*/}
                        {  result && this.checkOpeningBalanceMonth(number) }
                      </td>
                  ))}
                  <td >
                    <ResultChartWeek
                        loadAllYears={()=>{
                          this.state.years.map((year)=>{
                            this.fetchDataParams(year,this.state.month, "initial_balance")

                          })
                        }}
                        loadYear={(year)=>  this.fetchDataParams(year,this.state.month, "initial_balance")}
                        years={this.state.years}
                        year={this.state.year}
                        title={"Saldo Inicial" }
                        keyLabel={"initial_balance"}
                        allYearsData={ this.state.allData}
                        dataKey={"balance-week-line"}
                        weeks={this.state.weeks}
                        month={this.state.month}

                    />
                  </td>

                </tr>
                <tr>

                  <td style={{
                    backgroundColor:  "#e5e5e5",
                    color: '#2A3170',
                    fontSize: 13
                  }}> Saldo Final</td>
                  {Object.entries(weeks_util).map(([name, number])=>(
                      <td style={{
                        backgroundColor:  "#e5e5e5",
                        color: '#2A3170',
                        fontSize: 13
                      }}>
                        {/* this.numberToCurrency(0)*/}
                        {this.getResultFinalMonthsSum(name,number)}
                      </td>
                  ))}
                  <td >
                    <ResultChartWeek
                        loadAllYears={()=>{
                          this.state.years.map((year)=>{
                            this.fetchDataParams(year,this.state.month, "final_balance")

                          })
                        }}
                        loadYear={(year)=>  this.fetchDataParams(year,this.state.month, "final_balance")}
                        years={this.state.years}
                        year={this.state.year}
                        title={"Saldo Final" }
                        keyLabel={"final_balance"}
                        allYearsData={ this.state.allData}
                        dataKey={"balance-week-line"}
                        weeks={this.state.weeks}
                        month={this.state.month}

                    />
                  </td>
                </tr>
                </tbody>
                {data.map((contabilParam)=>{
                  return( <React.Fragment>
                    { contabilParam.label === "Exclusão"  &&
                        <tbody key={contabilParam.id}>
                        <tr id="principal">
                          <th className={'comment-wrapper'}>
                            {this.state.comments && <Comment
                                refreshComments={()=> this.setComments()}
                                comments={this.state.comments}
                                model_reference={'contabil_pattern'}
                                week={0}
                                year={0}
                                model_reference_id={contabilParam.key}>
                            </Comment>}
                            {contabilParam.label }  {cash_flow_formula_ids[contabilParam.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
                          </th>
                          {Object.entries(weeks).map(([name, number])=>(
                              <th className={'comment-wrapper'}>
                                {/*this.state.comments && <Comment
                                    refreshComments={()=> this.setComments()}
                                    comments={this.state.comments}
                                    model_reference={'balance_sheet'}
                                    week={number}
                                    weekName={name}
                                    year={this.state.year}
                                    model_reference_id={contabilParam.id}>
                                </Comment>*/}
                                <DetailingResult
                                    refreshDetailingResults={()=> this.setDetailingResults()}
                                    detailingResults={this.state.comments}
                                    model_reference={'balance_sheet'}
                                    month={this.state.month}
                                    monthName={number}
                                    year={this.state.year}
                                    type_detailing_result={""}
                                    type_result={"week"}
                                    model_reference_id={contabilParam.id}
                                    model_reference_label={contabilParam.label}
                                    reference_id={"error"}>
                                </DetailingResult>
                                {result &&  ( this.numberToCurrency(result.params[contabilParam.id][number] ))}
                              </th>
                          ))}
                          <td >
                            <ResultChartWeek
                                loadAllYears={()=>{
                                  this.state.years.map((year)=>{
                                    this.fetchInitialParams(year, false,   this.state.month)
                                  })
                                }}
                                loadYear={(year)=> this.fetchInitialParams(year, false,   this.state.month)}
                                years={this.state.years}
                                year={this.state.year}
                                title={contabilParam.label}
                                parameter_id={contabilParam.id}
                                allYearsData={this.state.allYearsData}
                                dataKey={"balance-params"}
                                weeks={this.state.weeks}
                                month={this.state.month}
                            />
                          </td>


                        </tr>
                        {this.renderChildreen(contabilParam, contabilParam.childreen_attributes, 1,  '+')}
                        </tbody> }
                  </React.Fragment>)})}
                <tbody>
                <tr></tr>
                <tr id="principal">
                  <td tyle={{
                    backgroundColor:  "#fffdfd",
                    color: '#1598D3',
                    fontSize: 13
                  }}> Check c/ Banco</td>
                  {Object.entries(weeks_util).map(([name, number])=>(
                      <td style={{
                        backgroundColor:  "#fffdfd",
                        color: '#1598D3',
                        fontSize: 13
                      }} >
                         {/* this.numberToCurrency(0)*/}
                        {this.checkBank(year, week,name,number)}
                      </td>
                  ))}

                </tr>
                <tr>
                  <td style={{
                    backgroundColor:  "#e5e5e5",
                    color: '#2A3170',
                    fontSize: 13
                  }}> Saldo Contas Bancárias </td>
                  {Object.entries(weeks_util).map(([name, number])=>(
                      <td style={{
                        backgroundColor:  "#e5e5e5",
                        color: '#2A3170',
                        fontSize: 13
                      }}>
                         {/* this.numberToCurrency(0)*/}
                        {this.vlSaldoBank(year, week,name,number)}
                      </td>
                  ))}
                </tr>

                </tbody>
              </table>
            </div>
          </div>
          <LoadingDialog open={this.requestsCounter > 0}/>
        </React.Fragment>);
  }
}

export default Balance;

const monthDict = {
  "01" : "jan",
  "02" : "fev",
  "03" : "mar",
  "04" : "abr",
  "05" : "mai",
  "06" : "jun",
  "07" : "jul",
  "08" : "ago",
  "09" : "set",
  "10": "out",
  "11": "nov",
  "12": "dez",
}
const weeksDict = {
  "1" : "01",
  "2" : "02",
  "3" : "03",
  "4" : "04",
  "5" : "05",
  "6" : "06",
  "7" : "07",
  "8" : "08",
  "9" : "09",
  "10":  "10",
  "11":  "11",
  "12":  "12",
  "13":  "13",
  "14":  "14",
  "15":  "15",
  "16":  "16",
  "17":  "17",
  "18":  "18",
  "19":  "19",
  "20":  "20",
  "21":  "21",
  "22":  "22",
  "23":  "23",
  "24":  "24",
  "25":  "25",
  "26":  "26",
  "27":  "27",
  "28":  "28",
  "29":  "29",
  "30":  "30",
  "31":  "31",

}
//update