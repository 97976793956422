import React from "react";

import { MentionsInput, Mention } from "react-mentions";

import provideExampleValue from "./provideExampleValue";

import defaultStyle from "./styles/defaultStyle";
import defaultMentionStyle from "./styles/defaultMentionStyle";
import hashtagMentionStyle from "./styles/hashtagMentionStyle";

function SingleLine({
  value,
  data,
  hashtagData,
  customData,
  titleData,
  onChange,
  onAdd,
  inputRef,
  onMudado,
  costCenterAreaData
}) {
  return (
    <div className="single-line">
      <h3>Formula</h3>

      <MentionsInput
        singleLine
        value={value}
        onChange={onChange}
        style={defaultStyle}
        placeholder={"Referencie parametros usando '@'"}
      >
        
        <Mention
          key={2}
          className={"hashtag_mention"}
          markup="#[__display__]($[__id__])"
          renderSuggestion={(entry, search, highlightedDisplay, index, focused)=>{
            
            return <div>
                      <small style={{fontStyle: 'italic', color: "#bababa"}}>{entry.group} - </small>
                      {entry.display}
                    </div>
          }}
          trigger="#"
          data={hashtagData}
          style={{ backgroundColor: "#2B7DE999" }}
          appendSpaceOnAdd={true}
        />
        
      </MentionsInput>
    </div>
  );
}

export default SingleLine;
