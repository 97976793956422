import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimeLineIcon from '@material-ui/icons/Timeline';
import chartIco from 'chart-ico.svg';
Highcharts.setOptions({
  colors: ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"]
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export default function ResultChart(props) {
  const [open, setOpen] = React.useState(false);
  const [chartStyle, setChartStyle] = React.useState("line");
  const classes = useStyles();
  let options = {}
  
  if(props.allYearsData){
    let loadedData = (serie)=>{
        
        return data(dataForFormula(props.allYearsData?.[serie]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey]))  
    }
    let serieDict = {
      analisado: "Real",
      budget: "Orçado"
    }
    options = {
      title: {
        text: props.title
      },
      xAxis: {
            categories: ['Jan', 'Fev', 'Mar','Abr', 'Mai', "Jun", "Jul", "Ago", 'Set', 'Out', 'Nov', 'Dez']
        },
      series: ["analisado", "budget"].map((serie)=>{
        return {
          type: chartStyle,
          name: serieDict[serie],
          data: loadedData(serie)
        }
      })
      }
  }else{
    options = {
      title: {
        text: props.title
      },
      xAxis: {
            categories: ['Jan', 'Fev', 'Mar','Abr', 'Mai', "Jun", "Jul", "Ago", 'Set', 'Out', 'Nov', 'Dez']
        },
      series: [{
        type: 'column',
        data: data()
      }]
      }
  }
  function tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }

  function dataForFormula(result){
    const { months} = props;
    let data = []
    let i = 1
    Object.entries(months).map(([name, number]) => {
      if(i < 12){
        data.push(tryEval(result?.[name]))  
      }
      i += 1
    })
    return data
  }
  

  function data(desordenedMonthData=props.data){
    
    let result = []
    let {title, is_fixed, months, costCenterRecordsGrouped} = props;
    if(props.areas){
      result = Object.values(months).map((month)=>{
        return parseFloat(costCenterRecordsGrouped[`["${title}", ${is_fixed}, ${month}]`])
      })
      console.log()
    }else if(props.param){
      result =  desordenedMonthData.map((entry)=>{ 
        try{
          return parseFloat(entry[props.param])
        }catch(e){
          return 0
        }
        
      })
    }else{
      result = desordenedMonthData
    }
    return result
  }
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      
    <div style={{marginRight: props.marginRight || 20}} onClick={handleClickOpen}>
     {/*<i style={{fontSize: props.fontSize || 16}}  className={'fa fa-chart-bar'}/>*/}
     <img src={chartIco} style={{width: 16, heigth: 16}}></img>
    </div>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
      >
        
        <DialogContent >
          <div className={'flex'} style={{justifyContent: 'space-between'}}>
            <Grid container alignItems="center" className={classes.root}>
              <BarChartIcon style={{cursor: 'pointer', color: chartStyle == "column" ? 'rgb(113,172,233)' : 'rgba(0, 0, 0, 0.54)'}} onClick={()=> setChartStyle("column")} />
              <Divider orientation="vertical" flexItem />
              <TimeLineIcon style={{cursor: 'pointer', color: chartStyle == "line" ? 'rgb(113,172,233)' : 'rgba(0, 0, 0, 0.54)'}} onClick={()=> setChartStyle("line")} />
            </Grid>
            <Grid container alignItems="center" className={classes.root}>
              {props?.years?.map((year)=>{
                return <React.Fragment>
                  <div 
                    onClick={()=> props.loadYear(year)}
                    style={{
                    height: 24 + 10.5,
                    width: 24 + 10.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    color: props?.allYearsData?.[year] ? 'rgb(113,172,233)' : 'rgba(0, 0, 0, 0.54)'
                  }}>{year}</div>
                  <Divider orientation="vertical" flexItem />  
                </React.Fragment>
              })}
              
            </Grid>
          </div>
          {open  && <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}



