import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Select,
  FlexCenter,
  GreenLabel,
  BlueButton
} from "../../styled_components/default";

class Edit extends React.Component {
  state = {
    stage_text: "Salvar colunas",
    headerItems: [],
    currentData: [],
    selectedOptions: []
  };

  fetchHeadersRender() {
    let self = this;
    axios
      .get(`/balance_sheets/${this.props.balance_sheet_id}/show_header_pattern_of_beads.json`, {
        params: {
          data_type: this.props.header_data_type,
        }
      })
      .then(result => {
        if (result.data) {
          self.setState({ headerItems: result.data });
        }
      });
  }

  optionsRender() {
    const { header_financial_income } = this.props;
    const { selectedOptions } = this.state;

    const options = Object.entries(header_financial_income).map(option => {
      return <option value={option[1]} key={option[0]} disabled={selectedOptions.includes(String(option[0]))}>{option[0]}</option>
    });

    return options;
  }

  handleSelectChange(e, item) {
    const value = e.target.value;
    const text = e.currentTarget[e.currentTarget.selectedIndex].text;

    this.setState(prevState => {
      let newCurrentData;
      if (value === "Selecione" && text === "Selecione") {
        newCurrentData = prevState.currentData.filter(data => data.header !== item[1]);
      } else {
        newCurrentData = prevState.currentData.filter(data => data.header !== item[1]);
        newCurrentData.push({ value, fileHearder: text, header: item[1] });
      }

      const newSelectedOptions = newCurrentData.map(data => String(data.fileHearder));

      return {
        currentData: newCurrentData,
        selectedOptions: newSelectedOptions
      };
    });
  }

  saveHeaderData() {
    this.setState({ saving: true, stage_text: "Salvando linhas..." });

    axios
      .post(`/balance_sheets/${this.props.balance_sheet_id}/configure_header.json`, {
        header_columns: this.state
      })
      .then(result => {
        if (result.data.success) {
          this.setState({ stage_text: "Redirecionando para configuração..." });
          location.reload();
        }
        this.setState({ saving: false });
      });
  }

  componentDidMount() {
    this.fetchHeadersRender();
  }

  render() {
    const { headerItems, currentData } = this.state;

    return (
      <div>
        <FlexCenter>
          {
            headerItems.length === 0 ?
              <div>Vazio</div>
              :
              headerItems.map(item => (
                <div className={"margin-10"} style={{ flex: 1 }} key={item[0]}>
                  <GreenLabel>{item[1]}</GreenLabel>
                  <Select onChange={e => this.handleSelectChange(e, item)}>
                    <option>Selecione</option>
                    {this.optionsRender()}
                  </Select>
                </div>
              ))
          }
        </FlexCenter>
        <FlexCenter>
          <BlueButton onClick={() => this.saveHeaderData()}>
            {this.state.stage_text}
          </BlueButton>
        </FlexCenter>

        {currentData.length > 0 && <table className={'bordered margin-top-20'}>
          <thead>
            <tr>
              <th colSpan={currentData.length} style={{ textAlign: 'center' }}>Demonstração das colunas escolhidas</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {currentData.map((row, index) => (
                <th key={index}>{row.header}</th>
              ))}
            </tr>
            <tr>
              {currentData.map((row, index) => (
                <td key={index}>{row.value}</td>
              ))}
            </tr>
          </tbody>
        </table>}
      </div>
    );
  }
}

export default Edit;
