import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';


const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonClass: {
    backgroundColor: '#fff',
    marginBottom: 3,
    color: '#2a3170',
    borderColor: '#2a3170',
    borderWidth: 1
  }
}));

export default function SimplePopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentPosition, setCurrentPosition] = React.useState(props.formula.position);
  const [ccCurrentPosition, setCcCurrentPosition] = React.useState(props.formula.cc_position);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const  handleChange = async (event) =>{
    setCurrentPosition(event.target.value);
    let query = props.query || {}
    query.scope_eq = "dre"

    let req = await axios.put(props.link_to, {q: query, data: {method: "put", remote: true}, format: "js", new_position: event.target.value})
    try{
      eval(req.data)
      setAnchorEl(null);
    }catch(e){

    }
    
  }

  const  ccHandleChange = async (event) =>{
    setCcCurrentPosition(event.target.value);
    
    let query = props.query || {}
    query.scope_eq = "dre_cc"

    let req = await axios.put(props.link_to, {q: query, data: {method: "put", remote: true}, format: "js", new_position: event.target.value})
    try{
      eval(req.data)
      setAnchorEl(null);
    }catch(e){

    }
    
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let {formula, total_entries, labels, cc_labels} = props;

  return (
    <div>
      <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick} className={classes.buttonClass}>
        {props.scope == "dre_cc" ? formula.cc_position : formula.position}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          <Typography className={classes.typography}>Posição atual (DRE): {formula.position}</Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Posição DRE</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={currentPosition}
              onChange={handleChange}
              label="Age"
            >
              {labels.map(([label, key])=>{
                return <MenuItem value={key}>{key} - {label}</MenuItem>  
              })}
              
            </Select>
          </FormControl>
        </div>
        {(formula.cost_center_demonstration || formula.scope == "dre_cc") &&
          <div>
            <Typography className={classes.typography}>Posição atual (DRE CC): {formula.cc_position}</Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Posição Centro de Custos</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={ccCurrentPosition}
                onChange={ccHandleChange}
                label="Age"
              >
                {cc_labels.map(([label, key])=>{
                  return <MenuItem value={key}>{key} - {label}</MenuItem>  
                })}
                
              </Select>
            </FormControl>
          </div>
        }
  
      </Popover>
    </div>
  );
}