import React, { useState, useEffect }  from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import calendarIco from 'bt_calendar.svg';
import LoadingDialog from "./LoadingDialog";
import {makeStyles} from "@material-ui/core/styles";
import ReorderIcon from '@mui/icons-material/Reorder';
import {blue} from "@material-ui/core/colors";
import olhoOff from "../../assets/images/bt_olho_off.svg";
import olhoOn from "../../assets/images/bt_olho_on.svg";
import './styles/DetailingResult.css';
import { Space } from "../styled_components/default"
import downloadIco from 'bt_download.svg';
import moveIcon from 'move-btn.svg'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

Highcharts.setOptions({
  colors: ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"]
});


export default function DetailingResult(props) {

		const { lineParams, visibleParams } = props

    const [open, setOpen] = useState(false);
    const [openLines, setOpenLines] = useState(false);
    const [lineRecords, setLineRecords] = useState({});
    const [lines, setLines] = useState({});
    const [requestsCounter, setRequestsCounter] = useState(0);
		const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
		const [loadingLineRecords, setLoadingLineRecords] = useState(true)
		const [visibleParamsControl, setVisibleParamsControl] = useState(visibleParams);

		
		function removeFormatter(value) {
			return parseFloat(value.replace('R$', '').replace(/\./g, '').replace(',', '.').replace('-', '').replace(' ', ''));
		}

		function handleSort(keyParams) {
				const key = lineParams[keyParams];
				let direction = 'ascending';
				if (sortConfig.key === key && sortConfig.direction === 'ascending') {
					direction = 'descending';
				}

						//	Object.entries(maxSpeed)
						//	.sort(([,a],[,b]) => a-b)
						//	.reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

				const sortedData = Object.values(lines).sort((a,b) => {
						if (keyParams.includes("Data")) {
								const [dayA, monthA, yearA] = a[key].split("/");
								const [dayB, monthB, yearB] = b[key].split("/");
								const dateA = new Date(yearA, monthA - 1, dayA);
								const dateB = new Date(yearB, monthB - 1, dayB);
								return direction === 'ascending' ? dateA - dateB : dateB - dateA;
						} else if (keyParams.includes("Valor Líquido")) {
								const valueA = removeFormatter(a[key]);
								const valueB = removeFormatter(b[key]);
								return direction === 'ascending' ? valueA - valueB : valueB - valueA;
							} else {
								return direction === 'ascending' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
						}
				});
					
				setLines(sortedData);
				setSortConfig({ key, direction });
			};


    function changeVisibleParams(event) {
        // event.target.name]: event.target.checked
        let {name, checked} = event.target

				const field = lineParams[name]

        const updatedParams = { ...visibleParamsControl };
        updatedParams[field] = checked;
			
        setVisibleParamsControl(updatedParams);
    }
		
   // function handleClickOpen() {
   //     setRequestsCounter(1);
	 // 		fetchData();
	 // 		fetchLines();
	 // 		setOpenLines(true);
   // }

    function handleClose() {
        setOpen(false);
        setOpenLines(false);
    }

		function abrirModalOcultarColunas(){
        setOpenLines(false);
				setOpen(true);
		}

    // Função que abre a modal
    function abrirModal() {
        setRequestsCounter(1);
        fetchLines();

				setOpenLines(true);
    }
    function handleRedirect(redirect) {
      console.log("redirect " + redirect);
      Turbolinks.visit("/financial_management_movements/" + redirect)
    }

    function handleChange(e) {
        // props.changeVisibleMonths(e)
        console.log(e)
        changeVisibleParams(e)

    }

    async function fetchLines() {
			await axios.get(`/results/fetch_line_patterns`, {
				params: mountParamsFetchLines()
			})
			.then(response => {
				setLines(response.data.records);
				setLineRecords(response.data.lines);

				setRequestsCounter(0);
				setLoadingLineRecords(false);
			})
			.catch(error => {
				console.log(error.response)
			});
    }

    function mountParamsFetchLines() {
			return {
				"contabilParam_id": props.model_reference_id,
				"contabilParam_month": props.month,
				"month_day_week": props.monthName,
				"contabilParam_year": props.year,
				"contabilParam_categories": lineParams,
				"type_detailing_result": props.type_detailing_result,
				"type_result": props.type_result,
				"reference_id": props.reference_id,
				"visibleParams": visibleParamsControl,
				"name_month": props.monthName
			}
    }

	async function exportToXlsx() {
      try {        
        const res = await axios.get(`/results/detailing_export.xlsx`, {
          responseType: 'blob',
          params: mountParamsFetchLines()
        });

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Relatório detalhamento - ${props.monthName}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Não foi possível gerar relatorio de detalhamento', error)
      }
    }

    return (


        <div className={'comment-block'}>
            <i className={'fa fa-eye edit'} onClick={abrirModal}></i>
            <DialogContent>
                <LoadingDialog open={requestsCounter > 0}/>
            </DialogContent>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle id="simple-dialog-title">Detalhamento - {props.monthName}</DialogTitle>
                <DialogContent>
									<FormGroup>
										{lineParams && Object.keys(lineParams).map((key) => (
											visibleParamsControl[lineParams[key]] ? (
												<FormControlLabel
													control={<Checkbox defaultChecked onChange={handleChange} name={key} />}
													label={key}
												/>
											) : (
												<FormControlLabel
													control={<Checkbox onChange={handleChange} name={key} />}
													label={key}
												/>
											)
										))}
									</FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Fechar
                    </Button>
                    <Button style={{
                        float: 'right',
                        margin: "0 10px 10px"
                    }} variant="contained" color="primary" onClick={abrirModal}>
                        Buscar
                    </Button>
                </DialogActions>

            </Dialog>
            <Dialog
                open={openLines}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle id="simple-dialog-title">
                  <div style={{display: 'flex', justifyContent: 'space-between' }}>
                    <div className='padding-top-8'>
                      Detalhamento - {props.monthName}
                    </div>
                    { Object.keys(lines).length > 0 &&
                      <div style={{ display: 'flex'}}>
												<button style={{
														display: 'flex',
														justifyContent: 'center',
														borderRadius: 12,
														background: '#dddcdc',
														alignItems: 'center',
														width: 178,
														fontSize: '1.2rem',
														color: '#666565',
														border: 'none'
													}} onClick={abrirModalOcultarColunas}>
														Selecionar colunas
												</button>
												<Space />
                        <a className='pointer' onClick={()=> exportToXlsx()} title="Download para XLSX">
                            <img src={downloadIco} style={{width: 36}}></img>
                        </a>
                      </div>
                    }
                  </div>
                </DialogTitle>
                <div id="container">
                    <div className={'table-responsive table-height'}>
                         <table  className={'table table-bordered table-striped table-hover table-sticky'}>
                        <thead>
                        <tr>
                            <td></td>
                            {Object.values(lineRecords).map((line, value) => {
                                return (
                                    <td onClick={() => handleSort(line)} style={{cursor: 'pointer'}} className={'table-thead-detaillg-result'}>
																				<ReorderIcon />
																				{line ==="manual" ? "Lançamento Manual" : line }
																		</td>
                                );
                            })}

                        </tr>
                        </thead>
												<tbody>
														{loadingLineRecords ? (
																<tr>
																		<td colSpan={Object.keys(lineRecords).length + 1}>
																				<Skeleton height={500} />
																		</td>
																</tr>
														) : (
																<>
																		{Object.keys(lines).length === 0 && (
																				<tr>
																						<th colSpan={Object.keys(lineRecords).length + 1} style={{ textAlign: 'center' }}>
																								Sem dados para detalhar
																						</th>
																				</tr>
																		)}
																		{Object.values(lines).map((line, value) => (
																				<tr key={value}>
																						<td></td>
																						{Object.entries(lineRecords).map(([key, record], index) => (
																								<th key={index} className="table-tbody-detaillg-result">
																										{key === "manual" && line[key] === true ? (
																												<div
																														title="Abrir Lançamento Manual"
																														style={{ color: 'rgb(36,44,86)', fontWeight: 'bold' }}
																														className="pointer"
																														onClick={() => handleRedirect(line["redirect"])}
																												>
																														<img src={olhoOn} style={{ width: 36 }} alt="Open Manual Entry" />
																												</div>
																										) : (
																												line[key] === undefined ? " " : line[key]
																										)}
																								</th>
																						))}
																				</tr>
																		))}
																</>
														)}
												</tbody>
                    </table>
                  </div>
                </div>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
