import React from "react";
import downloadIco from 'bt_download.svg';
import Select from 'react-select';

class AgingList extends React.Component {
  constructor(props) {
    super(props);
    this.requestsCounter = 0
    this.state = {
      detailsItems: {},
      isLoading: true,
      expandedCategories: {},
      expandedPartners: {},
      basisDate: '',
      filteredPartners: [],
      selectedPartner: null,
    };
  }

  componentDidMount() {
    this.searchAgingList();
  }

  async searchAgingList(client = '') {
    try {
      const encodedClient = client ? encodeURIComponent(client) : '';
      const res = await axios.get(`/results/aging_list.json?client=${encodedClient}`);

      const sumCategories = res.data.sum_categories;
      const detailsItems = res.data.details_items;
      const basisDate = res.data.basis_date;

      this.setState({ sumCategories, detailsItems, isLoading: false, basisDate });
      
      if (!client) {
        this.filterPartners(detailsItems);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error('Erro ao buscar os dados do banco:', error);
    }
  }

  async exportToXlsx () {
    try {
      const { selectedPartner } = this.state
      const encodedClient = selectedPartner ? encodeURIComponent(selectedPartner) : '';
      const res = await axios.get(`/results/aging_list/export.xlsx?client=${encodedClient}`, { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'AgingList.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Não foi possível gerar relatório', error);
    }
  }

  calculateTotalPeriods(sumCategories) {
    let total = 0
    Object.entries(sumCategories).map(([_, value]) => {
      total += value
    })
    return total
  }

  renderTableHeader() {
    return (
      <tr>
        <th style={{ paddingRight: 150, color: "#1598d3" }}>Categoria</th>
        <th key='sum-categories' style={{ paddingRight: 120, color: "#1598d3" }}>Total</th>
        <th key='header-number-nf' style={{ paddingRight: 100, color: "#1598d3" }}>Nota Fiscal</th>
        <th key='header-due-date' style={{ paddingRight: 88, color: "#1598d3" }}>Data Vencimento</th>
        <th key='header-issue-nf' style={{ paddingRight: 88, color: "#1598d3" }}>Data Emissão</th>
      </tr>
    );
  }

  renderTableBody() {
    const { detailsItems, sumCategories, expandedCategories } = this.state;
    const categories = Object.keys(sumCategories);
    const numberOfColumns = 5

    return (
      <React.Fragment>
        {categories.map((category) => (
          <React.Fragment key={category}>
            {this.renderCategoriesAndMonths(category, sumCategories, expandedCategories)}
            {expandedCategories[category] && this.renderLineDetails(detailsItems, category)}
          </React.Fragment>
        ))}

        {this.renderSeparatedLine(numberOfColumns)}
        {this.renderTotalMonths(sumCategories)}
      </React.Fragment>
    );
  }

  renderCategoriesAndMonths(category, sumCategories, expandedCategories) {
    return (
      <tr key={category} onClick={() => this.toggleCategory(category)}>
        <th style={{ zIndex: 0 }}>
          <div style={{ marginRight: 10, display: 'inline-block' }}>
            <i className={`fa fa-chevron-${expandedCategories[category] ? 'down' : 'right'}`} />
          </div>
          {category}
        </th>

        {
          <th key={`sum-category-${category}`} className='column-total-value'>
            {this.numberToCurrency(sumCategories[category])}
          </th>
        }
        <th className='column-total-value'>-</th>
        <th className='column-total-value'>-</th>
        <th className='column-total-value'>-</th>
      </tr>
    );
  }

  renderSeparatedLine(numberOfColumns) {
    return (
      <tr>
        {Array.from({ length: numberOfColumns }).map((_, cellIndex) => (
          <th key={cellIndex}>-</th>
        ))}
      </tr>
    );
  }

  renderTotalMonths(sumCategories) {
    return (
      <tr className="total-row">
        <th style={{ paddingLeft: 5 }}>Total a receber:</th>
        <th>
          {this.numberToCurrency(
            this.calculateTotalPeriods(sumCategories)
          )}
        </th>
        <th>-</th>
        <th>-</th>
        <th>-</th>
      </tr>
    );
  }

  renderLineDetails(detailsItems, category) {
    const { expandedPartners } = this.state;
    return (
      <>
        {detailsItems[category].map((partner, index) =>
          <React.Fragment key={`fragment-${partner.partner}-${index}`}>
            <tr key={`line-${partner.partner}`} className="line" onClick={() => this.togglePartner(category, partner.partner)}>
              <td>
                <div style={{ marginRight: 10, display: 'inline-block' }}>
                  <i className={`fa fa-chevron-${expandedPartners[category]?.[partner.partner] ? 'down' : 'right'}`} />
                </div>
                {partner.partner}
              </td>

              <td className='column-total-value'>{this.numberToCurrency(partner.value_accumulated)}</td>
              <td className='column-total-value'>-</td>
              <td className='column-total-value'>-</td>
              <td className='column-total-value'>-</td>
            </tr>

            {expandedPartners[category]?.[partner.partner] && Object.entries(partner.transactions).map(([index, transaction]) =>
              this.renderTransactionRow(partner.partner, transaction, index)
            )}
          </React.Fragment>
        )}
      </>
    );
  }

  renderTransactionRow(namePartner, transaction, index) {
    const transactionKey = `${namePartner}-${transaction.description}-${transaction.value}-${index}`;
    return (
      <tr key={transactionKey} style={{ color: '#b4bfc9', fontStyle: 'italic' }}>
        <td style={{ paddingLeft: 40 }}>{transaction.description}</td>
        <td className='column-total-value'>{this.numberToCurrency(transaction.value)}</td>
        <td className='column-total-value' key={`nf-${transaction.number_nf}`}>{transaction.number_nf}</td>
        <td className='column-total-value' key={`issue-date-${transaction.due_date}`}>{transaction.due_date}</td>
        <td className='column-total-value' key={`issue-date-${transaction.number_nf}`}>{transaction.issue_date_nf}</td>
      </tr>
    );
  }

  numberToCurrency(number) {
    if (number === 0 || number) {
      return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    return "-";
  }

  toggleCategory = (category) => {
    this.setState(prevState => ({
      expandedCategories: {
        ...prevState.expandedCategories,
        [category]: !prevState.expandedCategories[category],
      },
    }));
  };

  togglePartner = (category, partner) => {
    this.setState(prevState => ({
      expandedPartners: {
        ...prevState.expandedPartners,
        [category]: {
          ...prevState.expandedPartners[category],
          [partner]: !prevState.expandedPartners[category]?.[partner],
        },
      },
    }));
  };

  filterPartners = (detailsItems) => {
    const filteredPartners = new Set();

    Object.values(detailsItems).forEach(category => {
      category.forEach(item => {
        filteredPartners.add(item.partner);
      });
    });

    const formattedPartners = Array.from(filteredPartners)
      .sort((a, b) => a.localeCompare(b))
      .map(partner => ({ value: partner, label: partner }));

    this.setState({ filteredPartners: formattedPartners });
  };

  handlePartnerChange = (selectedOption) => {
    const client = selectedOption ? selectedOption.value : '';
    this.setState({ selectedPartner: client }, () => {
      this.searchAgingList(client);
    });
  };

  render() {
    if (this.state.isLoading) {
      return this.renderSkeleton();
    }

    return (
      <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <small style={{ fontSize: 10, padding: "20px 0 0 43px", display: 'block', color: 'grey' }}>
            Data base (realizado): {this.state.basisDate}
          </small>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="padding-top-8 padding-right-20" style={{ minWidth: '300px', zIndex: 5 }}>
              <Select
                options={this.state.filteredPartners}
                placeholder={"Filtrar por Cliente"}
                isClearable={true}
                onChange={this.handlePartnerChange}
                TextFieldProps={{
                  InputLabelProps: {
                    htmlFor: 'react-select-single',
                    shrink: true,
                  },
                }}
              />
            </div>
            <div onClick={() => { this.exportToXlsx() }} className="padding-top-8 padding-right-40">
              <a style={{ marginLeft: 20 }} className='pointer' title="Download para XLSX">
                <img src={downloadIco} style={{ width: 36 }} alt="Download Icon" />
              </a>
            </div>
          </div>
        </div>

        <div className="content margin-left-40 margin-right-30">
          <table id="table-result" className="margin-bottom-20 without-acumulado balance-table">
            <thead style={{ position: 'sticky', top: 0, zIndex: 3 }}>
              {this.renderTableHeader()}
            </thead>
            <tbody>
              {this.renderTableBody()}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }

  renderSkeleton() {
    return (
      <div className="content margin-left-40 margin-right-40 padding-top-40">
        <table id="table-result" className="margin-bottom-20 without-acumulado balance-table">
          <thead>
            <tr>
              <th>Categoria</th>
              {Array.from({ length: 4 }).map((_, index) => (
                <th key={index} className="skeleton-cell"></th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 9 }).map((_, rowIndex) => (
              <tr key={rowIndex}>
                <td className="skeleton-cell"></td>
                {Array.from({ length: 4 }).map((_, cellIndex) => (
                  <td key={cellIndex} className="skeleton-cell"></td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AgingList;
