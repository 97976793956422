import React from "react";

import { MentionsInput, Mention } from "react-mentions";

import provideExampleValue from "./provideExampleValue";

import defaultStyle from "./styles/defaultStyle";
import defaultMentionStyle from "./styles/defaultMentionStyle";
import hashtagMentionStyle from "./styles/hashtagMentionStyle";

function SingleLine({
  value,
  data,
  hashtagData,
  customData,
  titleData,
  onChange,
  onAdd,
  inputRef,
  onMudado,
  costCenterAreaData,
  label
}) {
  return (
    <div className="single-line">

      <h4>{label !== 'undefined' ? label : "Formula"}</h4>

      <MentionsInput
        singleLine={true}
        value={value}
        
        allowSpaceInQuery={true}
        onChange={onChange}
        style={defaultStyle}
        placeholder={"Referencie parametros usando '@'"}
      >
        <Mention
          key={1}
          data={data}
          className={"arroba_mention"}
          renderSuggestion={(entry, search, highlightedDisplay, index, focused)=>{
            
            return <div >
                      <small style={{fontStyle: 'italic', color: "#bababa"}}>{titleData[entry.id] ? (titleData[entry.id] + " -> ") : ""}</small>
                      {entry.display}
                    </div>
          }}
          trigger="@"
          appendSpaceOnAdd={true}
          style={{ backgroundColor: "#3f12" }}
        />
        <Mention
          key={2}
          className={"hashtag_mention"}
          markup="#[__display__]($[__id__])"
          renderSuggestion={(entry, search, highlightedDisplay, index, focused)=>{
            
            return <div>
                      <small style={{fontStyle: 'italic', color: "#bababa"}}>{entry.group} - </small>
                      {entry.display}
                    </div>
          }}
          trigger="#"
          data={hashtagData}
          style={{ backgroundColor: "#2B7DE999" }}
          appendSpaceOnAdd={true}
        />
         <Mention
          key={2}
          className={"hashtag_mention"}
          markup="![__id__]"
          trigger="!"
          data={customData}
          style={{ backgroundColor: "#fce358" }}
          appendSpaceOnAdd={true}
        />
        <Mention
          key={2}
          className={"hashtag_mention"}
          markup="?[__display__]($[@cc__id__])"
          trigger="?"
          data={costCenterAreaData}
          style={{ backgroundColor: "cyan" }}
          appendSpaceOnAdd={true}
        />
      </MentionsInput>
    </div>
  );
}

export default SingleLine;
