import React from "react";
import LoadingDialog from './../LoadingDialog'
import Comment from './../Comment'
import DetailingResult from './../DetailingResult'
import MonthFilter from './../MonthFilter'
import popupIco from 'popup-ico.svg';
import {popupCenter} from './../popup/popupCenter'
import olhoOn from 'bt_olho_on.svg'
import olhoOff from 'bt_olho_off.svg'
import ResultChart from "../ResultChart";
import moment from 'moment';
import downloadIco from 'bt_download.svg';

import {BlueLink, CentralButton, LeftButton, RightButton} from "../../styled_components/default";
import ManualLaunch from "../ManualLaunch";
class Balance extends React.Component {

  constructor(props){
  	super(props)
  	this.requestsCounter = 0

  	this.state ={

        months:{
            "janeiro": 1,
            "fevereiro": 2,
            "março": 3,
            "abril": 4,
            "maio": 5,
            "junho": 6,
            "julho": 7,
            "agosto": 8,
            "setembro": 9,
            "outubro": 10,
            "novembro": 11,
            "dezembro": 12
        },
        recordsFetchedByYear: {},
        allData: {},
        allkeyData: {},
        liquidProfitFormula: {},
        allYearsData: {},
        showZeroLines: false,
			lineParams: {}, 
			visibleParams: {},
  		year: this.props.defaultYear,
  		activeBranches: {},
        months_foreseen_accomplished: {},
        months_notification: {},
        code_contabil_pattern_param_list: {},
        months_selected: '',
        month_number_selected: 0,
        value_initial_balance: 0,
        count_search_month_selected: 0,
        recordsFetched: {},
        activeKeys: {},
  		loading: false,
  		data: [],
  		data_balance: [],
  		years: [],
        allDataYears: [],
  		resultIds: [],
  		cash_flow_formula_ids: {},
      activeRows: {},
      periods: {},
      visibleMonths: {
        janeiro: true,
        fevereiro: true,
        março: true,
        abril: true,
        maio: true,
        junho: true,
        julho: true,
        agosto: true,
        setembro: true,
        outubro: true,
        novembro: true,
        dezembro: true,
      },
      result_operational: {
          "1" : 0,
          "2" : 0,
          "3" : 0,
          "4" : 0,
          "5" : 0,
          "6" : 0,
          "7" : 0,
          "8" : 0,
          "9" : 0,
          "10":  0,
          "11":  0,
          "12":  0
      },
      result_not_operational:  {
          "1" : 0,
          "2" : 0,
          "3" : 0,
          "4" : 0,
          "5" : 0,
          "6" : 0,
          "7" : 0,
          "8" : 0,
          "9" : 0,
          "10":  0,
          "11":  0,
          "12":  0
     },
      initial_balance: {
            "1" : 0,
            "2" : 0,
            "3" : 0,
            "4" : 0,
            "5" : 0,
            "6" : 0,
            "7" : 0,
            "8" : 0,
            "9" : 0,
            "10":  0,
            "11":  0,
            "12":  0
      },
      final_balance: {
            "1" : 0,
            "2" : 0,
            "3" : 0,
            "4" : 0,
            "5" : 0,
            "6" : 0,
            "7" : 0,
            "8" : 0,
            "9" : 0,
            "10":  0,
            "11":  0,
            "12":  0
        },
        result_months: {
            "1" : 0,
            "2" : 0,
            "3" : 0,
            "4" : 0,
            "5" : 0,
            "6" : 0,
            "7" : 0,
            "8" : 0,
            "9" : 0,
            "10":  0,
            "11":  0,
            "12":  0
        },
      vls_saldo_bank: {
            janeiro: 0,
            fevereiro: 0,
            março: 0,
            abril:0,
            maio: 0,
            junho: 0,
            julho:0,
            agosto: 0,
            setembro: 0,
            outubro: 0,
            novembro: 0,
            dezembro: 0,
        },
      check_bank: {
            janeiro: 0,
            fevereiro: 0,
            março: 0,
            abril:0,
            maio: 0,
            junho: 0,
            julho:0,
            agosto: 0,
            setembro: 0,
            outubro: 0,
            novembro: 0,
            dezembro: 0,
        }

  	}
  }
  fetchInitialBalance(){
      let self = this;
      console.log('fetchInitialBalance')
      Object.entries(this.state.months).map(([month_name, month_number]) => {
              self.state.final_balance[month_number] =    0
              self.state.initial_balance[month_number] =  0
              self.state.result_operational[month_number] =  0
              self.state.result_not_operational[month_number] =  0
      })
      self.state.count_search_month_selected=  0
  }
  async fetchDataParams(year=null, label){


      this.setState({loading: true})
      await axios.get('/results/get_result_chart_balance.json',{params: {
              balence_type: "total_balance"
          }}).then(response => {
          this.setState({allData:  response.data.result })
          this.setState({allDataYears:  response.data.years })
      })
      /*if(label ==="Operacional" ){

          this.setState((oldState)=>{
              oldState.allData[year] = {"result_operational":  this.state.result_operational}
              return {...oldState}
          })



      }else{
          if(label ==="Não Operacional" ){
              this.setState((oldState)=>{
                  oldState.allData[year] = {"result_not_operational":  this.state.result_not_operational}
                  return {...oldState}
              })

          }else{
              if(label ==="initial_balance"){
                  this.setState((oldState)=>{
                      oldState.allData[year] = {"initial_balance":  this.state.initial_balance}
                      return {...oldState}
                  })


              }else{
                  if(label ==="final_balance"){
                      this.setState((oldState)=>{
                          oldState.allData[year] = {"final_balance":  this.state.final_balance}
                          return {...oldState}
                      })


                  }else{
                      if(label ==="check_bank"){
                          this.setState((oldState)=>{
                              oldState.allData[year] = {"check_bank":  this.state.check_bank}
                              return {...oldState}
                          })


                      }else{
                          if(label ==="vls_saldo_bank"){
                              this.setState((oldState)=>{
                                  oldState.allData[year] = {"vls_saldo_bank":  this.state.vls_saldo_bank}
                                  return {...oldState}
                              })

                          }else{
                              if(label ==="result_months"){
                                  this.setState((oldState)=>{
                                      oldState.allData[year] = {"result_months":  this.state.result_months}
                                      return {...oldState}
                                  })

                              }
                          }
                      }
                  }
              }

          }
      }*/



    }

  async fetchInitialParams(year=null, changeData=true){

  	this.setState({loading: true})
  	this.requestsCounter += 1
	  let result = await axios.get('/results/balances.json', {params: {
      year: year
    }})
    this.requestsCounter -= 1
    this.setState({loading: false})
    if(changeData){

      this.setState({cash_flow_formula_ids: result.data.cash_flow_formula_ids})
      this.setState({data: result.data.params})
      this.setState({result: result.data.result})
      {/*this.setState({resultIds: result.data.result.result_ids})*/}
      this.setState({resultIds: {}})
    }


    this.setState({allYearsData:{
      ...this.state.allYearsData,
      [year]: result.data.result
    }})
	
  }



  async fetchDetail(key, year=this.state.year, hide=true, updateLocal=true, month = 0){
    const {recordsFetched} = this.state;
    const {cost_center_heads, is_budget} = this.props;

    if(recordsFetched[key] && hide){
      this.setState((oldState)=>{
        oldState.activeKeys[key] = {} 
        oldState.recordsFetched[key] = null
        return {...oldState}
      })
    }else{
      this.setState({loading: true})
      this.requestsCounter += 1
      let details = await axios.get(`/results/${key}/detail.json`, {params: {
          year: year,
          value_param: 'f_saldo_atual',
          type: 'month',
          month: month
      }})
      this.requestsCounter -= 1
      this.setState({loading: false})
      
      this.setState((oldState)=>{
        let dataFetched = {}
        if (Object.values(Object.values(details.data)[0].childreen).length > 0){
          dataFetched = Object.values(details.data)[0].childreen
        }else{
          dataFetched = Object.values(details.data)[0].lines
        }

        if(updateLocal){
          oldState.recordsFetched[key] = dataFetched
        }

        oldState.recordsFetchedByYear[year] ||= {}
        oldState.recordsFetchedByYear[year][key] = dataFetched
        return {...oldState}
      })
    }
    
  }
  async setComments(){
    
    let comments = await axios.get('/comments.json', {params:{
      year: [this.state.year, 0]
    }})  
    
    
    this.setState({comments: comments.data})
  }
  async setDetailingResults(){

        let comments = await axios.get('/comments.json', {params:{
                year: [this.state.year, 0]
            }})


        this.setState({comments: comments.data})
  }
  async getCheckBank(year, month_name,month_number) {

        await axios.get(`/results/get_bank_statements_month.json`, {
            params: {
                year: year,
                month: month_number
            }
        }).then(response => {
            this.setState((oldState)=>{
                oldState.vls_saldo_bank[month_name] =  response.data.value
                return {...oldState}
            })

        })

    }
  async setYears(){
  	this.requestsCounter += 1
  	let years = await axios.get('/balance_sheets/years.json')
  	this.requestsCounter -= 1
  	this.setState({years: years.data})
    this.setComments()
  }
  async setCheckBank(year, months){

      Object.entries(months).map(([name, number])=>(
              this.getCheckBank(year,name,number )
      ))


  }
  async setMonthCompensationValidation(year){

        await axios.get(`/results/month_compensation_validation.json`, {
            params: {
                year: year
            }
        }).then(response => {
            this.setState({months_foreseen_accomplished:  response.data.months_foreseen_accomplished })
            this.setState({months_notification:  response.data.months_notification })

        })

    }
    async get_code_contabil_pattern_param_list(year){

        await axios.get(`/results/get_code_contabil_pattern_param_list.json`, {
            params: {
                year: year,
                type: "year"
            }
        }).then(response => {
            this.setState({code_contabil_pattern_param_list:  response.data.code_contabil_pattern_param_list })

        })

    }

		async fetchDataColumnsPatterns() {
			await axios.get(`/results/columns_patterns.json`)
				.then(response => {
					console.log(response.data.columns_patterns)
					console.log(response.data.visible_params)
					this.setState({lineParams: response.data.columns_patterns})
					this.setState({visibleParams: response.data.visible_params});
				})
				.catch(error => {
					console.log(error.response)
				});

		}

  componentDidMount(){
    console.log('componentDidMount')
		this.fetchDataColumnsPatterns();
  	this.setYears();
    this.setComments();
    this.fetchInitialBalance();
    this.fetchConflicts();
  	this.fetchInitialParams(this.state.year);
    this.getInitialBalance(this.state.year);

  	this.setMonthCompensationValidation(this.state.year);
  	this.get_code_contabil_pattern_param_list(this.state.year);
    this.setCheckBank(this.state.year, this.state.months);
  }

  async exportResult(year, initial_balance,  final_balance, result_operational, result_not_operational,vls_saldo_bank,check_bank){
      this.setState({loading: true})
      this.requestsCounter += 1
      await  axios.get(`/export/export_balance`, {params: {
              year: year,
              type_export: 'months',
              initial_balance: initial_balance,
              final_balance: final_balance,
              result_operational: result_operational,
              result_not_operational: result_not_operational,
              vls_saldo_bank: vls_saldo_bank,
              check_bank: check_bank
          }}).then((result)=>{

              window.location.href = "/export/balance_xlsx/" + year + "/" + result.data.filename
      })
      this.requestsCounter -= 1
      this.setState({loading: false})
  }
  numberToCurrency(number){
  	if(number == 0 || number){
  		return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })	
  	}
  	return "-"
	
  }
  removeAllChildren(row, rows){
    if(row.childreen_attributes.length > 0){
      rows[row.id] = false
      row.childreen_attributes.map((child)=>(this.removeAllChildren(child, rows)))
      
    }else{
      rows[row.id] = false
    }
  }
  toggleRow(parent, row){
    
  	let rows = this.state.activeBranches
  	rows[row.id] = !rows[row.id]
    if(!rows[row.id]){
      this.removeAllChildren(row, rows)  
    }
    
  	this.setState({activeBranches: rows})
  }
  branchIsActive(row){
  	return this.state.activeBranches[row.id]
  }

  sumResults(values){
  	let result = Object.values(Object.values(values)).reduce((a,b)=> parseFloat(a)+ parseFloat(b), 0)
  	return this.numberToCurrency(result)
  	// return 0
  }
  renderChildreenFor(key=null, level){
    
    let data = this.state.recordsFetched[key]

    if(data){
      return (<React.Fragment>
        {Object.values(data).map((row)=>(this.renderRow(row, level, key)))}
      </React.Fragment>)
    }
  }
  renderRow(row, level=0, key=null){
    
    let childrenLines = (entry, month_name)=>{
      try{
        let initial = Object.values(entry.lines).map((x)=> x.months[month_name]).filter((a)=> parseFloat(a).toString() != "NaN").reduce((a,b)=> parseFloat(a) + parseFloat(b), 0)

        if (Object.values(entry.childreen).length > 0) {
          Object.values(entry.childreen).map((child)=> {
            initial += childrenLines(child, month_name)
          })
        }

        return initial
      }
      catch(e){
        console.log("ERRO AQUI", e)
        return 0
      }
      
    }

    const { months} = this.props;
    const  {costCentersFetched, activeKeys} = this.state;
    let sum = 0

    return <React.Fragment>
        {!row.months ?
          <tr>
            <td ><div 
                onClick={()=> {
                  
                  this.setState((oldState)=>{
                    if(row.label){
                      
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? oldState.activeKeys[key] : {}
                      oldState.activeKeys[key][row.label] = oldState.activeKeys[key][row.label] ? null : {}
                    }else{
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? null : {}  
                    }
                    
                    return {...oldState}
                  })
                }}
                style={{paddingLeft: level * 10}}>
                  {row.label}
                </div>
            </td>
            {Object.entries(months).map(([name, number]) => {
              let monthValue = childrenLines(row, name)
              if(monthValue.toString() != "NaN"){
                sum += monthValue  
              }
              
              console.log(sum)
              return (
                <td style={{paddingLeft: "76px !important"}} className="comment-wrapper">
                  {/*this.state.comments && <Comment
                    refreshComments={()=> this.setComments()}
                    comments={this.state.comments}
                    model_reference={'balance_sheet_row'}
                    month={number}
                    year={this.state.year}
                    model_reference_id={key}>
                  </Comment>*/}
                  {this.numberToCurrency(monthValue)}
                </td>
              );
            })}
            <td>
              <ResultChart
                  loadAllYears={()=>{
                      this.state.years.map((year)=>{
                          this.fetchDetail(key, year, false, false)
                      })
                  }}
                  loadYear={(year)=> {
                      this.fetchDetail(key, year, false, false)
                  }}
                  years={this.state.years}
                  year={this.state.year}
                  title={row.label}
                  line={row}
                  allYearsData={this.state.recordsFetchedByYear}
                  dataKey={"balance-detail-line"}
                  months={this.state.months}
              />
            </td>
            <td>
              {this.numberToCurrency(sum)}
            </td>
        </tr>
      :
        this.renderLine(row, level, key)
      }

      
      {activeKeys[key] && row.childreen && Object.values(row.childreen).map((child)=>{
          return this.renderRow(child, level + 1, key)
        })}
      {(activeKeys[key] && activeKeys[key][row.label]) && row.lines &&  Object.keys(row.childreen).length < 1 && Object.values(row.lines).map((line)=>{
          return this.renderLine(line, level + 1, key)
        })}
      </React.Fragment>
  }
  removeSufix(code){
    let {sufix} = this.props;

    return code.replace("-"+sufix, "")
  }
  renderLine(line, level=0, key){
    const { months, year } = this.state;
    let totalLine = 0
    line.id = this.removeSufix(line.id)
    return <tr className="line">
      <td>
        <div style={{paddingLeft: 65 + (level * 5)}}>{line.label} </div>
      </td>
      {Object.entries(months).map(([name, number]) => {
          if(parseFloat(line.months[name]).toString() != "NaN"){
            totalLine += parseFloat(line.months[name])  
          }
          
          return (
            <td style={{paddingLeft: "76px !important",}} className="comment-wrapper">
              {/*this.state.comments && <Comment
                refreshComments={()=> this.setComments()}
                comments={this.state.comments}
                model_reference={'balance_sheet_line'}
                month={number}
                year={this.state.year}
                model_reference_id={line.id}>
              </Comment> */}
                <DetailingResult
                    refreshDetailingResults={()=> this.setDetailingResults()}
                    detailingResults={this.state.comments}
                    model_reference={'balance_sheet'}
                    month={number}
                    monthName={name}
                    type_detailing_result={"line"}
                    type_result={"month"}
										lineParams={this.state.lineParams}
										visibleParams={this.state.visibleParams}
                    year={this.state.year}
                    model_reference_id={line.id}
                    model_reference_label={line.label}
                    reference_id={line.id}>
                </DetailingResult>
                {line.months[name] ? (     parseFloat(line.months[name]) ).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                }) : "-"}
            </td>
          );
        })}
        <td >
            <ResultChart
                loadAllYears={()=>{
                    this.state.years.map((year)=>{
                        this.fetchDetail(key, year, false, false)
                    })
                }}
                loadYear={(year)=> {
                    this.fetchDetail(key, year, false, false)
                }}
                years={this.state.years}
                year={this.state.year}
                title={line.label}
                line={line}
                allYearsData={this.state.recordsFetchedByYear}
                dataKey={"balance-detail-line"}
                months={this.state.months}
            />
        </td>
    </tr>
  }
  getDataForParam(param){
    const {result, resultIds, cash_flow_formula_ids, showZeroLines, recordsFetched} = this.state;
    console.log(result.params[param.id])
    
    return result.params[param.id]
  }
  renderChildreen(contabilParam, childreen_attributes=[], level=0, signal = '+'){
  	const {months} = this.state;
  	const {result, resultIds, cash_flow_formula_ids, showZeroLines, recordsFetched, code_contabil_pattern_param_list} = this.state;

  	let childreen = null

    //contabilParam.label === 'Saídas'
	  childreen = childreen_attributes.map((child)=> {
      //let empty = true
      let empty = false

      {Object.entries(months).map(([name, number])=>{
        /*let monthValue = signal !== '+' ? (parseFloat(result?.params?.[child.id]?.[name]) * - 1) : (parseFloat(result?.params?.[child.id]?.[name]) )*/
        let monthValue =  (parseFloat(result?.params?.[child.id]?.[name]) )

        if(monthValue.toString() != "NaN"){
          empty = false
          
        }
      })}
    
    if(empty == true && !showZeroLines){
        // if(empty == false && !showZeroLines){
      return null
    }else{

		  return (<React.Fragment>


                   <tr>
                      <td className={'comment-wrapper'} style={{paddingLeft: 20 + (10 * level)}}  onClick={()=>
                          !child.is_tail ? this.toggleRow(contabilParam, child) : this.fetchDetail(child.fr_id)
                      }>
                        {/*this.state.comments && <Comment
                            refreshComments={()=> this.setComments()}
                            comments={this.state.comments}
                            model_reference={'contabil_pattern'}
                            month={0}
                            year={0}
                            model_reference_id={child.key}>
                        </Comment>*/}
                        <div style={{marginRight: 10, display: 'inline-block'}}>

                          {!child.is_tail && <i className={`fa fa-chevron-${this.branchIsActive(child) ? 'down' : 'right'}`}/>}
                          {child.is_tail && <i className={`pull-left fa fa-chevron-${recordsFetched[child.fr_id] ? 'down' : 'right'}`}/>}
                        </div>

                        {child.label} {cash_flow_formula_ids[child.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
                      </td>
                      {Object.entries(months).map(([name, number])=>(
                          <td style={{paddingLeft: "76px !important"}} className={'comment-wrapper'}>

                              <DetailingResult
                                  refreshDetailingResults={()=> this.setDetailingResults()}
                                  detailingResults={this.state.comments}
                                  model_reference={'balance_sheet'}
                                  month={number}
                                  monthName={name}
                                  year={this.state.year}
                                  type_detailing_result={""}
                                  type_result={"month"}
                                  model_reference_id={child.id}
                                  model_reference_label={child.label}
                                  reference_id={child.id}
																	lineParams={this.state.lineParams}
																	visibleParams={this.state.visibleParams}>
                              </DetailingResult>

                              {code_contabil_pattern_param_list[child.id] !== undefined  && code_contabil_pattern_param_list[child.id][number] === true &&
                                  <ManualLaunch style={{paddingLeft: 40 }}></ManualLaunch>
                              }
                            {result && result.params[child.id] && this.numberToCurrency( (parseFloat(result?.params?.[child.id]?.[name]) ))}


                          </td>
                      ))}
                      <td>
                          <ResultChart
                            loadAllYears={()=>{
                              this.state.years.map((year)=>{
                                  this.fetchInitialParams( year, false)

                              })
                            }}
                            loadYear={(year)=> this.fetchInitialParams(year, false)}
                            years={this.state.years}
                            year={this.state.year}
                            title={child.label}
                            parameter_id={child.id}
                            allYearsData={this.state.allYearsData}
                            dataKey={"balance-params"}
                            months={this.state.months}
                        />
                      </td>
                   </tr>
                    {this.renderChildreenFor(child.fr_id, level)}
			        {this.branchIsActive(child) && <React.Fragment>
			        	{this.renderChildreen(child, child.childreen_attributes, (level + 1), contabilParam.label.trim() === 'Entradas' ? '+' : '-' )}
			        </React.Fragment>}
					
				</React.Fragment>)
      }
        	
    })
  	
  	
  	return childreen
  	
  }

  changeYear(year){

    console.log('changeYear')

  	this.setState({year});
    this.fetchInitialBalance()
  	this.fetchInitialParams(year);
  	this.props.changeYear(year);
    this.setCheckBank(year, this.state.months);
    this.getInitialBalance(year);
    this.setMonthCompensationValidation(year);
    this.get_code_contabil_pattern_param_list(year);
    //this.getResultFinalMonthsSum()

    Object.keys(this.state.recordsFetched).map((key, i)=>{
      setTimeout(()=>{
        this.fetchDetail(key, year, false)
      },10 * i)
      
    })

  }

  async getInitialBalance(year) {

          await axios.get(`/results/get_final_balance.json`, {
              params: {
                  year: year
              }
          }).then(response => {

              this.setState((oldState)=>{
                  oldState.initial_balance[response.data.month_number] =  response.data.value
                  return {...oldState}
              });

              this.setState({months_selected: response.data.month});
              this.setState({month_number_selected: response.data.month_number});
              this.setState({value_initial_balance: response.data.value});

          })

   }

  checkOpeningBalanceMonth(number_name){
    return this.numberToCurrency(this.state.initial_balance[number_name]);
  }

  calculateFinalBalance(number) {
    const { state } = this;
    const { initial_balance, final_balance, result_operational, result_not_operational, month_number_selected, value_initial_balance } = state;
  
    final_balance[number] = initial_balance[number] + result_operational[number] + result_not_operational[number];
  
    if (number + 1 !== month_number_selected) {
      initial_balance[number + 1] = final_balance[number];
    } else {
      initial_balance[number + 1] = value_initial_balance;
    }
  
    return final_balance[number];
  }

  vlSaldoBank(month_name) {
        return this.numberToCurrency( this.state.vls_saldo_bank[month_name]);
 }
  checkMonth(month_name, number_month, type_contabilParam = ""){

      const { data, result } = this.state;
      let checagem = 0;
      let saidas = [];
      let sum_saidas = 0;
      let entradas = [];
      let entradas_count = 0;
      let sum_entradas = 0;

      data &&
      data.forEach((contabilParam) => {
          if (type_contabilParam === contabilParam.label) {
              contabilParam.childreen_attributes.forEach((childreen) => {
                  const childreen_label = childreen.label.trim();
                  const childreen_attributes = childreen.childreen_attributes;
                  const childreen_ids = childreen_attributes.map((child) => child.id);

                  if (childreen_label === 'Saídas' && saidas.length === 0) {
                      saidas = childreen_ids;
                      saidas.forEach((saida) => {
                          if (result.params[saida]  !== undefined) {
                              sum_saidas += parseFloat(result.params[saida][month_name]);
                          }
                      });
                  } else if (entradas.length === 0) {
                      entradas = childreen_ids;
                      entradas.forEach((entrada) => {
                          if (result.params[entrada]  !== undefined) {
                              sum_entradas += parseFloat(result.params[entrada][month_name]);
                          }
                      });
                  }
              });
          }

          checagem = sum_entradas + sum_saidas;
      });

      const result_operational = this.state.result_operational;
      const result_not_operational = this.state.result_not_operational;

      if (type_contabilParam.trim() === 'Operacional') {
          result_operational[number_month] = checagem;
      } else {
          result_not_operational[number_month] = checagem;
      }

      return this.numberToCurrency(checagem);
  }

  checkBank(year, month_name,month_number){
    let self = this;
    let value_month = 0
    const {vls_saldo_bank, final_balance} = this.state;

    if (vls_saldo_bank[month_name] !== 0) {
      value_month = vls_saldo_bank[month_name] - final_balance[month_number]
      self.state.check_bank[month_name] = value_month

      return this.numberToCurrency(value_month);
    } else{
      return "-";
    }
  }

  getResultMonthsSum(name,number_month){
    let checagem = 0;
    let self = this;
    const {result_operational, result_not_operational} = this.state;


    checagem = result_operational[number_month] + result_not_operational[number_month]
    self.state.result_months[number_month] = checagem

    return this.numberToCurrency(checagem);
  }

  changeVisibleMonths(event){
    // event.target.name]: event.target.checked
    let {name, checked} = event.target
    let props = this.props;
    this.setState((oldState)=>{
      oldState.visibleMonths[name] = checked
      oldState.months = {}
      Object.entries(oldState.visibleMonths).map(([name, checked])=>{
        if(checked){
          oldState.months[name] = props.months[name]
        }
      })
      
      return {...oldState}
    });  
  }

  changeVisibleParams(event, data){
        // event.target.name]: event.target.checked
      let {name, checked} = event.target
      let self = this;

      this.setState({visibleParams: data})

      this.setState((oldState)=>{
            oldState.visibleParams[name] = checked
            return {...oldState}
      });

      console.log(self.visibleParams)
    }

  fetchConflicts(){
    axios.get('/configured_patterns/childreen_conflicts.json').then((conflicts)=>{
      this.setState({conflicts: conflicts?.data})
    })
  }



  // fetch get_periods using axios
  fetchGetPeriods(){
    axios.get('/liquid_profits/get_periods.json').then((periods)=>{
      this.setState({periods: periods?.data})
    })
  }

  render() {

    const {data, result, year, years, resultIds, cash_flow_formula_ids, showZeroLines, months, conflicts,months_foreseen_accomplished, code_contabil_pattern_param_list, months_notification} = this.state;
    const {initial_months_foreseen_accomplished, initial_months_notification} = this.props
    let foreseen_accomplished = []
    let notification = []
    console.log(months_notification)


    if(Object.keys(months_notification ).length !== 0 ){
      notification = months_notification
    }else{
      notification = initial_months_notification
    }

    if(Object.keys(months_foreseen_accomplished ).length !== 0 ){
        foreseen_accomplished = months_foreseen_accomplished
    }else{
        foreseen_accomplished = initial_months_foreseen_accomplished
    }
    
    const finalBalanceData = Object.entries(months).map(([name, number]) => {
      const finalBalance = this.calculateFinalBalance(number);
      return { name, year: this.state.year, finalBalance, number };
    });

    return (
    	<React.Fragment>
            <br></br>
    		<div  style={{display: 'flex', justifyContent: 'space-between' }}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20 }}>


			{/* <MonthFilter changeVisibleMonths={(e)=> this.changeVisibleMonths(e)} visibleMonths={this.state.visibleMonths} months={this.props.months}></MonthFilter>

                {showZeroLines ?
                  <div title="Mostrar linhas zeradas" style={{color: 'rgb(36,44,86)', fontWeight: "bold"}} className={'pointer'} onClick={()=> this.setState({showZeroLines: !showZeroLines})}> <img src={olhoOff} style={{width: 36}}></img></div>
                  :
                  <div title="Ocultar linhas zeradas" style={{color: 'rgb(36,44,86)', fontWeight: "bold"}} className={'pointer'} onClick={()=> this.setState({showZeroLines: !showZeroLines})}> <img src={olhoOn} style={{width: 36}}></img></div>
                }
                <small style={{fontSize: 10, padding: "10px 0 0 43px", display: 'block', color: 'grey'}}>* Você está visualizando números no formato n/{this.props.divisor}</small> */} 
              </div>
	            <form className="form flex" style={{paddingRight: 35, alignItems: 'center', height: '100%'}}>
                <div className={'with-date-ico'}>
                  <select className={'margin-left-20'} value={year} onChange={(e)=> this.changeYear(e.target.value)}>
                    {years.map((option_year)=>(
                      <option value={option_year}> {option_year} </option>
                    ))}
                    
                    
                  </select>
                </div>

                    <div onClick={()=> this.exportResult(this.state.year, this.state.initial_balance, this.state.final_balance, this.state.result_operational, this.state.result_not_operational,this.state.vls_saldo_bank, this.state.check_bank )}>
                        <a style={{marginLeft: 20}} >
                            <img src={downloadIco} style={{width: 36}}></img>
                        </a>
                    </div>


	            </form>

	        </div>
            <br></br>

          {conflicts?.length > 0 && <div className={" alert alert-alert"} style={{padding: 10}}>
            <ul>
              {conflicts?.map(([code, companies, difference])=>{
                return <React.Fragment>
                          <li >{code} está parametrizado como {difference.reduce((a,b)=> a + ", " + b, "")}</li>
                      </React.Fragment>
              })}
            </ul>
          </div>}
          
	        <div id="table-result-wrapper">
	    		<div className="content margin-left-40 margin-right-40 padding-top-40 ">
		            <table id="table-result" className={"margin-bottom-20 without-acumulado balance-table"}>
		                <thead>
                            <tr>
                                <th></th>
                                {Object.entries(months ).map(([name, number])=>( foreseen_accomplished[number] !== undefined  &&  foreseen_accomplished[number] === true ?  <th style={{color: "#1598d3",  textAlign: 'center'}}>REAL</th> :
                                    <th style={{color: "#a7d8ee", paddingLeft: 40, textAlign: 'center'}}>PREV {notification[number]?.confirm === true ? <i className={"fa fa-info-circle"}  title={notification[number]?.info_days} style={{color: "#bc8431"}}/> : ""}</th>)) }
                            </tr>
		                    <tr>
		                        <th>
		                            Nome
		                        </th>

                                { Object.entries(months || {}).map(([name, number])=>(
                                    <th style={{paddingLeft: "76px !important",
                                        color: foreseen_accomplished[number] !== undefined  && foreseen_accomplished[number] === true ? "#1598d3": "#a7d8ee"
                                    }}>
                                        {monthDict[`${number}`.padStart(2, '0')]}/{year}
                                    </th>
                                ))}
                            </tr>

		                </thead>

                        {data.map((contabilParam)=>{
                            return( <React.Fragment>
                                { contabilParam.label !== "Exclusão"  &&
                                    <tbody key={contabilParam.id}>
                                        <tr  style={{borderBottom: '3px solid #E4E5E4'}}></tr>
                                        <tr id="principal" style={{borderBottom: '1px solid #e1e1e1'}}>
                                            <th className={'comment-wrapper'}>
                                                {this.state.comments && <Comment
                                                    refreshComments={()=> this.setComments()}
                                                    comments={this.state.comments}
                                                    model_reference={'contabil_pattern'}
                                                    month={0}
                                                    year={0}
                                                    model_reference_id={contabilParam.key}>
                                                </Comment>}
                                                {/*code_contabil_pattern_param_list[contabilParam.id] !== undefined &&
                                                    <ManualLaunch style={{paddingLeft: 40 }}></ManualLaunch>
                                                */}

                                                {contabilParam.label.toUpperCase() }  {cash_flow_formula_ids[contabilParam.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
                                            </th>

                                        </tr>

                                        {contabilParam.childreen_attributes.map((child)=> {

                                                return( <React.Fragment>
                                                    <tr id="principal">
                                                        <th className={'comment-wrapper'}>
                                                            {this.state.comments && <Comment
                                                                refreshComments={()=> this.setComments()}
                                                                comments={this.state.comments}
                                                                model_reference={'contabil_pattern'}
                                                                month={0}
                                                                year={0}
                                                                model_reference_id={child.key}>
                                                            </Comment>}
                                                            {child.label.toUpperCase()}  {cash_flow_formula_ids[child.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
                                                        </th>
                                                        {Object.entries(months).map(([name, number])=>(

                                                            <th style={{paddingLeft: "76px !important"}} className={'comment-wrapper'}>
                                                                {this.state.comments && <Comment
                                                                    refreshComments={()=> this.setComments()}
                                                                    comments={this.state.comments}
                                                                    model_reference={'balance_sheet'}
                                                                    month={number}
                                                                    monthName={name}
                                                                    year={this.state.year}
                                                                    model_reference_id={child.id}>
                                                                </Comment>}

                                                                {code_contabil_pattern_param_list[child.id] !== undefined  && code_contabil_pattern_param_list[child.id][number] === true &&
                                                                    <ManualLaunch style={{paddingLeft: 40 }}></ManualLaunch>
                                                                }
                                                            {result &&  ( this.numberToCurrency(result.params[child.id] !== undefined ?  result.params[child.id][name] : 0 ))}
                                                            </th>
                                                        ))}
                                                        <td >
                                                            <ResultChart
                                                                loadAllYears={()=>{
                                                                    this.state.years.map((year)=>{
                                                                        this.fetchInitialParams(year, false)
                                                                    })
                                                                }}
                                                                loadYear={(year)=> this.fetchInitialParams(year, false)}
                                                                years={this.state.years}
                                                                year={this.state.year}
                                                                title={child.label}
                                                                parameter_id={child.id}
                                                                allYearsData={this.state.allYearsData}
                                                                dataKey={"balance-params"}
                                                                months={this.state.months}
                                                            />
                                                        </td>

                                                    </tr>

                                                    {this.renderChildreen(child, child.childreen_attributes, 1, child.label.trim() !== 'Entradas'   ? '-': '+')}
                                                </React.Fragment>)

                                            })
                                        }

                                        <tr>

                                            <td style={{
                                                backgroundColor:  "#e5e5e5",
                                                color: '#424242',
                                                fontSize: 13
                                            }}> Supervit/Deficit - {contabilParam.label  }</td>
                                            {Object.entries(months).map(([name, number])=>(
                                                <td key={number+"_sp_df_"+contabilParam.id} style={{
                                                    paddingLeft: "76px !important",
                                                    backgroundColor:  "#e5e5e5",
                                                    color: '#424242',
                                                    fontSize: 13
                                                }}>
                                                    {result && this.checkMonth(name,number, contabilParam.label)  }
                                                </td>
                                            ))}
                                            <td >
                                                <ResultChart
                                                    loadAllYears={()=>{
                                                        this.state.years.map((year)=>{
                                                            this.fetchDataParams(year, contabilParam.label)
                                                        })
                                                    }}
                                                    loadYear={(year)=> this.fetchDataParams(year, contabilParam.label)}
                                                    years={this.state.allDataYears}
                                                    year={this.state.year}
                                                    title={"Supervit/Deficit - "+ contabilParam.label }
                                                    keyLabel={contabilParam.label === "Operacional" ? "result_operational" : "result_not_operational"}
                                                    allYearsData={ this.state.allData}
                                                    dataKey={"balance-line"}
                                                    months={this.state.months}

                                                />
                                            </td>

                                        </tr>
                                        <tr></tr>
                                    </tbody> }
                                </React.Fragment>)})}
		                	<tbody>

                                <tr>
                                  <td style={{
                                      backgroundColor:  "#b5c7dc",
                                      color: '#2A3170',
                                      fontSize: 13
                                  }}> Movimentação do Mês</td>
                                  {Object.entries(months).map(([name, number])=>(
                                      <td key={number+"_moviment"} style={{
                                          paddingLeft: "76px !important",
                                          backgroundColor:  "#b5c7dc",
                                          color: '#2A3170',
                                          fontSize: 13
                                      }}>
                                        {this.getResultMonthsSum(name,number)}
                                      </td>
                                  ))}
                                    <td>
                                        <ResultChart
                                            loadAllYears={()=>{
                                                this.state.years.map((year)=>{
                                                    this.fetchDataParams(year, "result_months")
                                                })
                                            }}
                                            loadYear={(year)=> this.fetchDataParams(year, "result_months")}
                                            years={this.state.allDataYears}
                                            year={this.state.year}
                                            title={"Movimentação do Mês" }
                                            keyLabel={"result_months"}
                                            allYearsData={ this.state.allData}
                                            dataKey={"balance-line"}
                                            months={this.state.months}

                                        />
                                    </td>
                                </tr>

                            </tbody>
                                {/** **/}

                            <tbody>
                                <tr></tr>
                                <tr>

                                    <td style={{
                                        backgroundColor:  "#e5e5e5",
                                        color: '#2A3170',
                                        fontSize: 13
                                    }}> Saldo Inicial</td>

                                    {Object.entries(months).map(([name, number])=>(
                                        <td key={number+"_saldo_ini" } style={{
                                            paddingLeft: "76px !important",
                                            backgroundColor:  "#e5e5e5",
                                            color: '#2A3170',
                                            fontSize: 13
                                        }}>

                                            {  result && this.checkOpeningBalanceMonth(number)}

                                        </td>
                                    ))}
                                    <td>
                                        <ResultChart
                                            loadAllYears={()=>{
                                                this.state.years.map((year)=>{
                                                    this.fetchDataParams(year, "initial_balance")
                                                })
                                            }}
                                            loadYear={(year)=> this.fetchDataParams(year, "initial_balance")}
                                            years={this.state.allDataYears}
                                            year={this.state.year}
                                            title={"Saldo Inicial" }
                                            keyLabel={"initial_balance"}
                                            allYearsData={ this.state.allData}
                                            dataKey={"balance-line"}
                                            months={this.state.months}

                                        />
                                    </td>
                                </tr>
                                <tr>

                                  <td style={{
                                      backgroundColor:  "#e5e5e5",
                                      color: '#2A3170',
                                    fontSize: 13
                                  }}> Saldo Final</td>
                                  {finalBalanceData.map(({ number, finalBalance })=>(
                                      <td key={number+"_saldo_end"} style={{
                                          paddingLeft: "76px !important",
                                          backgroundColor:  "#e5e5e5",
                                          color: '#2A3170',
                                        fontSize: 13
                                      }}>
                                        {this.numberToCurrency(finalBalance)}
                                      </td>
                                  ))}
                                    <td>
                                        <ResultChart
                                            loadAllYears={()=>{
                                                this.state.years.map((year)=>{
                                                    this.fetchDataParams(year, "final_balance")
                                                })
                                            }}
                                            loadYear={(year)=> this.fetchDataParams(year, "final_balance")}
                                            years={this.state.allDataYears}
                                            year={this.state.year}
                                            title={"Saldo Final" }
                                            keyLabel={"final_balance"}
                                            allYearsData={ this.state.allData}
                                            dataKey={"balance-line"}
                                            months={this.state.months}
                                        />
                                    </td>
                                </tr>
                                <tr></tr>
                            </tbody>
                        {data.map((contabilParam)=>{
                            return( <React.Fragment>
                                { contabilParam.label === "Exclusão"  &&
                                    <tbody key={contabilParam.id}>
                                    <tr  style={{borderBottom: '3px solid #E4E5E4'}}></tr>
                                    <tr id="principal" style={{borderBottom: '1px solid #e1e1e1'}}>
                                        <th className={'comment-wrapper'}>
                                            {this.state.comments && <Comment
                                                refreshComments={()=> this.setComments()}
                                                comments={this.state.comments}
                                                model_reference={'contabil_pattern'}
                                                month={0}
                                                year={0}
                                                model_reference_id={contabilParam.key}>
                                            </Comment>}
                                            {contabilParam.label.toUpperCase() }  {cash_flow_formula_ids[contabilParam.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
                                        </th>
                                        {Object.entries(months).map(([name, number])=>(
                                            <th style={{paddingLeft: "76px !important"}} className={'comment-wrapper'}>
                                                {/*this.state.comments && <Comment
                                                    refreshComments={()=> this.setComments()}
                                                    comments={this.state.comments}
                                                    model_reference={'balance_sheet'}
                                                    month={number}
                                                    monthName={name}
                                                    year={this.state.year}
                                                    model_reference_id={contabilParam.id}>
                                                </Comment>*/}
                                                <DetailingResult
                                                    refreshDetailingResults={()=> this.setDetailingResults()}
                                                    detailingResults={this.state.comments}
                                                    model_reference={'balance_sheet'}
                                                    month={number}
                                                    monthName={name}
                                                    year={this.state.year}
                                                    type_detailing_result={""}
																										lineParamsProp={this.state.lineParams}
																										visibleParamsProp={this.state.visibleParams}
                                                    type_result={"month"}
                                                    model_reference_id={contabilParam.id}
                                                    model_reference_label={contabilParam.label}
                                                    reference_id={"error"}>
                                                </DetailingResult>
                                                {result &&  ( this.numberToCurrency(result.params[contabilParam.id][name] === undefined ? 0 : result.params[contabilParam.id][name] ))}
                                            </th>

                                        ))}

                                    </tr>
                                    {this.renderChildreen(contabilParam, contabilParam.childreen_attributes, 1,  '+')}
                                    </tbody> }
                            </React.Fragment>)})}
                            <tbody>
                                <tr></tr>

                                <tr id="principal">
                                    <td style={{
                                        backgroundColor:  "#fffdfd",
                                        color: '#1598D3',
                                        fontSize: 13
                                    }}> Check c/ Banco</td>
                                    {Object.entries(months).map(([name, number])=>(
                                        <td style={{
                                            paddingLeft: "76px !important",
                                            backgroundColor:  "#fffdfd",
                                            color: '#1598D3',
                                            fontSize: 13
                                        }} key={number+"saldo_check_cb"}>
                                            {this.checkBank(year,name,number)}
                                        </td>
                                    ))}

                                </tr>
                                <tr>
                                    <td style={{
                                        backgroundColor:  "#e5e5e5",
                                        color: '#2A3170',
                                        fontSize: 13
                                    }}> Saldo Contas Bancárias </td>
                                    {Object.entries(months).map(([name, number])=>(
                                        <td key={number+"_saldo_cb"} style={{
                                            paddingLeft: "76px !important",
                                            backgroundColor:  "#e5e5e5",
                                            color: '#2A3170',
                                            fontSize: 13
                                        }}>
                                            {this.vlSaldoBank(name)}
                                        </td>
                                    ))}

                                </tr>
                                <tr></tr>
		                    </tbody>


		            </table>
		        </div>
		     </div>		
		     <LoadingDialog open={this.requestsCounter > 0}/>	
    </React.Fragment>);
  }
}



export default Balance;

const monthDict = {
  "01" : "janeiro",
  "02" : "fevereiro",
  "03" : "março",
  "04" : "abril",
  "05" : "maio",
  "06" : "junho",
  "07" : "julho",
  "08" : "agosto",
  "09" : "setembro",
  "10": "outubro",
  "11": "novembro",
  "12": "dezembro",
}

//update
