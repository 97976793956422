import React from "react";
import formula_tutorial from "formula_tutorial.jpeg";
import FormulaMentionInput from "./FormulaMentionInput";
import {
  WhiteBox,
  Title,
  TextInput,
  Select,
  Label,
  Line,
  TextArea,
  GreenButton,
  EntryBox,
  BlueButton,
  FilterLabel,
  FilterSection
} from "../styled_components/default.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class Formula extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();

    let formula = this.props.formula || {};
    
    this.state = {
      data: [],
      hashtagData: [],
      pattern: formula.pattern || "",
      label: formula.label,
      position: formula.position,
      scope: formula.scope,
      description: formula.description,
      layout: formula.layout,
      number_format: formula.number_format,
      cost_center_demonstration: formula.cost_center_demonstration,
      formula_type: formula.formula_type,
      contabil_pattern_reference_id: formula.contabil_pattern_reference_id,
      contabil_patterns: [],
      customData: this.props.customData
    };
  }

  async save() {
    const { number_format, pattern, label, scope, formula_type, contabil_pattern_reference_id, position, cost_center_demonstration, layout, description } = this.state;
    let result = null;

    if (this.props.formula.id) {
      result = await axios.put(`/formulas/${this.props.formula.id}.json`, {
        formula: { description, layout, number_format, pattern, label, scope, formula_type, contabil_pattern_reference_id, position, cost_center_demonstration}
      });
    } else {
      result = await axios.post(`/formulas.json`, {
        contabil_pattern_id: this.props.contabil_pattern_id,
        formula: { description, layout, number_format, pattern, label, scope, formula_type,  position, cost_center_demonstration, contabil_pattern_reference_id: this.props.contabil_pattern_id}
      });
    }

    if ([201, 200].includes(result.status)) {
      alert("Formula alterada com sucesso")
      let link = `/formulas?contabil_pattern_id=${this.props.contabil_pattern_id ? this.props.contabil_pattern_id : this.props.formula.contabil_pattern_reference_id}`
      
      if(scope.search("attachment") >= 0){
        link = `/attachments/formulas?contabil_pattern_id=${this.props.contabil_pattern_id ? this.props.contabil_pattern_id : this.props.formula.contabil_pattern_reference_id}`
      }
      Turbolinks.visit(link)
    } else {
      alert("Ocorreu algum erro");
    }
  }

  componentDidMount() {
    try {
      
      this.setState({
        data: this.props.params.map(([display, id]) => ({ id, display })),
        hashtagData: this.props.formulas.map(([group, display, id]) => ({
          id,
          display,
          group
        }))
      });
    } catch (e) {
      console.log(e);
    }
  }

  scopes(scope){
    if(this.props.is_attachment){
      return <React.Fragment>
              <option selected={scope == "attachment_table"} value={"attachment_table"}>ANEXO - TABELA</option>
          </React.Fragment>
    }else{
      return <React.Fragment>
              <option selected={scope == "cash_flow"} value={"cash_flow"}> FLUXO DE CAIXA</option>
              <option selected={scope == "dre"} value={"dre"}> DRE</option>
              <option selected={scope == "dre_cc"} value={"dre_cc"}> DRE CENTRO DE CUSTOS</option>
          </React.Fragment>
    }
    
  }

  render() {
    const { number_format, data, hashtagData, label, formula_type, scope, contabil_pattern_reference_id, position, customData, cost_center_demonstration, layout, description } = this.state;
    const {titleData, contabil_patterns, costCenterAreaData} = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <div className={"bg-content flex-1 margin-right-10"}>
          <div style={{display: "flex"}}>
            <div>
              <div className={"input"}>
                <label>Nome</label>
                <input
                  placeholder={"Nome"}
                  value={label}
                  type={"text"}
                  onChange={x => this.setState({ label: x.currentTarget.value })}
                />
              </div>
              <div className={"input"}>
                <label>Descrição</label>
                <textarea
                  style={{width: 336, resize: 'vertical', marginBottom: 27}}
                  placeholder={"Descrição"}
                  value={description}
                  type={"text"}
                  onChange={x => this.setState({ description: x.currentTarget.value })}
                />
              </div>
              
              
              <div className={"input"}>
                <label>Tipo</label>
                <select
                  value={formula_type}
                  onChange={x => this.setState({ formula_type: x.currentTarget.value })}
                >
                  <option> -- Tipo -- </option>
                  <option selected={formula_type == "grouped"} value={"grouped"}> GRUPO</option>
                  <option selected={formula_type == "formula"} value={"formula"}> FORMULA</option>
                </select>
              </div>
               {this.state.formula_type == "formula" && <div className={"input"}>
                <label>Layout</label>
                <select
                  value={layout}
                  onChange={x => this.setState({ layout: x.currentTarget.value })}
                >
                  <option> -- Layout -- </option>
                  <option selected={layout == "normal"} value={"normal"}> NORMAL</option>
                  <option selected={layout == "especial"} value={"especial"}> ESPECIAL</option>
                </select>
              </div>}
              {this.state.formula_type == "grouped" && <div className={"input"}>
                <label>Layout</label>
                <select
                  value={layout}
                  onChange={x => this.setState({ layout: x.currentTarget.value })}
                >
                  <option> -- Layout -- </option>
                  <option selected={layout == "normal"} value={"normal"}>NORMAL</option>
                  <option selected={layout == "grupo_especial"} value={"grupo_especial"}>ESPECIAL</option>
                </select>
              </div>}
              <div className={"input"}>
                <label>Escopo</label>
                  <select
                  value={scope}
                  onChange={x => this.setState({ scope: x.currentTarget.value })}
                >
                  <option> -- Escopo -- </option>
                  {this.scopes(scope)}
                </select>
              </div>

              <div className={"input"}>
                <label>Formato</label>
                  <select
                  value={number_format}
                  onChange={x => this.setState({ number_format: x.currentTarget.value })}
                >
                  <option> -- Formato do resultado -- </option>
                  <option selected={number_format == "integer"} value={"integer"}> Valor numérico</option>
                  <option selected={number_format == "money"} value={"money"}> Valor monetário</option>
                  <option selected={number_format == "percentage"} value={"percentage"}> Porcentagem</option>
                  
                </select>
              </div>

              { scope == "dre" && <FormControlLabel
                  control={
                    <Switch
                      checked={cost_center_demonstration}
                      onChange={()=> this.setState({cost_center_demonstration: !this.state.cost_center_demonstration})}
                      color="primary"
                    />
                  }
                  label="Mostrar no centro de custos"
                />}
              </div>
          </div>
          <FormulaMentionInput
            inputRef={this.input}
            titleData={titleData}
            data={data}
            customData={customData}
            costCenterAreaData={costCenterAreaData}
            hashtagData={hashtagData}
            value={this.state.pattern}
            onChange={(e, n, x, m) => {
              this.setState({ pattern: n });
            }}
          />
          <BlueButton style={{ marginTop: 20, border: 'none' }} onClick={() => this.save()}> Salvar Formula</BlueButton>
        </div>

        <div className={"bg-content flex-1 container-subtitle"}>
          <span style={{ fontSize: 18, fontWeight: 600, padding: 40, color: '#2a3170' }}>LEGENDA</span>
          
          <div style={{padding: 15, display: 'flex', flexDirection: 'column'}}>
            <span style={{fontSize: 16, marginTop: 15}}>
              1. Utilizando <span style={{fontWeight: 'bold', backgroundColor: '#3f12'}}>'@'</span>
              , o sistema faz chamadas de Contas de Modelo Contábil.
            </span>
            <span style={{fontSize: 16, marginTop: 15}}>2. Utilizando <span style={{fontWeight: 'bold', backgroundColor: '#2B7DE999'}}>'#'</span>, 
              o sistema faz chamadas de Fórmulas que já foram criadas no sistema.
            </span>
            <span style={{fontSize: 16, marginTop: 15}}>
              3. Utilizando <span style={{fontWeight: 'bold', backgroundColor: '#fce358'}}>'@'</span>, 
              o sistema exibe os Inputs que foram adicionados no sistema.
            </span>
            <span style={{fontSize: 16, marginTop: 15}}>
              4. Utilizando <span style={{fontWeight: 'bold', backgroundColor: 'lightGray'}}>'!'</span>, 
              é possível manipular o tipo de informção que deseja usar dos parâmetros do sistema. Para isso, basta se adicionar um ponto após uma conta '.' e utilizar as seguintes sintaxes:
            </span>
            <span style={{fontSize: 16, marginLeft: 10}}>4.1 <span style={{fontWeight: 'bold', backgroundColor: 'lightGray'}}>.difference</span> (utiliza a diferença dos saldos)</span>
            <span style={{fontSize: 16, marginLeft: 10}}>4.2 <span style={{fontWeight: 'bold', backgroundColor: 'lightGray'}}>.f_saldo_anterior</span> (utiliza o valor do saldo anterior)</span>
            <span style={{fontSize: 16, marginLeft: 10}}>4.2 <span style={{fontWeight: 'bold', backgroundColor: 'lightGray'}}>.f_saldo_atual</span> (utiliza o valor do saldo atual)</span>
            <span style={{fontSize: 16, marginTop: 15}}>
              4. Utilizando <span style={{fontWeight: 'bold', backgroundColor: 'cyan'}}>'?'</span>, 
              é possível utilizar contas por Centro de Custo e tipo de Despesa (fixa ou variável).
            </span>
          </div>
          {/* <img src={formula_tutorial} style={{height: 430}}/> */}
        </div>
      </div>
    );
  }
}

export default Formula;
