export const ListMonths = {
	"01":"January",
	"02":"February",
	"03":"March",
	"04":"April",
	"05":"May",
	"06":"June",
	"07":"July",
	"08":"August",
	"09":"September",
	"10":"October",
	"11":"November",
	"12":"December"
}

export const ListMonthsKey = {
	0:"January",
	1:"February",
	2:"March",
	3:"April",
	4:"May",
	5:"June",
	6:"July",
	7:"August",
	8:"September",
	9:"October",
	10:"November",
	11:"December"
}

export const ListMonthsValue = {
	"January"  : 0,  
	"February" : 1,
	"March"    : 2,
	"April"    : 3,
	"May"      : 4,
	"June"     : 5,
	"July"     : 6,
	"August"   : 7,
	"September": 8,
	"October"  : 9,
	"November" : 10,
	"December" : 11
}





export const ListMonthsPtBR = {
	"janeiro":"January",
	"fevereiro":"February",
	"marco":"March",
	"abril": "April",
	"maio": "May",
	"junho": "June",
	"julho": "July",
	"agosto": "August",
	"setembro": "September",
	"outubro": "October",
	"novembro": "November",
	"dezembro": "December"
}

export const monthPtBR = {
	1: "JANEIRO", 
	2: "FEVEREIRO",
	3: "MARÇO", 
	4: "ABRIL", 
	5: "MAIO",
	6: "JUNHO", 
	7: "JULHO", 
	8: "AGOSTO", 
	9: "SETEMBRO",
 10: "OUTUBRO",
 11: "NOVEMBRO", 
 12: "DEZEMBRO"
}

export const monthsEnToBR = {
 "January"  : "JANEIRO", 
 "February" : "FEVEREIRO",
 "March"    : "MARÇO", 
 "April"    : "ABRIL", 
 "May"      : "MAIO",
 "June"     : "JUNHO", 
 "July"     : "JULHO", 
 "August"   : "AGOSTO", 
 "September": "SETEMBRO",
 "October"	 : "OUTUBRO",
 "November" : "NOVEMBRO", 
 "December"  : "DEZEMBRO"
}
