import React from "react";


import BalanceByMonth from './BalanceByMonth'
import BalanceByDay from './BalanceByDay'
import BalanceByWeek from './BalanceByWeek'


import Budget from "./Budget";
import {BlueLink, CentralButton, LeftButton, RightButton} from "../../styled_components/default";
import MonthFilter from "../MonthFilter";
import olhoOff from "../../../assets/images/bt_olho_off.svg";
import olhoOn from "../../../assets/images/bt_olho_on.svg";
import downloadIco from "../../../assets/images/bt_download.svg";
class Result extends React.Component {
    constructor(props){
        super(props)
        let current_tab = props.current_tab || "balanceByMonth"
        // if(props.is_group_type){
        //     current_tab = props.current_tab || "segmented"
        // }
        this.state ={
            activeTab: current_tab,
            year: props.initial_year,
            month: props.initial_month,
            week: props.initial_week
        }
    }
  	

 	activeTab(tab){
 		return this.state.activeTab === tab
	}

    changeYear(year){
        this.setState({year})
    }
    changeMonth(month){
        this.setState({month})
    }
    changeWeek(week){
        this.setState({week})
    }

    normal_render(){
        const {months, report, is_group_type, has_childreen} = this.props;
        const {year, month, week} = this.state;

        let numberToCurrency = function(number){
            return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }

        return (
            <div>

                <div className="flex">
                    <div className="bg-content flex-1 with-header">

                        <div className="header" style={{display: 'flex', justifyContent: 'space-between' }}>
                            <div className="label" style={{display: 'flex', marginRight: 16}}>FLUXO DE CAIXA</div>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 20 }}>
                                <RightButton active={this.activeTab("balanceByMonth")}
                                             onClick={()=> this.setState({activeTab: "balanceByMonth"})}> Mensal</RightButton>
                                {/*<CentralButton active={this.activeTab("balanceByWeek")}
                                               onClick={()=> this.setState({activeTab: "balanceByWeek"})}> Semanal</CentralButton>*/}
                                <LeftButton active={this.activeTab("balanceByDay")}
                                            onClick={()=> this.setState({activeTab: "balanceByDay"})}> Diário</LeftButton>


                            </div>
                        </div>

                        {/*
                            <><React.Fragment>

                            </React.Fragment>

                            </> */}
                        {/*} {this.activeTab("borboleta") && <Borboleta scope={"borboleta"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("dre") && <Dre scope={"dre"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("dre_cc") && <Dre scope={"dre_cc"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} costCenterMode={true} defaultYear={year} {...this.props} />}
                        */}
                        {this.activeTab("balanceByMonth") && <BalanceByMonth  changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("balanceByWeek") && <BalanceByWeek  changeWeek={this.changeWeek.bind(week)} defaultWeek={week} changeYear={this.changeYear.bind(this)}  defaultYear={year}   {...this.props} />}
                        {this.activeTab("balanceByDay") && <BalanceByDay  changeMonth={this.changeMonth.bind(month)} defaultMonth={month} changeYear={this.changeYear.bind(this)}  defaultYear={year}   {...this.props} />}

                        {/*} {this.activeTab("flow") && <Flow  changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("segmented") && <Segmentado   changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("segmented_by_month") && <SegmentadoByMonth   changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("attachment_table") && <Attachment scope={"attachment_table"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                    */}
                    </div>

                </div>
        </div>);
    }


  render() {

        return  this.normal_render()

  }
}

export default Result;



