

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import PopHover from './PopHover'


const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    color: "rgb(68,185,189)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonClass: {
    backgroundColor: '#fff',
    marginBottom: 3,
    color: '#2a3170',
    borderColor: '#2a3170',
    borderWidth: 1
  }
}));

export default function Comment(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [description, setDescription] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let  updateComment = async function(){
    
    let {year, month, model_reference, model_reference_id} = props
    await axios.put("/comments.json", {
      year,
      month,
      model_reference,
      model_reference_id,
      description
    })
    props.refreshComments();
    handleClose()
  }

  let changeDescription= function(e){
    setDescription(e.target.value)
  }

  let comment = function(){
    let {year, month, model_reference, model_reference_id} = props
    
    let key = `["${year}", "${month}", "${model_reference}", "${model_reference_id}"]`
    
    
    return props.comments[key]
    
  }

  return (
    <div className={'comment-block'} >
      <i className={'fa fa-edit edit'}  onClick={handleClick} >
        
      </i>
      {
        comment() && <PopHover description={comment()}/>
      }
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          <Typography className={classes.typography}>INSERIR COMENTÁRIO</Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              id="outlined-multiline-static"
              label="Insira seu comentário aqui"
              multiline
              defaultValue={comment()}
              rows={4}
              placeHolder="Insira o comentário aqui"
              variant="outlined"
              onChange={(e)=> changeDescription(e)}
            />
           
          </FormControl>
        </div>
        <Button style={{
          float: 'right',
          margin: "0 10px 10px"
        }} variant="contained" color="primary" onClick={()=> updateComment()}>
          Salvar
        </Button>
        
        
  
      </Popover>
    </div>
  );
}