import React from "react";
import LoadingDialog from './../LoadingDialog'
import RemovedAmount from './../RemovedAmount'
import actionCable from 'actioncable';
import PizzaChart from './../PizzaChart'
import PopHover from './../PopHover'
import {BlueLink} from '../../styled_components/default';
import BalanceSegmentadoByMonth from "./BalanceSegmentadoByMonth";
import ResultChart from "../ResultChart";
const CableApp = {}

CableApp.cable = actionCable.createConsumer('ws://agrega.devlabs.digital/cable')

class Segmentado extends React.Component {
  
  constructor(props){
    super(props)
    this.requestsCounter = 0
    this.cable_counting = 0
    let months = {}
    Object.entries(props.months).map(([key, value])=>{
      if(Object.keys(props.visible_months[props.defaultYear]).includes(key)){
        months[key] = value
      }
    })
    this.state ={
      currentCompany: null,
      removedAmounts: {},
      totalByMonth: {},
      totalByMonthAndYear: {},
      months: months,
      companyData: {},
      formulas: [],
      report: [],
      labels: [],
      companies: [],
      years: [],
      year: this.props.defaultYear,
      recordsFetched: {},
      cost_center_mode: false,
      costCentersFetched: {},
      costCenterAreas:[],
      despesasType: {},
      costCenterRecordsGrouped: {},
      activeAreaType: {}, 
      activeKeys: {},
      formulasTotal: {},
      formulasReport: {},
      dataModel: {},
      allYearCompanyData: {},
      currentMonth: "janeiro",
      currentModality: "dre_cc",
      modalities: {
        "dre": "DRE",
        "dre_cc": "DRE - Gerencial",
        "cash_flow": "Fluxo de caixa",
        "balance_sheet": "Balancete"
      }

    }
    if (props.rails_env == "development") {
      CableApp.cable = actionCable.createConsumer('ws://localhost:3000/cable')
    }
  }

  monthDict(){
    let defaultMonths =  {
      "janeiro": 1,
      "fevereiro": 2,
      "março": 3,
      "abril": 4,
      "maio": 5,
      "junho": 6,
      "julho": 7,
      "agosto": 8,
      "setembro": 9,
      "outubro": 10,
      "novembro": 11,
      "dezembro": 12,
      "total": 13
    }
    let visibleMonths = {}
    Object.entries(defaultMonths).map(([month_name, month_number])=>{
      
      if(this.state.months[month_name] || month_number == 13){
        visibleMonths[month_name] = month_number
      }
    })
    
    return visibleMonths
  }

  tryEval(str) {
    try {
      // rm '{' and '}' from str 
      str = str.replace(/[{}]/g, '')
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }

  refreshRemovedAmounts(){
    this.setRemovedAmounts()
  }

  async setRemovedAmounts(year=this.state.year){
    
    let removedAmounts = await axios.get('/removed_amounts.json', {params:{
      year
    }})  
    
    
    this.setState({removedAmounts: removedAmounts.data})
  }

  async setYears(){
    let years = await axios.get('/balance_sheets/years.json')
    let {data} = years;
    this.setState({years: data})
    this.setState({year: data[data.length - 1]})
  }

  async fetchCostCenterData(year=null){
    const {is_budget} = this.props;
     this.setState({loading: true})
     this.requestsCounter += 1
    let result = await axios.get('/results/cost_centers.json', {params: {
        year,
        is_budget
    }})
    this.requestsCounter -= 1
    const {data} = result
    
    this.setState({
      costCenterRecordsGrouped: data
    })
  }

  async fetchData(year=null, company_id, loadUniqueData=true){
    let {currentModality} = this.state
    if(currentModality == "balance_sheet"){
      return false
    }
    this.requestsCounter += 1
    const {costCenterMode, is_budget} = this.props;
    
    let dataModel = {}
    this.setState({loading: true})
    let url = `/results/${currentModality}.json`
    let result = await axios.get(url, {params: {
        company_id,
        year,
        is_budget
    }})
    const {formulas, report, labels, areas, cost_center_grouped_by_month} = result.data
    this.requestsCounter -= 1
    
    this.setState((oldState)=>{
      if(loadUniqueData){
        oldState.companyData[company_id] = {
          formulas, report, labels, costCenterAreas: areas, recordsFetched: {}, costCenterGroupedByMonth: cost_center_grouped_by_month
        }      
      }
      oldState.allYearCompanyData[year] = oldState.allYearCompanyData[year] ? oldState.allYearCompanyData[year] : {}
      oldState.allYearCompanyData[year][company_id] = {
        formulas, report, labels, costCenterAreas: areas, recordsFetched: {}, costCenterGroupedByMonth: cost_center_grouped_by_month
      } 
      if(Object.keys(dataModel).length == 0){
        oldState.dataModel = report  
        oldState.formulas = formulas
        oldState.labels = labels
        oldState.costCenterAreas = areas
        oldState.costCenterGroupedByMonth = cost_center_grouped_by_month
      }
      return {...oldState}
    })
    
  }

  async fetchCompanies(){
    let data = await axios.get("/companies/childreen.json")
    let companies = data.data
    
    Object.values(companies).map((company_id)=>{
      this.fetchData(this.state.year, company_id)
    })
    
    this.setState({companies})
  }

  async setupCable(year){
    let self = this
    CableApp.cable.subscriptions.create({
      channel: 'TotalChannel'
    },
    {
      received: (data)=>{
        if (data.counter) {
          this.cable_counting += parseInt(data.counter)
          this.forceUpdate()

          if(this.cable_counting == 0){
            setTimeout(function(){
              self.fetchDataForRange(year)  
            }, 1000)
          }
        }
      }
    }
    )
  }

  // fetch api/companies
  fetchCurrentCompany(){
    this.requestsCounter += 1
    axios.get(`/api/companies.json`, {params:{
      only_current: true
    }})
    .then(res => {
      this.requestsCounter -= 1
      this.setState({currentCompany: res.data.data[0].attributes})
    })
    .catch(err => {
      this.requestsCounter -= 1
      console.log(err)
    })
  }

  async componentDidMount(){
    const {year} = this.state
    let x = await this.setYears()
    await this.fetchCompanies();

    // this.fetchCostCenterData(this.state.year)
    // this.fetchData(this.state.year)
    Object.values(this.monthDict()).map((n)=>{
      this.fetchDataForRange(year, n)  
    })
    
    this.setRemovedAmounts(year)
    // this.setupCable(year)
    this.changeMonth("total")
    this.fetchCurrentCompany();
  }

  async fetchAreaData(key, fixed, type_id, type_label){
    
    const {year, costCentersFetched} = this.state;
    const {cost_center_heads, is_budget} = this.props;
    let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"

    Object.entries(cost_center_heads).map(([id, label])=>{
      if(!costCentersFetched[label]){
        costCentersFetched[label] = {}
      }
    })

    if(costCentersFetched[type_label][key] && costCentersFetched[type_label][key][fixed_label]){
      this.setState((oldState)=>{
        oldState.costCentersFetched[type_label][key][fixed_label] = null
        return {...oldState}
      })
    }else{
      let details = await axios.get(`/results/${key}/cost_center_detail.json`, {params: {
          year: year,
          fixed: Boolean(fixed),
          type: type_id,
          is_budget,
      }})
      
      this.setState((oldState)=>{
        if(!oldState.costCentersFetched[type_label][key])
        { oldState.costCentersFetched[type_label][key] = {} }
        
        oldState.costCentersFetched[type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]
        return {...oldState}
      })
    }

  }

  async toggleAreaType(key, fixed, type_id, type_label){
    
    this.setState((oldState)=>{
      oldState.activeAreaType[`${key},${fixed}`] = oldState.activeAreaType[`${key},${fixed}`] ? oldState.activeAreaType[`${key},${fixed}`] : {}
      oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`] = !oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`]
      return {...oldState}
    })

  }

  formulaIsGrouped(key){
    const {formulas} = this.state

    return formulas[key]?.formula_type == "grouped"

  }

  async defineDefaultVisibleMonths(year){
    let months = {}
    Object.entries(this.props.months).map(([key, value])=>{
      if(Object.keys(this.props.visible_months[year]).includes(key)){
        months[key] = value
      }
    })
    
    this.setState({months, visibleMonths: this.props.visible_months[year]});
  }

  async changeYear(year){
    await this.defineDefaultVisibleMonths(year)
    this.setState({year})
    this.props.changeYear(year)
    this.fetchCompanies()
    this.fetchCostCenterData(year)
    this.setState({
      costCentersFetched: {},
      despesasType: {}
    })
    Object.values(this.monthDict()).map((n)=>{
      this.fetchDataForRange(year, n)  
    })
    
    this.setRemovedAmounts(year)
    
    
  }

  // renderLine(line, level=0){
  //   let { months} = this.state;
  //   let totalLine = 0
  //   return <tr>
  //     <td>
  //       <div style={{paddingLeft: level * 10}}>{line.label}</div>
  //     </td>
  //     {false && Object.entries(companies).map(([name, number]) => {
  //         if(parseFloat(line.months[name]).toString() != "NaN"){
  //           if(number != this.props.current_company_id){
  //             totalLine += parseFloat(line.months[name])    
  //           }
  //         }
          
  //         return (
  //           <td>
  //             {line.months[name] ? (parseFloat(line.months[name]) * - 1).toLocaleString("pt-BR", {
  //                 style: "currency",
  //                 currency: "BRL"
  //               }) : "-"}
  //           </td>
  //         );
  //       })}
  //     <td>{(totalLine * -1).toLocaleString("pt-BR", {
  //                 style: "currency",
  //                 currency: "BRL"
  //               }) }</td>
  //   </tr>
  // }


  costCenterGroupedBySpendType(despesa_id, fixed, despesa_type_label, month){
    const {months} = this.props;
    const {costCentersFetched} = this.state;

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;
    let result = 0
    try{
      
      rows = costCentersFetched[despesa_type_label][despesa_id][fixed_label]


      Object.values(rows).map((despesa)=>{
        if(String(parseFloat(despesa.months[month])) != "NaN"){
          result += parseFloat(despesa.months[month])  
        }
        
      })
      
    }catch(e){

    }
    

    
    return result
  }

  renderCostCenterRows(despesa_id, fixed, despesa_type_label){
    const {months} = this.props;
    const {costCentersFetched} = this.state;

    

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;

    try{
      
      rows = costCentersFetched[despesa_type_label][despesa_id][fixed_label]
      
    }catch(e){

    }

    
    
    return <React.Fragment>
      {rows && Object.entries(rows).map(([key, data])=> {
        let sum = 0
        
        return <tr>
        <td style={{textIndent: 32}}>{key}</td>
        {false && Object.entries(companies).map(([name, number]) => {
          sum += data.months[number] ? parseFloat(data.months[number]) : 0
          
          return (
            <td>
              {this.numberToCurrency(data.months[number] * -1)  || "-"}
            </td>
          );
        })}
        <td className={'acumulado-line'}>{this.numberToCurrency(sum * -1)}</td>
      </tr>})}

    </React.Fragment>
    
  }
  

  numberToCurrency = function(number=0, number_format="money") {
    if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
      number = 0
    }

    if(number_format == "percentage"){
      this.percentFormat(parseFloat(number * -1))
    }

    if(number_format == "integer"){
      return new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(number * -1))
    }

    if(!number_format || number_format == "money"){
      return parseFloat(number).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    }
  };

  renderRow(row, level=0, key=null){
    
    let childrenLines = (entry, month_name)=>{
      try{
        let initial = Object.values(entry.lines).map((x)=> x.months[month_name]).filter((a)=> parseFloat(a).toString() != "NaN").reduce((a,b)=> parseFloat(a) + parseFloat(b), 0)

        if (Object.values(entry.childreen).length > 0) {
          Object.values(entry.childreen).map((child)=> {
            initial += childrenLines(child, month_name)
          })
        }

        return initial
      }
      catch(e){
        console.log("ERRO AQUI", e)
        return 0
      }
      
    }

    let { months} = this.state;
    const  {costCentersFetched, activeKeys} = this.state;
    let sum = 0
    
    return <React.Fragment>
        {!row.months ?
          <tr>
            <td ><div 
                onClick={()=> {
                  
                  this.setState((oldState)=>{
                    if(row.label){
                      
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? oldState.activeKeys[key] : {}
                      oldState.activeKeys[key][row.label] = oldState.activeKeys[key][row.label] ? null : {}
                    }else{
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? null : {}  
                    }
                    
                    return {...oldState}
                  })
                }}
                style={{paddingLeft: level * 10}}>
                  {row.label}
                </div>
            </td>
            {false && Object.entries(companies).map(([name, number]) => {
              let monthValue = childrenLines(row, name)
              if(monthValue.toString() != "NaN"){
                sum += monthValue  
              }
              
              console.log(sum)
              return (
                <td>
                  {this.numberToCurrency(monthValue)}
                </td>
              );
            })}
            <td className={'acumulado-line'}>
              {this.numberToCurrency(sum)}
            </td>
        </tr>
      :
        this.renderLine(row, level)
      }

      
      {activeKeys[key] && row.childreen && Object.values(row.childreen).map((child)=>{
          return this.renderRow(child, level + 1)
        })}
      {(activeKeys[key] && activeKeys[key][row.label]) && row.lines &&  Object.keys(row.childreen).length < 1 && Object.values(row.lines).map((line)=>{
          return this.renderLine(line, level + 1)
        })}
      </React.Fragment>
  }
 
  renderChildreenFor(key=null){
    
    let data = this.state.recordsFetched[key]
    if(data){
      return (<React.Fragment>
        {Object.values(data).map((row)=>(this.renderRow(row, 0, key)))}
      </React.Fragment>)
    }
  }

  async fetchDetail(key){
    const {year, recordsFetched} = this.state;
    const {cost_center_heads, is_budget} = this.props;
    
    if(recordsFetched[key]){
      this.setState((oldState)=>{
        oldState.activeKeys[key] = {} 
        oldState.recordsFetched[key] = null
        return {...oldState}
      })
    }else{
      let details = await axios.get(`/results/${key}/detail.json`, {params: {
          year: year,
          cost_center_mode: this.state.cost_center_mode && cost_center_heads[key],
          is_budget
      }})
      
      this.setState((oldState)=>{
        
        if (Object.values(Object.values(details.data)[0].childreen).length > 0){
          oldState.recordsFetched[key] = Object.values(details.data)[0].childreen
        }else{
          oldState.recordsFetched[key] = Object.values(details.data)[0].lines
        }
        
        return {...oldState}
      })
    }
    
  }
  async fetchDataForRange(year, month=this.state.currentMonth, loadUniqueData=true){
      const {is_budget, scope} = this.props;
      const {currentModality, currentMonth} = this.state
      if(month == "total"){
        return false
      }
      console.log(month)
      if(currentModality == "balance_sheet"){
        return false
      }

      let start_at = new Date(`${month}/01/${year}`)
      let end_at = new Date(`${month}/01/${year}`)  

      if (month == 13) {
        start_at = new Date(`01/01/${year}`)
        end_at = new Date(`12/01/${year}`)  
      }
      

      this.requestsCounter += 1;
      
      let report = await axios.get('/formulas/borboleta.json',{params: {
          scope: currentModality,
          consolidado: true,
          with_removed_amounts: true,
          range: {
            start_at, 
            end_at
          },
          is_budget
      }})

      this.requestsCounter -= 1;
      this.setState((oldState)=>{
        if(loadUniqueData){
          oldState.totalByMonth[month] = report.data
        }
        oldState.totalByMonthAndYear[year] = oldState.totalByMonthAndYear[year] ? oldState.totalByMonthAndYear[year] : {}
        oldState.totalByMonthAndYear[year][month] = report.data
        // oldState.formulasTotal = report.data
        return {...oldState}
      })
  }

  paramLabel(value, param){
    let label;
    if (param == "difference") {
      label = <label title={"diferença"} style={{color: "#232D6A"}}>dif</label>
    }
    if (param == "f_saldo_atual") {
      label = <label title={"saldo atual"} style={{color: "#3FABAE"}}>sat</label> 
    }
    if (param == "f_saldo_anterior") {
      label = <label title={"saldo anterior"} style={{color: "#EFBF6A"}}>san</label>
    }

    return <div>{value} {label}</div>
  }

  toggleDespesasType(key){
    let {cost_center_heads} = this.props;
    let [area_id, is_fixed] = key

    Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
      this.fetchAreaData(area_id, is_fixed, despesa_type_id, label)  
    })
    
    this.setState((oldState)=>{
      oldState.despesasType[key] = !oldState.despesasType[key]
      if(!oldState.despesasType[key]){
        this.state.activeAreaType[`${key}`] = {}  
      }
      
      return {...oldState}
    })
  }


  renderCostCenterAreas(form_key, value, number_format="money"){
    const { months, cost_center_heads} = this.props;
    const  {costCentersFetched,
            costCenterAreas,
            despesasType,
            costCenterGroupedByMonth,
            companyData,
            currentMonth, 
            companies, 
            allYearCompanyData,
            dataModel} = this.state;
    
    let areaByMonth = (b_fixed, despesa_id, number, monthName)=>{

      try{
        let value = companyData[number]?.costCenterGroupedByMonth[b_fixed ? "fixed" : "not_fixed"][`[${despesa_id}, ${this.monthDict()[monthName]}]`]
        if(monthName == "total"){
          value = 0
          Object.values(this.monthDict()).map((month)=>{
            let monthValue = parseFloat(companyData[number]?.costCenterGroupedByMonth[b_fixed ? "fixed" : "not_fixed"][`[${despesa_id}, ${month}]`])
            if (monthValue) {
              value += monthValue
            }
          })
        }
        
        
        return value
        
      }catch(e){
        return 0
      }
      
    }
    let permittedAreas = {}
    
    
    dataModel?.[form_key]?.[form_key]?.cost_center_params?.ids?.map((id)=>{
      permittedAreas[`${id[0].area.id}-${id[0].is_fixed}`] = true
    })
    
    
    

    let lines = Object.entries(costCenterAreas || {}).map(([key, value])=>{
      const [fixed, despesa_id] = JSON.parse(key);
      let b_fixed = Boolean(fixed)

      

      
      //this.fetchAreaData(id, fixed)
      if (permittedAreas?.[`${despesa_id}-${fixed}`]) {
        
        return <React.Fragment><tr>
        <td ><div onClick={()=> this.toggleDespesasType([despesa_id, b_fixed])} >
        <i className={`pull-left fa fa-chevron-${despesasType[[despesa_id, b_fixed]] ? 'down' : 'right'}`}/>
        {value.label}</div></td>
        {Object.entries(this.monthDict()).map(([monthName, number]) => {
          let total = 0
          Object.entries(companies).map(([name, number]) => {
            if(this.props.current_company_id != number){
              total += areaByMonth(b_fixed, despesa_id, number, monthName) ? parseFloat(areaByMonth(b_fixed, despesa_id, number, monthName)) : 0
            }
          })
          return <td className={ number == 13 ? "last-column": ""}>
                  <label>
                    {this.numberToCurrency(total * -1)}
                  </label>
                  {number == 13 && <ResultChart
                        loadAllYears={()=>{this.setupAllYearsData()}}
                        loadYear={(year)=> this.loadYear(year)} 
                        years={this.state.years}
                        year={this.state.year} 
                        title={"formula"} 
                        fixed={b_fixed}
                        despesa_id={despesa_id}
                        months={this.props.months}
                        dataKey={"cost-center-area-segmentado"}
                        allYearsData={allYearCompanyData}
                        >
                      </ResultChart>}
                </td>
        })}
        
        {false && Object.entries(companies).map(([name, number]) => {
          if(this.props.current_company_id == number){
            return (
              <td>
                {this.numberToCurrency(areaByMonth(b_fixed, despesa_id, number))}
              </td>
            );  
          }
          
        })}
      </tr>
      
      </React.Fragment>
      }
      

    })

    return <React.Fragment>{lines}</React.Fragment>

  }

  percentFormat(number){
    return `${parseFloat(number).toFixed(2)}%`
  }

  renderCostCenterParams(report, number_format="money"){

    let {despesasType} = this.state;

    let {cost_center_heads} = this.props;
    
    let { months} = this.state;
    const {costCenterRecordsGrouped, costCenterGroupedByMonth} = this.state;
    
    return  <React.Fragment>
      {report.cost_center_params.ids.map((id)=>{
          
          let total = 0
         return  <React.Fragment><tr>
            <td onClick={()=> this.toggleDespesasType([id[0].area.id, id[0].is_fixed])}>
              
              <i className={`pull-left fa fa-chevron-${despesasType[[id[0].area.id, id[0].is_fixed]] ? 'down' : 'right'}`}/>
              {id[0].area.label} - {id[0].is_fixed ? "Fixo" : "Variável"}
            </td>
            {Object.values(months).map(month => {
              let row = parseFloat(costCenterRecordsGrouped[`["${id[0].area.label}", ${id[0].is_fixed}, ${month}]`])

              if(String(row) != "NaN"){
                total += row
              }  

              if(costCenterGroupedByMonth[id[0].is_fixed ? "fixed" : "not_fixed"][`[${id[0].area.id}, ${month}]`]){
                return <td>{this.numberToCurrency(costCenterGroupedByMonth[id[0].is_fixed ? "fixed" : "not_fixed"][`[${id[0].area.id}, ${month}]`])}</td>  
              }else{
                return <td >{this.numberToCurrency(costCenterRecordsGrouped[`["${id[0].area.label}", ${id[0].is_fixed}, ${month}]`])}</td>  
              }
              

              
            })}
            <td> {this.numberToCurrency(total)}</td>
          </tr>
          {despesasType[[id[0].area.id, id[0].is_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
            let exist = false
            try{
              exist = !!costCentersFetched[label][id[0].area.id][id[0].is_fixed ? "fixed" : "not_fixed"]  

            }catch(e){

            }
            total = 0
            return <React.Fragment>
              <tr>
                <td style={{textIndent: 16}} onClick={()=> this.toggleAreaType(id[0].area.id, id[0].is_fixed, despesa_type_id, label)} >
                  <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`}/>
                  {label}
                </td>  
                 {false && Object.entries(companies).map(([name, number]) => {
                    let value = this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, number )
                    if (String(value) != "NaN") {
                      total += value  
                    }else{
                      
                    }                    
                    return (
                      <td>
                        {this.numberToCurrency(value)}
                      </td>
                    );
                  })}
                <td className={'acumulado-line'}>{this.numberToCurrency(total)}</td>
              </tr>
            {this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`] && this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`][`${despesa_type_id}-${label}`] && this.renderCostCenterRows(id[0].area.id, id[0].is_fixed, label)}
            </React.Fragment>

          })}
          </React.Fragment>
        })}

        
      
      
    </React.Fragment>
                         

  }
  getNumerFormattedWithoutReduced(value, format){
    let {removedAmounts} = this.state;
    
    let result = value
    if(format == "percentage"){
      result = this.percentFormat(value)
    }
    if(format == "integer"){
      result = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value))
    }
    if(!format || format == "money"){
      result = this.numberToCurrency((value)) 
    }
    
    return result
  }

  getNumerFormatted(value, format, year, month, formula_id){
    if(!year){
      return this.getNumerFormattedWithoutReduced(value, format)
    }
    let {removedAmounts} = this.state;
    let key = `[${year}, "${month}", ${formula_id}]`
    let removedValue = parseFloat(removedAmounts[key])

     if (String(removedValue) == "NaN" || removedValue == undefined) {
        removedValue = 0
      }

    let result = value
    if(format == "percentage"){
      result = this.percentFormat((value  * -1) - removedValue)
    }
    if(format == "integer"){
      result = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format((parseFloat(value) * -1) - removedValue)
    }
    if(!format || format == "money"){
      result = this.numberToCurrency((value - removedValue) * -1) 
    }
    
    return result
  }



  changeMonth(currentMonth){
    let self = this;
    this.setState({currentMonth}, function(){
      self.changeModality(self.state.currentModality)
    })
  }

  async changeModality(currentModality){
    let self = this;
    let {currentMonth, year} = this.state
    let newMonth = currentMonth

    if(currentModality == "balance_sheet"){
      if(currentMonth == "total"){
        newMonth = "janeiro"
      }
    }
    
    this.setState({currentModality, currentMonth: newMonth}, function(){
       if(currentModality != "balance_sheet"){  
        this.fetchCompanies();
        
        Object.values(this.monthDict()).map((n)=>{
          this.fetchDataForRange(year, n)  
        })
      }
      
    })  
   
  }

  paramLabel(value, param){
    let label;
    if (param == "difference") {
      label = <label title={"diferença"} style={{color: "#232D6A"}}>dif</label>
    }
    if (param == "f_saldo_atual") {
      label = <label title={"saldo atual"} style={{color: "#3FABAE"}}>sat</label> 
    }
    if (param == "f_saldo_anterior") {
      label = <label title={"saldo anterior"} style={{color: "#EFBF6A"}}>san</label>
    }

    return <div>{value} {label}</div>
  }
  

  getFloat(number){
    if (parseFloat(number).toLocaleString() == "NaN"){
      return 0
    }else{
      return parseFloat(number)
    }
  }

  isParamZero(p_value, param){
    if(this.state.showZeroLines){
      return false
    }

    let { months} = this.state;
    let isZero = true
    Object.entries(months).map(([name, number]) => {
      
      if(this.getFloat(p_value[name][param]) != 0){
        isZero = false
      }
    })
    return isZero

  }
  

  renderLine(key, value){
    const { report, 
            labels, 
            formulas, 
            year, 
            years, 
            recordsFetched, 
            cost_center_mode, 
            formulasTotal, 
            companies, 
            dataModel, 
            companyData, 
            month, 
            currentMonth,
            allYearCompanyData,
            modalities,
            currentModality } = this.state;
    let { months} = this.state;
    
    return (!formulas[key]?.cost_center_demonstration || !Boolean(cost_center_mode)) && this.formulaIsGrouped(key) && Object.entries(value[key]["params"]).map(
      ([p_key, p_value]) => {
        let total = 0
        
        return (
          <React.Fragment>
            {value[key]["lines"][p_key] && Object.values(value[key]["lines"][p_key]).map((param)=>{
              let lineChartData = [];
              return <React.Fragment>
              {/*{!this.isParamZero(p_value, param) && <tr>*/}
              {true && <tr>
                  <td >
                     {
                      this.paramLabel(labels[p_key], param)
                    }
                  </td>
                  {/*<td className={'comment-wrapper'}>
                                      {p_value[name] &&
                                        this.numberToCurrency(p_value[currentMonth][param])}
                                    </td>*/}
                  {Object.entries(this.monthDict()).map(([monthName, number]) => {                                    
                    let total = 0
                    
                    Object.entries(companies).map(([name, company_id]) => {
                      let line_value = companyData?.[company_id]?.['report']?.[key]?.[key]?.["params"]?.[p_key]?.[monthName]?.[param]
                      if(line_value){
                        if(company_id != this.props.current_company_id){
                          total += this.getFloat(line_value)        
                        } 
                      }
                    })
                    lineChartData.push(total)

                    return <td className={number == 13 ? "last-column": ""}>
                      <label>
                      {this.numberToCurrency(total)} 
                      </label>
                      {number == 13 && <ResultChart
                        loadAllYears={()=>{this.setupAllYearsData()}}
                        loadYear={(year)=> this.loadYear(year)} 
                        years={this.state.years}
                        year={this.state.year} 
                        title={"formula"} 
                        param={param}
                        months={this.props.months}
                        formula_key={key}
                        param_key={p_key}
                        inversedSignal={true}
                        dataKey={"line-segmentado"}
                        allYearsData={allYearCompanyData}
                        >
                      </ResultChart>}
                    </td>
                  })}
                  {/*<td>
                    <RemovedAmount 
                      removedAmounts={this.state.removedAmounts}
                      refreshRemovedAmounts={()=> this.refreshRemovedAmounts()}
                      year={year}
                      month={currentMonth}
                      reference_id={p_key}
                      reference_model={'param'}
                     />
                  </td>*/}
                  {/* <td className={'acumulado-line'}>{this.numberToCurrency(total)}</td> */}
                  {false && Object.entries(companies).map(([name, company_id]) => {
                    let line_value = companyData?.[company_id]?.['report']?.[key]?.[key]?.["params"]?.[p_key]?.[currentMonth]?.[param]

                    return (
                      (company_id == this.props.current_company_id) && <td>
                        {this.numberToCurrency(line_value)} 
                      </td>
                    );
                  })}
                </tr>}
              
            </React.Fragment>
            })}

          </React.Fragment>
        );
      }
    )
  }

  renderIndicators(key, value){
    const { report, 
            labels, 
            formulas, 
            year, 
            years, 
            recordsFetched, 
            cost_center_mode, 
            formulasTotal, 
            companies, 
            dataModel, 
            companyData, 
            month, 
            currentMonth,
            allYearCompanyData,
            modalities,
            currentModality } = this.state;
    let { months} = this.state;
    return <React.Fragment>
      {this.formulaIsGrouped(key) && value[key]["indicators"] && Object.entries(value[key]["indicators"]).map(
        ([p_key, p_value]) => {
          
          
          return (
            <React.Fragment>
              
                <React.Fragment>
                  <tr>
                    <td >
                      <i className={`pull-left fa}`}/> 
                      {p_key}
                    </td>
                    {Object.entries(this.monthDict()).map(([monthName, number]) => {                                    
                      let total = 0
                      Object.entries(companies).map(([name, company_id]) => {
                        let line_value = companyData?.[company_id]?.['report']?.[key]?.[key]?.["indicators"]?.[p_key]?.[monthName]

                        if(line_value){
                          if(company_id != this.props.current_company_id){
                            total += this.getFloat(line_value)
                          }
                        }
                      })
                      return <td className={number == 13 ? "last-column": ""}>
                        <label>
                          {this.numberToCurrency(total)}
                        </label>
                        {number == 13 && <ResultChart
                          loadAllYears={()=>{this.setupAllYearsData()}}
                          loadYear={(year)=> this.loadYear(year)} 
                          years={this.state.years}
                          year={this.state.year} 
                          title={"formula"} 
                          months={this.props.months}
                          formula_key={key}
                          param_key={p_key}
                          inversedSignal={true}
                          dataKey={"indicators-segmentado"}
                          allYearsData={allYearCompanyData}
                          >
                        </ResultChart>}
                      </td>
                    })}
                  </tr>
              </React.Fragment>
              
            </React.Fragment>
          );
        }
      )}
    </React.Fragment>
  }

  dataForFormula(result, key){
    const { months} = this.props;
    const {formulas} = this.state;
    let data = []
    let i = 1
    Object.entries(months).map(([name, number]) => {
      if(i < 12){
        try{
          data.push( this.tryEval(result?.[number]?.[formulas?.[key].fr_id]) * -1 )  
        }catch(e){}
        
      }
      i += 1
    })
    return data
  }

  setupAllYearsData(){
    let {years} = this.state;
    years.map((year)=>{
      this.loadYear(year, false)
    })
  }

  loadYear(year, loadUniqueData=false){
    Object.entries(this.state.companies).map(([name, numero]) => {
      this.fetchData(year,numero, loadUniqueData)
    })
    let months  = [1,2,3,4,5,6,7,8,9,10,11,12,13]
    months.map((n)=>{
      this.fetchDataForRange(year, n, loadUniqueData)  
    })
  }

  render() {
    const { report, 
            labels, 
            formulas, 
            year, 
            years, 
            recordsFetched, 
            cost_center_mode, 
            formulasTotal, 
            totalByMonth,
            companies, 
            dataModel, 
            companyData, 
            month, 
            currentMonth,
            modalities,
            currentModality } = this.state;
    let { months} = this.state;


    

    return (
      <React.Fragment>
        <div className="header" style={{display: 'flex', justifyContent: 'space-between' }}>
          <div style={{display: 'flex', justifyContent: 'space-between' }}>
            <div className="label" style={{marginRight: 16}}>RESUMO EXECUTIVO</div>
             
          </div> 
          <form className="form flex" style={{paddingRight: 35, alignItems: 'center', height: '100%'}}>
               <React.Fragment>
                <BlueLink 
                  bordered
                  active={currentModality == "dre"}
                  onClick={()=> this.changeModality("dre")}
                > <div style={{paddingBottom: currentModality == "dre" ? 19 : 0}}>DRE</div> </BlueLink>
                <BlueLink 
                  bordered
                  active={currentModality == "dre_cc"}
                  onClick={()=> this.changeModality("dre_cc")}
                > <div style={{paddingBottom: currentModality == "dre_cc" ? 19 : 0}}>DRE - Gerencial</div></BlueLink>    
                <BlueLink 
                  bordered
                  active={currentModality == "cash_flow"}
                  onClick={()=> this.changeModality("cash_flow")}
                > <div style={{paddingBottom: currentModality == "cash_flow" ? 19 : 0}}>Fluxo de caixa</div></BlueLink>    
                <BlueLink 
                  bordered
                  active={currentModality == "balance_sheet"}
                  onClick={()=> this.changeModality("balance_sheet")}
                > <div style={{paddingBottom: currentModality == "balance_sheet" ? 19 : 0}}>Balancete</div></BlueLink>    
              </React.Fragment>
              
              
                
              <div className={'with-date-ico chevron'}>
                <select onChange={(e)=> this.changeYear(e.target.value)}>
                  {years.map((option_year)=>(
                    <option value={option_year} selected={year == option_year }> {option_year} </option>
                  ))}
                  
                  
                </select>
              </div>
            </form>
        </div>
        {currentModality == "balance_sheet" ? (
          <BalanceSegmentadoByMonth 
          companies={companies} 
          months={this.props.months} 
          defaultYear={this.props.defaultYear} 
          currentMonth={currentMonth} 
          visible_months={this.props.visible_months}
          year={year}/>)
         :
          <div id="table-result-wrapper">
          <div className="content ">
            <table id="table-result" className={'segmentado segmentado-by-month margin-40'}>
              <thead>
                
                <tr>
                  <th>Nome</th>
                  {Object.entries(months).map(([name, number]) => {
                    return <th>{name}</th>
                  })}
                  <th className={'acumulado-header'}>Total</th>
                  {/*<th>Movimentações</th>*/}
                  {Object.values(months).map(month => {
                      <th className={'acumulado-header'}>
                        {this.cable_counting > 0 ? 
                            <div>
                            <div style={{color: 'red'}}>(Recarregando {this.cable_counting} formulas)</div>
                            <br/><div >Acumulado</div>
                            </div>
                            :
                            <div >Acumulado</div>
                        }
                        
                        
                        </th>
                  })}
                  
                  {false && Object.entries(companies).map(([name, numero]) => (
                    (numero == this.props.current_company_id) && <th>{name}</th>
                  ))}
                </tr>
              </thead>

              {Object.entries(dataModel).map(([key, value]) => {
                let formulaTotal = 0
                console.log(formulas?.[key]?.formula_type)
                return (
                  <tbody className={`body-${formulas[key].formula_type} body-${formulas[key].formula_type}-${formulas[key].layout}`}>
                   
                    {formulas?.[key]?.formula_type != "formula" && currentModality == "dre_cc" &&  this.renderCostCenterAreas(key, value)}
                    {this.renderLine(key, value)}
                    {this.renderIndicators(key, value)}
                    <tr id="principal" className={`formula-${formulas[key].formula_type} formula-${formulas[key].formula_type}-${formulas[key].layout} format-${formulas[key].number_format} formula-${formulas[key].is_only_index ? "only-index" : "more-than-index"}`}>
                      <td>
                        <PopHover 
                          
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          withoutico description={this.props.formulas[key]}>
                          {key}
                        </PopHover>
                      </td>
                     

                      {/*<td>
                        <RemovedAmount 
                          removedAmounts={this.state.removedAmounts}
                          refreshRemovedAmounts={()=> this.refreshRemovedAmounts()}
                          year={year}
                          month={currentMonth}
                          reference_id={formulas?.[key]?.fr_id}
                          reference_model={'formula'}
                         />
                      </td>*/}
                      {Object.entries(this.monthDict()).map(([name, number]) => {
                        return <td className={`acumulado-line total-column `} >
                          <div className={`${number == 13 ? 'last-line' : 'month-lines'}`}>
                            {
                              <label data-value={this.tryEval(totalByMonth?.[number]?.[formulas?.[key].fr_id])} style={{ display: 'flex'}}>
                                {(this.state?.currentCompany?.initial_cash_formula == formulas?.[key].id || this.state?.currentCompany?.final_cash_formula == formulas?.[key].id) && number == 13 ?
                                   "-"
                                :
                                  this.getNumerFormatted(this.tryEval(totalByMonth?.[number]?.[formulas?.[key].fr_id]) , formulas[key].number_format, year, currentMonth, formulas?.[key]?.fr_id)
                                }  
                              </label>
                            }
                            {number == 13 && <>
                              <ResultChart
                                loadAllYears={()=>{this.setupAllYearsData()}}
                                loadYear={(year)=> this.loadYear(year)} 
                                years={this.state.years}
                                year={this.state.year} 
                                title={"formula"} 
                                months={this.props.months}
                                inversedSignal={true}
                                formula_id={formulas?.[key].fr_id}
                                dataKey={"result-segmentado"}
                                allYearsData={this.state.totalByMonthAndYear}
                                data={this.dataForFormula(totalByMonth, key)} 
                                >
                              </ResultChart>
                            </>}
                          </div>
                        </td>
                      })}
                      
                      {false && Object.entries(companies).map(([name, company_id]) => {
                        if(company_id != this.props.current_company_id){
                          formulaTotal += this.tryEval(companyData?.[company_id]?.["report"]?.[key]?.[key]?.["result"]?.[currentMonth])
                        }
                        
                        return (
                          (company_id == this.props.current_company_id) && <td>
                            {this.getNumerFormatted(this.tryEval(companyData?.[company_id]?.["report"]?.[key]?.[key]?.["result"]?.[currentMonth]), formulas[key]?.number_format)}
                            
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td></td>
                      <>
                      {Object.entries(this.monthDict()).map(()=><td></td>)}
                      </>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        }
        
        <LoadingDialog open={this.requestsCounter > 0}/>
      </React.Fragment>
    );
  }
}

export default Segmentado;

