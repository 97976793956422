import React from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';

const Autocomplete = ({ 
  loadOptions, 
  placeholder, 
  onChange, 
  isMulti = false, 
  value,
  noOptionsMessage = () => "Sem registros para apresentar",
  debounceDelay = 300
}) => {

  const customLoadOptions = debounce((inputValue, callback) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }
    loadOptions(inputValue).then(options => callback(options));
  }, debounceDelay);

    
  return (
    <AsyncSelect
      cacheOptions
      loadOptions={customLoadOptions}
      defaultOptions
      placeholder={placeholder}
      onChange={onChange}
      isMulti={isMulti}
      value={value}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default Autocomplete;
