import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Drawer from 'react-modern-drawer'
import { HiddenLinkWrapper } from "../styled_components/default"

import 'react-modern-drawer/dist/index.css'

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
 
function adicionarConta() {  
  window.location.href = "/financial_management_bank_acccounts";
}

export default function BankList({data}){
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
      setIsOpen((prevState) => !prevState)
  }

  const numberToCurrency = (number) => {
    if(number == 0 || number){
      return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
    return "-"
  }
  
  const list = () => {
      return (
        <>
          <div>
            <Title style={{marginBottom: 15}}>
              Detalhamento de Contas
            </Title>
            <hr/>
            {Object.entries(data)
              .sort(([, bankA], [, bankB]) => bankB.saldo_total - bankA.saldo_total)
              .map(([key, value])=> (
              <div className="widget" style={{marginBottom: 15, display: 'flex', justifyContent: 'center'}}>
                <Card>
                  <CardWrapper>
                      <div key={key}>
                        <Tag color={getRandomColor()} />
                        <CardTitle>
                          {value.descricao}
                        </CardTitle>

                        <div>
                          <CardValue>{numberToCurrency(value.saldo_total)}</CardValue>
                          <hr/>
                          <table> 
                            <tr>
                              <th><CardLabel>Conta(R$)</CardLabel></th>
                              <th><CardLabel style={{marginLeft: 10}}>Aplicacão(R$)</CardLabel></th>
                            </tr> 
                            <tr style={{height: 40}}>
                              <td style={{padding: 0}}><CardLabel>{value.conta === "" ? '0,00' : numberToCurrency(value.conta)}</CardLabel></td>
                              <td><CardLabel style={{paddingLeft: 10}}>{value.conta === "" ? '0,00' : numberToCurrency(value.aplicacao)}</CardLabel></td>
                            </tr>
                          </table>
                          <hr/>
                          <CardLabelFooter>Atualizado até {value.ultima_atualizacao} dias</CardLabelFooter>
                        </div>
                      </div>
                    </CardWrapper> 
                  </Card>
                </div>
              ))
            }
            <AddButton onClick={adicionarConta}>
              <ButtonText>Adicionar conta</ButtonText>
            </AddButton>
        </div>
      </>
    )
  }

  return (
   <>
     <HiddenLinkWrapper onClick={toggleDrawer}>Detalhes Contas</HiddenLinkWrapper>
     <Drawer
       style={{overflowY: 'auto'}}
       size='300px'
       open={isOpen}
       onClose={toggleDrawer}
       direction='right'
       className='bla bla bla'
      >
        {list()}
      </Drawer>
   </> 
  )
}

const Details = styled.p`
 padding-top: 3px;
 color: black;
 cursor: pointer;
 font-size: 12px;
 border-radius: 10px;
 background: white;
 height: 24px;
 font-family: 'Roboto';
`

const CardWrapper = styled.div`
  display: grid;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  margin: 5%;
`;

const Card = styled.div`
  width: 90%;
  height: fit-content;
  border-radius: 10px;
  background-color: #f1eeee;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardTitle = styled.h3`
  font-size: 15px;
  margin: 0;
  color: #1598D3;
  font-family: 'Roboto';
  line-height: 1.8;
`;

const CardValue = styled.span`
  margin-top: auto;
  font-size: 2rem;
  color: #676767;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Tag = styled.div`
  width: 22px;
  height: 4px;
  top: 2px;
  background-color: ${props => props.color};
  border-radius: 26px;
  transform: translateX(-50%);
  margin-left: 10px;
`;

const CardLabel = styled.span`
  font-size: 1.1rem;
  color: #666;
`;

const CardLabelFooter = styled.span`
  font-size: 11px;
  color: #666;
  margin-bottom: 5px;
`;

const TotalBalanceCard = styled.div`
  flex-direction: column;
  align-items: center;
  padding: 14px 0px 14px 15px;
  gap: 11px;
  width: 210px;
  height: 73px;
  background: #1598d3;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const TotalBalanceTitle = styled.h3`
  font-size: 14px;
  margin: 0;
  color: #676767;
  font-family: 'Roboto';
  color: #FFFFFF;
`;

const TotalBalanceValue = styled.span`
  font-size: 24px;
  font-family: 'Roboto';
  color: #ffffff;
`;

const AddButton = styled.button`
  width: 100%;
  background-color: #0ac76c;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;

  &:hover {
    background-color: #09b863;
  }

  svg {
    margin-right: 8px;
  }
`;

const ButtonText = styled.span`
  margin-left: 4px;
`;

const Title = styled.h1`
  display: flex;
  justify-content: center;
  margin-bottom: 11px;
  padding-top: 10px;
  font-size: 18px;
  margin: 0;
  color: #676767;
  font-family: 'Roboto';
`
