import React from "react";
import formula_tutorial from "formula_tutorial.jpeg";
import ChartFormulaMentionInput from "./ChartFormulaMentionInput";
import {
  WhiteBox,
  Title,
  TextInput,
  Select,
  Label,
  Line,
  TextArea,
  GreenButton,
  EntryBox,
  BlueButton,
  FilterLabel,
  FilterSection
} from "../styled_components/default.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class ChartFormula extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();

    let chart_formula = this.props.chart_formula || {};
    
    this.state = {
      data: [],
      hashtagData: [],
      pattern: chart_formula.pattern || "",
      label: chart_formula.label,
      position: chart_formula.position,
      scope: chart_formula.scope,
      is_total: chart_formula.is_total, 
      cost_center_demonstration: chart_formula.cost_center_demonstration,
      chart_formula_type: chart_formula.chart_formula_type,
      contabil_pattern_reference_id: chart_formula.contabil_pattern_reference_id,
      contabil_patterns: [],
      customData: this.props.customData
    };
  }

  async save() {
    const { pattern, label, scope, contabil_pattern_reference_id, position, cost_center_demonstration, is_total } = this.state;
    const {chart_id} = this.props
    let result = null;

    if (this.props.chart_formula.id) {
      result = await axios.put(`/charts/${chart_id}/chart_formulas/${this.props.chart_formula.id}.json`, {
        chart_formula: { pattern, 
                         label, 
                         scope, 
                         contabil_pattern_reference_id, 
                         position, 
                         cost_center_demonstration,
                         is_total}
      });
    } else {
      result = await axios.post(`/charts/${chart_id}/chart_formulas.json`, {
        chart_formula: { pattern,
                         label,
                         scope,
                         contabil_pattern_reference_id,
                         position,
                         cost_center_demonstration,
                         is_total}
      });
    }

    if ([201, 200].includes(result.status)) {
      alert("Formula alterada com sucesso")
      let link = `/charts/${this.props.chart_id}`
      Turbolinks.visit(link)
    } else {
      alert("Ocorreu algum erro");
    }
  }

  componentDidMount() {
    try {
      
      this.setState({
        hashtagData: this.props.formulas.map(([group, display, id, type]) => ({
          id,
          display,
          group
        }))
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { data, hashtagData, label, formula_type, scope, contabil_pattern_reference_id, position, customData, cost_center_demonstration, is_total } = this.state;
    const {titleData, contabil_patterns, costCenterAreaData} = this.props;
    return (
      <div className={"bg-content flex-1 "}>
        <div>
          <div >
            <div>
              <div className={"input"}>
                <label>Nome da formula</label>
                <input
                  placeholder={"Nome"}
                  value={label}
                  type={"text"}
                  onChange={x => this.setState({ label: x.currentTarget.value })}
                />
              </div>

            </div>
            
            <FormControlLabel
               control={
                 <Switch
                   checked={is_total}
                   onChange={()=> this.setState({is_total: !this.state.is_total})}
                   color="primary"
                 />
               }
               label="Coluna de total ?"
             />
            
           
          </div>
          <ChartFormulaMentionInput
            inputRef={this.input}
            titleData={titleData}
            data={data}
            customData={customData}
            costCenterAreaData={costCenterAreaData}
            hashtagData={hashtagData}
            value={this.state.pattern}
            onChange={(e, n, x, m) => {
              this.setState({ pattern: n });
            }}
          />
          <button onClick={() => this.save()}> Salvar Formula</button>
        </div>
      </div>
    );
  }
}

export default ChartFormula;
