import React, { useState } from 'react';
// import Collapse from 'react-collapse';

function BankStatement() {
  const [days, setDays] = useState([]);

  const addDay = () => {
    setDays([...days, { value: '', applications: '' }]);
  };

  const handleValueChange = (index, value) => {
    const newDays = [...days];
    newDays[index].value = value;
    setDays(newDays);
  };

  const handleApplicationsChange = (index, applications) => {
    const newDays = [...days];
    newDays[index].applications = applications;
    setDays(newDays);
  };

  return (
    <div>
      <button onClick={addDay}>Adicionar Dia</button>
      {days.map((day, index) => (
        <Collapse key={index}>
          <div>
            <label htmlFor={`value-${index}`}>Valor:</label>
            <input
              id={`value-${index}`}
              type="number"
              value={day.value}
              onChange={(e) => handleValueChange(index, e.target.value)}
            />
          </div>
          <div>
            <label htmlFor={`applications-${index}`}>Aplicações:</label>
            <textarea
              id={`applications-${index}`}
              value={day.applications}
              onChange={(e) => handleApplicationsChange(index, e.target.value)}
            />
          </div>
        </Collapse>
      ))}
    </div>
  );
}

export default BankStatement;

