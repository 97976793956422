import React,{ Component } from 'react'
import calendarIco from '../../assets/images/calendar-ico.png';
import moment from 'moment';
import { Divisor } from '../styled_components/default'


export default class MonthRangeInput extends Component{
    constructor(props){
        super(props)
        this.requestsCounter = 0
        let startDate = null;
        let singleDate = true;
        if(props.date){
            startDate = moment(props.date).toDate()
        }else{
            startDate = moment(props.startDate).toDate()
        }
        let endDate = moment(props.endDate).toDate()

        this.state = {
            startMonth: startDate ? startDate.getMonth() + 1 : 1,
            endMonth:  endDate ? endDate.getMonth() + 1 : 1,
            year: startDate.getFullYear() || 2020,
            lastYear: endDate?.getFullYear(),
            singleDate
        }
    }


    renderInput(){
        const {startMonth, endMonth, year, lastYear, singleDate} = this.state;

        return <div className={`input`} style={{width: 160}} onClick={()=> this.setState({selectorOpen: true})}>
            <div style={{display: 'flex'}}>
                {singleDate ?
                    <div className={'months'}>
                        {monthsDict[startMonth]}
                    </div>:
                    <div className={'months'}>
                        {monthsDict[startMonth]?.substring(0,3)} - {monthsDict[endMonth]?.substring(0,3)}
                    </div>
                }
                <Divisor heigth={15} />
                <div className="flex" style={{marginLeft: 10}}>
                    <div className={'year'}>{year}</div>
                    {this.props.yearRange && <div style={{marginLeft: 22}} className={'year'}>{lastYear}</div>}
                </div>
            </div>

            <img className={'calendar-ico'} src={calendarIco}></img>
        </div>


    }

    setCurrentStartMonth(month){
        const {year, lastYear} = this.state;
        let self = this;
        if((parseFloat(month) <= parseFloat(this.state.endMonth)) || this.state.singleDate || (year != lastYear)){
            this.setState({
                startMonth: month
            })
        }

        if(this.state.singleDate){
            setTimeout(()=>{
                self.setupData()
            }, 100)

        }

    }
    setCurrentEndMonth(month){
        const {year, lastYear} = this.state;
        if((parseFloat(month) >= parseFloat(this.state.startMonth)) || (year != lastYear)){
            this.setState({
                endMonth: month
            })
        }
    }

    setupData(){
        this.setState({isSaving: true})
        var startDate = new Date(this.state.year,this.state.startMonth - 1, 1);
        if(this.props.yearRange){
            var endDate = new Date(this.state.lastYear,this.state.endMonth - 1 , 1);
        }else{
            var endDate = new Date(this.state.year,this.state.endMonth - 1 , 1);
        }

        this.setState({
            selectorOpen: false,
        })
        try{
            this.props.onChange(startDate, endDate)
        }catch(e){}

    }

    resetState(){
        if(!this.state.singleDate){
            this.setState({
                startMonth: this.props.startDate.getMonth() + 1,
                endMonth: this.props.endDate.getMonth() + 1,
                year: this.props.startDate.getFullYear(),
                lastYear: this.props.endDate.getFullYear(),
            })
        }

        this.setState({selectorOpen: false})
        this.setState({isSaving: false})


    }

    renderSelector(){

        const {startMonth, endMonth, singleDate, year, lastYear} = this.state;

        const {minYear, maxYear} = this.props;
        return (
            <div style={{width: 260, left: `calc(100% - ${this.props.isFirst ? '70px': '235px'})`, marginRigth: 15}} className={`selector ${singleDate ? "single" : ""}`}>
                <div className={'flex'}>
                    <div className={'year-selector'} style={{width: '100%'}}>
                        <div className={'chevron'} onClick={()=> {
                            debugger
                            if(minYear && year == parseInt(minYear)){
                                alert("Não existem dados para o ano de "+ (parseInt(minYear) - 1))
                            }else{
                                this.setState({year: year - 1})
                            }

                        }}>
                            <i className={'fa fa-chevron-left'}></i>
                        </div>
                        <div className={'defined-year'}>
                            {year}
                        </div>
                        <div className={'chevron'} onClick={()=> {
                            if(maxYear && year == parseInt(maxYear)){
                                alert("Não existem dados para o ano de "+ (parseInt(maxYear) + 1))
                            }else{
                                this.setState({year: year + 1})
                            }

                        }}>
                            <i className={'fa fa-chevron-right'}></i>
                        </div>
                    </div>
                    {
                        this.props.yearRange && <div className={'year-selector'}>
                            <div className={'chevron'} onClick={()=> this.setState({lastYear: this.state.lastYear - 1})}>
                                <i className={'fa fa-chevron-left'}></i>
                            </div>
                            <div className={'defined-year'}>
                                {this.state.lastYear}
                            </div>
                            <div className={'chevron'} onClick={()=> this.setState({lastYear: this.state.lastYear + 1})}>
                                <i className={'fa fa-chevron-right'}></i>
                            </div>
                        </div>
                    }

                </div>
                {isMobile ?
                    <div className={'months-selector'} style={{display: 'flex', height: 107}} onBlur={()=> this.resetState()}>
                        <div class={'flex-1'} style={{display: 'flex', flexWrap: 'wrap'}}>{
                            Object.entries(monthsDict).map(([key, value])=>(
                                <div
                                    style={{width: '25%'}}
                                    onClick={()=> this.setCurrentStartMonth(key)}  className={`month-row ${
                                    key == startMonth ? 'active' : ''
                                }${
                                    !singleDate && (lastYear ? year == lastYear : true) && (parseFloat(key) > parseFloat(endMonth)) ? 'disabled' : ''
                                }`}>{value.substring(0,3)}</div>
                            ))
                        }</div>
                        {!singleDate && <React.Fragment>
                            <div style={{width: 19}} className={'center-line'}></div>
                            <div class={'flex-1'} style={{display: 'flex', flexWrap: 'wrap'}}>{
                                Object.entries(monthsDict).map(([key, value])=>(
                                    <div
                                        style={{width: '25%'}}
                                        onClick={()=> this.setCurrentEndMonth(key)}  className={`month-row ${
                                        key == endMonth ? 'active' : ''
                                    }${
                                        (lastYear ? year == lastYear : true) && parseFloat(key) < parseFloat(startMonth) ? 'disabled' : ''
                                    }`}>{value.substring(0,3)}</div>
                                ))
                            }</div>
                        </React.Fragment>}
                    </div>
                    : <div className={'months-selector'}>
                        <div class={'flex-1'}>{
                            Object.entries(monthsDict).map(([key, value])=>(
                                <div onClick={()=> this.setCurrentStartMonth(key)}  className={`month-row ${
                                    key == startMonth ? 'active' : ''
                                }${
                                    !singleDate && (lastYear ? year == lastYear : true) && (parseFloat(key) > parseFloat(endMonth)) ? 'disabled' : ''
                                }`}>{value}</div>
                            ))
                        }</div>
                        {!singleDate && <React.Fragment>
                            <div className={'center-line'}></div>
                            <div className={'flex-1'}>{
                                Object.entries(monthsDict).map(([key, value])=>(
                                    <div onClick={()=> this.setCurrentEndMonth(key)}  className={`month-row ${
                                        key == endMonth ? 'active' : ''
                                    }${
                                        (lastYear ? year == lastYear : true) && parseFloat(key) < parseFloat(startMonth) ? 'disabled' : ''
                                    }`}>{value}</div>
                                ))
                            }</div>
                        </React.Fragment>}
                    </div>}
                {!singleDate && <div className={'filter-button'} onClick={()=> this.setupData()}>
                    Filtrar
                </div>}
            </div>
        )
    }

    render(){
        return <div id={"month-range-input"} tabIndex="0"  onBlur={()=> this.resetState()} >
            {this.renderInput()}
            {this.state.selectorOpen && this.renderSelector()}
        </div>
    }
}
export const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 1000px)").matches


const monthsDict = {
    1: "janeiro",
    2: "fevereiro",
    3: "março",
    4: "abril",
    5: "maio",
    6: "junho",
    7: "julho",
    8: "agosto",
    9: "setembro",
    10: "outubro",
    11: "novembro",
    12: "dezembro",
}
