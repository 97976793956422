import React, {Fragment} from "react";
import LoadingDialog from './../LoadingDialog'
import BalanceSegmentado from './BalanceSegmentado'
import RemovedAmount from './../RemovedAmount'
import {BlueLink} from '../../styled_components/default';
import actionCable from 'actioncable';
import PizzaChart from './../PizzaChart'
import PopHover from './../PopHover'

const CableApp = {}

CableApp.cable = actionCable.createConsumer('ws://agrega.devlabs.digital/cable')

class Segmentado extends React.Component {
  
  constructor(props){
    super(props)
    this.requestsCounter = 0
    this.cable_counting = 0
    
    this.state ={
      removedAmounts: {},
      months: {total: 13, ...this.props.months},
      lastMonth: "dezembro",
      formulasTotalByMonth: {},
      companyData: {},
      formulas: [],
      report: [],
      labels: [],
      companies: [],
      currentCompany: null,
      years: [],
      year: this.props.defaultYear,
      recordsFetched: {},
      cost_center_mode: false,
      costCentersFetched: {},
      costCenterAreas:[],
      despesasType: {},
      costCenterRecordsGrouped: {},
      activeAreaType: {}, 
      activeKeys: {},
      formulasTotal: {},
      formulasReport: {},
      dataModel: {},
      currentMonth: monthDictReverse[this.props.month],
      currentModality: "dre_cc",
      modalities: {
        "dre": "DRE",
        "dre_cc": "DRE - Gerencial",
        "cash_flow": "Fluxo de caixa",
        "balance_sheet": "Balancete"
      }

    }
    if (props.rails_env == "development") {
      CableApp.cable = actionCable.createConsumer('ws://localhost:3000/cable')
    }
  }

  //fetch api/companies/last_balance_sheet_month/:year
  async fetchLastBalanceSheetMonth(year){
    axios.get(`api/companies/last_balance_sheet_month/${year}.json`).then(response => {
      this.setState({
        lastMonth: response.data
      })
      
      this.fetchDataForRange(year, null, response.data)
    })
  }

  // fetch api/companies
  fetchCurrentCompany(){
    this.requestsCounter += 1
    axios.get(`/api/companies.json`, {params:{
      only_current: true
    }})
    .then(res => {
      this.requestsCounter -= 1
      this.setState({currentCompany: res.data.data[0].attributes})
    })
    .catch(err => {
      this.requestsCounter -= 1
      
    })
  }


  tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }

  refreshRemovedAmounts(){
    this.setRemovedAmounts()
  }

  async setRemovedAmounts(year=this.state.year){
    
    let removedAmounts = await axios.get('/removed_amounts.json', {params:{
      year
    }})  
    
    
    this.setState({removedAmounts: removedAmounts.data})
  }

  async setYears(){
    let years = await axios.get('/balance_sheets/years.json')
    let {data} = years;
    this.setState({years: data})
    this.setState({year: data[data.length - 1]})
  }

  async fetchCostCenterData(year=null){
    const {is_budget} = this.props;
     this.setState({loading: true})
     this.requestsCounter += 1
    let result = await axios.get('/results/cost_centers.json', {params: {
        year,
        is_budget
    }})
    this.requestsCounter -= 1
    const {data} = result
    
    this.setState({
      costCenterRecordsGrouped: data
    })
  }

  async fetchData(year=null, company_id){
    let {currentModality} = this.state
    if(currentModality == "balance_sheet"){
      return false
    }
    this.requestsCounter += 1
    const {costCenterMode, is_budget} = this.props;
    
    let dataModel = {}
    this.setState({loading: true})
    let url = `/results/${currentModality}.json`
    let result = await axios.get(url, {params: {
        company_id,
        year,
        is_budget
    }})
    const {formulas, report, labels, areas, cost_center_grouped_by_month} = result.data
    this.requestsCounter -= 1
    
    this.setState((oldState)=>{
      oldState.companyData[company_id] = {
        formulas, report, labels, costCenterAreas: areas, recordsFetched: {}, costCenterGroupedByMonth: cost_center_grouped_by_month
      }      
      if(Object.keys(dataModel).length == 0){
        oldState.dataModel = report  
        oldState.formulas = formulas
        oldState.labels = labels
        oldState.costCenterAreas = areas
        oldState.costCenterGroupedByMonth = cost_center_grouped_by_month
      }
      return {...oldState}
    })
    
  }

  async fetchCompanies(){
    let data = await axios.get("/companies/childreen.json")
    let companies = data.data
    
    Object.values(companies).map((company_id)=>{
      this.fetchData(this.state.year, company_id)
    })
    
    this.setState({companies})
  }

  async setupCable(year){
    let self = this
    CableApp.cable.subscriptions.create({
      channel: 'TotalChannel'
    },
    {
      received: (data)=>{
        if (data.counter) {
          this.cable_counting += parseInt(data.counter)
          this.forceUpdate()

          if(this.cable_counting == 0){
            setTimeout(function(){
              self.fetchDataForRange(year)  
            }, 1000)
          }
        }
      }
    }
    )
  }

  async componentDidMount(){
    const {year} = this.state
    let x = await this.setYears()
    await this.fetchCompanies();

    // this.fetchCostCenterData(this.state.year)
    // this.fetchData(this.state.year)
    this.fetchDataForRange(year)
    this.fetchDataForRange(year, null, "janeiro")
    this.setRemovedAmounts(year)
    this.setupCable(year)
    this.changeMonth("total")
    this.fetchCurrentCompany()
    this.fetchLastBalanceSheetMonth(year)
  }

  async fetchAreaData(key, fixed, type_id, type_label){
    
    const {year, costCentersFetched} = this.state;
    const {cost_center_heads, is_budget} = this.props;
    let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"

    Object.entries(cost_center_heads).map(([id, label])=>{
      if(!costCentersFetched[label]){
        costCentersFetched[label] = {}
      }
    })

    if(costCentersFetched[type_label][key] && costCentersFetched[type_label][key][fixed_label]){
      this.setState((oldState)=>{
        oldState.costCentersFetched[type_label][key][fixed_label] = null
        return {...oldState}
      })
    }else{
      let details = await axios.get(`/results/${key}/cost_center_detail.json`, {params: {
          year: year,
          segmented: true,
          fixed: Boolean(fixed),
          type: type_id,
          is_budget,
      }})
      
      this.setState((oldState)=>{
        Object.entries(details.data.companies).map(([company_id, company_data])=>{
          
          oldState.costCentersFetched[type_label][key] ||= {}
          oldState.costCentersFetched[type_label][key][company_id] ||= {} 
          
          oldState.costCentersFetched[type_label][key][company_id][Object.keys(company_data)[0]] = Object.values(company_data)[0]
        })

        
        return {...oldState}
      })
    }

  }

  async toggleAreaType(key, fixed, type_id, type_label){
    
    this.setState((oldState)=>{
      oldState.activeAreaType[`${key},${fixed}`] = oldState.activeAreaType[`${key},${fixed}`] ? oldState.activeAreaType[`${key},${fixed}`] : {}
      oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`] = !oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`]
      return {...oldState}
    })

  }

  formulaIsGrouped(key){
    const {formulas} = this.state

    return formulas[key]?.formula_type == "grouped"

  }

  changeYear(year){
    this.setState({year})
    this.props.changeYear(year)
    this.fetchCompanies()
    this.fetchCostCenterData(year)
    this.setState({
      costCentersFetched: {},
      despesasType: {}
    })
    
    this.fetchDataForRange(year)
    this.fetchDataForRange(year, null, "janeiro")
    this.setRemovedAmounts(year)
    this.fetchLastBalanceSheetMonth(year)
    
  }

  // renderLine(line, level=0){
  //   let { months} = this.state;
  //   let totalLine = 0
  //   return <tr>
  //     <td>
  //       <div style={{paddingLeft: level * 10}}>{line.label}</div>
  //     </td>
  //     {Object.entries(companies).map(([name, number]) => {
  //         if(parseFloat(line.months[name]).toString() != "NaN"){
  //           if(number != this.props.current_company_id){
  //             totalLine += parseFloat(line.months[name])    
  //           }
  //         }
          
  //         return (
  //           <td>
  //             {line.months[name] ? (parseFloat(line.months[name]) * - 1).toLocaleString("pt-BR", {
  //                 style: "currency",
  //                 currency: "BRL"
  //               }) : "-"}
  //           </td>
  //         );
  //       })}
  //     <td>{(totalLine * -1).toLocaleString("pt-BR", {
  //                 style: "currency",
  //                 currency: "BRL"
  //               }) }</td>
  //   </tr>
  // }


  costCenterGroupedBySpendType(company_id, despesa_id, fixed, despesa_type_label){
    const {months} = this.props;
    const {costCentersFetched} = this.state;

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;
    let result = 0
    try{
      
      rows = costCentersFetched[despesa_type_label]?.[despesa_id]?.[company_id]?.[fixed_label]
      
      

      Object.values(rows || {}).map((despesa)=>{
        Object.entries(despesa.months).map(([month_number, month])=>{
          if(String(parseFloat(month)) != "NaN"){
            if(this.state.currentMonth == "total"){
              result += parseFloat(month) * -1 
            }else if(monthDict[this.state.currentMonth] == month_number){
              result += parseFloat(month) * -1 
            }
          }
        })
        
      })
      
    }catch(e){
      
    }
    

    
    return result
  }

  renderCostCenterRows(despesa_id, fixed, despesa_type_label){
    const {months} = this.props;
    const {costCentersFetched, companies} = this.state;

    

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;
    let keys = []
    let labelDict = {}
    try{
      // rows = costCentersFetched[despesa_type_label][despesa_id][fixed_label]
      // rows = costCentersFetched[despesa_type_label][despesa_id][company_id][fixed_label]
      
      Object.entries(companies).map(([name, number]) => {
        Object.entries(costCentersFetched[despesa_type_label][despesa_id][number][fixed_label]).map(([key, value])=>{
          keys.push(key)
          labelDict[key] = value.label
        })
      })
      
      keys = [...new Set(keys)]

      
      
    }catch(e){
      
    }

    
    
    return <React.Fragment>
      {keys.map((key)=>{
        let totalLine = 0
        return <tr>
          <td style={{paddingLeft: 56}}>{key} - {labelDict[key]}</td>
          {Object.entries(companies).map(([name, number]) => {
            if(this.props.current_company_id != number){
              let result = 0
              try{
                Object.entries(costCentersFetched[despesa_type_label][despesa_id][number][fixed_label][key].months).map(([month_n, month])=>{
                  if(String(parseFloat(month)) != "NaN"){
                    if(this.state.currentMonth == "total"){
                      result += parseFloat(month) * -1
                    }else if(monthDict[this.state.currentMonth] == month_n){
                      result += parseFloat(month) * -1
                    }
                    totalLine += parseFloat(month) * -1
                  }
                })
              }catch(e){
                
              }

              return <td>{result.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    })}</td>
            }
          } 
          )}
          <td>{totalLine.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    })}</td>
          {Object.entries(companies).map(([name, number]) => {
            if(this.props.current_company_id == number){
              let result = 0
              try{
                Object.values(costCentersFetched[despesa_type_label][despesa_id][number][fixed_label][key].months).map((month)=>{
                  if(String(parseFloat(month)) != "NaN"){
                    result += parseFloat(month) * -1
                  }
                })
              }catch(e){
                
              }

              return <td>{result.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    })}</td>
            }
          } 
          )}
        </tr>
      })}
     

    </React.Fragment>
    
  }
  

  numberToCurrency = function(number=0, number_format="money") {
    if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
      number = 0
    }

    if(number_format == "percentage"){
      this.percentFormat(parseFloat(number * -1))
    }

    if(number_format == "integer"){
      return new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(number * -1))
    }

    if(!number_format || number_format == "money"){
      return parseFloat(number).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    }
  };

  renderRow(param,key, p_key){
    const {labels, 
      recordsFetched,       
      companies, 
      companyData, 
      currentMonth,
      } = this.state;
      let total = 0
    return <React.Fragment>
      {/*{!this.isParamZero(p_value, param) && <tr>*/}
      {<tr>
          <td  onClick={()=> this.fetchDetail(p_key)}>
            <i className={`pull-left fa fa-chevron-${recordsFetched[p_key] ? 'down' : 'right'}`}/>
              {
              this.paramLabel(labels[p_key], param)
            }
          </td>
          {/*<td className={'comment-wrapper'}>
                              {p_value[name] &&
                                this.numberToCurrency(p_value[currentMonth][param])}
                            </td>*/}
          {Object.entries(companies).map(([name, company_id]) => {
            let line_value = companyData?.[company_id]?.['report']?.[key]?.[key]?.["params"]?.[p_key]?.[currentMonth]?.[param]

            if(line_value){
              if(company_id != this.props.current_company_id){
                total += this.getFloat(line_value)  
              }
              
            }
            return (
              (company_id != this.props.current_company_id) && <td>
                {this.numberToCurrency(line_value)}
              </td>
            );
          })}
          <td className={'acumulado-line'}><label>{this.numberToCurrency(total)}</label></td>
          {Object.entries(companies).map(([name, company_id]) => {
            let line_value = companyData?.[company_id]?.['report']?.[key]?.[key]?.["params"]?.[p_key]?.[currentMonth]?.[param]

            return (
              (company_id == this.props.current_company_id) && <td>
                {this.numberToCurrency(line_value)}
              </td>
            );
          })}
        </tr>}
        {
          this.renderFetchedLines(p_key)
        }
      
    </React.Fragment>
  }

  renderInsideRows(data, index){
    const {labels, 
      recordsFetched,       
      companies, 
      companyData, 
      currentMonth,
      } = this.state;
    
    let keys = {}
    Object.values(data).map((row)=>{
        Object.entries(row).map(([key, values])=>{
            keys[key] = values.fr_id
        })
        
    })
    
    return <React.Fragment>
      {Object.entries(keys).map(([key, key_fr_id]) => {
        let total = 0
        return <>
          <tr>
          <td style={{paddingLeft: 16 * index }} onClick={()=> this.fetchDetail(key_fr_id)}>
            <i className={`pull-left fa fa-chevron-${recordsFetched[key_fr_id] ? 'down' : 'right'}`}/>
              {key}
          </td>
            {Object.entries(companies).map(([name, company_id]) => {
              if (company_id != this.props.current_company_id){
                let lineValue = 0
                if(currentMonth == "total"){
                  lineValue = parseFloat(Object.values(data?.[company_id]?.[key]?.sum_of_lines || {}).reduce((a,b)=>{ return a + b}, 0)) * -1
                }else{
                  lineValue = parseFloat(data?.[company_id]?.[key]?.sum_of_lines[currentMonth]) * -1
                }
                total += lineValue

                return (
                  <td>
                    {this.numberToCurrency(lineValue)}
                  </td>
                );
              }
            })}
            <td className={'acumulado-line'}><label>{this.numberToCurrency(total)}</label></td>
            {Object.entries(companies).map(([name, company_id]) => {
              if (company_id == this.props.current_company_id){
                let lineValue = 0
                if(currentMonth == "total"){
                  lineValue = parseFloat(Object.values(data?.[company_id]?.[key]?.sum_of_lines || {}).reduce((a,b)=>{ return a + b}, 0)) * -1
                }else{
                  lineValue = parseFloat(data?.[company_id]?.[key]?.sum_of_lines[currentMonth]) * -1
                }
                total += lineValue

                return (
                  <td>
                    {this.numberToCurrency(lineValue)}
                  </td>
                );
              }
            })}
          </tr>
          {this.renderFetchedLines(key_fr_id, index + 1)}
        </>
      })}

      
      
      
    </React.Fragment>
  }
 
  

  async fetchDetail(key){
    const {year, recordsFetched} = this.state;
    const {cost_center_heads, is_budget} = this.props;
    
    if(recordsFetched[key]){
      this.setState((oldState)=>{
        oldState.activeKeys[key] = {} 
        oldState.recordsFetched[key] = null
        return {...oldState}
      })
    }else{
      let details = await axios.get(`/results/${key}/detail.json`, {params: {
          year: year,
          segmented: true,
          cost_center_mode: this.state.cost_center_mode && cost_center_heads[key],
          is_budget
      }})
      
      this.setState((oldState)=>{
        Object.entries(details.data.companies).map(([company_id, company_data])=>{
          oldState.recordsFetched[key] ||= {}
          if (Object.values(Object.values(company_data)[0].childreen).length > 0){
            oldState.recordsFetched[key][company_id] = Object.values(company_data)[0].childreen
            oldState.recordsFetched[key]["has_childreen"] = true
          }else{
            oldState.recordsFetched[key][company_id] = Object.values(company_data)[0].lines
          }
        })
        
        
        return {...oldState}
      })
    }
    
  }
  async fetchDataForRange(year, company_id=null, lastMonth=null){
      const {is_budget, scope} = this.props;
      let {currentModality, currentMonth} = this.state
      if(currentModality != "cash_flow" && lastMonth){
        return false
      }

      if(lastMonth){
        currentMonth = lastMonth
      }

      if(currentModality == "balance_sheet"){
        return false
      }

      let start_at = new Date(`${monthDict[currentMonth]}/01/${year}`)
      let end_at = new Date(`${monthDict[currentMonth]}/01/${year}`)  

      if (currentMonth == "total") {
        start_at = new Date(`01/01/${year}`)
        end_at = new Date(`12/01/${year}`)  
      }
      

      this.requestsCounter += 1;
      let report = await axios.get('/formulas/borboleta.json',{params: {
          scope: currentModality,
          company_id,
          consolidado: true,
          with_removed_amounts: true,
          range: {
            start_at, 
            end_at
          },
          is_budget
      }})
      
      this.requestsCounter -= 1;
      this.setState((oldState)=>{
        if(currentMonth != "total"){
          oldState.formulasTotalByMonth[currentMonth] = report.data
        }else{
          // oldState.formulasTotal = report.data
          oldState.formulasTotalByMonth["total"] = report.data
        }
        return {...oldState}
      })
  }

  paramLabel(value, param){
    let label;
    if (param == "difference") {
      label = <label title={"diferença"} style={{color: "#232D6A"}}>dif</label>
    }
    if (param == "f_saldo_atual") {
      label = <label title={"saldo atual"} style={{color: "#3FABAE"}}>sat</label> 
    }
    if (param == "f_saldo_anterior") {
      label = <label title={"saldo anterior"} style={{color: "#EFBF6A"}}>san</label>
    }

    return <div>{value} {label}</div>
  }

  toggleDespesasType(key){
    let {cost_center_heads} = this.props;
    let [area_id, is_fixed] = key

    Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
      this.fetchAreaData(area_id, is_fixed, despesa_type_id, label)  
    })
    
    this.setState((oldState)=>{
      oldState.despesasType[key] = !oldState.despesasType[key]
      if(!oldState.despesasType[key]){
        this.state.activeAreaType[`${key}`] = {}  
      }
      
      return {...oldState}
    })
  }


  renderCostCenterAreas(form_key, value, number_format="money"){
    const { months, cost_center_heads} = this.props;
    const  {costCentersFetched,
            costCenterAreas,
            despesasType,
            costCenterGroupedByMonth,
            companyData,
            currentMonth, 
            companies, 
            dataModel} = this.state;
    
    let areaByMonth = (b_fixed, despesa_id, number)=>{

      try{
        let value = companyData[number]?.costCenterGroupedByMonth[b_fixed ? "fixed" : "not_fixed"][`[${despesa_id}, ${monthDict[currentMonth]}]`]
        if(currentMonth == "total"){
          value = 0
          Object.values(monthDict).map((month)=>{
            let monthValue = parseFloat(companyData[number]?.costCenterGroupedByMonth[b_fixed ? "fixed" : "not_fixed"][`[${despesa_id}, ${month}]`])
            if (monthValue) {
              value += monthValue
            }
          })
        }
        
        
        return value
        
      }catch(e){
        return 0
      }
      
    }
    let permittedAreas = {}
    
    
    dataModel?.[form_key]?.[form_key]?.cost_center_params?.ids?.map((id)=>{
      permittedAreas[`${id[0].area.id}-${id[0].is_fixed}`] = true
    })
    
    
    

    let lines = Object.entries(costCenterAreas || {})?.map(([key, value])=>{
      const [fixed, despesa_id] = JSON.parse(key);
      let b_fixed = Boolean(fixed)

      

      
      //this.fetchAreaData(id, fixed)
      if (permittedAreas?.[`${despesa_id}-${fixed}`]) {
        let total = 0
        return <React.Fragment>
        <tr>
          <td ><div onClick={()=> this.toggleDespesasType([despesa_id, b_fixed])} >
          <i className={`pull-left fa fa-chevron-${despesasType[[despesa_id, b_fixed]] ? 'down' : 'right'}`}/>
          {value.label} </div></td>
          {Object.entries(companies).map(([name, number]) => {
            if(this.props.current_company_id != number){
              total += areaByMonth(b_fixed, despesa_id, number) ? parseFloat(areaByMonth(b_fixed, despesa_id, number)) : 0
              return (
                <td>
                  {this.numberToCurrency(areaByMonth(b_fixed, despesa_id, number) * -1)}
                </td>
              );  
            }
            
          })}
          <td className={'acumulado-line'}>
            <label>
              {this.numberToCurrency(total * -1)}
            </label>
          </td>
          {Object.entries(companies).map(([name, number]) => {
            if(this.props.current_company_id == number){
              return (
                <td>
                  {this.numberToCurrency(areaByMonth(b_fixed, despesa_id, number) * -1)}
                </td>
              );  
            }
            
          })}
        </tr>
        {despesasType[[despesa_id, b_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
            let exist = false
            try{
              exist = oldState.activeAreaType[`${despesa_id},${b_fixed}`][`${despesa_type_id}-${label}`]

            }catch(e){

            }
            total = 0
            return <React.Fragment>
              {<tr>
                <td style={{textIndent: 16}}  onClick={()=> this.toggleAreaType(despesa_id, b_fixed, despesa_type_id, label)}>
                  <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`}/>
                  {label}
                </td>  
                {Object.entries(companies).map(([name, company_id]) => {
                  if(this.props.current_company_id != company_id){
                    let value = this.costCenterGroupedBySpendType(company_id, despesa_id, b_fixed, label )
                    if (String(value) != "NaN") {
                      total += value  
                    }else{
                      
                    }                    
                    return (
                      <td className={'comment-wrapper'}>
                        {this.numberToCurrency(value)}
                      </td>
                    );
                  }else{
                    return <></>
                  }
                  })}
                <td className={'cost-center-type comment-wrapper'}>
                  <p>{this.numberToCurrency(total)}</p>                 
                </td>
                {Object.entries(companies).map(([name, company_id]) => {
                  if(company_id == this.props.current_company_id){
                    return <td className={'comment-wrapper'}>
                      {this.numberToCurrency(this.costCenterGroupedBySpendType(this.props.current_company_id, despesa_id, b_fixed, label ))}
                    </td>
                  }
                })}
                  
                  

              </tr>}
            {this.state.activeAreaType?.[`${despesa_id},${b_fixed}`]?.[`${despesa_type_id}-${label}`] && this.renderCostCenterRows(despesa_id, b_fixed, label, despesa_type_id)}
            </React.Fragment>

          })}
      </React.Fragment>
      }
      

    })

    return <React.Fragment>{lines}</React.Fragment>

  }

  percentFormat(number){
    return `${parseFloat(number).toFixed(2)}%`
  }

  // renderCostCenterParams(report, number_format="money"){

  //   let {despesasType} = this.state;

  //   let {cost_center_heads} = this.props;
    
  //   let { months} = this.state;
  //   const {costCenterRecordsGrouped, costCenterGroupedByMonth} = this.state;
    
  //   return  <React.Fragment>
  //     {report.cost_center_params.ids.map((id)=>{
          
  //         let total = 0
  //        return  <React.Fragment><tr>
  //           <td onClick={()=> this.toggleDespesasType([id[0].area.id, id[0].is_fixed])}>
              
  //             <i className={`pull-left fa fa-chevron-${despesasType[[id[0].area.id, id[0].is_fixed]] ? 'down' : 'right'}`}/>
  //             {id[0].area.label} - {id[0].is_fixed ? "Fixo" : "Variável"}
  //           </td>
  //           {Object.values(months).map(month => {
  //             let row = parseFloat(costCenterRecordsGrouped[`["${id[0].area.label}", ${id[0].is_fixed}, ${month}]`])

  //             if(String(row) != "NaN"){
  //               total += row
  //             }  

  //             if(costCenterGroupedByMonth[id[0].is_fixed ? "fixed" : "not_fixed"][`[${id[0].area.id}, ${month}]`]){
  //               return <td>{this.numberToCurrency(costCenterGroupedByMonth[id[0].is_fixed ? "fixed" : "not_fixed"][`[${id[0].area.id}, ${month}]`])}</td>  
  //             }else{
  //               return <td >{this.numberToCurrency(costCenterRecordsGrouped[`["${id[0].area.label}", ${id[0].is_fixed}, ${month}]`])}</td>  
  //             }
              

              
  //           })}
  //           <td> {this.numberToCurrency(total)}</td>
  //         </tr>
  //         {despesasType[[id[0].area.id, id[0].is_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
  //           let exist = false
  //           try{
  //             exist = !!costCentersFetched[label][id[0].area.id][id[0].is_fixed ? "fixed" : "not_fixed"]  

  //           }catch(e){

  //           }
  //           total = 0
  //           return <React.Fragment>
  //             <tr>
  //               <td style={{textIndent: 16}} onClick={()=> this.toggleAreaType(id[0].area.id, id[0].is_fixed, despesa_type_id, label)} >
  //                 <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`}/>
  //                 {label}
  //               </td>  
  //                {Object.entries(companies).map(([name, number]) => {
  //                   let value = this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, number )
  //                   if (String(value) != "NaN") {
  //                     total += value  
  //                   }else{
                      
  //                   }                    
  //                   return (
  //                     <td>
  //                       {this.numberToCurrency(value)}
  //                     </td>
  //                   );
  //                 })}
  //               <td className={'acumulado-line'}>
  //                 <label>
  //                   {this.numberToCurrency(total)}
  //                 </label>
  //               </td>
  //             </tr>
  //           {this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`] && this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`][`${despesa_type_id}-${label}`] && this.renderCostCenterRows(id[0].area.id, id[0].is_fixed, label)}
  //           </React.Fragment>

  //         })}
  //         </React.Fragment>
  //       })}

        
      
      
  //   </React.Fragment>
                         

  // }
  getNumerFormattedWithoutReduced(value, format){
    let {removedAmounts} = this.state;
    
    let result = value
    if(format == "percentage"){
      result = this.percentFormat(value)
    }
    if(format == "integer"){
      result = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value))
    }
    if(!format || format == "money"){
      result = this.numberToCurrency((value)) 
    }
    
    return result
  }

  getNumerFormatted(value, format, year, month, formula_id){
    if(!year){
      return this.getNumerFormattedWithoutReduced(value, format)
    }
    let {removedAmounts} = this.state;
    let key = `[${year}, "${month}", ${formula_id}]`
    let removedValue = parseFloat(removedAmounts[key])

     if (String(removedValue) == "NaN" || removedValue == undefined) {
        removedValue = 0
      }

    let result = value
    if(format == "percentage"){
      result = this.percentFormat((value  * -1) - removedValue)
    }
    if(format == "integer"){
      result = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format((parseFloat(value) * -1) - removedValue)
    }
    if(!format || format == "money"){
      result = this.numberToCurrency((value - removedValue) * -1) 
    }
    
    return result
  }



  changeMonth(currentMonth){
    let self = this;
    this.setState({currentMonth}, function(){
      self.changeModality(self.state.currentModality)
    })
  }

  async changeModality(currentModality){
    let self = this;
    let {currentMonth, lastMonth} = this.state
    let newMonth = currentMonth

    if(currentModality == "balance_sheet"){
      if(currentMonth == "total"){
        newMonth = monthDictReverse[this.props.month]
      }
    }
    
    this.setState({currentModality, currentMonth: newMonth}, function(){
       if(currentModality != "balance_sheet"){  
        this.fetchCompanies();
        this.fetchDataForRange(this.state.year)
        this.fetchDataForRange(this.state.year, null, lastMonth)
        this.fetchDataForRange(this.state.year, null, "janeiro")
      }
      
    })  
   
  }

  paramLabel(value, param){
    let label;
    if (param == "difference") {
      label = <label title={"diferença"} style={{color: "#232D6A"}}>dif</label>
    }
    if (param == "f_saldo_atual") {
      label = <label title={"saldo atual"} style={{color: "#3FABAE"}}>sat</label> 
    }
    if (param == "f_saldo_anterior") {
      label = <label title={"saldo anterior"} style={{color: "#EFBF6A"}}>san</label>
    }

    return <div>{value} {label}</div>
  }
  

  getFloat(number){
    if (parseFloat(number).toLocaleString() == "NaN"){
      return 0
    }else{
      return parseFloat(number)
    }
  }

  isParamZero(p_value, param){
    if(this.state.showZeroLines){
      return false
    }

    let { months} = this.state;
    let isZero = true
    Object.entries(months).map(([name, number]) => {
      
      if(this.getFloat(p_value[name][param]) != 0){
        isZero = false
      }
    })
    return isZero

  }

  renderFetchedRows(){

  }

  renderFetchedLines(key, index=1){
    const {companies, currentMonth} = this.state;
    let data = this.state.recordsFetched[key]
    if (data?.has_childreen) {
      return this.renderInsideRows(data, index + 1)
    }else{
      let allCodes = Object.values(data || {})?.map((item) => Object.keys(item)).flat()
      allCodes = [...new Set(allCodes)];
      let labelDict = {}
      Object.entries(companies).map(([name, number]) => {
        allCodes.map((code) => {
          labelDict[code] ||= data[number][code]?.label
        })
      })
      
      
      return data && <>
      {allCodes.map((code) => {
        let lineTotal = 0
        return <tr>
          <td style={{paddingLeft: index == 1 ? 40 : (16 * index)}}>
            {labelDict[code] || code}
          </td>
          {Object.entries(companies).map(([name, number]) => {
            if (number != this.props.current_company_id){
              let value = 0
              try{
                value = data[number][code]?.months[currentMonth] || 0
              }catch(e){
              
              }
              
              if(currentMonth == "total"){
                value = Object.values(data[number][code]?.months || {}).reduce((a,b)=>{return a + b}, 0)
              }
              value *= -1
              lineTotal += value
              return (
                <td>
                  {this.numberToCurrency(value)}
                </td>
              );
            }
          })}
          <td className={'acumulado-line'}>
            <label>
              {this.numberToCurrency(lineTotal)}
            </label>
          </td>
          {Object.entries(companies).map(([name, number]) => {
            
            if (number == this.props.current_company_id){
              let value = 0
              try{
                value = (data[number][code]?.months[currentMonth] || 0) 
              }catch(e){
                
              }
              
              if(currentMonth == "total"){
                value = Object.values(data[number][code]?.months || {}).reduce((a,b)=>{return a + b}, 0)
              }
              value *= -1
              lineTotal += value
              return (
                <td>
                  {this.numberToCurrency(value)}
                </td>
              );
            }
          })}
        </tr>
      })}
      </>
    }
    
      
    
  }
  

  renderLine(key, value){
    const { report, 
            labels, 
            formulas, 
            year, 
            years, 
            recordsFetched, 
            cost_center_mode, 
            formulasTotal, 
            companies, 
            dataModel, 
            companyData, 
            month, 
            currentMonth,
            modalities,
            currentModality } = this.state;
    let { months} = this.state;
    
    return (!formulas[key]?.cost_center_demonstration || !Boolean(cost_center_mode)) && this.formulaIsGrouped(key) && Object.entries(value[key]["params"]).map(
      ([p_key, p_value]) => {
        
        
        return (
          <React.Fragment>
            {value[key]["lines"][p_key] && Object.values(value[key]["lines"][p_key]).map((param)=>(
              this.renderRow(param, key, p_key)
            ))}

          </React.Fragment>
        );
      }
    )
  }

  renderIndicators(key, value){
    const { report, 
            labels, 
            formulas, 
            year, 
            years, 
            recordsFetched, 
            cost_center_mode, 
            formulasTotal, 
            companies, 
            dataModel, 
            companyData, 
            month, 
            currentMonth,
            modalities,
            currentModality } = this.state;
    let { months} = this.state;
    let total = 0
    return <Fragment>
      {this.formulaIsGrouped(key) && value[key]["indicators"] && Object.entries(value[key]["indicators"]).map(
        ([p_key, p_value]) => {
          return (
                  <tr className="indicator-line">
                    <td >
                      <i className={`pull-left fa}`}/> 
                      {p_key}
                    </td>
                    {Object.entries(companies).map(([name, company_id]) => {
                      let line_value = companyData?.[company_id]?.['report']?.[key]?.[key]?.["indicators"]?.[p_key]?.[currentMonth]

                      if(line_value){
                        if(company_id != this.props.current_company_id){
                          line_value = this.getFloat(line_value) * -1 
                          total += line_value
                        }
                      }
                      return (
                        <td>
                          {this.numberToCurrency(line_value, "integer")}
                        </td>
                      );
                    })}
                    
                    
                    <td className={'acumulado-line'}> 
                      <label>
                        {this.numberToCurrency(total, "integer")}
                      </label>
                    </td>
                  </tr> 
          );
        }
      )}
    </Fragment>
  }
  activeTab(tab){

  }
  toggleLastColumn(){
    this.setState({lastColumnHide: !this.state.lastColumnHide})
    $('td:last-child').toggle();
  }

  monthForFormula(month, formula_id){
    let {lastMonth} = this.state;

    if(this.state?.currentCompany?.initial_cash_formula == formula_id){
      if(month == "total"){
        return "janeiro"
      }else{
        return month
      }
    }else if(this.state?.currentCompany?.final_cash_formula == formula_id){
      if(month == "total"){
        return lastMonth
      }else{
        return month
      }
    }else{
      return month
    }
    
  }
  render() {
    const { report, 
            labels, 
            formulas, 
            year, 
            years, 
            recordsFetched, 
            cost_center_mode, 
            formulasTotal, 
            formulasTotalByMonth,
            companies, 
            dataModel, 
            companyData, 
            month, 
            currentMonth,
            modalities,
            currentModality } = this.state;
    let { months} = this.state;

    

    return (
      <React.Fragment>
        <div className="header" style={{display: 'flex', justifyContent: 'space-between' }}>
          <div style={{display: 'flex', justifyContent: 'space-between' }}>
            <div className="label" style={{marginRight: 16}}>RESUMO EXECUTIVO</div>
             
          </div> 
          
          <form className="form flex" style={{paddingRight: 35, alignItems: 'center', height: '100%'}}>
              <React.Fragment>
                <BlueLink 
                  bordered
                  active={currentModality == "dre"}
                  onClick={()=> this.changeModality("dre")}
                > <div style={{paddingBottom: currentModality == "dre" ? 19 : 0}}>DRE</div> </BlueLink>
                <BlueLink 
                  bordered
                  active={currentModality == "dre_cc"}
                  onClick={()=> this.changeModality("dre_cc")}
                > <div style={{paddingBottom: currentModality == "dre_cc" ? 19 : 0}}>DRE - Gerencial</div></BlueLink>    
                <BlueLink 
                  bordered
                  active={currentModality == "cash_flow"}
                  onClick={()=> this.changeModality("cash_flow")}
                > <div style={{paddingBottom: currentModality == "cash_flow" ? 19 : 0}}>Fluxo de caixa</div></BlueLink>    
                <BlueLink 
                  bordered
                  active={currentModality == "balance_sheet"}
                  onClick={()=> this.changeModality("balance_sheet")}
                > <div style={{paddingBottom: currentModality == "balance_sheet" ? 19 : 0}}>Balancete</div></BlueLink>    
              </React.Fragment>
              
              {/* <select onChange={(e)=> this.changeModality(e.target.value)}>
                {Object.entries(modalities).map(([id, name])=>(
                  <option value={id} selected={currentModality == id }> {name} </option>
                ))}
                
                
              </select> */}
              
              <div className={'with-date-ico chevron'}>
                <select className={'margin-left-20 margin-right-20 '} onChange={(e)=> this.changeMonth(e.target.value)}>
                  {Object.entries(months).sort((a,b)=> a[1] - b[1]).map(([name, number])=>{
                    if(!(name == "total" && currentModality == "balance_sheet")){
                      return <option value={name} selected={currentMonth == name }> {name} </option>  
                    }
                  })}
                 </select>
              </div>
                
              <div className={'with-date-ico chevron'}>
                <select onChange={(e)=> this.changeYear(e.target.value)}>
                  {years.map((option_year)=>(
                    <option value={option_year} selected={year == option_year }> {option_year} </option>
                  ))}
                  
                  
                </select>
              </div>
            </form>
        </div>
        {currentModality == "balance_sheet" ? (
          <BalanceSegmentado 
          companies={companies} 
          current_company_id={this.props.current_company_id}
          months={this.props.months} 
          defaultYear={this.props.defaultYear} 
          currentMonth={currentMonth} 
          year={year}/>)
         :
          <div id="table-result-wrapper">
          <div className="content ">
            <table id="table-result" className={'segmentado margin-40'}>
              <thead>
                <tr>
                  <th>Nome</th>
                  {Object.entries(companies).map(([name, numero]) => (
                    (numero != this.props.current_company_id) && <th>{name}</th>
                  ))}
                  {/*<th>Movimentações</th>*/}
                  <th className={'acumulado-header'}>
                    {this.cable_counting > 0 ? 
                      <div>
                        <div style={{color: 'red'}}>(Recarregando {this.cable_counting} formulas)</div>
                        <br/><div >Acumulado</div>
                      </div>
                      :
                      <div >Acumulado</div>
                    }
                    
                    
                  </th>
                  {Object.entries(companies).map(([name, numero]) => (
                    (numero == this.props.current_company_id) && <th className={this.state.lastColumnHide ? 'relative' : ''}>
                      <i style={{cursor: 'pointer', float: 'left', margin: '0 5px'}}onClick={()=> this.toggleLastColumn()} className={`fa fa-chevron-${this.state.lastColumnHide ? 'left' :'right'}`}/>
                      {!this.state.lastColumnHide && <div>{name}</div>}
                    </th>
                  ))}
                </tr>
              </thead>

              {Object.entries(dataModel).map(([key, value]) => {
                let formulaTotal = 0
                
                return (
                  <tbody className={`body-${formulas[key].formula_type} body-${formulas[key].formula_type}-${formulas[key].layout}`}>
                    {formulas?.[key]?.formula_type != "formula" && currentModality == "dre_cc" &&  this.renderCostCenterAreas(key, value)}
                    {this.renderLine(key, value)}
                    {this.renderIndicators(key, value)}
                    <tr id="principal" className={`formula-${formulas[key].formula_type} formula-${formulas[key].formula_type}-${formulas[key].layout} format-${formulas[key].number_format} formula-${formulas[key].is_only_index ? "only-index" : "more-than-index"}`}>
                      <td>
                        <PopHover 
                          
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          withoutico description={this.props.formulas[key]}>
                          {key}
                        </PopHover>
                      </td>
                      {Object.entries(companies).map(([name, company_id]) => {
                        if(company_id != this.props.current_company_id){
                          formulaTotal += this.tryEval(companyData?.[company_id]?.["report"]?.[key]?.[key]?.["result"]?.[this.monthForFormula(currentMonth, formulas[key].id)])
                        }
                        
                        return (
                          (company_id != this.props.current_company_id) && <td>
                            {this.getNumerFormatted(this.tryEval(companyData?.[company_id]?.["report"]?.[key]?.[key]?.["result"]?.[this.monthForFormula(currentMonth, formulas[key].id)]), formulas[key]?.number_format)}
                          </td>
                        );
                      })}
                      {/*<td>
                        <RemovedAmount 
                          removedAmounts={this.state.removedAmounts}
                          refreshRemovedAmounts={()=> this.refreshRemovedAmounts()}
                          year={year}
                          month={this.monthForFormula(currentMonth, formulas[key].id)}
                          reference_id={formulas?.[key]?.fr_id}
                          reference_model={'formula'}
                         />
                      </td>*/}
                      <td className={'acumulado-line ' + this.monthForFormula(currentMonth, formulas[key].id)}>
                        {
                          <label data-value={this.tryEval(formulasTotalByMonth?.[this.monthForFormula(currentMonth, formulas[key].id)]?.[formulas[key].fr_id])} style={{ display: 'flex'}}>
                            {this.getNumerFormatted(this.tryEval(formulasTotalByMonth?.[this.monthForFormula(currentMonth, formulas[key].id)]?.[formulas[key].fr_id]) , formulas[key].number_format, year, currentMonth, formulas?.[key]?.fr_id)}  
                            
                            <PizzaChart
                              style={{marginLeft: 10}}
                              companyData={companyData}
                              format={formulas[key].number_format}
                              p_key={key}
                              companies={companies}
                              currentMonth={currentMonth}
                              currentCompanyId={this.props.current_company_id}
                              title={"TITULO"}> 
                              
                            </PizzaChart>
                          </label>
                          
                        }
                      </td>
                      {Object.entries(companies).map(([name, company_id]) => {
                        if(company_id != this.props.current_company_id){
                          formulaTotal += this.tryEval(companyData?.[company_id]?.["report"]?.[key]?.[key]?.["result"]?.[currentMonth])
                        }
                        
                        return (
                          (company_id == this.props.current_company_id) && <td>
                            {this.getNumerFormatted(this.tryEval(companyData?.[company_id]?.["report"]?.[key]?.[key]?.["result"]?.[this.monthForFormula(currentMonth, formulas[key].id)]), formulas[key]?.number_format)}
                            
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      {Object.entries(companies).map(()=><td></td>)}
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        }
        
        <LoadingDialog open={this.requestsCounter > 0}/>
      </React.Fragment>
    );
  }
}

export default Segmentado;
const monthDict = {
  "janeiro": 1,
  "fevereiro": 2,
  "março": 3,
  "abril": 4,
  "maio": 5,
  "junho": 6,
  "julho": 7,
  "agosto": 8,
  "setembro": 9,
  "outubro": 10,
  "novembro": 11,
  "dezembro": 12,
  "total": 13
}

const monthDictReverse = {
  1: "janeiro",
  2: "fevereiro",
  3: "março",
  4: "abril",
  5: "maio",
  6: "junho",
  7: "julho",
  8: "agosto",
  9: "setembro",
  10: "outubro",
  11: "novembro", 
  12: "dezembro", 
  13: "total"
}