
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import PopHover from './PopHover'
import chartIco from "../../assets/images/graph-svg.svg";
import handIco from "../../assets/images/hand-regular.svg";


const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    color: "rgb(68,185,189)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonClass: {
    backgroundColor: '#fff',
    marginBottom: 3,
    color: '#2a3170',
    borderColor: '#2a3170',
    borderWidth: 1
  }
}));

export default function ManualLaunch(props) {


  return (

      <div className={'comment-block'}  style={{left: -20 }}>
        {/*<div className={'comment-block'} style={{paddingLeft: 40 }}>*/}
       <i className={'fa fa-hand-paper-o'} ></i>

      </div>
  );
}
