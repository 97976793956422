import React from "react";
import LoadingDialog from './../LoadingDialog'
import Comment from './../Comment'
import ResultChart from './../ResultChart'
import MonthFilter from './../MonthFilter'
import {BlueLink} from '../../styled_components/default';
import PopHover from './../PopHover'
import popupIco from 'popup-ico.svg';
import downloadIco from 'bt_download.svg';
import olhoOn from 'bt_olho_on.svg'
import olhoOff from 'bt_olho_off.svg'
import {popupCenter} from './../popup/popupCenter'
class Dre extends React.Component {
  
  constructor(props){
    super(props)
    let months = {}
    
      Object.entries(props.months).map(([key, value])=>{
        if(Object.keys(props.visible_months?.[props.defaultYear] || {}).includes(key)){
          months[key] = value
        }
        if(props.is_budget){
          months[key] = value
        }
      })
    
    this.loading = 0
    this.state ={
      formulasTotalByYear: {},
      areasDict: {},
      allYearsData: {},
      showZeroLines: false,
      formulas: [],
      report: [],
      labels: [],
      formulaOpened: {},
      years: [],
      year: this.props.defaultYear,
      recordsFetched: {},
      cost_center_mode: false,
      costCentersFetched: {},
      costCentersFetchedByYear: {},
      costCenterAreas:[],
      despesasType: {},
      costCenterRecordsGrouped: {},
      activeAreaType: {}, 
      activeKeys: {},
      formulasTotal: {},
      loading: 0,
      visibleMonths: props.visible_months[props.defaultYear] || {
        janeiro: true,
        fevereiro: true,
        março: true,
        abril: true,
        maio: true,
        junho: true,
        julho: true,
        agosto: true,
        setembro: true,
        outubro: true,
        novembro: true,
        dezembro: true,
      },
      months: months
      

    }
  }

  tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }
  async setComments(year){
    
    let comments = await axios.get('/comments.json', {params:{
      year
    }})  
    
    
    this.setState({comments: comments.data})
  }

  async setYears(){
    const {year} = this.state;
    let years = {}
    if (this.props.is_budget) {
      years = await axios.get('/budgets/years.json')  
    }else{
      years = await axios.get('/balance_sheets/years.json')
    }
    
    this.setState({years: years.data, year: (year || years.data[years.data.length - 1])})
  }

  async fetchCostCenterData(year=null){
    const {is_budget} = this.props;
     this.loading += 1
    let result = await axios.get('/results/cost_centers.json', {params: {
        year,
        is_budget
    }})
    
    const {data} = result
    
    this.loading -= 1
    this.setState({
      costCenterRecordsGrouped: data
    })
  }

  async fetchData(year=null, loadUniqueData=true){
    const {costCenterMode, is_budget} = this.props;
    this.loading += 1
    let url = costCenterMode ?  '/results/dre_cc.json' : '/results/dre.json'
    let result = await axios.get(url, {params: {
        year,
        is_budget
    }})
    const {formulas, report, labels, areas, cost_center_grouped_by_month} = result.data
    
    if(loadUniqueData){
      this.setState({
        formulas,
        report,
        labels,
        costCenterAreas: areas,
        recordsFetched: {},
        costCenterGroupedByMonth: cost_center_grouped_by_month
      })  
    }
    
    this.loading -= 1

    this.setState((oldState)=>{
      oldState.allYearsData[year] = {
        formulas,
        report,
        labels,
        costCenterAreas: areas,
        recordsFetched: {},
        costCenterGroupedByMonth: cost_center_grouped_by_month
      }
      return {...oldState}
    })
  }

  async componentDidMount(){
    let x = await this.setYears()
    this.setComments(this.state.year);
    this.fetchCostCenterData(this.state.year)
    this.fetchData(this.state.year)
    this.fetchDataForRange(this.state.year)
    this.fetchAreas()
    this.changeVisibleMonths()
  }

  async fetchAreaData(key, fixed, type_id, type_label, year=this.state.year){
    const {costCentersFetched} = this.state;
    const {cost_center_heads, is_budget} = this.props;
    let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"

    Object.entries(cost_center_heads).map(([id, label])=>{
      if(!costCentersFetched[label]){
        costCentersFetched[label] = {}
      }
    })

    if(costCentersFetched[type_label][key] && costCentersFetched[type_label][key][fixed_label]){
      this.setState((oldState)=>{
        oldState.costCentersFetched[type_label][key][fixed_label] = null
        return {...oldState}
      })
    }else{
      let details = await axios.get(`/results/${key}/cost_center_detail.json`, {params: {
          year: year,
          fixed: Boolean(fixed),
          type: type_id,
          is_budget,
      }})
      
      this.setState((oldState)=>{
        if(!oldState.costCentersFetched[type_label][key])
        { oldState.costCentersFetched[type_label][key] = {} }
        
        oldState.costCentersFetched[type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]
        return {...oldState}
      })
      this.setState((oldState)=>{
        oldState.costCentersFetchedByYear[year] ||= {}
        oldState.costCentersFetchedByYear[year][type_label] ||= {}
        oldState.costCentersFetchedByYear[year][type_label][key] ||= {}
        
        oldState.costCentersFetchedByYear[year][type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]
        return {...oldState}
      })
    }
    
  }

  async toggleAreaType(key, fixed, type_id, type_label){
    
    this.setState((oldState)=>{
      oldState.activeAreaType[`${key},${fixed}`] = oldState.activeAreaType[`${key},${fixed}`] ? oldState.activeAreaType[`${key},${fixed}`] : {}
      oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`] = !oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`]
      return {...oldState}
    })

  }

  formulaIsGrouped(key){
    const {formulas} = this.state

    return formulas[key].formula_type == "grouped"

  }

  setupAllYearsData(){
    let {years} = this.state;
    years.map((year)=>{
      this.loadYear(year, false)
    })
  }

  loadYear(year, loadUniqueData=false){
    this.fetchData(year, loadUniqueData)
    this.fetchDataForRange(year, false)
    
    
  }

  defineDefaultVisibleMonths(year){
    let months = {}
    Object.entries(this.props.months).map(([key, value])=>{
      if(Object.keys(this.props.visible_months[year]).includes(key)){
        months[key] = value
      }
    })
    
    this.setState({months, visibleMonths: this.props.visible_months[year]});
  }

  changeYear(year){
    this.setState({year})
    this.props.changeYear(year)
    this.fetchData(year)
    this.fetchCostCenterData(year)
    this.setComments(year)
    this.setState({
      costCentersFetched: {},
      despesasType: {}
    })
    this.fetchDataForRange(year)
    this.defineDefaultVisibleMonths(year)
  }

  renderLine(line, options={}, level=1){
    
    const { months, showZeroLines} = this.state;
    let linesToInt = []
    let totalLine = 0
    let valueExists = false
    Object.entries(months).map(([name, number]) => {
      if(parseFloat(line.months[name]).toString() != "NaN"){
        if ( parseFloat(line.months[name]) != 0){
          valueExists = true
        }
        linesToInt.push(parseInt(line.months[name]) || 0 )
        totalLine += parseFloat(line.months[name]) || 0
      }
    })
    // remove all values equal 0 from linestoInt
    // linesToInt = linesToInt.filter((value)=>{
    //   return value != 0
    // })

    if(!showZeroLines && !valueExists){
      return <></>
    }
    
    return <tr className={"account-line"}>
      <td>
        <div style={{paddingLeft: level * 10}}>{line.label}</div>
      </td>
      {Object.entries(months).map(([name, number]) => {
          return (
            <td className={'comment-wrapper'}>
                  {this.state.comments && <Comment 
                    refreshComments={()=> this.setComments(this.state.year)}
                    comments={this.state.comments}
                    model_reference={'parameter_lines'}
                    month={number}
                    monthName={name}
                    year={this.state.year}
                    model_reference_id={line.label}>
                  </Comment>}
                  {line.months[name] ? (parseFloat(line.months[name]) * - 1).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }) : "-"}
                </td>
          );
        })}
      <td className={'comment-wrapper flex-chart'}>
        {this.state.comments && <Comment 
          refreshComments={()=> this.setComments(this.state.year)}
          comments={this.state.comments}
          model_reference={'parameter_lines'}
          month={"total"}
          monthName={"total"}
          year={this.state.year}
          model_reference_id={line.id}>
        </Comment>}
          <p>
          {(totalLine * -1).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }) }
          </p>
          <ResultChart
            loadAllYears={()=>{this.setupAllYearsData()}}
            loadYear={(year)=> this.loadYear(year)} 
            years={this.state.years}
            year={this.state.year} 
            title={line.label} 
            data={this.dataForFormula(options["p_value"])} 
            param={options["param"]}
            p_key={options["p_key"]}
            line_key={options["line_key"]}
            dataKey={"param-lines"}
            formula_id={options["key"]}
            months={this.props.months}
            allYearsData={this.state.allYearsData}
          />
        
      </td>
      
    </tr>
  }


  costCenterGroupedBySpendType(despesa_id, fixed, despesa_type_label, month){
    const {months} = this.props;
    const {costCentersFetched} = this.state;

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;
    let result = 0
    try{
      
      rows = costCentersFetched[despesa_type_label][despesa_id][fixed_label]


      Object.values(rows).map((despesa)=>{
        if(String(parseFloat(despesa.months[month])) != "NaN"){
          result += parseFloat(despesa.months[month])  
        }
        
      })
      
    }catch(e){

    }
    

    
    return result
  }

  renderCostCenterRows(despesa_id, fixed, despesa_type_label, despesa_type_id=null){
    const {months, showZeroLines} = this.state;
    const {costCentersFetched} = this.state;
    const {cost_center_heads, is_budget} = this.props;
    let numberToCurrency = function(number=0) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }
      
      return parseFloat(number * -1).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;

    try{
      
      rows = costCentersFetched[despesa_type_label][despesa_id][fixed_label]
      
    }catch(e){

    }
    
    let sum = {}
    let totalSum = {}
    {rows && Object.entries(rows).map(([key, data])=> {
      {Object.entries(months).map(([name, number]) => {
        totalSum[key] ||= 0
        sum[key] ||= []
        totalSum[key] += parseFloat(data.months[number]) || 0
        sum[key].push(data.months[number] ? parseFloat(data.months[number]) : 0)
      })}  
    })}

    
    
    return <React.Fragment>
      {rows && Object.entries(rows).map(([key, data])=> {
        if(!showZeroLines && sum[key].filter((x)=> x != 0).length == 0){
          return <></>
        }
        return <tr className={'cost-center-row'}>
        <td style={{textIndent: 32}}>{key}-{data.label}</td>
        {Object.entries(months).map(([name, number]) => {
          return (
            <td className={'cost-center-type-column comment-wrapper'}>
              {this.state.comments && <Comment 
                refreshComments={()=> this.setComments(this.state.year)}
                comments={this.state.comments}
                model_reference={'cost_centers-area-internal-row'}
                month={number}
                monthName={name}
                year={this.state.year}
                model_reference_id={`${key}-${number}`}>
              </Comment>}
              {numberToCurrency(data.months[number]) || "-"}
            </td>
          );
        })}
        <td className={'cost-center-line-total flex-chart comment-wrapper'}>
          {this.state.comments && <Comment 
            refreshComments={()=> this.setComments(this.state.year)}
            comments={this.state.comments}
            model_reference={'cost_centers-area-internal-row'}
            month={'total'}
            monthName={'total'}
            year={this.state.year}
            model_reference_id={`${key}-total`}>
          </Comment>}
          <p>{numberToCurrency(totalSum[key])}</p>
          <ResultChart
            dataKey={"cost_center_type_line"}
            years={this.state.years}
            year={this.state.year} 
            cc_key={key}
            title={`${key}-${data.label}`}
            is_fixed={fixed}
            despesa_type_id={despesa_type_id}
            area_id={despesa_id}
            cost_center_heads={cost_center_heads}
            is_budget={is_budget}
            label={despesa_type_label}
            months={this.props.months}
            allYearsData={this.state.allYearsData}
            >
          </ResultChart>
        </td>
      </tr>})}

    </React.Fragment>
    
  }
  numberToCurrency(number=0) {
    if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
      number = 0
    }
    
    return parseFloat(number * -1).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }

  renderRow(row, options={}, level=1, key=null){
    
    let childrenLines = (entry, month_name)=>{
      try{
        let initial = Object.values(entry.lines).map((x)=> x.months[month_name]).filter((a)=> parseFloat(a).toString() != "NaN").reduce((a,b)=> parseFloat(a) + parseFloat(b), 0)

        if (Object.values(entry.childreen).length > 0) {
          Object.values(entry.childreen).map((child)=> {
            initial += childrenLines(child, month_name)
          })
        }

        return initial
      }
      catch(e){
        console.log("ERRO AQUI", e)
        return 0
      }
      
    }

    const { months} = this.state;
    const  {costCentersFetched, activeKeys} = this.state;
    let sum = 0

    
    
    return <React.Fragment>
        {!row.months ?
          <tr className="account-number-row">
            <td ><div 
                onClick={()=> {
                  
                  this.setState((oldState)=>{
                    if(row.label){
                      
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? oldState.activeKeys[key] : {}
                      oldState.activeKeys[key][row.label] = oldState.activeKeys[key][row.label] ? null : {}
                    }else{
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? null : {}  
                    }
                    
                    return {...oldState}
                  })
                }}
                style={{paddingLeft: (level + 1) * 10}}>
                  <i className={`pull-left fa fa-chevron-${ activeKeys[key]?.[row.label] ? 'down' : 'right'}`}/>
                  {row.label}
                </div>
            </td>
            {Object.entries(months).map(([name, number]) => {
              let monthValue = childrenLines(row, name)
              if(monthValue.toString() != "NaN"){
                sum += monthValue  
              }
              
              console.log(sum)
              return (
                <td>
                  {this.numberToCurrency(monthValue)}
                  
                </td>
              );
            })}
            <td className={'line-total flex-chart'} style={{justifyContent: 'flex-end'}}>
              <div style={{marginRight: 10}}>{this.numberToCurrency(sum)}</div>
              <ResultChart
                    loadAllYears={()=>{this.setupAllYearsData()}}
                    loadYear={(year)=> this.loadYear(year)} 
                    years={this.state.years}
                    year={this.state.year} 
                    title={row.label}
                    data={this.dataForFormula(options["p_value"])} 
                    param={options["param"]}
                    p_key={options["p_key"]}
                    line_key={options["line_key"]}
                    dataKey={"param-head"}
                    formula_id={options["key"]}
                    months={this.props.months}
                    allYearsData={this.state.allYearsData}
                  />
            </td>
        </tr>
      :
        this.renderLine(row, options, level + 1)
      }

      
      {activeKeys[key]?.[row.label] && row.childreen && Object.entries(row.childreen).map(([child_key, child])=>{
          
          return this.renderRow(child, {...options, line_key: child_key}, level + 1)
        })}
      {(activeKeys[key]?.[row.label]) && row.lines &&  Object.keys(row.childreen).length < 1 && Object.entries(row.lines).map(([line_key, line])=>{
          
          return this.renderLine(line, {...options, line_key}, level + 2)
        })}
      </React.Fragment>
  }

  renderChildreenFor(key=null, options){
    
    let data = this.state.recordsFetched[key]
    if(data){
      return (<React.Fragment>
        {Object.entries(data).map(([row_id, row])=>(this.renderRow(row, {...options, line_key: row_id}, 0, key)))}
      </React.Fragment>)
    }
  }

  async fetchDetail(key){
    const {year, recordsFetched} = this.state;
    const {cost_center_heads, is_budget} = this.props;
    
    if(recordsFetched[key]){
      this.setState((oldState)=>{
        oldState.activeKeys[key] = {} 
        oldState.recordsFetched[key] = null
        return {...oldState}
      })
    }else{
      let details = await axios.get(`/results/${key}/detail.json`, {params: {
          year: year,
          cost_center_mode: this.state.cost_center_mode && cost_center_heads[key],
          is_budget
      }})
      
      this.setState((oldState)=>{
        
        if (Object.values(Object.values(details.data)[0].childreen).length > 0){
          oldState.recordsFetched[key] = Object.values(details.data)[0].childreen
        }else{
          oldState.recordsFetched[key] = Object.values(details.data)[0].lines
        }
        
        return {...oldState}
      })
    }
    
  }
  async fetchDataForRange(year, loadUniqueData=true){
    const {is_budget, scope} = this.props;
    this.loading += 1
    let report = await axios.get('/formulas/borboleta.json',{params: {
        scope,
        range: {
          start_at: (new Date(`01/01/${year}`)), 
          end_at: (new Date(`12/12/${year}`))
        },
        is_budget
    }})
    this.loading -= 1
    if(loadUniqueData){
      this.setState({
        formulasTotal: report.data
      })
    }
    

    this.setState((oldState)=>{
      oldState.formulasTotalByYear[year] = report.data
      return {...oldState}
    })
}

  paramLabel(value, param){
    let label;
    if (param == "difference") {
      label = <label title={"diferença"} style={{color: "#232D6A"}}>dif</label>
    }
    if (param == "f_saldo_atual") {
      label = <label title={"saldo atual"} style={{color: "#3FABAE"}}>sat</label> 
    }
    if (param == "f_saldo_anterior") {
      label = <label title={"saldo anterior"} style={{color: "#EFBF6A"}}>san</label>
    }

    // return <div>{value} {label}</div>
    return <div>{value}</div>
  }

  toggleDespesasType(key){
    let {cost_center_heads} = this.props;
    let [area_id, is_fixed] = key
    
    Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
      this.fetchAreaData(area_id, is_fixed, despesa_type_id, label)  
    })
    
    this.setState((oldState)=>{
      oldState.despesasType[key] = !oldState.despesasType[key]
      if(!oldState.despesasType[key]){
        this.state.activeAreaType[`${key}`] = {}  
      }
      
      return {...oldState}
    })
  }


  renderCostCenterAreas(number_format="money"){
    const { months, cost_center_heads} = this.props;
    const  {costCentersFetched, costCenterAreas, despesasType, costCenterGroupedByMonth} = this.state;
    
    let areaByMonth = (b_fixed, despesa_id, number)=>{

      try{
        let value = costCenterGroupedByMonth[b_fixed ? "fixed" : "not_fixed"][`[${despesa_id}, ${number}]`]
        
        return value
        
      }catch(e){
        
      }
      
    }
    

    let lines = Object.entries(costCenterAreas).map(([key, value])=>{
      const [fixed, despesa_id] = JSON.parse(key);
      let b_fixed = Boolean(fixed)
      //this.fetchAreaData(id, fixed)
      return <React.Fragment><tr>
        <td ><div onClick={()=> this.toggleDespesasType([despesa_id, b_fixed])} >
        <i className={`pull-left fa fa-chevron-${despesasType[[despesa_id, b_fixed]] ? 'down' : 'right'}`}/>
        {value.label}</div></td>
        {Object.entries(months).map(([name, number]) => {

          return (
            <td className={'comment-wrapper'}>
              
              {areaByMonth(b_fixed, despesa_id, number)}
            </td>
          );
        })}
        <td>
          
        </td>
      </tr>
      {despesasType[[despesa_id, b_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
        let exist = false
        try{
          exist = !!costCentersFetched[label][despesa_id][b_fixed ? "fixed" : "not_fixed"]  

        }catch(e){

        }
        
        return <React.Fragment>
          <tr>
            <td style={{textIndent: 16}} onClick={()=> this.fetchAreaData(despesa_id, b_fixed, despesa_type_id, label)} >
              <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`}/>
              {label}
            </td>  
          </tr>
        {this.renderCostCenterRows(despesa_id, b_fixed, label)}
        </React.Fragment>

      })}
      </React.Fragment>

    })

    return <React.Fragment>{lines}</React.Fragment>

  }

  percentFormat(number){
    return `${parseFloat(number).toFixed(2)}%`
  }

  renderCostCenterParams(report, number_format="money"){

    let {despesasType} = this.state;

    let {cost_center_heads, is_budget} = this.props;
    let numberToCurrency = function(number=0, number_format=number_format) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }
      if(number_format == "percentage"){
        this.percentFormat(parseFloat(number * -1))
      }
      if(number_format == "integer"){
        return new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(number * -1))
      }

      if(!number_format || number_format == "money"){
        return parseFloat(number * -1).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        });
      }
    };
    const { months} = this.state;
    const {costCenterRecordsGrouped, costCenterGroupedByMonth, areasDict} = this.state;
    
    return  <React.Fragment>
      {report.cost_center_params.ids.map((id)=>{
          
          let total = 0
         return  <React.Fragment>{!this.isCostCenterZero(id) && <tr>
             <td onClick={()=> this.toggleDespesasType([id[0].area.id, id[0].is_fixed])}>
               
               <i className={`pull-left fa fa-chevron-${despesasType[[id[0].area.id, id[0].is_fixed]] ? 'down' : 'right'}`}/>
               {areasDict[id[0].area.id]} - {id[0].is_fixed ? "Fixo" : "Variável"}
             </td>
             {Object.entries(months).map(([month_name, month]) => {
               let row = parseFloat(costCenterRecordsGrouped[`["${areasDict[id[0].area.id]}", ${id[0].is_fixed}, ${month}]`])
 
               if(String(row) != "NaN"){
                 total += row
               }  
               return <td className={'comment-wrapper'}>
                  {this.state.comments && <Comment 
                    refreshComments={()=> this.setComments(this.state.year)}
                    comments={this.state.comments}
                    model_reference={'cost_centers-area'}
                    month={month}
                    monthName={month_name}
                    year={this.state.year}
                    model_reference_id={`${id[0].area.id}-${id[0].is_fixed}-${month}`}>
                  </Comment>}
                  {
                    numberToCurrency(costCenterRecordsGrouped[`["${id[0].area.label}", ${id[0].is_fixed}, ${month}]`])
                  }
                </td>
                 
               
               
             })}
             <td className={'flex-chart comment-wrapper'}> 
                {this.state.comments && <Comment 
                  refreshComments={()=> this.setComments(this.state.year)}
                  comments={this.state.comments}
                  model_reference={'cost_centers-area'}
                  month={'total'}
                  monthName={'total'}
                  year={this.state.year}
                  model_reference_id={`${id[0].area.id}-${id[0].is_fixed}-total`}>
                </Comment>}
              <p>{numberToCurrency(total)}</p>
              <ResultChart
                loadAllYears={()=>{this.setupAllYearsData()}}
                loadYear={(year)=> this.loadYear(year)} 
                years={this.state.years}
                year={this.state.year} 
                title={id[0].area.label} 
                is_fixed={id[0].is_fixed}
                months={this.props.months}
                area_id={id[0].area.id}
                areas={true}
                dataKey={"area_header"}
                allYearsData={this.state.allYearsData}
                costCenterRecordsGrouped={costCenterRecordsGrouped}>
              </ResultChart>
              
             </td>
           </tr>}
          {despesasType[[id[0].area.id, id[0].is_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
            let exist = false
            try{
              exist = !!costCentersFetched[label][id[0].area.id][id[0].is_fixed ? "fixed" : "not_fixed"]  

            }catch(e){

            }
            total = 0
            return <React.Fragment>
              {!this.isDespendZero(id, label) && <tr>
                <td style={{textIndent: 16}} onClick={()=> this.toggleAreaType(id[0].area.id, id[0].is_fixed, despesa_type_id, label)} >
                  <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`}/>
                  {label}
                </td>  
                 {Object.entries(months).map(([name, number]) => {
                    let value = this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, number )
                    if (String(value) != "NaN") {
                      total += value  
                    }else{
                      
                    }                    
                    return (
                      <td className={'comment-wrapper'}>
                        {this.state.comments && <Comment 
                          refreshComments={()=> this.setComments(this.state.year)}
                          comments={this.state.comments}
                          model_reference={'cost_centers-area-internal'}
                          month={number}
                          monthName={name}
                          year={this.state.year}
                          model_reference_id={`${id[0].area.id}-${id[0].is_fixed}-${label}-${number}`}>
                        </Comment>}
                        {numberToCurrency(value)}
                      </td>
                    );
                  })}
                <td className={'cost-center-type flex-chart comment-wrapper'}>
                  {this.state.comments && <Comment 
                    refreshComments={()=> this.setComments(this.state.year)}
                    comments={this.state.comments}
                    model_reference={'cost_centers-area-internal'}
                    month={'total'}
                    monthName={'total'}
                    year={this.state.year}
                    model_reference_id={`${id[0].area.id}-${id[0].is_fixed}-${label}-total`}>
                  </Comment>}
                  <p>{numberToCurrency(total)}</p>
                 <ResultChart
                    dataKey={"cost_center_type"}
                    loadAllYears={()=>{this.setupAllYearsData()}}
                    loadYear={(year)=> this.loadYear(year)} 
                    years={this.state.years}
                    year={this.state.year} 
                    title={id[0].area.label + " - " + label}
                    area_id={id[0].area.id}
                    is_fixed={id[0].is_fixed}
                    despesa_type_id={despesa_type_id}
                    cost_center_heads={cost_center_heads}
                    is_budget={is_budget}
                    label={label}
                    months={this.props.months}
                    allYearsData={this.state.allYearsData}
                    >
                  </ResultChart>
                </td>
              </tr>}
            {this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`] && this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`][`${despesa_type_id}-${label}`] && this.renderCostCenterRows(id[0].area.id, id[0].is_fixed, label, despesa_type_id)}
            </React.Fragment>

          })}
          </React.Fragment>
        })}

        
      
      
    </React.Fragment>
                         

  }

  getNumerFormatted(value, format){
    let result = value
    if(format == "percentage"){
      result = this.percentFormat(value )
    }
    if(format == "integer"){
      result = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value))
    }
    if(!format || format == "money"){
      result = this.numberToCurrency(value * -1) 
    }
    
    return result
  }

  dataForFormula(result){
    const { months} = this.props;
    let data = []
    let i = 1
    Object.entries(months).map(([name, number]) => {
      if(i < 12){
        data.push(this.tryEval(result[name]))  
      }
      i += 1
    })
    return data
  }

  getFloat(number){
    if (parseFloat(number).toLocaleString() == "NaN"){
      return 0
    }else{
      return parseFloat(number)
    }
  }

  async fetchAreas() {
    
    let result = await axios.get(`/cost_center_areas.json`, {
      params: {
        query: this.state.query
      }
    });

    let hash_data = {}
    
    result.data.map((area)=>{
      hash_data[area.id] = area.label
    })
    
    this.setState({areasDict: hash_data })  
    
  }

  isParamZero(p_value, param){
    if(this.state.showZeroLines){
      return false
    }

    const { months} = this.props;
    let isZero = true
    Object.entries(months).map(([name, number]) => {
      
      if(this.getFloat(p_value[name][param]) != 0){
        isZero = false
      }
    })
    return isZero

  }
  isDespendZero(id, label){
    if(this.state.showZeroLines){
      return false
    }

    const { months} = this.props;
    let isZero = true
    Object.entries(months).map(([name, number]) => {
      let row = this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, number )
      if(this.getFloat(row) != 0){
        isZero = false
      }
    })
    return isZero
    
  }

  isCostCenterZero(id){
    if(this.state.showZeroLines){
      return false
    }

    const { months} = this.props;
    const {costCenterRecordsGrouped, areasDict} = this.state;
    
    let isZero = true
    Object.values(months).map(month => {
      
      let row = parseFloat(costCenterRecordsGrouped[`["${areasDict[id[0]?.['area']?.id]}", ${id[0].is_fixed}, ${month}]`])
      
      if(this.getFloat(row) != 0){
        isZero = false
      }
    })
    return isZero

  }

  changeVisibleMonths(event){
    // event.target.name]: event.target.checked
    let {name, checked} = event.target
    let props = this.props;
    this.setState((oldState)=>{
      oldState.visibleMonths[name] = checked
      oldState.months = {}
      Object.entries(this.props.months_dict).map(([number, month_name])=>{
        if(oldState.visibleMonths[month_name]){
          oldState.months[month_name] = number
        }
      })
      
      return {...oldState}
    });  
  }

  propsSetState(newState){
    this.props.propsSetState(newState)
  }
  

  render() {
    const { report, labels, formulas, year, years, recordsFetched, cost_center_mode, formulasTotal, showZeroLines, formulaOpened } = this.state;
    const { months} = this.state;




    let numberToCurrency = function(number) {
      return parseFloat(number).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
      
      
    };

    return (
      <React.Fragment>
        <div className="header" style={{display: 'flex', justifyContent: 'space-between' }}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20 }}>
            <div
                className={'result-popup-button'}
                onClick={()=> {
                  popupCenter({url: `/popups/${this.props.activeTab("dre") ? "dre" : "dre_cc"}${this.props.is_budget ? "?is_budget=true" : ""}`,title: 'Editar', w: window.innerWidth * 0.8, h: window.innerHeight * 0.8})
                }} 
                style={{backgroundColor: '#F5F5F5', 
                        width: 36, 
                        height: 36,
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'}}>
                <img src={popupIco} style={{width: 14}}></img>
            </div>
            <div className="label" style={{marginRight: 16}}>RESULTADO MENSAL</div>
            
            
            <MonthFilter changeVisibleMonths={(e)=> this.changeVisibleMonths(e)} visibleMonths={this.state.visibleMonths} months={this.props.months}></MonthFilter>
             
             {showZeroLines ?
               <div title="Mostrar linhas zeradas" style={{color: 'rgb(36,44,86)', fontWeight: "bold"}}className={'pointer'} onClick={()=> this.setState({showZeroLines: !showZeroLines})}> <img src={olhoOff} style={{width: 36}}></img></div>
               :
               <div title="Ocultar linhas zeradas" style={{color: 'rgb(36,44,86)', fontWeight: "bold"}}className={'pointer'} onClick={()=> this.setState({showZeroLines: !showZeroLines})}> <img src={olhoOn} style={{width: 36}}></img></div>
             }
          </div>
          <form className="form flex" style={{paddingRight: 35, alignItems: 'center', height: '100%'}}>
              {!this.props.is_budget &&
                <React.Fragment>
                  <BlueLink 
                    bordered
                    active={this.props.activeTab("dre")}
                    onClick={()=> this.propsSetState({activeTab: "dre"})}
                 > <div style={{paddingBottom: this.props.activeTab("dre") ? 19 : 0}}>DRE</div> </BlueLink>
                 <BlueLink 
                    bordered
                    active={this.props.activeTab("dre_cc")}
                    onClick={()=> this.propsSetState({activeTab: "dre_cc"})}
                 > <div style={{paddingBottom: this.props.activeTab("dre_cc") ? 19 : 0}}>DRE - Gerencial</div></BlueLink>    
                </React.Fragment>
              }
              
              
                            
              <div className={'with-date-ico'}>
                <select 
                className={'margin-left-20 margin-right-20 '} onChange={(e)=> this.changeYear(e.target.value)}>
                  {years.map((option_year)=>(
                    <option value={option_year} selected={year == option_year }> {option_year} </option>
                  ))}
                </select>
              </div>
              <a href={`/export/xlsx/${year}/${this.props.scope}.xlsx?${this.props.is_budget ? "is_budget=true" : ""}`}>
                <img src={downloadIco} style={{width: 36}}></img>
              </a>
              
            </form>
        </div>
        <div>
            <small style={{fontSize: 10, padding: "10px 0 0 43px", display: 'block', color: 'grey'}}>* Você está visualizando números no formato n/{this.props.divisor}</small>
        </div>
        <div id="table-result-wrapper">
          <div className="content margin-left-40 margin-right-40 padding-top-40">
            <table id="table-result">
              <thead>
                <tr>
                  <th>Nome</th>
                  {Object.values(months).map(month => {
                    return <th>{monthDict[`${month}`.padStart(2, "0")]}/{year}</th>
                  })}

                  <th>Acumulado</th>
                </tr>
              </thead>

              {Object.entries(report).map(([key, value]) => {
                return (
                  <tbody className={`body-${formulas[key].formula_type} body-${formulas[key].formula_type}-${formulas[key].layout}`}>
                    {((formulas[key].layout != "grupo_especial") || formulaOpened[key]) && <React.Fragment>
                    {formulas[key].cost_center_demonstration && cost_center_mode && this.renderCostCenterAreas(formulas[key].number_format)}
                    {formulas[key].formula_type != "formula" && this.renderCostCenterParams(value[key], formulas[key].number_format)}
                    {(!formulas[key].cost_center_demonstration || !Boolean(cost_center_mode)) && !formulas[key].is_only_index && this.formulaIsGrouped(key) && Object.entries(value[key]["params"]).map(
                      ([p_key, p_value]) => {
                        return (
                          <React.Fragment>
                            {value[key]["lines"][p_key] && Object.values(value[key]["lines"][p_key]).map((param)=>(
                              <React.Fragment>
                              {!this.isParamZero(p_value, param) && <tr>
                                  <td  onClick={()=> this.fetchDetail(p_key)}>
                                    <i className={`pull-left fa fa-chevron-${recordsFetched[p_key] ? 'down' : 'right'}`}/> {
                                      this.paramLabel(labels[p_key], param)
                                    }
                                  </td>
                                  {Object.entries(months).map(([name, number]) => {
                                    return (
                                      <td className={'comment-wrapper'}>
                                      {this.state.comments && <Comment 
                                        refreshComments={()=> this.setComments(this.state.year)}
                                        comments={this.state.comments}
                                        model_reference={'cost_centers' + (this.props.is_budget ? '-orçamento': '')}
                                        month={number}
                                        monthName={name}
                                        year={this.state.year}
                                        model_reference_id={p_key}>
                                      </Comment>}
                                        {
                                          numberToCurrency(p_value?.[name]?.[param] || 0)
                                        }
                                      </td>
                                    );
                                  })}
                                  <td className={'flex-chart comment-wrapper'}>
                                  {this.state.comments && <Comment 
                                        refreshComments={()=> this.setComments(this.state.year)}
                                        comments={this.state.comments}
                                        model_reference={'cost_centers' + (this.props.is_budget ? '-orçamento': '')}
                                        month={'total'}
                                        monthName={'total'}
                                        year={this.state.year}
                                        model_reference_id={p_key}>
                                      </Comment>}
                                    <p>{
                                        (p_value &&
                                          Object.entries(p_value)
                                            .map(([k, v])=> k != "total" && v[param])
                                            .reduce(
                                              (a, b) => parseFloat(a || 0) + parseFloat(b || 0),
                                              0
                                            )
                                            .toLocaleString("pt-BR", {
                                              style: "currency",
                                              currency: "BRL"
                                        }))
                                      }</p>
                                      <ResultChart
                                        loadAllYears={()=>{this.setupAllYearsData()}}
                                        loadYear={(year)=> this.loadYear(year)} 
                                        years={this.state.years}
                                        year={this.state.year} 
                                        title={labels[p_key]} 
                                        data={this.dataForFormula(p_value)} 
                                        param={param}
                                        p_key={p_key}
                                        dataKey={"params"}
                                        formula_id={key}
                                        months={this.props.months}
                                        allYearsData={this.state.allYearsData}
                                        >
                                        </ResultChart>
                                  </td>
                                </tr>}
                              {this.renderChildreenFor(p_key, {
                                p_key,
                                param,
                                key,
                                p_value
                              })}
                            </React.Fragment>
                            ))}

                          </React.Fragment>
                        );
                      }
                    )}
                    
                    {this.formulaIsGrouped(key) && value[key]["indicators"] && Object.entries(value[key]["indicators"]).map(
                      ([p_key, p_value]) => {
                        
                        let total_indicators = 0
                        return (
                          <React.Fragment>
                            
                              <React.Fragment>
                                {<tr>
                                  <td >
                                    <i className={`pull-left fa}`}/> 
                                    {p_key}
                                  </td>
                                  {Object.entries(months).map(([name, number]) => {
  
                                    total_indicators += p_value?.[name] ? parseFloat(p_value?.[name]) : 0
                                    return (
                                      <td className={'comment-wrapper'}>
                                        {this.state.comments && <Comment 
                                          refreshComments={()=> this.setComments(this.state.year)}
                                          comments={this.state.comments}
                                          model_reference={'indicator-line'}
                                          month={number}
                                          monthName={name}
                                          year={this.state.year}
                                          model_reference_id={p_key}>
                                        </Comment>}
                                        {
                                          formulas[key].number_format == "percentage" ?
                                            (
                                              p_value && p_value[name] ?
                                                this.percentFormat(p_value[name])
                                              :
                                              "0%"
                                            )
                                          :
                                            (
                                            p_value && p_value[name] ?
                                              numberToCurrency(p_value[name])
                                              :
                                              numberToCurrency(0)
                                            )
                                        }
                                        
  
                                      </td>
                                      
                                      
                                    );
                                  })}
                                  <td className={'flex'} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div className={'indicator-total comment-wrapper'}>
                                      {this.state.comments && <Comment 
                                          refreshComments={()=> this.setComments(this.state.year)}
                                          comments={this.state.comments}
                                          model_reference={'indicator-line'}
                                          month={'total'}
                                          monthName={'total'}
                                          year={this.state.year}
                                          model_reference_id={p_key}>
                                        </Comment>}
                                      {
                                      numberToCurrency(total_indicators)
                                    }
                                    </div>
                                    <ResultChart
                                      loadAllYears={()=>{this.setupAllYearsData()}}
                                      loadYear={(year)=> this.loadYear(year)} 
                                      years={this.state.years}
                                      year={this.state.year} 
                                      title={p_key} 
                                      data={this.dataForFormula(p_value)} 
                                      p_value={p_value}
                                      p_key={p_key}
                                      dataKey={"indicators"}
                                      formula_id={key}
                                      months={this.props.months}
                                      allYearsData={this.state.allYearsData}
                                      
                                      ></ResultChart>
                                  </td>
                                </tr>}
                            </React.Fragment>
                            
                          </React.Fragment>
                        );
                      }
                    )}
                    </React.Fragment>}
                    <tr id="principal" className={`formula-${formulas[key].formula_type} formula-${formulas[key].formula_type}-${formulas[key].layout} format-${formulas[key].number_format} formula-${formulas[key].is_only_index ? "only-index" : "more-than-index"}`}>
                      <td onClick={()=>{ this.setState({formulaOpened : {...formulaOpened, [key]: !formulaOpened[key]}})}}>
                        {formulas[key].layout == "grupo_especial" && <i style={{margin: '4px 5px 0 0'}} className={`pull-left fa fa-chevron-${formulaOpened[key] ? 'down' : 'right'}`}/>}
                        
                        <PopHover 
                          
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          withoutico description={
                            <React.Fragment>
                              {this.state?.formulas?.[key]?.description && <React.Fragment><br></br><div className={"desc"}>{this.state.formulas[key].description}</div><hr></hr></React.Fragment>}
                              <div className={"desc"}>{this.props.formulas[key]}</div>

                            </React.Fragment>
                            }>
                          {key} 
                        </PopHover>
                      </td>
                      {Object.entries(months).map(([name, number]) => {

                        return (
                          <td className={'comment-wrapper'}>
                            
                            {value[key]["result"][name] && this.getNumerFormatted(this.tryEval(value[key]["result"][name]), formulas[key].number_format)}
                            {this.state.comments && <Comment 
                              refreshComments={()=> this.setComments(this.state.year)}
                              comments={this.state.comments}
                              model_reference={'formula' + (this.props.is_budget ? '-orçamento': '')}
                              month={number}
                              monthName={name}
                              year={year}
                              model_reference_id={formulas[key]?.id}>
                            </Comment>}
                          </td>
                        );
                      })}

                      <td className={'comment-wrapper'} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 6}}>
                        {!formulasTotal[formulas[key].fr_id] && "carregando..."}
                        {formulasTotal[formulas[key].fr_id] &&
                          <React.Fragment>
                            {this.state.comments && <Comment 
                                refreshComments={()=> this.setComments(this.state.year)}
                                comments={this.state.comments}
                                model_reference={'formula' + (this.props.is_budget ? '-orçamento': '')}
                                month={13}
                                monthName={name}
                                year={year}
                                model_reference_id={formulas[key]?.id}>
                              </Comment>}
                            <label data-value={this.tryEval(formulasTotal[formulas[key].fr_id])} style={{width: "100%", paddingRight: 10}}>
                              {this.getNumerFormatted(this.tryEval(formulasTotal[formulas[key].fr_id]) * -1 , formulas[key].number_format)}  
                            </label>
                            <ResultChart
                              loadAllYears={()=>{this.setupAllYearsData()}}
                              loadYear={(year)=> this.loadYear(year, false, true)} 
                              years={this.state.years}
                              year={this.state.year} 
                              numberFormat={formulas[key].number_format}
                              title={key} 
                              data={this.dataForFormula(value[key]["result"])}
                              p_key={key}
                              dataKey={"result"}
                              formula_id={key}
                              formula_fr_id={formulas[key].fr_id}
                              months={this.props.months}
                              allYearsData={this.state.allYearsData}
                              formulasTotalByYear={this.state.formulasTotalByYear}
                              ></ResultChart>

                            
                          </React.Fragment>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      {Object.entries(months).map(()=><td></td>)}
                      <td></td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        <LoadingDialog open={this.loading > 0}/>
      </React.Fragment>
    );
  }
}

export default Dre;
const monthDict = {
  "01" : "jan",
  "02" : "fev",
  "03" : "mar",
  "04" : "abr",
  "05" : "mai",
  "06" : "jun",
  "07" : "jul",
  "08" : "ago",
  "09" : "set",
  "10": "out", 
  "11": "nov", 
  "12": "dez", 
}