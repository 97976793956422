import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';

HC_more(Highcharts);

export default function BarIncomeWithSales({dataCategories, dataSeries, title}) { 

	const options =  {
		chart: {
			type: 'column',
      stacked: true,
      reflow: true,
		},
		title: {
				text: title,
				align: 'left'
		},
		xAxis: {
				categories: dataCategories,
				crosshair: true,
				accessibility: {
						description: ''
				}
		},
		yAxis: {
			title: {
					text: ''
			}
		},
		tooltip: {
			 pointFormat: '<b>R$ {point.y:,.2f}</b>'
		}, 
		plotOptions: {
			column: {
					stacking: 'normal',
					pointPadding: 0.2,
					borderWidth: 0
			}
		},
		series: [{
			name: 'Saldo',
			data: dataSeries
		}]
  }
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}
