import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Upload } from '@mui/icons-material';
import HC_more from 'highcharts/highcharts-more';

HC_more(Highcharts);

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});
const ChartLine = ({
   balance, 
   balancePrev, 
   categories, 
   year, 
   month, 
   balanceAccount, 
   balanceApplication, 
   balanceApplicationFuture,
   UploadDate}) => {

   useEffect(() => {
      const aplicacao = balanceApplicationFuture[balanceApplicationFuture.length-1]
      if(balanceApplicationFuture.length > 0 && aplicacao !== 0) {
         sessionStorage.setItem('aplicacao', aplicacao)
      }
   }, [balanceApplicationFuture])

   const formartDaysNull = (type, balance, balancePrev) => {
      const qtdDaysNullPrev = new Date().getDate();
      const newDataNext = [...Object.values(balancePrev)]; 
      const newDataPrev = [...balance];        
      
      const lastUploadDate = new Date(UploadDate[2]);
      const dia = lastUploadDate.getDate();
      const paramsDate = new Date(`${month}/${UploadDate[2].split('/')[1]}/${year}`)
      const daysFuture = categories.length - dia 
 

      if(paramsDate.getTime() == lastUploadDate.getTime()) {
         if(type == 'future') {
            Array.from({ length: dia }).map((_, day) => {
               newDataNext[day] = null;
            });
            return newDataNext;
         } else if (type == null) {
            Array.from({ length: daysFuture }).map((_, day) => {
               newDataPrev[dia+(day)] = null;
            });
            //newDataPrev[newDataPrev.length-(daysFuture+1)] = null
            // newDataPrev.pop()
            //newDataPrev[newDataPrev.indexOf(0)] = null;
            return newDataPrev
         }
      } else if (paramsDate.getTime() < lastUploadDate.getTime()) {
         if(type == 'future') {
            return [null]
         } else {
            Array.from({ length: categories }).map((_, day) => {
               newDataPrev[dia+(day+1)] = null;
            });
            //newDataPrev[newDataPrev.length-(daysFuture+1)] = null
            newDataPrev
            return newDataPrev;
         }
      } else if (`${month}/${year}` > `${UploadDate[2]?.split("/")[0]}/${UploadDate[2]?.split("/")[2]}`) {
         if(type == 'future') {
            return newDataNext
         } else {
            return null;
         }
      }
   }


   const accountProjected = () => {
      const account = formartDaysNull('future', balance, balancePrev)
      const application = balanceApplicationFuture

      const lastUpload = new Date(`01/${UploadDate[2]?.split("/")[0]}/${UploadDate[2]?.split("/")[2]}`)
      const currentDateParams = new Date(`01/${month}/${year}`)

      if(currentDateParams < lastUpload) { return null }

      const projected = account?.map((account, index) => {
        let valueApplication;
        if(`${month}/${year}` > `${UploadDate[2]?.split("/")[0]}/${UploadDate[2]?.split("/")[2]}`) {
          valueApplication = JSON.parse(sessionStorage.getItem('aplicacao')) 
        } else {
          valueApplication = application[index];
        }
        return (account !== null && valueApplication !== null) ?  account - valueApplication : null;
     });
     
     return projected;
   }

    const balanceProjeted = () => {
      const lastUpload = new Date(`01/${UploadDate[2]?.split("/")[0]}/${UploadDate[2]?.split("/")[2]}`)
      const currentDateParams = new Date(`01/${month}/${year}`)

      if(currentDateParams < lastUpload) { return null }

      return formartDaysNull('future', balance, balancePrev)
    }

   const applicationProjected = () => {
      const lastUpload = new Date(`01/${UploadDate[2]?.split("/")[0]}/${UploadDate[2]?.split("/")[2]}`)

      const currentDateParams = new Date(`01/${month}/${year}`)
      const aplicacao = sessionStorage.getItem('aplicacao')

      const daysInMonth = new Date(year, month, 0).getDate();


      if(currentDateParams < lastUpload) { return null }
      
      if(lastUpload === currentDateParams ) { return balanceApplicationFuture }
      
      const data = Array.from({ length: daysInMonth }, () => JSON.parse(aplicacao) )

      if(currentDateParams > lastUpload) {
         return data
      }
      return balanceApplicationFuture
   }

		const hideLengendsPar = (scope) => {
			const seriesIndex = scope.index;
			const futureSeries = scope.chart.series[seriesIndex + 1];
			if (futureSeries) {
				if (scope.visible) {
						futureSeries.hide();
				} else {
						futureSeries.show();
				}
			}

		}
	
   
   const options = {
      chart: {
         type: 'line'
      },
      title: {
         text: 'Saldo Bancário'
      },
      xAxis: {
         categories: categories,
         crosshair: true,
         labels: {
           formatter: function() {
             var date = new Date(year, month-1, this.value);
             var dayOfWeek = date.toLocaleDateString('pt-BR', { weekday: 'short' });
             return this.value + '<br>' + dayOfWeek[0].toUpperCase();
           }
         },
      },
      yAxis: {
         title: {
            text: 'Valores'
         }
      },
      events: {
          load: function () {
              var chart = this;
              window.addEventListener('resize', function () {
                  console.log('window resized');
                  chart.setSize(
                      window.innerWidth, // Largura da janela
                      window.innerHeight, // Altura da janela
                      true // Animação desativada
                  );
              });
          }
      },
      tooltip: {
         formatter: function () {
           return `<span>\u25CF</span> ${this.series.name}: ${formatter.format(this.y)} <br/>`;
         }
       },
      legend: {
         enabled: true,
         verticalAlign: 'top'
       },
			plotOptions: {
				series: {
					events: {
							legendItemClick: function () {
								//TODO: Refatorar para uma funcao externa
								var chart = this.chart;
								var clickedSeries = this;

                var currentIndex = clickedSeries.index;
                var nextIndex = currentIndex + 1;

								if(currentIndex === 2) {
									if(clickedSeries.visible) {
										chart.series[currentIndex].hide();

										return false;
									} else {
										chart.series[currentIndex].show();

										return false;
									}

									return false;
								}

								if (nextIndex < chart.series.length) {
										if (clickedSeries.visible) {
												chart.series[currentIndex].hide();
												chart.series[nextIndex].hide();

												return false;
										} else {
												chart.series[currentIndex].show();
												chart.series[nextIndex].show();

												return false;
										}
								}

								return false;

							}
					}
				}
			},
      series: [  
        {
         name: 'Saldo Total', 
         data: formartDaysNull(null, balance, balancePrev),
         type: 'line',
         color: '#1593cc',
         dataLabels: {
            formatter: function () {
               return formatter.format(this.y);
            }, 
         }
        }, 
				{
         showInLegend: false,
         data: balanceProjeted(),
         type: 'line',
         color: '#99DAF9',
         dataLabels: {
            formatter: function () {
               return formatter.format(this.y);
            }, 
         }
        },
        {
         name: 'Saldo Aplicações Financeiras', 
         data: formartDaysNull(null, balanceApplication, balanceApplicationFuture),
         type: 'line',
         color: '#9BD5C4',
         dataLabels: {
            formatter: function () {
               return formatter.format(this.y);
            }, 
         }
        },
        {
         name: 'Saldo Conta Corrente', 
         data: formartDaysNull(null, balanceAccount,balanceAccount),
         type: 'line',
         color: '#617E94',
         dataLabels: {
            formatter: function () {
               return formatter.format(this.y);
            }, 
         },
        },
        // {
        //  showInLegend: false,
        //  data: applicationProjected(),
        //  type: 'line',
        //  color: '#9BD5C490',
        //  dataLabels: {
        //     formatter: function () {
        //        return 'R$ ' + Highcharts.numberFormat(this.y, 0, ',', '.');
        //     }
        //  }
        // },
        {
         showInLegend: false,
         data: accountProjected(),
         type: 'line',
         color: '#617E94',
         dataLabels: {
            formatter: function () {
               return formatter.format(this.y);
            }, 
         }
        }
      ]
   };

   return (
      <div>
         <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
   );
};


export const ListMonths = {
	1:"January",
	2:"February",
	3:"March",
	4:"April",
	5:"May",
	6:"June",
	7:"July",
	8:"August",
	9:"September",
	10:"October",
	11:"November",
	12:"December"
}

export default ChartLine;
