import React, { useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import { FlexCenter, Title, Label, BlueButton } from '../../styled_components/default';
import AutoCompleteDefault from '../AutoCompleteDefault';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';
import { IconButton, Table, TableBody, TableCell, TableRow, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import LoadingDialog from './../LoadingDialog'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-responsive-modal/styles.css';
import '../styles/DatePicker.css';
import '../styles/DetailingResult.css';
import downloadIcoXlsx from 'bt_download_xlsx.svg';
import downloadIcoPdf from 'bt_download_pdf.svg';

registerLocale('pt', pt);

export default function ClientReport({
  subTitlePercent,
  isOpen,
  onClose
}) {
  const [selectedClients, setSelectedClients] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [requestsCounter, setRequestsCounter] = useState(0);

  useEffect(() => {
  }, [isOpen]);

  const fetchClients = async (query) => {
    try {
      const response = await axios.get(`/home/search_clients_by_name?partner_name=${query}`);
      const clients = response.data.clients.map(client => ({
        value: client,
        label: client
      }));

      return new Promise(resolve => {
        resolve(clients);
      });
    } catch (error) {
      console.error("Failed to fetch clients data:", error);
    }
  };

  const handleClientChange = (selectedClient) => {
    setSelectedClients(prev => [...prev, selectedClient]);
    setAutoCompleteValue(null);
  };

  const removeClient = (clientToRemove) => {
    setSelectedClients(prev => prev.filter(client => client.value !== clientToRemove.value));
  };

  const save = async () => {
    if (selectedClients.length === 0) {
      alert("Selecione ao menos um fornecedor.");
      return;
    }
    if (!startDate) {
      alert("Selecione a data inicial.");
      return;
    }
    if (!endDate) {
      alert("Selecione a data final.");
      return;
    }

    const clients = selectedClients.map(client => client.value);
    const params = new URLSearchParams({
      clients: JSON.stringify(clients),
      initial_date: startDate.toISOString().split('T')[0],
      final_date: endDate ? endDate.toISOString().split('T')[0] : null,
      type_card: subTitlePercent
    });

    try {
      setRequestsCounter(prev => prev + 1);
      const response = await axios.get(`/home/clients_report.json?${params.toString()}`);
      if (response.status !== 200) {
        throw new Error("Failed to generate report.");
      }
      
      setReportData(response.data);
      setReportModalOpen(true);
      setRequestsCounter(prev => prev - 1);
    } catch (error) {
      console.error("Error generating report:", error);
      alert("Desculpe, não foi possível gerar relatório.");
    }
  };

  const exportTo = async (type) => {
    try {
      setRequestsCounter(prev => prev + 1);
      const clients = selectedClients.map(client => client.value);
      const params = new URLSearchParams({
        clients: JSON.stringify(clients),
        initial_date: startDate.toISOString().split('T')[0],
        final_date: endDate ? endDate.toISOString().split('T')[0] : null,
        type_card: subTitlePercent
      });
      
      const res = await axios.get(`/home/export_clients_report.${type}?${params.toString()}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Relatório-Cliente-Fornecedor.${type}`);
      document.body.appendChild(link);
      link.click();
      setRequestsCounter(prev => prev - 1);
    } catch (error) {
      setRequestsCounter(prev => prev - 1);
      alert('Desculpe, não foi possível exportar relatório.')
      console.error('Não foi possível exportar relatório', error);
    }
  };

  const numberToCurrency = (number) => {
    if (number === 0 || number) {
      return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    return "-";
  }

  const ReportModal = ({ isOpen, onClose, data, title }) => (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle style={{paddingBottom: 2}}>
          {title}
          <div onClick={() => exportTo('xlsx')} style={{float: 'right'}}>
            <a style={{ marginLeft: 10 }} className='pointer' title="Download para XLSX">
              <img src={downloadIcoXlsx} style={{ width: 36 }} alt="Download Icon" />
            </a>
          </div>
          <div onClick={() => exportTo('pdf')} style={{float: 'right'}}>
            <a style={{ marginLeft: 20 }} className='pointer' title="Download para PDF">
              <img src={downloadIcoPdf} style={{ width: 36 }} alt="Download Icon" />
            </a>
          </div>
          
        </DialogTitle>
        <DialogContent>
          <div className="row" style={{padding: '0 15px 0 15px'}}>
            <div className="col-3" style={{border: '3px solid #f2f2f2', height: 45, color: 'rgb(97, 126, 148)'}}>
              <span>Valor Total:</span> 
              <b style={{fontSize: 16, float: 'right', paddingTop: 8}}>{data?.total}</b>
            </div>
            <div className="col-3" style={{border: '3px solid #f2f2f2', height: 45, color: 'rgb(97, 126, 148)'}}>
              <span>Realizado:</span> 
              <b style={{fontSize: 16, float: 'right', paddingTop: 8}}>{data?.total_realizado}</b>
            </div>
            <div className="col-3" style={{border: '3px solid #f2f2f2', height: 45, color: 'rgb(97, 126, 148)'}}>
              <span>Previsto:</span> 
              <b style={{fontSize: 16, float: 'right', paddingTop: 8}}>{data?.total_previsto}</b>
            </div>
            <div className="col-3" style={{border: '3px solid #f2f2f2', height: 45, color: 'rgb(97, 126, 148)'}}>
              <span>Vencidos:</span>
              <b style={{fontSize: 16, float: 'right', paddingTop: 8}}>{data?.total_atrasado}</b>
            </div>
          </div>

          <div id="container">
            <div className={'table-responsive table-height'}>
              <table className={'table table-bordered table-striped table-hover table-sticky'}>
                <thead>
                  <tr>
                    <td className={'table-thead-detaillg-result'}>Cliente / Fornecedor</td>
                    <td className={'table-thead-detaillg-result'}>Natureza</td>
                    <td className={'table-thead-detaillg-result'}>Descrição (Natureza)</td>
                    <td className={'table-thead-detaillg-result'}>Data Emissão NF</td>
                    <td className={'table-thead-detaillg-result'}>Data de Vencimento</td>
                    <td className={'table-thead-detaillg-result'}>Data de Pagamento</td>
                    <td className={'table-thead-detaillg-result'}>Valor Líquido</td>
                    <td className={'table-thead-detaillg-result'}>Número Nota</td>
                    <td className={'table-thead-detaillg-result'}>Matriz / Empresa</td>
                  </tr>
                </thead>
                <tbody>

                  {data && data.clients.length > 0 ? (
                    data.clients.map((record, index) => (
                      <tr key={index}>
                        <th className="table-tbody-detaillg-result">{record.partner}</th>
                        <th className="table-tbody-detaillg-result">{record.natureza}</th>
                        <th className="table-tbody-detaillg-result">{record.label_natureza}</th>
                        <th className="table-tbody-detaillg-result">{record.date_nf}</th>
                        <th className="table-tbody-detaillg-result">{record.due_date}</th>
                        <th className="table-tbody-detaillg-result">{record.pay_day}</th>
                        <th className="table-tbody-detaillg-result">{numberToCurrency(record.value)}</th>
                        <th className="table-tbody-detaillg-result">{record.number_nf}</th>
                        <th className="table-tbody-detaillg-result">{record.company}</th>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <th colSpan={10} style={{ textAlign: 'center' }}>
                        Sem dados para apresentar
                      </th>
                    </tr>
                  )}
                  
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingDialog open={requestsCounter > 0}/>
    </>
  );

  return (
    <>
      <Modal open={isOpen} onClose={onClose} center>
        <div>
          <FlexCenter style={{ width: 770, display: 'flex', marginTop: 15 }}>
            <Title style={{ display: 'flex', fontSize: 15}}>
              RELATÓRIO DE {subTitlePercent === 'Recebidos' ? 'CLIENTES' : 'FORNECEDORES'}
            </Title> 
          </FlexCenter>
          
          <Label style={{ display: 'flex', fontSize: 12, marginBottom: 10}}>{subTitlePercent === 'Recebidos' ? 'Cliente' : 'Fornecedor'}</Label>
          <AutoCompleteDefault
            loadOptions={fetchClients}
            onChange={handleClientChange}
            value={autoCompleteValue}
            placeholder={`Selecione o ${subTitlePercent === 'Recebidos' ? 'Cliente' : 'Fornecedor'}...`}
            noOptionsMessage={() => `${subTitlePercent === 'Recebidos' ? 'Cliente' : 'Fornecedor'} não encontrado`}
          />
        </div>

        {selectedClients.length > 0 && (
          <div>
            <Table>
              <TableBody>
                {selectedClients.map(client => (
                  <TableRow key={client.value} style={{ height: 50 }}>
                    <TableCell style={{ fontSize: 12 }}>{client.label}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      <IconButton onClick={() => removeClient(client)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}

        <div style={{ marginTop: 40 }}>
          <Label style={{ display: 'flex', fontSize: 12, marginBottom: 10}}>Período</Label>
          <Grid container spacing={2}>
            <Grid item>
              <Label style={{ display: 'flex', fontSize: 12}}>De</Label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                showYearDropdown
                showMonthDropdown
                dropdownMode="scroll"
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                locale="pt"
                className="date-picker-input"
                popperPlacement="top"
              />
            </Grid>
            <Grid item>
              <Label style={{ display: 'flex', fontSize: 12}}>Até</Label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                showYearDropdown
                showMonthDropdown
                dropdownMode="scroll"
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="dd/MM/yyyy"
                locale="pt"
                className="date-picker-input"
                popperPlacement="top"
              />
            </Grid>
          </Grid>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
          <BlueButton style={{align: 'right'}} className={'right'} onClick={save}>
            GERAR RELATÓRIO
          </BlueButton>
        </div>
      </Modal>

      <ReportModal
        isOpen={isReportModalOpen}
        onClose={() => setReportModalOpen(false)}
        data={reportData}
        title={`Relatório de ${subTitlePercent === 'Recebidos' ? 'Clientes' : 'Fornecedores'}`}
      />
    </>
  );
}
