import React from 'react';
import seta from 'seta.svg';
import setaBaixo from 'seta-baixo.svg';
import ResultChart from './ResultChart';
import { Chart as ChartJS } from 'chart.js/auto'
import {Line} from 'react-chartjs-2';

var formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});
export default class DashboardWidget extends React.Component {
    tryEval(formula){
        try{
            return parseFloat(parseFloat(eval(formula)).toFixed(2))
        }catch(e){
            return 0
        }
    }


    tryEvalFormatted(formula){
        let {number_format, enable_custom_formula} = this.props;

        try{
            if (number_format == "percentage") {

              return new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(this.tryEval(formula)).toFixed(2)) + " %"
            }else if (number_format == "integer"){
              return new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(this.tryEval(formula)))
            }else{
              if(enable_custom_formula){
                return formatter.format(parseFloat(this.tryEval(formula)) * -1)
              }else{
                return formatter.format(parseFloat(this.tryEval(formula)))
              }
              
            }
            
        }catch(e){
            return 0
        }
    }
    formatCash(n){
      let multiplier = 1
      if(n < 0){
        n = n * -1
        multiplier = -1
      }
      if (n < 1e3) return n * multiplier;
      if (n >= 1e3 && n < 1e6) return +(n * multiplier / 1e3).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return +(n * multiplier / 1e6).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return +(n * multiplier / 1e9).toFixed(1) + "B";
      if (n >= 1e12) return +(n * multiplier / 1e12).toFixed(1) + "T";
    }

    numericDifference(data){
        let {current_amount, number_format} = this.props;
        try{
            let value = 0
            // debugger
            if (number_format == "percentage") {
                value =  (this.tryEval(data) - this.tryEval(current_amount))
            }else{
                value =  (this.tryEval(data) / this.tryEval(current_amount) - 1) * -1 * 100
            }
            let fValue = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value).toFixed(2))
            
            return value  
            
            
        }catch(e){
            return 0
        }  
    }

    difference(data, color){
        let {current_amount, number_format, is_charge} = this.props;
        let compared = this.tryEval(data)
        let current = this.tryEval(current_amount)
        if (Math.sign(compared) == -1 && Math.sign(compared) == 1){

        }
        try{
            let value = 0
            if (number_format == "percentage") {
                value =  (current - compared)
            }else{
                
                value =  ((current / compared) * 100) - 100
                // value =  (compared / current - 1) * -1 * 100
            }
            let fValue = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value).toFixed(2))
            
            if(String(value) != "NaN"){
                if (number_format == "percentage") {
                    return <span style={{color: color}}>
                            ({` ${fValue} pp`})
                        </span>
                }else{
                    return <span style={{color: color}}>
                            ({` ${fValue}%`})
                        </span>
                }
                
            }else{
                return "-"
            }
            
        }catch(e){
            console.log(e)
            return "-"
        }  
    }

    getDifferenceColor(isPositive){
      let green = "#009d57"
      let red = "#ed1c24"
      let finalColor = ""
      let toggleCollor = (color)=>{ return color == green ? red : green}
      
      if(isPositive == "positive"){
        finalColor =  green
      }else{
        finalColor =  red
      }

      if(this.props.is_charge){
        finalColor = toggleCollor(finalColor)
      }
      return finalColor
      
    }
    dataForChart(result){
      // budget, current_year, last_year
      const { months, enable_custom_formula, last_month} = this.props;
      let data = []
      let i = 1
      function tryEval(str) {
        try {
          let formulaSolved = parseFloat(eval(str))
          return formulaSolved ? formulaSolved : 0;
        } catch (e) {
          return 0
        }
      }
      Object.entries(months).map(([name, number]) => {
        if(i <= months[last_month]){
          let value = 0
          if(enable_custom_formula){
            value = tryEval(result?.current_year?.[name]) * -1
          }else{
            value = tryEval(result?.current_year?.[name])
          }
          data[number - 1] = value
          
        }
        i += 1
      })
    
      
      return data
    }

    CurrentAmount(scope){
        let {current_amount, month_values, last_month, enable_custom_formula, balance_sheet_last_month} = this.props;

        if(scope == 'attachment_table'){
            return month_values['current_year'][last_month]
        }
        else if(enable_custom_formula && balance_sheet_last_month != null){
            return balance_sheet_last_month
            }
        else {
            return current_amount
        }
    }

    arrowRules(rule){
        let {budget, last_amount, scope, is_charge, number_format} = this.props;
        let is_positive = false
        if(rule == 'last_amount_rule'){
            if((this.tryEval(this.CurrentAmount(scope)) > this.tryEval(last_amount)) || (this.tryEvalFormatted(last_amount) == 'R$ 0,00' || this.tryEvalFormatted(last_amount) == 'R$ 0,00' || this.tryEvalFormatted(last_amount) == "R$ NaN") || (this.tryEval(this.CurrentAmount(scope)) == this.tryEval(last_amount)) || this.tryEvalFormatted(budget) == "R$ NaN") {
              is_positive = true
            }
        }else{
            if((this.tryEval(this.CurrentAmount(scope)) > this.tryEval(budget)) || (this.tryEvalFormatted(budget) == '0,00' || this.tryEvalFormatted(budget) == 'R$ 0,00') || (this.tryEval(this.CurrentAmount(scope)) == this.tryEval(budget)) || this.tryEvalFormatted(budget) == "R$ NaN") {
              is_positive = true
            }
        }
        if(is_charge){
            is_positive = !is_positive
        }

        return is_positive ? 'positive' : 'negative'
    }
    

    render(){
      let {title, current_year, current_amount, last_month, number_format, last_amount, budget, month_values, months, layout, months_ordered, scope, enable_custom_formula, balance_sheet_last_month, is_charge} = this.props;
      let green = "#009d57"
      let red = "#ed1c24"

      if(layout && layout.length > 0 && layout == "apenas_total" || enable_custom_formula && balance_sheet_last_month !== null){
          return (
              <div class="widget  margin-right-20" style={{borderBottom: '5px solid rgb(0, 157, 87)'}}>
                  <div class="padding-30">
                      <div class="title padding-top-20 padding-bottom-20">
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                              {title}
                          </div>
                      </div>
                      <div class={"current-amount"}>{this.tryEvalFormatted(this.CurrentAmount(scope))}</div>
                  </div>
                  <div class={"widget-footer"} style={{backgroundColor: 'transparent'}}>
                      <div style={{display: 'flex', flex: 1, maxHeight: 80, width: "100%"}}>
                        <Line 
                              options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                interaction: {
                                  intersect: false,
                                },
                                plugins:{
                                  legend:{
                                    display: false
                                  },
                                  title:{
                                    display: false
                                  },
                                },
                                scales: {
                                  y: {
                                      display: false // Hide Y axis labels
                                  },
                                  x: {
                                      display: false // Hide X axis labels
                                  }
                              }   
                              }} 
                              
                              
                              data={{
                              labels: months_ordered.slice(0, months[last_month]),
                              datasets: [
                                {
                                  data: this.dataForChart(month_values),
                                  backgroundColor: '#009d57',
                                  borderColor: '#009d57',
                                  fill: false,
                                  cubicInterpolationMode: 'monotone',
                                  tension: 0.4
                                }
                              ]
                            }}>    
                        </Line>
                      </div>
                  </div>
              </div>

          );
      }
      return (
          <div class="widget  margin-right-20">
              <div class="padding-30">
                  <div class="title padding-bottom-20">
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                       {title}
                       <ResultChart
                        year={current_year} 
                        years={['budget', 'current_year', 'last_year']}
                        title={title}
                        data={month_values}
                        dataKey={"result_dashboard"}
                        numberFormat={number_format}
                        months={months}
                        allYearsData={month_values}
                        blueIco={true}
                        icon={{
                          width: 20,
                          height: 20,
                          marginRight: -1
                        }}
                        ></ResultChart>
                      </div>
                  </div>
                  <div class={"current-amount"}>{this.tryEvalFormatted(this.CurrentAmount(scope))}</div>
              </div>
              <div class={"widget-footer"}>
                  <div style={{display: 'flex', flex: 1}}>
                      <div className={'arrow-area'} style={{
                          backgroundColor: this.getDifferenceColor(this.arrowRules('budget_rule'))
                      }}>
                          { this.arrowRules('budget_rule') == 'positive' ?
                              <img src={seta} style={{width: 15}}/> :
                              <img src={setaBaixo} style={{width: 15, transform: 'rotate(180deg)'}}/>
                          }
                      </div>
                      <div className={'padding-left-10 padding-top-20'}>
                          <div class="footer-description margin-bottom-20">Orçamento</div>
                          <div>
                              <div
                                  class="amount"
                                  style={{
                                      color: this.getDifferenceColor(this.arrowRules('budget_rule'))
                                  }}>
                                  {this.tryEvalFormatted(budget)}
                                  <small style={{display: 'block'}}>{this.difference(budget, this.getDifferenceColor(this.arrowRules('budget_rule')))}</small>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div style={{display: 'flex', flex: 1}}>
                      <div className={'arrow-area'} style={{
                          backgroundColor: this.getDifferenceColor(this.arrowRules('last_amount_rule'))
                      }}>
                          { this.arrowRules('last_amount_rule') == 'positive' ?
                              <img src={seta} style={{width: 15}}/> :
                              <img src={setaBaixo} style={{width: 15, transform: 'rotate(180deg)'}}/>
                          }
                      </div>
                      <div className={'padding-left-10 padding-top-20'}>
                          <div class="footer-description margin-bottom-20">Ano anterior</div>
                          <div>
                              <div
                                  class="amount"
                                  style={{
                                      color: this.getDifferenceColor(this.arrowRules('last_amount_rule'))
                                  }}>
                                  {this.tryEvalFormatted(last_amount)}
                                  <small style={{display: 'block'}}>{this.difference(last_amount, this.getDifferenceColor(this.arrowRules('last_amount_rule')))}</small>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      );
    }
}
