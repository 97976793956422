import React from "react"
import abinbev from "abinbev.png";
class CompanySelect extends React.Component {
  state = {}

  toggleOppened(){
    this.setState({opened: !this.state.opened})
  }

  UNSAFE_componentWillMount(){
    const {all_options, currentCompanyId} = this.props;
    this.setState({currentCompany: all_options.filter((opt)=>opt.id === currentCompanyId)[0] || {} })
  }

  renderCompany(company, level=0){
     return <React.Fragment>
            <div 
              onClick={()=> this.props.onChange(company.id)}
              className={'company-select-button'}
              style={{
                borderBottom: '1px solid #e5e5e5', 
                width: 310,
                backgroundColor: level > 0 ? 'rgb(230,230,230)' : `#f1f1f1`,
                height: 70,
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                cursor: 'pointer',
                paddingLeft: 16
                
              }}>
              <div style={{
                marginLeft:  20 * level,
                fontSize: 20,
                color: '#676767'

              }}>
                {level > 0 ? " L " : ""}  
              </div>
              
                <img 
                  src={company.logo}                    
                  style={{width: 50,
                          height: 50,
                          padding: 6,
                          marginRight: 15,
                          borderRadius: 50,
                          border: '1px #e5e5e5 solid'
                        }}
                />
                <span style={{color: '#676767', fontFamily: 'Roboto'}}>{company.label}</span>
            </div>
            {company.childreen.map((child)=> this.renderCompany(child, level + 1))}
          </React.Fragment>
  }

  async closeSelect(){
    let self = this
    setTimeout(()=>{
      self.setState({opened: false})
    }, 500)
  }

  render () {
    const {opened, currentCompany} = this.state;
    const {options, currentCompanyId} = this.props;

    return (
      <div style={{zIndex: 9, position: 'relative'}} tabIndex="0" onBlur={()=> this.closeSelect()}>
        <div 
          onClick={()=> this.toggleOppened()}
          style={{
            borderBottom: '1px solid #e5e5e5', 
            width: 260,
            backgroundColor: '#f1f1f1',
            height: 74,
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            cursor: 'pointer',
            paddingLeft: 8
          }}>
            <img 
              src={currentCompany.logo} 
              style={{width: 50, height: 50, padding: 6, marginRight: 15, borderRadius: 50, border: '1px #e5e5e5 solid'}}
            />
          <span style={{color: '#676767', marginRight: 10, fontFamily: 'Roboto'}}>{currentCompany.label}</span>
          <i className={`fa fa-chevron-${opened ? "up" : "down"}`} style={{position: 'absolute', right: 10, color: '#676767'}}/>
        </div>
        
        {opened &&
          <div style={{position: 'absolute', top: 74, maxHeight: 'calc(100vh - 198px)',overflow: 'scroll'}}>
          	{options.map((company)=>(
                  console.log( "company " + company.label),
                  console.log("company logo  " + company.logo ),
                  this.renderCompany(company)
            ))}
          </div>
        }
      </div>
      
    );
  }
}

export default CompanySelect
