import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DrillDownDailyMovement from './home/Modais/DrillDownDailyMovement.js';
import { ListMonths, ListMonthsPtBR, ListMonthsKey } from '../constants'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ChartLine from './ChartLine';

const currencyOptions = {
  style: 'currency',
  currency: 'BRL'
};

export default function DashboardChartWidget({uploadDate}) {
  const chartRef = useRef(null);
  const [showDrillDown, setShowDrillDown] = useState(false);
  const [keyDataTable, setKeyDataTable] = useState(null);
  const [resource, setResource] = useState(null); // Inicializado como null para indicar que está carregando
  const [uploadDateState, setUploadDateState] = useState(null);
  const [sumKey, setSumKey] = useState({});
  const [currentDate, setCurrentDate] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const { search } = document.location;
			const query = new URLSearchParams(search);
			const paramsDate = query.get('year')

			try {
				let dateFilter;

				if(!paramsDate) {
					if(uploadDate === "Nenhuma Atualização") {
						const today = new Date();
						dateFilter = `${ListMonthsKey[today.getMonth()]}/${today.getFullYear()}`
					} else {
						const [ year, month, day ] = uploadDate.split('-')
						dateFilter = `${ListMonths[month]}/${year}`
					}
				} else {
					dateFilter = paramsDate
				}

				const response = await fetch(`/home/chart_bar_data?year=${dateFilter}`);
				if (!response.ok) {
					throw new Error('Fetch error....');
				}
				const result = await response.json();
				setResource(result);
			} catch (error) {
				console.log('Erro ao buscar os dados');
			}
		};

		fetchData();
	}, []);

  const valueToReceived = () => {
    const result = [];
    const currentDay = new Date().getDate();
    if (resource && resource.recebido_previsto && resource.dias) {
      Array.from({ length: currentDay }).forEach((_, day) => {
        result.push(resource.recebido_previsto[day]);
      });
      Array.from({ length: resource.dias.length - currentDay }).forEach((_, day) => {
        result.push(resource.a_receber_previsto[currentDay + day]);
      });
    }
    return result;
  };

  const defineSumKey = (selectedIndex) => {
    const uploadDateTime = new Date(resource?.upload_real_date[2])?.getTime();
    const prepareCurrentTime = new Date(`${resource?.month}/${selectedIndex + 1}/${resource?.year}`).getTime();
    setUploadDateState(uploadDateTime);
    setCurrentDate(prepareCurrentTime);
    let sumKeyObject = {};
    Object.entries(resource?.details_chart_contabil_pattern_param[selectedIndex + 1]).forEach(([linesKey, value]) => {
      Object.values(value).forEach((v) => {
        sumKeyObject[linesKey] ||= {};
        sumKeyObject[linesKey]["previsto"] ||= 0;
        sumKeyObject[linesKey]["realizado"] ||= 0;
        sumKeyObject[linesKey]["previsto"] += linesKey === "saida" ? v.pago_previsto : v.recebido_previsto;
        sumKeyObject[linesKey]["realizado"] += linesKey === "saida" ? v.pago : v.recebido;
      });
    });
    setSumKey(sumKeyObject);
  };

  const handleClick = (event) => {
    setKeyDataTable(event.point.category);
    defineSumKey(event.point.index);
    setShowDrillDown(prevState => !prevState);
  };

  const options = {
    chart: {
      type: 'column',
      stacked: true,
      reflow: true,
    },
    title: {
      text: 'Movimentação Diária'
    },
    xAxis: {
      categories: resource?.dias,
      crosshair: true,
      labels: {
        formatter: function () {
          const year = resource?.year;
          const month = resource?.month;
          const montJs = month - 1;
          const date = new Date(year, montJs, this.value);
          const dayOfWeek = date.toLocaleDateString('pt-BR', { weekday: 'short' });
          return this.value + '<br>' + dayOfWeek[0].toUpperCase();
        }
      },
    },
    yAxis: [{
      title: {
        text: 'Entradas e Saídas'
      },
    }],
    legend: {
      enabled: true,
      verticalAlign: 'top'
    },
    tooltip: {
      pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: R$ {point.y:,.2f}<br/>'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        events: {
          click: handleClick
        }
      },
    },
    series: resource ? [
      {
        name: 'Valor Recebido',
        data: resource.recebido,
        color: '#20c492',
      },
      {
        name: 'Valor A receber',
        data: valueToReceived(),
        color: '#75d8ba',
      },
      {
        name: 'Valor Pago',
        data: resource.pago,
        color: '#d53443',
      },
      {
        name: 'Valor A pagar',
        data: resource.a_pagar_previsto,
        color: '#d57881',
      },
    ] : []
  };

  if (!resource) {
    return (
      <>
        <Skeleton height={300} />
        <Skeleton height={300} />
      </>
    );
  }

  return (
    <>
      <ChartLine
        balance={resource.saldo}
        balanceAccount={resource.saldo_conta}
        balanceApplication={resource.saldo_aplicacao}
        balanceApplicationFuture={resource.saldo_aplicacao_projetado}
        balancePrev={resource.saldo_previsto}
        categories={resource.dias}
        year={resource.year}
        month={resource.month}
        UploadDate={resource.upload_real_date} 
      />
      <HighchartsReact 
				highcharts={Highcharts} 
				options={options} 
			/>
      {showDrillDown && (
        <DrillDownDailyMovement
          keyDataTable={keyDataTable}
          details_chart_contabil_pattern_param={resource.details_chart_contabil_pattern_param}
          currentTime={currentDate}
          uploadDate={uploadDateState}
          sumKey={sumKey}
					isOpen={showDrillDown}
          currencyOptions={currencyOptions}
          contabil_pattern_params={resource.contabil_pattern_params}
          onClose={() => setShowDrillDown(false)}
        />
      )}
    </>
  );
}
