import React, { useState, useEffect, useRef } from 'react'; 
import html2pdf from 'html2pdf.js';
import Select from 'react-select'
import IconDownload from "../../assets/images/download-btn.svg";
import icon_help from 'icon_help.svg'; import { Flex, 
	FlexBetween, 
	H3, 
  Divisor
} from '../styled_components/default';
import { monthsEnToBR, ListMonthsValue } from "../constants" 
import FilterDate from "./FilterDate";

export default function HomeInfo(props){
	const imgRef = useRef(null)
	const [displayLogo, setDisplayLogo] = useState('none')
  const [base64, setBase64] = useState('');
	const textInfo = `
A 'Data da última atualização' que você vê representa a data do último lançamento financeiro registrado no sistema CASH, lançamentos estes que foram inseridos via “upload” dos arquivos “Realizados” (a pagar, a receber) da empresa.

É importante notar que essa data não é o “dia” em que foi inserido o arquivo no sistema, mas sim o dia em que o pagamento ou o recebimento que está no arquivo foi realizado.
`;

  useEffect(() => {
    const convertToBase64 = async () => {
			const { company_logo } = props
      try {
        const response = await fetch(company_logo);
        const blob = await response.blob();
        const reader = new FileReader();
        
        reader.onloadend = () => {
          setBase64(reader.result);
        };
        
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };

    convertToBase64();
  }, []);


	const generatePDF = () =>{
		setDisplayLogo('block')
		const element = document.querySelector('#home')
		const hiddenElement = document.getElementById('hidden_in_pdf')
    
		const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
		hiddenElement.hidden = true
		
		let opt = {
			html2canvas: { windowHeight: screenHeight, windowWidth: screenWidth, useCORS: true },
			jsPDF: { unit: 'px', format: [screenWidth, screenWidth], orientation: 'portrait', userUnit: 2 }, 
			margin: [10, 10, 10, 10]
		}
		html2pdf().set(opt).from(element).save();

		setTimeout(()=>{
		  hiddenElement.hidden = false
			setDisplayLogo('none')
    }, 2000)
	}

	const parseDate = (dateString) => {
		const [year, month, day] = dateString.split('-');
		const monthName = monthNamesPTbr[parseInt(month) - 1];
		
		return `${day} de ${monthName} de ${year}`;
	}

	const toDateLocale = (dateString) => {
			const date = new Date(dateString);
			date.setDate(date.getDate() + 1);
			return date.toLocaleDateString('pt-BR');
	};

  const baseDate = () => {
    const { basis_date } = props
    const options = [
        { value: basis_date.minor_basis_date, label: parseDate(basis_date.minor_basis_date), isDisabled: true },
        ...basis_date.companies.map(company => ({
            value: company.company_id,
            label: `${company.company_name} - ${toDateLocale(company.basis_date)}`,
            isDisabled: true
        }))
    ];

    return options
  }


	function displayBasesDateGroup() {
		if (infoUpload() === "(Mês fechado)") {
				return (
						<Flex>
								<p style={{ fontWeight: 400, marginRight: 10 }}>Data do último lançamento: </p>
								<i style={{ fontSize: 11, float: 'right' }}>{infoUpload()}
									<img src={icon_help} title={textInfo} style={{ marginInline: 8 }} />
								</i>
						</Flex>
				);
		} else {
				return (
          <div style={{ display: 'grid', marginBottom: 20 }}>
						<Flex>
								<p style={{ fontWeight: 400 }}>Data do último lançamento:</p>
								<Select options={baseDate()} defaultValue={baseDate()[0]} styles={customStyles} />
						</Flex>
            <i style={{ fontSize: 11, marginTop: -10, display: infoUpload() === "(Mês fechado)" && !company_matriz  ? 'none' : 'block' }}>{infoUpload()}
              <img src={icon_help} title={textInfo} style={{ marginInline: 8 }} />
            </i>
          </div>
				);
		}

	}


	const infoUpload = () => {
		const { params_date, upload_date, basis_date, company_matriz } = props
		

		let uploadDate = new Date(upload_date);

    if(!company_matriz) { uploadDate = new Date(basis_date.minor_basis_date) }

		const currentDate = new Date();
		const onDayAgo = currentDate.setDate(currentDate.getDate() - 1)
		const month = ListMonths[params_date?.split("/")[0]];
		const year = params_date?.split("/")[1];

		const paramsDate = new Date(`${month}/${uploadDate.getDate()}/${year}`)		
		const diffDate = parseInt((onDayAgo - uploadDate) / (1000 * 60 * 60 * 24));
		const invalidDate =  paramsDate === null || paramsDate === undefined || isNaN(paramsDate.getTime())

		const msgDelayDays =  diffDate > 1 ? 'dias em atraso até o último dia útil' : 'dia em atraso até o último dia útil'  
		const msgClosedMonth = '(Mês fechado)';

		if (paramsDate >= uploadDate || invalidDate) {
			return `(${diffDate} ${msgDelayDays})`
		} else {
			return msgClosedMonth
		}
	}

	const customStyles = {
		control: (provided) => ({
			...provided,
			width: 160,
      backgroundColor: 'transparent',
			marginTop: -11,
			fontSize: 11,
			marginLeft: 6,
			borderColor: 'none',
			borderStyle: 'none',
			borderRadius: 0, 
			boxShadow: 'none',
      color: 'blue'
		}),
	};

		const {last_upload_date, company_matriz, company_logo} = props


		const formaterrLastUploadDate = () => {
			const [year, month, day ] = last_upload_date.split('-')

			if(last_upload_date === "Nenhuma Atualização"){
				return last_upload_date 
			}

			return `${day} de ${MonthsPTbr[month]} de ${year}`
		}

		return (
      <>
				<img className={"company-logo"} style={{width: '10%', height: 'auto', padding: '5px', display: displayLogo}} src={base64} />
        <Flex style={{color: '#2A3170', justifyContent: 'space-between'}}>
          <div>
            <H3 style={{ 
                  fontFamily: 'Roboto',
                  letterSpacing: '0.05em',
              }}
            >Dashboard</H3>
          </div>
          <Divisor />
          <Flex style={{marginLeft: 5, justifyContent: 'space-between', width: '90%'}}>
            {!company_matriz && (
              <>
								{displayBasesDateGroup()}
              </>
            )}
            {company_matriz && (
              <Flex>
                <div>
                  <p style={{ fontWeight: 400, marginRight: 15 }}>Data do último lançamento: {formaterrLastUploadDate()} </p>
                  <i style={{ fontSize: 11, marginTop: -10, marginBottom: 10, display: infoUpload() === "(Mês fechado)" && !company_matriz  ? 'none' : 'block' }}>{infoUpload()}
                    <img src={icon_help} title={textInfo} style={{ marginInline: 8 }} />
                  </i>
                </div>
                <Divisor />
              </Flex>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 15 }}>
              <FilterDate style={{marginTop: 10}} currentDate={props.update_date_params}/>
              <img style={{ marginTop: -10, width: 30 }} id="hidden_in_pdf" src={IconDownload} onClick={() => generatePDF()}></img>
            </div>
          </Flex>
        </Flex>
      </>
		);
}

const ListMonths = {
	"January":"01",
	"February":"02",
	"March"  :"03",
	"April"  :"04",
	"May"    :"05",
	"June"   :"06",
	"July"  :"07",
	"August" :"08",
	"September":"09",
	"October":"10",
	"November":"11",
	"December":"12"
}

export const monthNamesPTbr = [
	'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
	'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
];

const MonthsPTbr = {
	"01":"Janeiro",
	"02":"Fevereiro",
	"03":"Março",  
	"04":"Abril",  
	"05":"Maio",   
	"06":"Junho",   
	"07":"Julho",  
	"08":"Agosto", 
	"09":"Setembro",
	"10":"Outubro",
	"11":"Novembro",
	"12":"Dezembro"
}
