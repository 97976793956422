import React, { useState, useEffect } from 'react';
import { 
	BarChart, 
	Bar, 
	Rectangle, 
	Cell,
	XAxis, 
	YAxis, 
	CartesianGrid, 
	Tooltip, 
	ResponsiveContainer 
} from 'recharts';


const toCurrency = (value) => {
  return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

const formatter = (payload) => {
  return toCurrency(payload?.value);
}

const CustomTooltip = ({ active, payload, label, value }) => {
  if (active && payload && payload.length) {
    console.log(payload[0]);
    return (
        <span className="label" style={{ color: 'white', fontWeight: 500, fontSize: '1.0rem' }}>
          {
            payload[0].payload.type === "received" ? formatter(payload[0]) : `-${formatter(payload[0])}`
          }
        </span>
    );
  }
  return null;
};


function TotalBalanceMonthBarChart({color, data}){
	const colors = [
		'white', 
		'white', 
		color, 
		'white', 
		'white'
	];

  return (
    <div>
      <BarChart
        width={150}
        height={100}
        data={data}
      >
        <XAxis 
          dataKey="name" 
          tick={{ fill: 'white' }}
          tickLine={false}
        />
        <Tooltip position={{y: -5}} content={<CustomTooltip />} />
        <Bar dataKey="uv" barSize={15} height={60} 
          fill='white' 
          onMouseOver={() => null}
        >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index]} />
        ))}
        </Bar>
      </BarChart>
    </div>
  );
};

export default TotalBalanceMonthBarChart;

