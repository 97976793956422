import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Highcharts from 'highcharts'
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from 'highcharts-react-official'
import popupIco from 'popup-ico.svg';
import {popupCenter} from './popup/popupCenter'
import moment from 'moment';
import { Divider, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

window.moment = moment;
var formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});
Highcharts.setOptions({
    colors: ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"]
});
const range = ( a , b ) => Array.from( new Array( b > a ? b - a : a - b ), ( x, i ) => b > a ? i + a : a - i );
HighchartsMore(Highcharts);
export default class FlowChart extends React.Component {
    
    state ={
        currentSituation: "cash_flow",
        activeRow: {
            formula: {},
            area: {},
            param: {},
            cost_center: {}
        },
        dataLabels: {},
        legendData: {
            data1: this?.props?.principalDataLabel?.replace("-range", ""),
            data2: this?.props?.secondaryDataLabel?.replace("-range", "")
        },
        chartIsVisible: true,
        areasDict: {},
        activeCosts: {},
        formulaIsVisible: {},
        balanceRows: {},
        costCentersFetched: {},
        despesasType: {},
        costCenterMonthReports: {},
        reports: {},
        currentFormulaData: [],
        currentColumnId: null,
        
        options: {
            yAxis: {
                stackLabels: {
                    style: {
                        color: 'black'
                    },
                    enabled: true,
                    y: -30
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    pointPadding: 0,
                    groupPadding: 0,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
          chart: {
                type: 'waterfall'
            },

            title: {
                text: `${this.props.currentMonth}`
            },

            xAxis: {
                type: 'category'
            },

            yAxis: {
                title: {
                    text: 'R$'
                }
            },

            legend: {
                enabled: false
            },

            tooltip: {
                pointFormat: '<b>${point.y:,.2f}</b> R$'
            },

            series: [{
                upColor: "#65BCFF",
                color: "#F7999C",
                data: [{
                    name: 'Start',
                    y: 120000
                }, {
                    name: 'Product Revenue',
                    y: 569000
                }, {
                    name: 'Service Revenue',
                    y: 231000
                }, {
                    name: 'Positive Balance',
                    color: Highcharts.getOptions().colors[1]
                }, {
                    name: 'Fixed Costs',
                    y: -342000
                }, {
                    name: 'Variable Costs',
                    y: -233000
                }, {
                    name: 'Balance',
                    isSum: true,
                    color: Highcharts.getOptions().colors[1]
                }],
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return formatter.format(this.y);
                    },
                    enabled: true,
                    // y: -70,
                    style: {
                        fontWeight: 'bold',
                        textShadow: 'none',
                        color: 'black'
                    }
                },
                pointPadding: 0
            }]
        }
    }

    async showDataFor(id, formula, currentTotal){
        let self = this;
        this.setState({
            currentColumnId: id,
            currentFormula: formula,
            currentTotal
        })

        let formulaData = await axios.get(`/chart_formulas/${id}.json`)
        this.setState({currentFormulaData: formulaData.data})
        
    }

    setupChartData(situation="cash_flow"){
        let self = this;
        this.setState({chartIsVisible: false})
        let {comparedLabel} = this.props;
        
        let { principalDataLabel, secondaryDataLabel, isRange, chartData, currentMonth } = this.props;
        
        // if (isRange.toString() === "true") {
        //     principalDataLabel = principalDataLabel + "-range"
        //     secondaryDataLabel = secondaryDataLabel + "-range"
        // }

        let newChartData = {}
        let newChartDataFirstMonth = {}   
        
        Object.entries(chartData).map(([key, value])=>{
            newChartData[key] = eval(value?.[currentMonth]) * -1
            try{
                newChartDataFirstMonth[key] = eval(value?.["janeiro"]) * -1
            }catch(e){

            }
            
        })
        debugger
        let entries = Object.values(this.props?.chart?.[situation])
        let totalYvalue = 0
        
        let data = entries.map(([label, formula, id], i)=>{

            try{
                let alreadyUpdated = false
                let newFormula = formula
                let newFormulaComponents = newFormula.split(' ').map((x)=> x.trim() )
                let replaceData = newChartData
                if (i == 0 && currentMonth == "total"){
                    replaceData = newChartDataFirstMonth
                }
                Object.entries(replaceData).map(([key, value])=>{
                   
                    if (value.toString().search("Infinity") > -1) {
                        if(newFormulaComponents.includes(String(key))){
                            newFormula = newFormula.replace(`${key}`, "0")
                        }else{
                            newFormula = newFormula.replace(`[${key}]`, "0")
                        }
                        
                    }else{
                        
                        
                        if(newFormulaComponents.includes(String(key)) && !alreadyUpdated ){
                            newFormula = newFormula.replace(`${key}`, parseFloat(value) * -1)    
                            alreadyUpdated = true
                        }
                        
                    }
                    
                })
                
                let yValue = eval(newFormula)
                
                if(typeof yValue != "number"){
                    yValue = 0
                }
                totalYvalue += yValue
                return {
                    events:{
                        click: ()=> self.showDataFor(id, formula, yValue)
                    },
                    color: i == 0 ? "#617e94" : null,
                    name: label,
                    y: yValue.toString() == "NaN" ? 0 : yValue,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return formatter.format(this.y);
                        },
                        enabled: true,
                        verticalAlign: yValue > 0 ? 'top' : 'bottom',
                        y: yValue > 0 ? -24 : 24,
                        style: {
                            fontWeight: 'bold',
                            textShadow: 'none',
                            color: 'black'
                        }
                    },
                }
            }catch(e){
                debugger
            }
            
        })
        
        data.push({
            color: "#617e94",
            isSum: true,
            name: "Caixa Final",
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return formatter.format(this.y);
                },
                enabled: true,
                verticalAlign: totalYvalue > 0 ? 'top' : 'bottom',
                y: totalYvalue > 0 ? -24 : 24,
                style: {
                    fontWeight: 'bold',
                    textShadow: 'none',
                    color: 'black'
                }
            },
        })
        data = data.filter((x)=> x /* só dados validos */)

        
        this.setState((oldState)=>{
            oldState.options.series[0].data = data
            oldState.dataIsSetted = true
            return {...oldState}
        })
        setTimeout(()=>{
            this.setState({chartIsVisible: true})
        }, 1)
        
        this.forceUpdate()
    }

    renderHighChart(){
        const {options, dataIsSetted} = this.state;
        
        return dataIsSetted && <HighchartsReact
                highcharts={Highcharts}
                options={options}
              />   
    }

    getIndicatorLine(indicator_months, data_label ){
        const {principalDataLabel, startDate, endDate} = this.props
        let {legendData} = this.state;
        let total = 0;
        
        let legend = legendData[data_label]
        
        if(principalDataLabel.search("range") >= 0){
            let legend_str = legend.replace("-range", "")
            
            let startMonth = moment(startDate[legend_str]).toDate().getMonth() + 1
            let endMonth = moment(endDate[legend_str]).toDate().getMonth() + 1
            
            for (let month = startMonth; month <= endMonth; month++) {
                // console.log(monthsDict[month])
                let monthValue = parseFloat(indicator_months[monthsDict[month]])
                if(monthValue > 0){
                    total += monthValue
                }
            }
            
        }else{
            total =  indicator_months[monthsDict[this.getCurrentMonth(data_label)]]
        }
        
        return total

        
    }

    getBalanceSheetLineValue(line, data_label){
        const {principalDataLabel, startDate, endDate} = this.props
        let {legendData} = this.state;
        let total = 0;
        
        let legend = legendData[data_label]
        
        if(principalDataLabel.search("range") >= 0){
            let legend_str = legend.replace("-range", "")
            
            let startMonth = moment(startDate[legend_str]).toDate().getMonth() + 1
            let endMonth = moment(endDate[legend_str]).toDate().getMonth() + 1
            
            for (let month = startMonth; month <= endMonth; month++) {
                total += line.months[monthsDict[month]]
            }
            
        }else{
            total =  line.months[monthsDict[this.getCurrentMonth(data_label)]]
        }
        
        return total

        
    }

    async toggleActive(id, activeType){
        try{
            const {activeRow} = this.state;
            this.setState({activeRow: {
                ...activeRow,
                [activeType]: {...activeRow[activeType], [id]: !activeRow[activeType][id]}}
            })
        }catch(e){}
        
    }


    async fetchDataForFormula(formula){
        
        this.toggleActive(formula.fr_id, "formula");
        ["data1", "data2"].map(async (data_label)=>{
            
            let year = this.getCurrentYear(data_label)
            const {principalDataLabel, secondaryDataLabel} = this.props
            let is_budget = null;
            
            if (secondaryDataLabel.search("budget") >= 0 && data_label == "data2") {
                is_budget = true
            }

            let params = {formula_ids: [formula.fr_id], year: year, is_budget}
            let formulaData = await axios.get(`/results/dre_cc.json`, {params})
            // this.toggleFormula(formula)
            
            this.setState((oldState)=>{
                oldState.reports[[data_label, formula.id]] = formulaData.data
                return {...oldState}
            })
            try{
                let costCenterMonthData = await axios.get(`/results/cost_centers.json`, {params})
                this.setState((oldState)=>{
                    oldState.costCenterMonthReports[[data_label, year]] = costCenterMonthData.data
                    return {...oldState}
                })
            }catch(e){
                // console.log("ERROOO", e)
                
            }
        })
         this.setState((oldState)=>{
            oldState.formulaIsVisible[formula.id] = !oldState.formulaIsVisible[formula.id]
            return {...oldState}
        })
    }

    componentDidMount(){
        this.fetchAreas()
        if(this.props.popup){
            this.setupChartData()
        }
        let {principalDataLabel, secondaryDataLabel} = this.props
        let {analisadoDate, budgetDate, comparadoDate, isRange, startDate, endDate} = this.props
        let dataDates = {}
        
        let dataLabels = {}
        
        this.setState({
            dataLabels,
            dataDates
        })
    }

    getCurrentMonth(data_label){
        let {endDate, startDate, isRange} = this.props;
        let {legendData} = this.state;
        if(isRange.toString() === "true"){
            data_label = data_label.replace("-range", "")
            return moment(endDate[legendData[data_label]]).toDate().getMonth() + 1;
        }else{
            return moment(this.state.dataDates[data_label]).toDate().getMonth() + 1;
        }
        
    }

    getCurrentYear(data_label){
        let {endDate, startDate, isRange} = this.props;
        let {legendData} = this.state;
        
        if(isRange.toString() === "true"){
            data_label = data_label.replace("-range", "")
            return moment(endDate[legendData[data_label]]).toDate().getFullYear();
        }else{
            return moment(this.state.dataDates[data_label]).toDate().getFullYear();    
        }
        
    }
    
    async fetchAreaData(key, fixed, type_id, type_label){
        
        
        ["data1", "data2"].map(async (data_label)=>{
            let year = this.getCurrentYear(data_label)

            const {costCentersFetched} = this.state;
            const {cost_center_heads} = this.props;
            let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"

            
            if(!costCentersFetched[data_label]){
              costCentersFetched[data_label] = {}
            }

            Object.entries(cost_center_heads).map(([id, label])=>{
              if(!costCentersFetched[data_label][label]){
                costCentersFetched[data_label][label] = {}
              }
            })
            
            if(costCentersFetched[data_label][type_label][key] && costCentersFetched[data_label][type_label][key][fixed_label]){
              this.setState((oldState)=>{
                oldState.costCentersFetched[data_label][type_label][key][fixed_label] = null
                return {...oldState}
              })
            }else{
              let details = await axios.get(`/results/${key}/cost_center_detail.json`, {params: {
                  year: year,
                  fixed: Boolean(fixed),
                  type: type_id,
                  is_budget: this.state.legendData[data_label].search("budget") >= 0 ? true : false
              }})
              
              this.setState((oldState)=>{
                
                if(!oldState.costCentersFetched[data_label][type_label][key])
                { oldState.costCentersFetched[data_label][type_label][key] = {} }

                if (!oldState.costCentersFetched[data_label][type_label][key][Object.keys(details.data)[0]])
                { oldState.costCentersFetched[data_label][type_label][key][Object.keys(details.data)[0]] = {}}
                
                oldState.costCentersFetched[data_label][type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]
                
                return {...oldState}
              })
            }
        })

    }

    toggleDespesasType(key, year){
        let {cost_center_heads} = this.props;
        let [area_id, is_fixed] = key
        this.toggleActive(key, "area");
        Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
          this.fetchAreaData(area_id, is_fixed, despesa_type_id, label)  
        })
        
        
        this.setState((oldState)=>{
          oldState.despesasType[key] = !oldState.despesasType[key]
          return {...oldState}
        })
    }

    async getBalanceRows(key){
        this.toggleActive(key, "param");
        ["data1", "data2"].map(async (data_label)=>{
            let year = this.getCurrentYear(data_label)
            let details = await axios.get(`/results/${key}/detail.json`, {params: {
                  year: year,
                  cost_center_mode: true,
                  is_budget: this.state.legendData[data_label] == "budget" ? true : false
              }})

            this.setState((oldState)=>{
                oldState.balanceRows[[data_label, year,key]] = Object.values(details.data)[0]
                return {...oldState}
            })
        })
    }

    numberToCurrency = function(number=0) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }
      
      return parseFloat(number * -1).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };

    getMonthRange(data, label, is_fixed, data_label){
        
        try{
            let {isRange, startDate, endDate} = this.props;
            let {legendData} = this.state;
            
            if(isRange.toString() === "true"){
                let legend = legendData[data_label]
                
                let firstMonth = moment(startDate[legend]).toDate().getMonth() + 1
                let endMonth = moment(endDate[legend]).toDate().getMonth() + 1
                let result = 0
                range(firstMonth, endMonth + 1).map((month_i)=>{
                    result += parseFloat(data[`["${label}", ${is_fixed}, ${month_i}]`])
                })
                return this.numberToCurrency(result)
            }else{
                return this.numberToCurrency(data[`["${label}", ${is_fixed}, ${this.getCurrentMonth(data_label)}]`])
            }
        }catch(e){
            return 0
        }
        
    }
    
    getFormatedFormula(){
        let {chartData, principalDataLabel, secondaryDataLabel} = this.props;
        let {currentFormula, currentFormulaData, currentTotal} = this.state 
        if(this.props.isRange){
            principalDataLabel = principalDataLabel.replace("-range", "") + "-range"
            secondaryDataLabel = secondaryDataLabel.replace("-range", "") + "-range"
        }
        
        let removeDataStr = currentFormula.replace("data1", principalDataLabel ).replace("data2", secondaryDataLabel)

        let formulaData = {}
        
        Object.values(currentFormulaData).map((arr)=>{
            arr.map((el)=>{
                formulaData[el.fr_id] = el.label
            })
        })

        Object.entries(chartData).map(([key,value])=>{
            let [form_id, type] = JSON.parse(key)

            removeDataStr = removeDataStr.replace(key, `${formulaData[form_id]}-${translate[type]}(${this.numberToCurrency(value)})`)
        })

        let upColor = "#00529c"
        let color =  "#ca2149"

        return <div style={{
                    fontSize: 17,
                    color: currentTotal > 0 ? upColor : color}}>
                {removeDataStr + " = " + this.numberToCurrency(currentTotal)}
                </div>
    }
    
    getFormattedDataLabel(data_label, withoutRange=false){
        let {legendData} = this.state
        if(this.props.isRange.toString() === "true" && !withoutRange){
            return legendData[data_label].replace("-range", "") + "-range"
        }else{
            return legendData[data_label].replace("-range", "")
        }
    }

    getChartData(fr_id, data_label, number_format="money"){
        let {chartData} = this.props;
        let {legendData} = this.state
        let legended_data_label = this.getFormattedDataLabel(data_label)
        
        if (number_format == "money" || number_format ==  null) {
            return this.numberToCurrency(chartData[`["${fr_id}", "${legended_data_label}"]`])    
        }else{
            return this.integerFormat(chartData[`["${fr_id}", "${legended_data_label}"]`])    
        }
        
    }
    
    getCostCenterLineData(reports, data_label, formula, key){
        
        let {isRange, startDate, endDate} = this.props;
        let {legendData} = this.state;
        
        if(isRange.toString() === "true"){
            let legend = legendData[data_label]
            
            let firstMonth = moment(startDate[legend]).toDate().getMonth() + 1
            let endMonth = moment(endDate[legend]).toDate().getMonth() + 1
            
            let result = 0
            range(firstMonth, endMonth + 1).map((month_i)=>{
                try{
                    result += parseFloat(reports[`${data_label},${formula.id}`]["report"][formula.label][formula.label].params[key][monthsDict[month_i]].difference)
                }catch(e){
                    
                }
                
            })
            return this.numberToCurrency(result * -1) 
        }else{
            try{
                return this.numberToCurrency(reports[`${data_label},${formula.id}`]["report"][formula.label][formula.label].params?.[key]?.[monthsDict[this.getCurrentMonth(data_label)]].difference * -1)
            }catch(e){
                return 0
            }
            

        }
        
    }


    getCostCenterDetailMonths(cost_center, data_label){
        let {isRange, startDate, endDate} = this.props;
        let {legendData} = this.state;
        
        try{
            if(isRange.toString() === "true"){
                let legend = legendData[data_label]
                
                let firstMonth = moment(startDate[legend]).toDate().getMonth() + 1
                let endMonth = moment(endDate[legend]).toDate().getMonth() + 1
                let result = 0
                range(firstMonth, endMonth + 1).map((month_i)=>{
                    result += this.convertIntoNumber(cost_center.months[month_i])
                })
                return result
            }else{
                return cost_center.months[this.getCurrentMonth(data_label)]    
            }
        }catch(e){
            return 0
        }
    }
    integerFormat(number, title){
        if (title) {
            return new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(number).toFixed(2) )
        }else{
            return new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(number).toFixed(2)  * -1)
        }
        
    }

    differenceBetweenData(data1, data2, type="money"){
        // Não me pergunte porque fiz isso, pra mim isso não tem lógica, foi o cliente que pediu assim
        // Ultima alteração 19/02 e o céu está aberto, com algumas nuvens, acho que chove hoje
        
        
        try{
            if(data1.toString().search(',') == -1){
                data1 = parseFloat(data1.toString().split("R$").join("").replace(/ /g,'').split(" ").join(''))    
            }else{
                data1 = parseFloat(data1.toString().split(".").join("").split("R$").join("").split(",").join(".").replace(/ /g,'').split(" ").join(''))    
            }
            
            if(data2.toString().search(',') == -1){
                data2 = parseFloat(data2.toString().split("R$").join("").replace(/ /g,'').split(" ").join(''))    
            }else{
                data2 = parseFloat(data2.toString().split(".").join("").split("R$").join("").split(",").join(".").replace(/ /g,'').split(" ").join(''))                    
            }
            
            
            
            let difference = (data1 - data2)
                
                
            
            if (type == null || type== "money") {
                return parseFloat(difference).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                });
                    
            }else if (type == "integer"){
                return this.integerFormat(difference)
            }
            
            
        }catch(e){
            return 0
        }
    }

    otherDataLabel(data_label){
        if (data_label == "data1") {
            return "data2"
        }
        if (data_label == "data2") {
            return "data1"
        }
    }

    renderCostCenterDetail(level, data_label, label, x){
        const {currentFormulaData, costCentersFetched} = this.state;
        try{
           return <React.Fragment>
                {costCentersFetched[data_label] && costCentersFetched[data_label][label] && costCentersFetched[data_label][label][x[0].area.id] && Object.entries(costCentersFetched[data_label][label][x[0].area.id][x[0].is_fixed ? `fixed` : `not_fixed`]).map(([id, cost_center])=>{
                     return <tr className={'borboleta cost-center-row'}>
                        <td  style={{textIndent: (level * 8) + 50}}>{id}-{cost_center.label}</td>
                        <td>{this.numberToCurrency(this.getCostCenterDetailMonths(cost_center, data_label))}</td>
                        {Object.keys(currentFormulaData).map((key)=>(
                            key != data_label &&  
                            <React.Fragment>
                                <td>{costCentersFetched?.[key]?.[label] && costCentersFetched[key][label][x[0].area.id] && costCentersFetched[key][label][x[0].area.id][x[0].is_fixed ? `fixed` : `not_fixed`][id] && this.numberToCurrency(this.getCostCenterDetailMonths(costCentersFetched[key][label][x[0].area.id][x[0].is_fixed ? `fixed` : `not_fixed`][id], key))}</td>
                                <td>
                                    {this.differenceBetweenData(this.numberToCurrency(this.getCostCenterDetailMonths(cost_center, data_label)), this.numberToCurrency(this.getCostCenterDetailMonths(costCentersFetched?.[key]?.[label]?.[x[0].area.id]?.[x[0].is_fixed ? `fixed` : `not_fixed`]?.[id], key)))}
                                </td>
                            </React.Fragment>
                        ))}
                    </tr>
                })}
            </React.Fragment> 
        }catch(e){
            
        }
        
    }

    async fetchAreas() {
    
        let result = await axios.get(`/cost_center_areas.json`, {
          params: {
            query: this.state.query
          }
        });

        let hash_data = {}
        
        result.data.map((area)=>{
          hash_data[area.id] = area.label
        })
        
        this.setState({areasDict: hash_data })  
        
    }

    toggleCost(label, area){
        this.toggleActive(`${area},${label}`, "cost_center")
        this.setState((oldState)=>{
            oldState.activeCosts[label] = !oldState.activeCosts[label]
            return {...oldState}
        })
    }

    costIsActive(label){
        return this.state.activeCosts[label]
    }

    
    convertIntoNumber(n=0){
        if((parseFloat(n) != NaN) || parseFloat(n) != undefined){
            return parseFloat(n)
        }else{
            return 0
        }
    }

    renderFormula(formula, data_label, alreadLoaded, level=0){
        const {options,
             reports,
             dataLabels,
             costCenterMonthReports,
             despesasType,
             costCentersFetched,
             balanceRows,
             currentFormula,
             legendData,
             currentFormulaData,
             formulaIsVisible,
             areasDict
         } = this.state;
        let {open, handleClose, chartData, indicatorsMap} = this.props
        let formulas = []
        let indicators = {}
        let indicators_od = {}
        let self_indices = {}
        let self_params = {}
        let self_cost_centers = {}
        let params = {}
        try{
            let data = reports[`${data_label},${formula.id}`]["report"][formula.label][formula.label]
            formulas = Object.entries(data.formulas)
            
            params = data.params
            
            self_indices = data.self_indices
            self_params = data.self_params
            self_cost_centers = data.self_cost_centers
            
            

        }catch(e){}
            
        try{
            indicators = reports[`${data_label},${formula.id}`]["report"][formula.label][formula.label].indicators || {}
            indicators_od = reports[`${this.otherDataLabel(data_label)},${formula.id}`]["report"][formula.label][formula.label].indicators || {}
        }catch(e){
            indicators = {}
            indicators_od = {}
        }
        
        return <React.Fragment> {!alreadLoaded && (<React.Fragment>{level == 0 && <thead><tr className={'first-line'}>
                                                                <th onClick={()=> this.fetchDataForFormula(formula)}>
                                                                    
                                                                </th>
                                                                <th>{this.props.title['data1']}</th>
                                                                <th>{this.props.title['data2']}</th>
                                                                <th>Diferença</th>
                                                                
                                                            </tr></thead>}
                                                            <tr id="principal" className={`formula-${formula.formula_type} formula-${formula.formula_type}-${formula.layout} format-${formula.number_format} formula-${formula.is_only_index ? "only-index" : "more-than-index"}`} >
                                                                <td style={{textIndent: level * 8}} onClick={()=> this.fetchDataForFormula(formula)}>
                                                                    <Chevron active={this.state.activeRow["formula"][formula.fr_id]}/>
                                                                    {formula.label}
                                                                </td>
                                                                <td>{this.getChartData(formula.fr_id, data_label, formula.number_format)}</td>
                                                                {Object.keys(currentFormulaData).map((key)=>(
                                                                    key != data_label && currentFormulaData[key].length > 0 
                                                                    &&  
                                                                    <React.Fragment>
                                                                        <td>{this.getChartData(formula.fr_id, key, formula.number_format)}</td>
                                                                        <td>{this.differenceBetweenData(this.getChartData(formula.fr_id, data_label), this.getChartData(formula.fr_id, key), formula.number_format)}</td>
                                                                    </React.Fragment>
                                                                ))}
                                                                
                                                                
                                                            </tr></React.Fragment>)}
                    {formulaIsVisible?.[formula.id] && <React.Fragment>
                        {reports[`${data_label},${formula.id}`] && formulas.length == 0 && Object.values(reports[`${data_label},${formula.id}`]["report"]).length > 0 && reports[`${data_label},${formula.id}`]["report"]?.[formula.label]?.[formula.label]['cost_center_params'].ids.map((x)=>{

                            return <React.Fragment>
                                        <tr className={'cost-center-area'} onClick={()=> this.toggleDespesasType([x[0].area.id, x[0].is_fixed], this.getCurrentYear(data_label))}>
                                            <td style={{textIndent: (level * 8) + 5}} >
                                                <Chevron active={this.state.activeRow['area'][`${x[0].area.id},${x[0].is_fixed}`]}></Chevron>
                                                {areasDict?.[x[0].area.id]} {x[0].is_fixed ? "Fixo" : "Variável"}
                                            </td>
                                            <td>{
                                                this.getMonthRange(costCenterMonthReports?.[`${data_label},${this.getCurrentYear(data_label)}`], areasDict?.[x[0].area.id], x[0].is_fixed, data_label)
                                            }</td>
                                            {Object.keys(currentFormulaData).map((key)=>(
                                                key != data_label && 
                                                currentFormulaData[key].length > 0 &&  
                                                
                                                    costCenterMonthReports[`${key},${this.getCurrentYear(key)}`] && 
                                                        
                                                        <React.Fragment>
                                                            <td>{this.getMonthRange(costCenterMonthReports[`${key},${this.getCurrentYear(key)}`], areasDict?.[x[0].area.id], x[0].is_fixed, key)}</td>
                                                            <td>{this.differenceBetweenData(
                                                                    this.getMonthRange(costCenterMonthReports?.[`${data_label},${this.getCurrentYear(data_label)}`], areasDict?.[x[0].area.id], x[0].is_fixed, data_label),
                                                                    this.getMonthRange(costCenterMonthReports?.[`${key},${this.getCurrentYear(key)}`], areasDict?.[x[0].area.id], x[0].is_fixed, key)
                                                                                            )}</td>
                                                        </React.Fragment>
                                                    
                                                
                                                

                                            ))}
                                        </tr>
                                        {despesasType[[x[0].area.id, x[0].is_fixed]] && Object.entries(this.props.cost_center_heads).map(([key, label])=>{
                                            let comparado_key = Object.keys(currentFormulaData).filter((x)=> x != data_label)[0]
                                            let analisado = "0";
                                            let comparado = "0";
                                            
                                            try{
                                                
                                                
                                                analisado = costCentersFetched[data_label][label] && costCentersFetched[data_label][label][x[0].area.id] && this.numberToCurrency(Object.entries(costCentersFetched[data_label][label][x[0].area.id][x[0].is_fixed ? `fixed` : `not_fixed`]).map(([id, cost_center])=>{return this.getCostCenterDetailMonths(cost_center, data_label)}).reduce((a,b)=> this.convertIntoNumber(a) + this.convertIntoNumber(b), 0))
                                                comparado = costCentersFetched[comparado_key][label] && costCentersFetched[comparado_key][label][x[0].area.id] && this.numberToCurrency(Object.entries(costCentersFetched[comparado_key][label][x[0].area.id][x[0].is_fixed ? `fixed` : `not_fixed`]).map(([id, cost_center])=>{return this.getCostCenterDetailMonths(cost_center, comparado_key)}).reduce((a,b)=> this.convertIntoNumber(a) + this.convertIntoNumber(b), 0)) 
                                                // console.log(costCentersFetched)
                                            }catch(e){
                                                // console.log(e)
                                                // console.log(costCentersFetched)
                                                
                                            }
                                                
                                            return <React.Fragment>
                                                <tr className={'borboleta cost-center-head'}>
                                                    <td onClick={()=> this.toggleCost(label, `${x[0].area.id},${x[0].is_fixed}`)}  style={{textIndent: (level * 8) + 15}}>
                                                        <Chevron active={this.state.activeRow['cost_center'][`${x[0].area.id},${x[0].is_fixed},${label}`]}></Chevron>
                                                        {label.toUpperCase()}
                                                    </td>
                                                    {currentFormulaData && Object.keys(currentFormulaData).map((otherdata_key)=>(
                                                        otherdata_key != data_label && currentFormulaData && currentFormulaData[otherdata_key].length > 0 &&  
                                                        <React.Fragment>
                                                            <td>{analisado}</td>
                                                            <td>{comparado}</td>
                                                            <td>{this.differenceBetweenData(analisado, comparado)}</td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                                {this.costIsActive(label) && this.renderCostCenterDetail(level, data_label, label, x)}
                                            </React.Fragment>
                                        })}
                                    </React.Fragment>
                        })}
                        {reports[`${data_label},${formula.id}`] && reports[`${data_label},${formula.id}`]["labels"] && Object.entries(reports[`${data_label},${formula.id}`]["labels"]).map(([key, value])=>{
                            return (value.toUpperCase() != "RECEITAS") &&  self_params[key] && <React.Fragment>
                            <tr id="balance-param" onClick={()=> this.getBalanceRows(key) }>
                                <td style={{textIndent: (level * 8) + 5}}>
                                    <Chevron active={this.state.activeRow["param"][key]}></Chevron>
                                    {value}
                                </td>
                                <td>{reports[`${data_label},${formula.id}`] && this.getCostCenterLineData(reports, data_label, formula, key)}</td>
                                {Object.keys(currentFormulaData).map((other_data_label)=>(
                                    other_data_label != data_label && 
                                    <React.Fragment>
                                        <td>{reports?.[`${other_data_label},${formula.id}`] && this.getCostCenterLineData(reports, other_data_label, formula, key) }</td>
                                        <td>{this.differenceBetweenData(this.getCostCenterLineData(reports, data_label, formula, key), this.getCostCenterLineData(reports, other_data_label, formula, key))}</td>
                                    </React.Fragment>
                                    
                                ))}
                            </tr>
                            {
                                balanceRows[[data_label, this.getCurrentYear(data_label),key]] && Object.entries(balanceRows[[data_label,this.getCurrentYear(data_label),key]].lines).map(([label_code, line])=>{
                                    return <tr id="balance-sheet-line">
                                        <td style={{textIndent: (level * 8) + 50}}>{line.label}</td>
                                        <td>{this.numberToCurrency(this.getBalanceSheetLineValue(line, data_label))}</td>
                                        {Object.keys(currentFormulaData).map((other_data_label)=>{
                                            let fx = balanceRows[`${other_data_label},${this.getCurrentYear(other_data_label)},${key}`]
                                            
                                            return (fx && fx.lines[label_code] && (other_data_label != data_label)) && 
                                            <React.Fragment>
                                                <td>{this.numberToCurrency(this.getBalanceSheetLineValue(fx.lines[label_code], other_data_label))}</td>
                                                <td>{this.differenceBetweenData(this.numberToCurrency(this.getBalanceSheetLineValue(line, data_label)), this.numberToCurrency(this.getBalanceSheetLineValue(fx.lines[label_code], other_data_label)))}</td>
                                            </React.Fragment>
                                        }
                                        )}
                                    </tr>
                                })
                            }
                            </React.Fragment>
                        })}
                        {   
                            <React.Fragment>
                                {
                                    Object.entries(indicators).map(([indicator_name,indicator_months])=>{
                                    
                                    return self_indices[indicatorsMap[indicator_name]] && <tr id="indicator-row">
                                                <td style={{textIndent: (level * 8) + 5}}>{indicator_name}</td>
                                                <td>{this.integerFormat(this.getIndicatorLine(indicator_months, data_label), true)}</td>
                                                 {Object.keys(currentFormulaData).map((other_data_label)=>{
                                                    
                                                     return  other_data_label != data_label && 
                                                        <React.Fragment>
                                                            <td>{this.integerFormat(this.getIndicatorLine(indicators_od?.[indicator_name], data_label), true)}</td>
                                                            <td>{this.differenceBetweenData(this.getIndicatorLine(indicator_months, data_label), this.getIndicatorLine(indicators_od?.[indicator_name], data_label), "integer")}</td>
                                                        </React.Fragment>
                                                        
                                                })}
                                            </tr>
                                   })
                                }
                            </React.Fragment>
                        }

                        {
                            <React.Fragment>
                                {
                                    formulas.map(([formula_id,formula_data])=>{
                                    
                                    return this.renderFormula(formula_data.data, data_label, alreadLoaded, level + 1)
                                   })
                                }
                            </React.Fragment>
                        }
                    </React.Fragment>}
                    
                </React.Fragment>
    }

    closeTable(){
        this.setState({currentColumnId: null})
    }
    renderContent(){
        const handleChange = (event, newValue) => {
            this.setState({currentTab: newValue});
          };
        let loadedFormulas = {}
        let botBorder = (showStyle) => {
            if (showStyle){
                return {
                    borderBottom: "3px solid",
                    marginBottom: 10,
                    paddingBottom: 10
                }
            }else{
                return {}
            }
            
        }
        const {options,
             reports,
             dataLabels,
             costCenterMonthReports,
             despesasType,
             costCentersFetched,
             balanceRows,
             currentFormula,
             legendData,
             currentFormulaData
         } = this.state;
        return <div style={{display: 'flex', flexDirection: 'column'}}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Tabs TabIndicatorProps={{style: {background:'#298793'}}} value={this.state.currentSituation} onChange={handleChange} >
                    <Tab 
                            
                        onClick={()=> {
                        this.setState({currentSituation: 'cash_flow'})
                        this.setupChartData('cash_flow')
                    }} label={this.props?.titles?.['cash_flow']} value="cash_flow" />
                    <Tab onClick={()=> {
                        this.setState({currentSituation: 'cash_flow_only'})
                        this.setupChartData('cash_flow_only')
                    }} label={this.props?.titles?.['cash_flow_only']} value="cash_flow_only" />
                </Tabs>
            </Box>
            <div style={{width: "100%", ...botBorder(this.state.currentColumnId)}}>
                <h2 style={{textAlign: 'center'}}>{this.props.chartTitle}</h2>
                {this.state.chartIsVisible &&  this.renderHighChart()}    
            </div>
            
            

        </div>
    }
    generatePopUp(){
        let self = this
        axios.post(`/flow_chart_popups.json`, {
            props: self.props
        }).then(function(result){
            popupCenter({url: `/flow_chart_popups/${result.data.popup_id}`, title: 'Gráfico', w: window.innerWidth * 0.8, h: window.innerHeight * 0.8})
        });
    }
    
    render(){
      
      let {open, handleClose, chartData} = this.props
      
      if(this.props.popup){
          return (<div>
              {this.renderContent()}
          </div>)
      }
      else{
      return (
        <div>
          <Dialog
            onEnter={()=> this.setupChartData()}
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"lg"}
            PaperComponent={PaperComponent}
            
          >
            
            <DialogContent>
                {this.renderContent()}
                
            </DialogContent>
            <DialogActions>
              <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <Button onClick={handleClose} 
                    style={{
                        background: "#298793",
                        color: "white", 
                        marginLeft: 62
                    }}>
                        Fechar
                    </Button>
                </div>
                <Button 
                    style={{
                        border: '1px solid',
                        zIndex: 20,
                        visibility: this.state.currentColumnId ? 'visible' : 'hidden',
                        color: "white",
                        background: "#5BB1FE"
                    }}
                    onClick={()=> this.closeTable()} 
                    >
                            <i className="fa fa-chevron-up"></i>
                </Button>
              
              </div>
              
              
              
            </DialogActions>
          </Dialog>
        </div>
      );
    }
    }
}

function Chevron({active}){
    return(
        <i style={{margin: "0 10px"}} className={`fa fa-chevron-${active ? 'down' : 'right'}`}></i>
    )
}

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}


const monthsDict = {
     1: "janeiro",
     2: "fevereiro",
     3: "março",
     4: "abril",
     5: "maio",
     6: "junho",
     7: "julho",
     8: "agosto",
     9: "setembro",
     10: "outubro",
     11: "novembro",
     12: "dezembro",
}

const translate = {
    "analisado": "Real",
    "comparado": "Real",
    "budget": "Orçado"
}