import React from "react";
import LoadingDialog from './../LoadingDialog'
import Comment from './../Comment'
import MonthFilter from './../MonthFilter'

class BalanceSegmentadoByMonth extends React.Component {

  constructor(props){
  	super(props)
  	this.requestsCounter = 0
    this.year = this.props.defaultYear
    let months = {}
    Object.entries(props.months).map(([key, value])=>{
      if(Object.keys(props.visible_months[props.defaultYear]).includes(key)){
        months[key] = value
      }
    })
  	this.state ={
      showZeroLines: false,
  		year: this.props.defaultYear,
      currentYear: this.props.defaultYear,
  		activeBranches: {},
  		loading: false,
  		data: [],
  		years: [],
  		resultIds: [],
  		cash_flow_formula_ids: {},
      activeRows: {},
      companiesData: {},
      visibleMonths: {
        janeiro: true,
        fevereiro: true,
        março: true,
        abril: true,
        maio: true,
        junho: true,
        julho: true,
        agosto: true,
        setembro: true,
        outubro: true,
        novembro: true,
        dezembro: true,
      },
      months: months
  	}
  }

  componentDidUpdate(){
    
    if(this.year && (this.year != this.props.defaultYear)){
       this.changeYear(this.props.defaultYear)
       this.year = this.props.defaultYear
     }
  }

  monthDict(){
    let defaultMonths =  {
      "janeiro": 1,
      "fevereiro": 2,
      "março": 3,
      "abril": 4,
      "maio": 5,
      "junho": 6,
      "julho": 7,
      "agosto": 8,
      "setembro": 9,
      "outubro": 10,
      "novembro": 11,
      "dezembro": 12
    }
    let visibleMonths = {}
    Object.entries(defaultMonths).map(([month_name, month_number])=>{
      
      if(this.state.months[month_name] || month_number == 13){
        visibleMonths[month_name] = month_number
      }
    })
    
    return visibleMonths
  }
  
  async fetchInitialParams(year=null){

    let {companies} = this.props;
  	this.setState({loading: true})
  	let self = this
    Object.values(companies).map((company_id)=>{
        
      self.requestsCounter += 1
      axios.get('/results/balances.json', {params: {
        year: year,
        company_id: company_id,
      }}).then((result)=>{
        self.requestsCounter -= 1
        
        self.setState((oldState)=>{
          oldState.companiesData[company_id] = {}
          oldState.companiesData[company_id]['cash_flow_formula_ids'] = result.data.cash_flow_formula_ids
          oldState.companiesData[company_id]['data'] = result.data.params
          oldState.companiesData[company_id]['result'] = result.data.result
          oldState.companiesData[company_id]['resultIds'] = result.data.result.result_ids
          oldState['cash_flow_formula_ids'] = result.data.cash_flow_formula_ids
          oldState['data'] = result.data.params
          oldState['result'] = result.data.result
          oldState['resultIds'] = result.data.result.result_ids
          return {...oldState}
        })
        
      })
      
    })
	
    
    
  
	
  }

  async setComments(){
    
    let comments = await axios.get('/comments.json', {params:{
      year: [this.state.year, 0]
    }})  
    
    
    this.setState({comments: comments.data})
  }

  async setYears(){
  	this.requestsCounter += 1
  	let years = await axios.get('/balance_sheets/years.json')
  	this.requestsCounter -= 1
  	this.setState({years: years.data})
    this.setComments()
  }

  async defineDefaultVisibleMonths(year){
    let months = {}
    debugger
    Object.entries(this.props.months).map(([key, value])=>{
      if(Object.keys(this.props.visible_months[year]).includes(key)){
        months[key] = value
      }
    })
    
    this.setState({months, visibleMonths: this.props.visible_months[year]});
  }

  async componentDidMount(){
    await this.defineDefaultVisibleMonths(this.state.year);
  	this.setYears()
    this.setComments()
  	this.fetchInitialParams(this.state.year)
  }

  numberToCurrency(number){
  	if(number == 0 || number){
  		return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })	
  	}
  	return "-"
	
  }
  removeAllChildren(row, rows){
    if(row.childreen_attributes.length > 0){
      rows[row.id] = false
      row.childreen_attributes.map((child)=>(this.removeAllChildren(child, rows)))
      
    }else{
      rows[row.id] = false
    }
  }

  toggleRow(parent, row){
    
  	let rows = this.state.activeBranches
  	rows[row.id] = !rows[row.id]
    if(!rows[row.id]){
      this.removeAllChildren(row, rows)  
    }
    
  	this.setState({activeBranches: rows})
  }

  branchIsActive(row){
  	return this.state.activeBranches[row.id]
  }

  sumResults(values){
  	let result = Object.values(Object.values(values)).reduce((a,b)=> parseFloat(a)+ parseFloat(b), 0)
  	return this.numberToCurrency(result)
  	// return 0
  }

  renderChildreen(contabilParam, childreen_attributes=[], level=0){
    const {currentMonth, companies} = this.props;
  	const {months} = this.state;
  	const {result, resultIds, cash_flow_formula_ids, companiesData} = this.state;
  	let childreen = null
  	
	 childreen = childreen_attributes.map((child)=> {
     let total = 0
		return (<React.Fragment>
          
					<tr>
						<td className={'comment-wrapper'} style={{paddingLeft: 20 + (10 * level)}}  onClick={()=> this.toggleRow(contabilParam, child)}>

              {this.state.comments && <Comment  
                refreshComments={()=> this.setComments()}
                comments={this.state.comments}
                model_reference={'contabil_pattern'}
                month={0}
                year={0}
                model_reference_id={child.key}>
              </Comment>}
							<div style={{marginRight: 10, display: 'inline-block'}}>

	    						{!child.is_tail && <i className={`fa fa-chevron-${this.branchIsActive(child) ? 'down' : 'right'}`}/>}
	    					</div>
							{child.label} {cash_flow_formula_ids[child.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
						</td>
            {
            Object.entries(this.monthDict()).map(([monthName, monthNumber])=>{
                let total = 0
                Object.values(companies).map((company_id)=>{                
                        if(companiesData?.[company_id]?.result && companiesData?.[company_id]?.result.params[child.id][monthName]){
                        total += companiesData?.[company_id]?.result.params[child.id][monthName]
                        }
                                
              
                })
                return  <td>{this.numberToCurrency(total)}</td>
            })
            }
              
			        </tr>
			        {this.branchIsActive(child) && <React.Fragment>
			        	{this.renderChildreen(child, child.childreen_attributes, (level + 1))}  					    
			        </React.Fragment>}
					
				</React.Fragment>)
        	
    })
  	
  	
  	return childreen
  	
  }

  async changeYear(year){
    await this.defineDefaultVisibleMonths(year)
  	this.setState({year})
  	this.fetchInitialParams(year)
  	// this.props.changeYear(year)
  }

  checkMonth(month_name, company_id){
  	let checagem = 0;
  	const {data, result} = this.state;

  	this.state.data && this.state.data.map((contabilParam)=>{
  		checagem += this.state.companiesData?.[company_id]?.result.params[contabilParam.id][month_name]
  	})
  	
  	return checagem;
  }

  changeVisibleMonths(event){
    // event.target.name]: event.target.checked
    let {name, checked} = event.target
    let props = this.props;
    this.setState((oldState)=>{
      oldState.visibleMonths[name] = checked
      oldState.months = {}
      Object.entries(oldState.visibleMonths).map(([name, checked])=>{
        if(checked){
          oldState.months[name] = props.months[name]
        }
      })
      
      return {...oldState}
    });  
  }

  numerize(n=0) {
    if(parseFloat(n).toString() == "NaN"){
      return 0
    }else{
      return parseFloat(n)
    }
  }

  render() {


    const {state, currentMonth, companies} = this.props;
    const {data, result, year, years, resultIds, cash_flow_formula_ids, showZeroLines, months, companiesData} = this.state;

    

    return (
    	<React.Fragment>
	        <div id="table-result-wrapper">
	    		<div className="content ">
		            <table id="table-result" className={"margin-bottom-20 margin-40  without-acumulado balance-table"}>
		                <thead>
		                    <tr>
		                        <th>
		                            Nome
		                        </th>
                                {Object.entries(this.monthDict()).map(([name, numero]) => (
                                    <th>{name}</th>
                                ))}
		                        
		                    </tr>
		                </thead>
		                
		                	{data.map((contabilParam)=>{
                                let total = 0
		                		return <tbody key={contabilParam.id}>
			                				<tr id="principal">
				                				<th className={'comment-wrapper'}>
                                  {this.state.comments && <Comment  
                                    refreshComments={()=> this.setComments()}
                                    comments={this.state.comments}
                                    model_reference={'contabil_pattern'}
                                    month={0}
                                    year={0}
                                    model_reference_id={contabilParam.key}>
                                  </Comment>}
				                					{contabilParam.label} {cash_flow_formula_ids[contabilParam.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
				                				</th>
                                    {Object.entries(this.monthDict()).map(([monthName, monthNumber])=>{
                                        let total = 0
                                        Object.values(companies).map((company_id) => {
                                            if(companiesData?.[company_id]?.result){
                                                total += this.numerize(companiesData?.[company_id]?.result.params[contabilParam.id][monthName])
                                            }   
                                        })
                                        
                                        return <th className={'comment-wrapper'}>
                                            {this.numberToCurrency(total)}
                                        </th>
                                    })}
						                      
						                    </tr>
                                
                                  {!(result && resultIds.includes(contabilParam.id)) && this.renderChildreen(contabilParam, contabilParam.childreen_attributes)}
                                
						                    
						                    
				                       </tbody>
		                	})}
		                	<tbody>
	                		<tr>
		                    	<td>Checagem</td>
                            
                            {
                                Object.entries(this.monthDict()).map(([monthName, monthNumber])=>{
                                    let total = 0
                                    Object.values(companies).map((company_id) => {	
                                        total += this.numerize(this.state.companiesData?.[company_id]?.result ? this.checkMonth(monthName, company_id) : 0)
                                    })
                                    return <td>
                                                {this.numberToCurrency(total)}
                                        </td>    
                                })
                            }
		                        
		                    </tr>
		                    </tbody>
		            </table>
		        </div>
		     </div>		
		     <LoadingDialog open={this.requestsCounter > 0}/>	
    </React.Fragment>);
  }
}

export default BalanceSegmentadoByMonth;



const monthDictByName = {
  "janeiro": 1,
  "fevereiro": 2,
  "março": 3,
  "abril": 4,
  "maio": 5,
  "junho": 6,
  "julho": 7,
  "agosto": 8,
  "setembro": 9,
  "outubro": 10,
  "novembro": 11,
  "dezembro": 12,
}
