import React from "react";
import BtnAdd from 'add.svg';
import BtnRm from 'remove.svg';

 class AddMoreInput extends React.Component {
    state = {
        inputList:[],
        setinputList: []
    }

    isInitialset(){
        console.log("isInitialset")
        let inputList = [{email:'', name:''}]
        let setinputList = [{email:'', name:''}]
        this.setState({ inputList: inputList });
        this.setState({ setinputList: setinputList });
    }

    handleinputchange = (e, index) => {
        debugger
        const {name, value}= e.target;
        const list = this.state.inputList;
        //list[index][name]= value;
        list[index][name.replace(/[^a-z]/g, '')]= value;
        //+ index.toString()
        this.setState({
            setinputList: list
        })
    }

    handleremove = (index)=>{
        const list=[...this.state.setinputList];
        list.splice(index,1);
        this.setState({
            setinputList: list
        })
    }

    handleaddclick=()=>{
        let self = this
        self.setState(  {
            setinputList: [ ...self.state.inputList, { email:'', name:''}  ]
        })

    }

     componentDidMount(){
         this.isInitialset()
     }

     render(){
        const {inputList,setinputList} = this.state;

        return (
            <div>
                {
                        setinputList.map((item, index) => {
                            return(
                                <div className={"row mb-3"}>
                                    <div className={"form-group col-md-4"}>
                                        <label htmlFor="email">Email</label>
                                        <input type="email" name={"email"+ index.toString()} className={"form-control"}
                                               placeholder="Digite o email" onChange={e => this.handleinputchange(e, index)}/>
                                    </div>
                                    <div className={"form-group col-md-4"}>
                                        <label htmlFor="name">Nome</label>
                                        <input type="text" name={"name"+ index.toString()} className="form-control"
                                               placeholder="Digite o nome" onChange={e =>this.handleinputchange(e, index)}/>
                                    </div>
                                    <div className={"form-group col-md-2 mt-4"}>
                                        {
                                            setinputList.length !== 1 &&
                                            <a title="Remover email" className={'btn pointer'}  style={{color: 'rgb(36,44,86)', fontWeight: "bold"}} onClick={()=>this.handleremove(index)}> <img src={BtnRm} style={{width: 36}}></img> </a>
                                        }
                                        {
                                            setinputList.length - 1 === index &&
                                             <a title="Adicionar mais emails" className={'btn pointer'}  style={{color: 'rgb(36,44,86)', fontWeight: "bold"}} onClick={()=>this.handleaddclick()}> <img src={BtnAdd} style={{width: 36}}></img> </a>

                                        }
                                    </div>

                                </div>


                                );

                        })



                }


            </div>

        );
    }
}
export default AddMoreInput;
