import React from "react";
import Dre from './results/Dre'
import Balance from './results/Balance'
import Flow from './results/Flow'
import {BlueLink} from '../styled_components/default';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BorboletaChart from './BorboletaChart';
import graphIco from 'graph_ico.png';
import popupIco from 'popup-ico.svg';
import LoadingDialog from './LoadingDialog'
import Comment from './Comment'
import BorboletaFormulaChart from './BorboletaFormulaChart'
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
import MonthRangeInput from './MonthRangeInput'
import {popupCenter} from './popup/popupCenter';
import moment from 'moment';
import downloadIco from 'bt_download.svg';
//comment

registerLocale('pt', pt)

class Borboleta extends React.Component {
    constructor(props){
        super(props)
        this.requestsCounter = 0
        this.alreadLoadedDates = {}
        this.state ={
            allYearsData: {},
            requestsRunning: 0,
            year: this.props.initial_year,
            formulas: [],
            chart: [],
            chart_formulas: [],
            chartData: {},
            modalIsOpen: false,
            analisadoDate: null,
            budgetDate: null,
            comparadoDate: null,
            loadedYears: {},
            reportLoadedYears: {},
            rangeReport:{
                analisado: {},
                budget: {},
                comparado: {}
            },
            report: {
                analisado: {},
                budget: {},
                comparado: {}
            },
            budgetReport: {},
            startDate: {
                budget: null,
                analisado: null,
                comparado: null
            },
            endDate: {
                budget: null,
                analisado: null,
                comparado: null
            },
        }
    }
  	

    numberToCurrency = function(number=0) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }
      
      return parseFloat(number * -1).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };

 	activeTab(tab){
 		return this.state.activeTab == tab
	}

    changeYear(year){
        this.setState({year})
    }

    async fetchFormulas(){
        this.requestsCounter += 1
        let formulas = await axios.get('/formulas/dashboard.json')
        this.setState({formulas: formulas.data})
        this.requestsCounter -= 1
    }

    async fetchGraphFormulas(){
        this.requestsCounter += 1
        let req = await axios.get('/charts/situations/borboleta.json')
        let {charts, title} = req.data
        this.requestsCounter -= 1
        this.setState({chart_formulas: charts})
        this.setState({chart_title: title})
    }

    setupRangeMonthFilter(){
       
    }

    setupChartData(){
        let data = {}
        let types = ["analisado", "budget", "comparado"]
        let self = this;
        types.map((type)=>{
                
                Object.entries(self.state.report[type]).map(([form_id, value])=>{
                    try{
                        data[`[\"${form_id}\", \"${type}\"]`] = eval(value)
                    }catch(e){
                        
                    }
                })
                
                Object.entries(self.state.rangeReport[type]).map(([form_id, value])=>{
                    try{
                        data[`[\"${form_id}\", \"${type}-range\"]`] = eval(value)    
                    }catch(e){

                    }
                    
                })
            
        })
        
        this.setState({chartData: data})
    }

    setupInitialDates(){
        let {month, year} = this.props
        
        let lastDate = moment(`${month}-01-${year}`, "MM-DD-YYYY").toDate()
        let lastDateYearBefore = moment(`${month}-01-${year - 1}`, "MM-DD-YYYY").toDate()
        
        let self = this;

        this.setState({analisadoDate: lastDate})
        this.setState({comparadoDate: lastDateYearBefore})
        this.setState({budgetDate: lastDate})

        this.fetchReport(lastDate, null, "analisado")
        this.fetchReport(lastDateYearBefore,null, "comparado")
        this.fetchReport(lastDate, true, "budget")

        this.setState({
            endDate: {
                analisado: lastDate,
                comparado: lastDateYearBefore,
                budget: lastDate,
            }
        })



        let startDate = new Date(lastDate)
        let startDateYearBefore = new Date(lastDateYearBefore)
        startDate.setMonth(0)
        startDateYearBefore.setMonth(0)

        this.setState({
            startDate: {
                analisado: startDate,
                comparado: startDateYearBefore,
                budget: startDate,
            }
        })

        this.fetchReport(lastDate, null, "analisado")
        this.fetchReport(lastDateYearBefore,null, "comparado")
        this.fetchReport(lastDate, true, "budget")
        
        self.requestsCounter += 3
        setTimeout(function(){
            self.fetchDataForRange("analisado", self)
            self.fetchDataForRange("budget", self)
            self.fetchDataForRange("comparado", self)    
        }, 100)
    }

    componentDidMount(){
        
        this.setupInitialDates()
        this.setComments()
        this.setupRangeMonthFilter()
        this.fetchFormulas()
        this.fetchGraphFormulas()
        this.hoverColumnsSetup()
    }

    hoverColumnsSetup(){
        setTimeout(function(){
            $('document').ready(function(e){
                $('tr').hover(function(e){
                    let dataId = $(this).data('id')
                    $(`tr[data-id=${dataId}]`).addClass('hover-row')
                }, function(e){
                    let dataId = $(this).data('id')
                    $(`tr[data-id=${dataId}]`).removeClass('hover-row')
                })
            });
        }, 3000)
        
        
    }
    
    setLoadedYearForReport(year, label, is_budget, callback){
        this.setState({reportLoadedYears: {...this.state.reportLoadedYears, ...{[`${year}-${label}-${is_budget}`]: true} }},callback)
        
    }
    yearIsloadedForReport(year, label, is_budget){
        return this.state.reportLoadedYears[`${year}-${label}-${is_budget}`]
    }

    async fetchReport(date, is_budget=null, entity=null, updatePairs=true){
        let yearAgoDate = new Date(date)
        yearAgoDate.setFullYear(date.getFullYear() - 1)
        let newstate = {}
        newstate[entity + "Date"] = date
        
        if (entity == "analisado" && updatePairs) {
            newstate['budgetDate'] = date
            newstate['comparadoDate'] = yearAgoDate
        }

        this.setState(newstate) 
        let report = {}
        if(this.alreadLoadedDates[`${date}-${is_budget}`]){
            report = this.state.reportsByDate[`${date}-${is_budget}`]
        }else{
            this.requestsCounter += 1
            this.alreadLoadedDates[`${date}-${is_budget}`] = true
            report = await axios.get('/formulas/borboleta.json',{params: {
                date: date,
                is_budget
            }})
            this.setState({reportsByDate: {
                ...this.state.reportsByDate,
                ...{[`${date}-${is_budget}`]: report}
            }})    
            this.requestsCounter -= 1
        }

        let response= this.state.report
        
        this.setState({requestsRunning: this.requestsCounter})
        response[entity] = report.data
        

        this.setState({report: response})  
        this.setupChartData();
        this.setComments();
        if (entity == "analisado") {
            
            this.fetchReport(newstate['budgetDate'], true, 'budget')
            this.fetchReport(newstate['comparadoDate'], null, 'comparado')
        }
        if(entity != 'comparado'){
            this.fetchData(date.getFullYear(), entity, is_budget)    
        }
        
    }

    orcadoVsAnalisado(id, numberFormat){
        try{
            let analisado = parseFloat(eval(this.state.report['analisado'][id]))
            let budget = parseFloat(eval(this.state.report['budget'][id]))

            let value = 0
            if (numberFormat == "percentage") {
                value = ((analisado.toFixed(2) - budget.toFixed(2))) 
            }else{
                value = ((analisado.toFixed(2) / budget.toFixed(2)) * 100) - 100    
            }
            if(String(value) != "NaN"){
                if (numberFormat == "percentage") {
                    if(analisado <=0 && budget <=0){
                        value = value * -1
                    }
                    return `${new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value).toFixed(2))} pp`.replace('.', ',')  
                }else{
                    return `${new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value).toFixed(2))}%`.replace('.', ',')        
                }
                
            }else{
                return "-"
            }
            
        }catch(e){
            console.log(e)
            return "-"
        }
        
    }

    analisadoVsComparado(id, numberFormat){
        try{
            let analisado = parseFloat(eval(this.state.report['analisado'][id]))
            let comparado = parseFloat(eval(this.state.report['comparado'][id]))

            let value = 0
            if (numberFormat == "percentage") {
                value = ((analisado.toFixed(2) - comparado.toFixed(2))) 
            }else{
                value = ((analisado.toFixed(2) / comparado.toFixed(2)) * 100) - 100    
            }
            if(String(value) != "NaN"){
                let fValue = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value).toFixed(2))
                if (numberFormat == "percentage") {
                    if(analisado <=0 && comparado <=0){
                        fValue = parseFloat(fValue) * -1
                    }
                    // fValue = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value * -1).toFixed(2))
                    return `${fValue} pp`.replace('.', ',')    
                }else{
                    return `${fValue}%`.replace('.', ',')        
                }
                
            }else{
                return "-"
            }
            
        }catch(e){
            console.log(e)
            return "-"
        }
    }

    rangeOrcadoVsAnalisado(id, numberFormat){
        try{
            let analisado = parseFloat(eval(this.state.rangeReport['analisado'][id]))
            let budget = parseFloat(eval(this.state.rangeReport['budget'][id]))

            let value = 0
            if (numberFormat == "percentage") {
                value = ((analisado.toFixed(2) - budget.toFixed(2)))  
            }else{
                value = ((analisado.toFixed(2) / budget.toFixed(2)) * 100)  - 100    
            }
            if(String(value) != "NaN"){
                let fValue = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value).toFixed(2))
                if (numberFormat == "percentage") {
                    if(analisado <=0 && budget <=0){
                        fValue = parseFloat(fValue) * -1
                    }
                    // fValue = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value * -1).toFixed(2))
                    return `${fValue} pp`.replace('.', ',')
                }else{
                    return `${fValue}%`.replace('.', ',')    
                }
                
            }else{
                return "-"
            }
            
        }catch(e){
            console.log(e)
            return "-"
        }
        
    }

    rangeAnalisadoVsComparado(id, numberFormat){
        try{
            let analisado = parseFloat(eval(this.state.rangeReport['analisado'][id]))
            let comparado = parseFloat(eval(this.state.rangeReport['comparado'][id]))
            
            let value = 0
            if (numberFormat == "percentage") {
                value = ((analisado.toFixed(2) - comparado.toFixed(2))) 
            }else{
                value = ((analisado.toFixed(2) / comparado.toFixed(2)) * 100) - 100    
            }
            if(String(value) != "NaN"){
                let fValue = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value).toFixed(2))
                if (numberFormat == "percentage") {
                    if(analisado <=0 && comparado <=0){
                        fValue = parseFloat(fValue) * -1
                    }
                    // fValue = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value * -1).toFixed(2))
                    return `${fValue} pp`.replace('.', ',')
                }else{
                    return `${fValue}%`.replace('.', ',')    
                }
                
            }else{
                return "-"
            }
            
        }catch(e){
            console.log(e)
            return "-"
        }
    }

    fetchDataForRange(field, self=this){
        let isFilled = self.state.startDate[field] && self.state.endDate[field]
        
        
        if (isFilled) {
            axios.get('/formulas/borboleta.json',{params: {
                range: {start_at: self.state.startDate[field], end_at: self.state.endDate[field]},
                is_budget: field == "budget" ? true : null
            }}).then((report)=>{
                self.requestsCounter -= 1
                let rangeResponse= self.state.rangeReport
                rangeResponse[field] = report.data
                self.setState({requestsRunning: self.requestsCounter})    
                self.setState({rangeReport: rangeResponse})    
                self.setupChartData()
            })
            
        }
    }

    setStartDate(date, field){
        let self = this
        this.setState((oldState)=>{
            oldState.startDate[field] = date
            return {...oldState}
        })
        self.requestsCounter += 1
        setTimeout(function(){

            self.fetchDataForRange(field, self)    
        }, 100)
        
    }

    setEndDate(date, field){
        let self = this
        this.setState((oldState)=>{
            oldState.endDate[field] = date
            return {...oldState}
        })
        self.requestsCounter += 1
        setTimeout(function(){
            self.fetchDataForRange(field, self)    
        }, 100)
        
    }

    
    tryEval(str, number_format="money") {
        try {
          let formulaSolved = eval(str)
          if(number_format == "integer"){
            return formulaSolved ? (new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(formulaSolved * -1))) : "-"
          }
          if (number_format == "percentage") {
            return formulaSolved ? `${new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(formulaSolved).toFixed(2) * -1)}%`.replace('.', ',') : "-";
          }
          if (!number_format || number_format == "money") {
            return formulaSolved ? this.numberToCurrency(formulaSolved) : "-";  
          }
          
        } catch (e) {
            console.log(e)
          return 0
        }
      }
    handleOpen(scope, isRange=false){
        let self = this;
        let options = {
            budget: "Orçado",
            comparado: "Real"
        }
        
        this.setupChartData()
        setTimeout(function(){
            self.setState({
                comparedLabel: options[scope.replace("-range", "")],
                isRange: isRange,
                secondaryDataLabel: scope,
                modalIsOpen: true
            })
        }, 100)
        
        
    }
    handleClose(open){
        this.setState({modalIsOpen: false})
    }

    getTitle(){
        let {analisadoDate, 
            budgetDate, 
            comparadoDate, 
            startDate, 
            endDate, 
            comparedLabel, 
            secondaryDataLabel} = this.state

        let {months_dict} = this.props

        if(this.state.isRange){
            let primary_date_start = startDate['analisado']
            let primary_date_end = endDate['analisado']
            let secondary_date_start = startDate[secondaryDataLabel.replace('-range', '')]
            let secondary_date_end = endDate[secondaryDataLabel.replace('-range', '')]

            let primary_month_start = months_dict[primary_date_start.getMonth() + 1]
            let primary_month_end = months_dict[primary_date_end.getMonth() + 1]
            let secondary_month_start = months_dict[secondary_date_start.getMonth() + 1]
            let secondary_month_end = months_dict[secondary_date_end.getMonth() + 1]

            let primary_year = primary_date_start.getFullYear()
            let secondary_year = secondary_date_start.getFullYear()

            return {
                data1: `Real(${primary_month_start}-${primary_month_end}/${primary_year})`,
                data2: `${comparedLabel}(${secondary_month_start}-${secondary_month_end}/${secondary_year})`,
                data1_short: `${primary_month_start}-${primary_month_end}/${primary_year}`,
                data2_short: `${secondary_month_start}-${secondary_month_end}/${secondary_year}`
            }
        }else{
            let primary_date = analisadoDate
            let secondary_date = eval(`${secondaryDataLabel}Date`)

            let primary_month = months_dict[primary_date.getMonth() + 1]
            let secondary_month = months_dict[secondary_date.getMonth() + 1]

            let primary_year = primary_date.getFullYear()
            let secondary_year = secondary_date.getFullYear()

            return {
                data1: `Real(${primary_month}/${primary_year})`,
                data2: `${comparedLabel}(${secondary_month}/${secondary_year})`,
                data1_short: `${primary_month}/${primary_year}`,
                data2_short: `${secondary_month}/${secondary_year}`
            }

            
        }

        
    }
    //exportação url
    exportAllUrl(){
        let {analisadoDate, budgetDate, comparadoDate, startDate, endDate} = this.state

        let urlSafeParams = new URLSearchParams({
            "orcamento": budgetDate,
            "comparado": comparadoDate,
            "real": analisadoDate,
            "orcamento_start": startDate['budget'],
            "orcamento_end": endDate['budget'],
            "real_start": startDate['analisado'],
            "real_end": endDate['analisado'],
            "comparado_start": startDate['comparado'], 
            "comparado_end": endDate['comparado']
        })
        
       
        
        let export_url = `/export/borboleta_screen.xlsx?${urlSafeParams.toString()}`
        
        return export_url

    
    }

    // funcções incluidas por conta do gráfico
    async setLoadedYear(year, label, is_budget){
        await this.setState({loadedYears: {...this.state.loadedYears, ...{[`${year}-${label}-${is_budget}`]: true}}})
    }
    yearIsloaded(year, label, is_budget){
        return this.state.loadedYears[`${year}-${label}-${is_budget}`]
    }
    
    async fetchData(year=null, label, is_budget){

        this.setState({loading: this.state.loading + 1})
        let url = '/results/dre_cc.json'
        let result = axios.get(url, {params: {
            year,
            is_budget
        }}).then((result)=>{
            const {formulas, report, labels, areas, cost_center_grouped_by_month} = result.data
        
            this.setState({
              loading: this.state.loading - 1,
            })

            this.setState((oldState)=>{
              oldState.allYearsData[label] = {
                formulas,
                report,
                labels,
                costCenterAreas: areas,
                recordsFetched: {},
                costCenterGroupedByMonth: cost_center_grouped_by_month
              }
              return {...oldState}
            })

        })
        
    }
  // funcções incluidas por conta do gráfico
    



    onApply() {
      console.log("apply button clicked");
    }

    onCancel() {
      console.log("cancel button clicked");
    }

    onRender() {
      console.log("app just got rendered");
    }

    onYearChange(year) {
      console.log("year changed to", year);
    }

    async setComments(){
        let {
            analisadoDate,
            budgetDate,
            comparadoDate
        } = this.state

        let years = [analisadoDate.getFullYear(), budgetDate.getFullYear(), comparadoDate.getFullYear()]

        let comments = await axios.get('/comments.json', {params:{
          year: years
        }})  
        
        
        this.setState({comments: comments.data})
    }

    

    render() {

        const { months, report } = this.props;
        const { year, formulas, startDate, endDate, modalIsOpen, chart, chartData, analisadoDate, budgetDate, comparadoDate } = this.state;

        return (
        	<div>
                
        		<div className={"flex"} style={{justifyContent: 'space-between'}}>
        			<div style={{display: 'flex', alignItems: 'center'}}>
        				
        			</div>	
        		</div>
    			<div className="flex">
    			    <div className="bg-content flex-1 with-header">
    			        
    			        <React.Fragment>
                            <div className="header" style={{display: 'flex', justifyContent: 'space-between' }}>
                              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20 }}>
                                {!isMobile() && <div
                                    onClick={()=> popupCenter({url: '/popups/borboleta',title: 'Editar', w: window.innerWidth * 0.8, h: window.innerHeight * 0.8})} 
                                    style={{backgroundColor: '#F5F5F5', 
                                             width: 36, 
                                             height: 36,
                                             borderRadius: 4,
                                             display: 'flex',
                                             justifyContent: 'center',
                                             alignItems: 'center'}}>
                                    <img src={popupIco} style={{width: 14}}></img>
                                </div>}
                                <div className="label" style={{marginRight: 16}}>VISÃO BORBOLETA</div>
                              </div>
                              <form className="form" style={{paddingRight: 35}}>
                                    <a href={`${this.exportAllUrl()}`}>
                                        <img src={downloadIco} style={{width: 36}}></img>
                                    </a>
                              </form>
                            </div>
                            <div>
                                <small style={{fontSize: 10, padding: "10px 0 0 43px", display: 'block', color: 'grey'}}>* Você está visualizando números no formato n/{this.props.divisor}</small>
                            </div>
                            <div id="table-result-wrapper">
                              
                              <div className="content padding-40">
                                <div id={"borboleta"}>
                                    <div className={'lateral-left'}>
                                        <h3>MÊS CORRENTE</h3>
                                        <table>
                                            <thead class="filter-head" style={{zIndex: 8}}>
                                                <tr>
                                                    <th>
                                                        <div class="input date">
                                                            <label class="integer optional" for="balance_sheet_year">
                                                                Real 
                                                            </label>
                                                            
                                                            {/*<DatePicker
                                                                locale="pt"
                                                                selected={analisadoDate}
                                                                onChange={date => this.fetchReport(date, null, 'analisado')}
                                                                selectsStart
                                                                dateFormat="MM/yyyy"
                                                                showMonthYearPicker
                                                              />*/}
                                                              {analisadoDate && 
                                                                    <MonthRangeInput
                                                                    isFirst={true}
                                                                    date={analisadoDate}
                                                                    onChange={(date) => {
                                                                        this.fetchReport(date, null, 'analisado', false)
                                                                    }}
                                                                  ></MonthRangeInput>}
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="input date">
                                                            <label class="integer optional" for="balance_sheet_year">
                                                                Orçamento
                                                            </label>
                                                            
                                                              {budgetDate && 
                                                                    <MonthRangeInput
                                                                    date={budgetDate}
                                                                    onChange={(date) => {
                                                                        this.fetchReport(date, true, 'budget')
                                                                    }}
                                                                  ></MonthRangeInput>}
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="input date">
                                                            <label class="integer optional" for="balance_sheet_year">
                                                                Comparado
                                                            </label>
                                                            
                                                              {comparadoDate && 
                                                                    <MonthRangeInput
                                                                    date={comparadoDate}
                                                                    onChange={(date) => {
                                                                        this.fetchReport(date, null, 'comparado')
                                                                    }}
                                                                  ></MonthRangeInput>}
                                                        </div>
                                                    </th>
                                                    <th style={{width: 100}}>
                                                        <a style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=> this.handleOpen("budget")}>
                                                            <img style={{width: 20, heigh: 20, marginRight: 5}} src={graphIco} />
                                                             Orçamento
                                                        </a>
                                                    </th>
                                                    <th style={{width: 100}}>
                                                        <a style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=> this.handleOpen("comparado")}>
                                                            <img style={{width: 20, heigh: 20, marginRight: 5}} src={graphIco} />
                                                             Comparado
                                                        </a>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formulas.map((formula)=>(
                                                    <tr data-id={`formula-${formula.id}`} className={` formula-${formula.formula_type}-${formula.layout} formula-${formula.is_only_index ? "only-index" : "more-than-index"}  ${formula.formula_type === 'formula' ? 'green-text' : ''}`}>
                                                        <td className={'comment-wrapper'}>
                                                            {this.state.comments && <Comment 
                                                              refreshComments={()=> this.setComments()}
                                                              comments={this.state.comments}
                                                              model_reference={'formula'}
                                                              month={analisadoDate.getMonth() + 1}
                                                              year={analisadoDate.getFullYear()}
                                                              model_reference_id={formula.id}>
                                                            </Comment>}
                                                            { this.state.report['analisado'][formula.fr_id] ? this.tryEval(this.state.report['analisado'][formula.fr_id], formula.number_format) : 0}
                                                        </td>
                                                        <td className={'comment-wrapper'}>
                                                            {this.state.comments && <Comment 
                                                              refreshComments={()=> this.setComments()}
                                                              comments={this.state.comments}
                                                              model_reference={'formula-orcamento'}
                                                              month={budgetDate.getMonth() + 1}
                                                              year={budgetDate.getFullYear()}
                                                              model_reference_id={formula.id}>
                                                            </Comment>}
                                                            { this.state.report['budget'][formula.fr_id] ? this.tryEval(this.state.report['budget'][formula.fr_id], formula.number_format) : 0}
                                                        </td>
                                                        <td className={'comment-wrapper'}>
                                                            {this.state.comments && <Comment 
                                                              refreshComments={()=> this.setComments()}
                                                              comments={this.state.comments}
                                                              model_reference={'formula'}
                                                              month={comparadoDate.getMonth() + 1}
                                                              year={comparadoDate.getFullYear()}
                                                              model_reference_id={formula.id}>
                                                            </Comment>}
                                                            { this.state.report['comparado'][formula.fr_id] ? this.tryEval(this.state.report['comparado'][formula.fr_id], formula.number_format) : 0}
                                                        </td>
                                                        <td>{this.orcadoVsAnalisado(formula.fr_id, formula.number_format)}</td>
                                                        <td>{this.analisadoVsComparado(formula.fr_id, formula.number_format)}</td>
                                                        
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={'central'} style={{marginTop: 141}}>
                                        <table>
                                            <tbody>
                                                {formulas.map((formula)=>{
                                                    

                                                    return <tr data-id={`formula-${formula.id}`} className={` formula-${formula.formula_type}-${formula.layout} formula-${formula.is_only_index ? "only-index" : "more-than-index"} central-row ${formula.formula_type === "formula" ? 'green-text' : ''}`}>
                                                        <div style={{display: 'inline-block'}}>
                                                        <BorboletaFormulaChart
                                                          marginRight={6}
                                                          formula_id={formula.label}
                                                          title={formula.label} 
                                                          dataKey={"result"}
                                                          months={this.props.months}
                                                          allYearsData={this.state.allYearsData}
                                                          ></BorboletaFormulaChart>
                                                        </div>
                                                        {formula.label}
                                                         
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={'lateral-right'}>
                                        <h3>YTD</h3>
                                        <table>
                                            <thead class="filter-head">
                                                <tr>
                                                    <th>
                                                        <div class="input date">
                                                            <label class="integer optional" for="balance_sheet_year">
                                                                Real
                                                            </label>
                                                            <div className="daterange">
                                                                {/*<DatePicker
                                                                    locale="pt"
                                                                    selected={startDate['analisado']}
                                                                    onChange={date => this.setStartDate(date, "analisado")}
                                                                    selectsStart
                                                                    startDate={startDate['analisado']}
                                                                    endDate={endDate['analisado']}
                                                                    dateFormat="MM/yyyy"
                                                                    showMonthYearPicker
                                                                  />
                                                                  <DatePicker
                                                                    locale="pt"
                                                                    selected={endDate['analisado']}
                                                                    onChange={date => this.setEndDate(date, "analisado")}
                                                                    selectsEnd
                                                                    startDate={startDate['analisado']}
                                                                    endDate={endDate['analisado']}
                                                                    dateFormat="MM/yyyy"
                                                                    showMonthYearPicker
                                                                  />*/}
                                                                  {startDate['analisado'] && 
                                                                    <MonthRangeInput
                                                                    startDate={startDate['analisado']}
                                                                    endDate={endDate['analisado']}
                                                                    onChange={(startDate, endDate) => {
                                                                        this.setStartDate(startDate, "analisado")
                                                                        this.setEndDate(endDate, "analisado")
                                                                    }}
                                                                  ></MonthRangeInput>}
                                                              </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="input date">
                                                            <label class="integer optional" for="balance_sheet_year">
                                                                Orçamento
                                                            </label>
                                                            <div className="daterange">
                                                            {startDate['budget'] && 
                                                                    <MonthRangeInput
                                                                    startDate={startDate['budget']}
                                                                    endDate={endDate['budget']}
                                                                    onChange={(startDate, endDate) => {
                                                                        this.setStartDate(startDate, "budget")
                                                                        this.setEndDate(endDate, "budget")
                                                                    }}
                                                                  ></MonthRangeInput>}
                                                              </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="input date">
                                                            <label class="integer optional" for="balance_sheet_year">
                                                                Comparado
                                                            </label>
                                                            <div className="daterange">
                                                            {startDate['comparado'] && 
                                                                    <MonthRangeInput
                                                                    startDate={startDate['comparado']}
                                                                    endDate={endDate['comparado']}
                                                                    onChange={(startDate, endDate) => {
                                                                        this.setStartDate(startDate, "comparado")
                                                                        this.setEndDate(endDate, "comparado")
                                                                    }}
                                                                  ></MonthRangeInput>}
                                                            </div>
                                                        </div>
                                                    </th>
                                                     <th style={{width: 100}}>
                                                        <a style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=> this.handleOpen("budget-range", true)}>
                                                            <img style={{width: 20, heigh: 20, marginRight: 5}} src={graphIco} />
                                                             Orçamento
                                                        </a>
                                                    </th>
                                                    <th style={{width: 100}}>
                                                        <a style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=> this.handleOpen("comparado-range", true)}>
                                                            <img style={{width: 20, heigh: 20, marginRight: 5}} src={graphIco} />
                                                             Comparado
                                                        </a>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formulas.map((formula)=>(
                                                    <tr data-id={`formula-${formula.id}`} className={`  formula-${formula.formula_type}-${formula.layout} formula-${formula.is_only_index ? "only-index" : "more-than-index"} ${formula.formula_type === 'formula' ? 'green-text' : ''}`}>
                                                        <td>{ this.state.rangeReport['analisado'][formula.fr_id] ? this.tryEval(this.state.rangeReport['analisado'][formula.fr_id], formula.number_format) : 0}</td>
                                                        <td>{ this.state.rangeReport['budget'][formula.fr_id] ? this.tryEval(this.state.rangeReport['budget'][formula.fr_id], formula.number_format) : 0}</td>
                                                        <td>{ this.state.rangeReport['comparado'][formula.fr_id] ? this.tryEval(this.state.rangeReport['comparado'][formula.fr_id], formula.number_format) : 0}</td>
                                                        <td>{this.rangeOrcadoVsAnalisado(formula.fr_id, formula.number_format)}</td>
                                                        <td>{this.rangeAnalisadoVsComparado(formula.fr_id, formula.number_format)}</td>
                                                        
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
    			        
    			    </div>
    			         
    			</div>
                {modalIsOpen && <BorboletaChart title={this.getTitle()} 
                                                chartTitle={this.state.chart_title}
                                                chart={this.state.chart_formulas} 
                                                comparedLabel={this.state.comparedLabel}
                                                chartData={chartData}
                                                startDate={startDate}
                                                endDate={endDate}
                                                isRange={this.state.isRange}
                                                principalDataLabel={this.state.isRange ? "analisado-range" : "analisado"}
                                                secondaryDataLabel={this.state.secondaryDataLabel}  
                                                cost_center_heads={this.props.cost_center_heads}
                                                open={modalIsOpen} 
                                                analisadoDate={analisadoDate}
                                                budgetDate={budgetDate}
                                                comparadoDate={comparadoDate}
                                                divisor={this.props.divisor}
                                                indicatorsMap={this.props.indicatorsMap}
                                                handleClose={this.handleClose.bind(this)}/>}
            <LoadingDialog open={this.requestsCounter > 0}/>
        </div>);
      }
}
// check if screen is mobile and if is android
export const isMobile = () => {
    return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );
}
export default Borboleta;



