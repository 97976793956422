import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Modal } from 'react-responsive-modal';
import { Title } from '../../../styled_components/default.js';

export default function DrillDownDailyMovement(props) {
  const { 
    details_chart_contabil_pattern_param, 
    keyDataTable, 
    onClose, 
    uploadDate, 
    currentTime,
    currencyOptions
  } = props;

  const [highlighted, setHighlighted] = useState('');
  const [showDrillDownRow, setShowDrillDownRow] = useState({});
  const [sumKey, setSumKey] = useState({});

  useEffect(() => {
    if (details_chart_contabil_pattern_param && keyDataTable) {
      const sumKeyObject = {};
      Object.entries(details_chart_contabil_pattern_param[keyDataTable]).forEach(([linesKey, value]) => {
        sumKeyObject[linesKey] = sumKeyObject[linesKey] || { previsto: 0, realizado: 0 };
        Object.values(value).forEach((v) => {
          sumKeyObject[linesKey].previsto += linesKey === "saida" ? v.pago_previsto : v.recebido_previsto;
          sumKeyObject[linesKey].realizado += linesKey === "saida" ? v.pago : v.recebido;
        });
      });
      setSumKey(sumKeyObject);
    }
  }, [details_chart_contabil_pattern_param, keyDataTable]);

  const handleMouseOver = (description) => setHighlighted(description);
  const handleMouseOut = () => setHighlighted('');

  const handleRowClick = (key) => {
    setShowDrillDownRow(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const isEven = (key) => key % 2 === 0 ? '#f2f2f2' : 'transparent';

  const renderRowHead = (dataAll, dataKey) => {
    const groupedData = dataAll.reduce((acc, item) => {
      const label = item.contail_pattern_param_label;
      acc[label] = acc[label] || 0;
      const value = currentTime <= uploadDate ? (dataKey === 'entrada' ? item.recebido : item.pago) : (dataKey === 'entrada' ? item.recebido_previsto : item.pago_previsto);
      acc[label] += value;
      return acc;
    }, {});

		const sortedGroupedData = Object.entries(groupedData).sort((a, b) => {
			const valueA = a[1];
			const valueB = b[1];

			if (valueA < 0 && valueB < 0) {
				return valueA - valueB;
			} else if (valueA >= 0 && valueB >= 0) {
				return valueB - valueA;
			} else {
				return valueB - valueA;
			}
		});;

    return sortedGroupedData.map(([key, value], index) => (
      <React.Fragment key={key}>
        <tr
          style={{ cursor: 'pointer', backgroundColor: highlighted === value + key ? '#F9CC35' : isEven(index) }}
          onMouseOver={() => handleMouseOver(value + key)}
          onMouseOut={handleMouseOut}
          onClick={() => handleRowClick(key)}
        >
          <th colSpan={4} style={{ color: '#617e94', paddingLeft: 35 }}>
            {showDrillDownRow[key] ? `▼ ${key}` : `▶ ${key}`}
          </th>
          <th colSpan={4} style={{ color: '#617e94', paddingLeft: 35 }}></th>
          <th style={{ color: '#617e94', textAlign: 'right' }}>
            {value.toLocaleString('pt-BR', currencyOptions)}
          </th>
        </tr>
        {showDrillDownRow[key] && renderRow(dataAll.filter(item => item.contail_pattern_param_label === key), key)}
      </React.Fragment>
    ));
  };

  const renderRow = (data, dataKey) => {
    return data.map((item, index) => (
      <tr
        key={dataKey + index}
        style={{ backgroundColor: highlighted === item.descricao + index ? '#F9CC35' : isEven(index) }}
        onMouseOver={() => handleMouseOver(item.descricao + index)}
        onMouseOut={handleMouseOut}
      >
        <th colSpan={4} style={{ color: '#617e94', textAlign: 'center' }}>
          {item.descricao}
        </th>
        <th colSpan={4} style={{ color: '#617e94' }}>
          {item.provider}
        </th>
        <th colSpan={4} style={{ color: '#617e94', textAlign: 'right' }}>
          {captureValues(item)}
        </th>
      </tr>
    ));
  };

  const captureValues = (item) => {
    let value = 0;
    if (item.ent_or_sai === "saida") {
      value = item.pago != 0 ? item.pago : item.pago_previsto
    } else {
      value = item.recebido != 0 ? item.recebido : item.recebido_previsto
    }

    return value.toLocaleString('pt-BR', currencyOptions)
  }

  if (!sumKey || Object.keys(sumKey).length === 0) {
    return <Skeleton height={200} />;
  }

  return (
    <Modal open={true} onClose={onClose} center>
      <Title>Detalhamento Entrada/Saída</Title>
      <div className="table-chart-container table-responsive" style={{ width: 1000 }}>
        <table className="table">
          <thead>
            <tr>
              <td colSpan={4} style={{ fontSize: 16 }}>{`Dia ${keyDataTable?.toString()}`}</td>
              <td colSpan={4} style={{ fontSize: 14 }}>Cliente/Fornecedor</td>
              <td colSpan={4} style={{ fontSize: 16 }}>Valor</td>
            </tr>
          </thead>
          <tbody>
            {keyDataTable && Object.keys(details_chart_contabil_pattern_param[keyDataTable]).map((data, index) => (
              <React.Fragment key={`${data}-${index}`}>
                <tr
                  style={{ cursor: 'pointer', backgroundColor: highlighted === data + index ? '#F9CC35' : isEven(index) }}
                  onMouseOver={() => handleMouseOver(data + index)}
                  onMouseOut={handleMouseOut}
                  onClick={() => handleRowClick(keyDataTable + data)}
                >
                  <td colSpan={4}>
                    {showDrillDownRow[data] ? data === "saida" ? " ▼  Despesas" : " ▼  Receitas" : data === "saida" ? " ▶  Despesas" : " ▶  Receitas"}
                  </td>
                  <td style={{ textAlign: 'right' }}></td>
                  <td colSpan={4} style={{ textAlign: 'right' }}>
                    {currentTime <= uploadDate ? sumKey[data].realizado.toLocaleString('pt-BR', currencyOptions) : sumKey[data].previsto.toLocaleString('pt-BR', currencyOptions)}
                  </td>
                </tr>
                {showDrillDownRow[keyDataTable + data] && renderRowHead(details_chart_contabil_pattern_param[keyDataTable][data], data)}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
