import React from "react";
import LoadingDialog from './LoadingDialog'

export default class Loading extends React.Component {
    constructor(props) {
        super(props)
        this.requestsCounter = 0
    }

    render(){
        const loading = this.props.loading
        console.log(window.location.hash.toString());
        console.log(window.location.hash.toString() === '#loading=true');
        if( window.location.hash.toString() === '#loading=true'){
            this.requestsCounter = 1
        }

        return (
            <>
                <LoadingDialog open={this.requestsCounter > 0}/>
            </>
        );
    }

}