import React, { useState } from "react";
import WidgetPercent from "./WidgetPercent";
import TotalBalanceMonthBarChart from "./TotalBalanceMonthBarChart";
import TotalBalancePieChart from "./TotalBalancePieChart";
import BankList from "../BankList"
import { Space, HiddenOptions, HiddenLinkWrapper, HiddenLink, FlexBetween, Flex } from "../../styled_components/default"
import ClientReport from "./ClientReport";

export default function Card ({
	title, 
	amount, 
	color, 
	subTitlePercent, 
	subTitlePercentPrev,
	balanceProvided,
	balanceReal,
	colorPercent,
	typeGraph, 
	dataChart,
  icon, 
  colorIcon
}) {
	const [configMenuVisibility, setConfigMenuVisibility] = useState(false);
	const [clientReportVisible, setClientReportVisible] = useState(false);

  const toggleConfigMenuVisibility = () => {
    setConfigMenuVisibility(!configMenuVisibility);
  };

	const showClientReport = () => {
    setClientReportVisible(true);
    setConfigMenuVisibility(false);
  };

	const closeClientReport = () => {
    setClientReportVisible(false);
  };

	return(
			<div>
				<div className="card border-0" style={{backgroundColor: color, padding: 13}}>
					<div>
						<div className="row" style={{marginBottom: 25}}>
							<div className="col-9 text-left">
								<div className="float-left" style={{width: '100%'}}>
                  <div style={{display: 'flex'}}>
                    <img src={icon} style={{width: typeGraph === 'pie' ? 30 : 35, marginTop: typeGraph === 'pie' ? -9 : '' }} />
                    <div style={{ marginLeft: 10}}>
                      <p style={{marginBottom: 1, color: 'white', fontWeight: 500}}>
                        {title}
                      </p>
                      <p className="m-0 title" style={{ fontSize: 22}}>{amount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                    </div>
                  </div>
								</div>
							</div>
							{subTitlePercent != "Conta" ? (
								<>
									<div className='options-icon-widgets' onClick={toggleConfigMenuVisibility}>
										<i className={'fa fa-ellipsis-h'} style={{ color: colorIcon, marginTop: '2px' }} />
									</div>
									{configMenuVisibility && (
										<HiddenOptions alignRight={true} top={50}>
											
                      {typeGraph !== "pie"
                        ? (
                          <>
                            {subTitlePercent === 'Recebidos' ? (
                              <HiddenLink  href="/results?current_tab=detailing">Aging List</HiddenLink>
                            ) : ''}

                            <HiddenLinkWrapper onClick={showClientReport}>
                              {subTitlePercent === 'Recebidos' ? 'Relatório de Clientes' : 'Relatório de Fornecedores'}
                            </HiddenLinkWrapper>
                          </>
                        ) :  (
                          <BankList data={dataChart}/>
                        )
                      }
										</HiddenOptions>
									)}
								</>
							) : (
								''
							)}

						</div>
          </div>
          <FlexBetween style={{alignItems: 'center', height: 95}}>
            <WidgetPercent 
              balance={balanceReal} 
              balanceNext={balanceProvided} 
              total={amount} 
              color={colorPercent}
              title={subTitlePercent}
              subtitle={subTitlePercentPrev}
            />
            {typeGraph === 'pie' ? (
              <TotalBalancePieChart application={balanceProvided} currentAccount={balanceReal} totalBalance={amount} />			
            ) : (
              <TotalBalanceMonthBarChart  color={colorPercent} data={dataChart}/>
            )
            }
          </FlexBetween>
          {clientReportVisible &&
            <ClientReport
              subTitlePercent={subTitlePercent}
              isOpen={clientReportVisible}
              onClose={closeClientReport} />
          }
        </div>
			</div>
	)
}
